import React, { Component } from 'react'
import { connect } from 'react-redux'

import { fill, concat, get, pick } from 'lodash'

import CustomInput from '../../common/components/CustomInput'
import { CloseIcon } from '../../common/SvgIcons'

import { selectUnit, selectSubUnit, resetFilterSetting } from '../data/projectViewConfig.actions'

class ProjectFilterChoice extends Component {

  /**
   * working on new dropdown component for project filter
   * not completed yet
   */

  static defaultProps = {
    min_li_count: 7,
    total_col: 2
  }

  state = {
    showDropdown: false
  }

  showQueryInterection = (event) => {
    this.setState({ showDropdown: true })
  }

  hideDropdown = () => {
    this.setState({ showDropdown: false })
  }

  handleAllUnitClick = () => {
    this.props.resetFilterSetting(this.props.projectId)
  }

  handleUnitClick = (unit, subunitList = []) => {
    // reducer
    this.props.selectUnit(
      this.props.projectId, // project Id
      pick(unit, ['id', 'name']), // unit data
      subunitList, // subunit list
    )
  }

  handleSubunitClick = (subunit) => {
    // reducer
    this.props.selectSubUnit(
      this.props.projectId, // project Id
      pick(subunit, ['id', 'name']), // subunit data
      this.props.projectViewConfig.subunitList, // subunit list
    )
    this.hideDropdown()
  }

  render = () => {
    const { total_col, projectViewConfig } = this.props
    const { showDropdown } = this.state

    const col_width = { width: `${100 / total_col}%` }
    const displayText = `${projectViewConfig.selectedUnit.name} > ${projectViewConfig.selectedSubunit.name}`

    return (
      <div className="chain-selection-dropdown">
        <CustomInput label=""
          type="text" className="input-light-grey textbox"
          placeholder="Select report choice"
          onFocus={(event) => this.showQueryInterection(event)}
          value={displayText}
          readOnly={true}
        />
        {(showDropdown) &&	// show close button for drop down
          <>
            <div className="icon-wrapper" onClick={this.hideDropdown}>
              <CloseIcon />
            </div>
            <div className="overlay" onClick={this.hideDropdown} />
          </>
        }

        <div className={`dropdown-wrapper left scale-transition ${showDropdown ? '' : 'scale-out'}`} >
          <div className='dropdown-container'>
            <div className="listview-header">
              <div className="textbox-wrapper header" style={{ ...col_width, paddingLeft: '1.5em' }}>
                Blocks
              </div>
              <div className="textbox-wrapper header" style={col_width} >
                Floors
              </div>
            </div>
            <div className='listview-table'>
              {this.renderUnitList(col_width)}
              {this.renderFloorList(col_width)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderUnitList = (col_width) => {
    let { min_li_count, projectDetails, projectViewConfig } = this.props
    // first pill for all blocks
    min_li_count = min_li_count - 1
    let unitList = get(projectDetails, 'units', [])

    // add buffer for better design
    if (unitList.length < min_li_count) {
      const buffer_array = fill(Array(min_li_count - unitList.length), "")
      unitList = concat(unitList, buffer_array)
    }

    return (
      <div className="listview-content" style={col_width}>
        <div className={`listview-tabs ${projectViewConfig.selectedUnit.id === -1 ? 'active' : ''}`}
          onClick={this.handleAllUnitClick} >
          All Blocks
          <div className='listview-arrow'>
            <span className="icon icon-play" />
          </div>
        </div>
        {unitList.map((unit, i) => {
          if (unit === "") {
            // just a buffer li for design purpose only
            return <div className='listview-tabs' key={i} style={{ cursor: 'default' }} />
          }
          else {
            return (
              <div key={i} className={`listview-tabs ${unit.id === projectViewConfig.selectedUnit.id ? 'active' : ''}`}
                onClick={this.handleUnitClick.bind(this, unit, unit.subunits)} >
                {unit.name}
                <div className='listview-arrow'>
                  <span className="icon icon-play" />
                </div>
              </div>
            )
          }
        })}
      </div>
    )
  }

  renderFloorList = (col_width) => {

    const { min_li_count, projectViewConfig } = this.props

    let list_data = [...projectViewConfig.subunitList]
    // add buffer for better design
    if (list_data.length < min_li_count) {
      const buffer_array = fill(Array(min_li_count - list_data.length), "")
      list_data = concat(list_data, buffer_array)
    }

    return (
      <div className="listview-content" style={col_width}>
        {list_data.map((subunit, i) => {
          if (subunit === "") {
            // just a buffer li for design purpose only
            return <div className='listview-tabs' key={i} style={{ cursor: 'default' }} />
          }
          else {
            return (
              <div key={i} className={`listview-tabs ${subunit.id === projectViewConfig.selectedSubunit.id ? 'active' : ''}`}
                onClick={this.handleSubunitClick.bind(this, subunit)} >
                {subunit.name}
              </div>
            )
          }
        })}
      </div>
    )
  }
}


export default connect(store => {
  const projectId = store.projectDetails.projectDetails.id
  const currSetting = store.projectViewConfig[projectId]
  return ({
    projectId: projectId,
    projectDetails: store.projectDetails.projectDetails,
    projectViewConfig: currSetting,
  })
}, { selectUnit, selectSubUnit, resetFilterSetting })(ProjectFilterChoice)