import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { get, find } from "lodash";

import QualityChecklist from "./QualityChecklist";

import Urls from "../../../UrlConfig";

/**
 * Scss:
 *      _project_quality_list
 * Common:
 *      _common_page_layout
 */

class QualityChecklistPopup extends Component {
  /**
   * wrapper component,
   * show loading while fetching all checklist data from server,
   * find single check data by selected check id
   *
   * manage loading, error
   *
   * Renders:
   *      QualityChecklistPopupConnect
   */

  handleImageClick = (imageId) => {
    const { history, projectId } = this.props;
    history.push(
      Urls.getProjectDetails(projectId, "quality", imageId, "show_qa_image")
    );
  };

  render = () => {
    const { fetched, fetchError, checkDetails, projectId } = this.props;

    let content;

    if (fetched) {
      let activityLabel = "";
      if (get(checkDetails, "subactivity.name", "")) {
        activityLabel = `${get(checkDetails, "activity.name", "")} - ${get(
          checkDetails,
          "subactivity.name",
          ""
        )}`;
      } else {
        activityLabel = get(checkDetails, "activity.name", "");
      }

      let unitLabel = "";
      if (get(checkDetails, "subunit.name", "")) {
        unitLabel = `${get(checkDetails, "unit.name", "")} , ${get(
          checkDetails,
          "subunit.name",
          ""
        )}`;
      } else {
        unitLabel = get(checkDetails, "unit.name", "");
      }

      content = (
        <div>
          <div className="check-name-content">
            <div className="check-name">{activityLabel}</div>
            <div className="check-subname">
              {unitLabel}
              {checkDetails.name && <span> ({checkDetails.name})</span>}
            </div>
          </div>
          {this.renderQualityImages()}

          <div className="checkitems-wrapper">
            <h2 className="checkitem-title">Checklist</h2>
            <QualityChecklist checkDetails={checkDetails} />
          </div>
        </div>
      );
    } else if (fetchError) {
      content = <div className="error-block big">Failed to fetch data</div>;
    } else {
      return null;
    }

    return (
      <div className="page-model">
        <div className="page-model-wrapper">
          <div className="page-model-cell">
            <div className="page-model-content pd-check-popup">
              <div className="model-form">
                <Link
                  to={Urls.getProjectDetails(projectId, "quality")}
                  className="close-icon-wrapper"
                >
                  <div className="close-icon"></div>
                </Link>
                <div className="title">Quality Details</div>
                {content}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderQualityImages = () => {
    const { checkDetails } = this.props;

    if (checkDetails.quality_images.length) {
      return (
        <div className="check-images">
          <div className="check-img-title">Quality Images</div>
          <div className="check-images-wrapper">
            {checkDetails.quality_images.map((checkImg) => {
              return (
                <div className="check-img-block" key={checkImg.id}>
                  <img
                    className="check-img"
                    src={checkImg.image}
                    alt=""
                    onClick={() => this.handleImageClick(checkImg.id)}
                  />
                  {checkImg.caption && (
                    <div className="check-img-info blockquote">
                      <div className="value">{checkImg.caption}</div>
                    </div>
                  )}
                  <div className="check-img-info">
                    <div className="value author">
                      - {get(checkImg, "uploaded_by.fullname")}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };
}

export default connect((store, props) => {
  const checkDetails = find(store.checklist.checklist, [
    "id",
    Number(props.checkId),
  ]);
  return {
    projectId: store.projectDetails.projectDetails.id,
    fetched: store.checklist.fetched,
    fetchError: store.checklist.fetchError,
    checkDetails,
  };
})(QualityChecklistPopup);
