import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import store from '../../store';

import { CloseIcon, CheckIcon, ExclamationMark } from '../SvgIcons'

/**
 * Scss:
 * 		_notification.scss
 */

class NotificationList extends React.Component {

	renderNotification = () => {
		if (this.props.count > 0) {
			return (
				this.props.notifications.map((notification, index) => {

					return (
						<NotificationDetail key={notification.title}
							type={notification.type}
							icon={notification.icon}
							title={notification.title}
							text={notification.text}
							timeout={notification.timeout}
						/>
					)
				})
			)
		}
	}

	render = () => {
		return (
			<div className="notification">
				{this.renderNotification()}
			</div>
		)
	}
}

export default connect((store) => {
	return {
		notifications: store.notifications.notifications,
		count: store.notifications.noti_count,
	};
})(NotificationList)

class NotificationDetail extends React.Component {

	constructor(props) {
		super(props);

		const timeout = props.timeout ? props.timeout : 5000;
		this.state = {
			timeout,
		}

		this.removeNotification = this.removeNotification.bind(this);
	}

	componentDidMount = () => {
		// remove notification automatically after 5 secs (default)
		const _this = this;
		this.timerFunc = setTimeout(() => {
			// console.log(_(this.props)
			_this.removeNotification();
		}, this.state.timeout);
	}

	removeNotification = () => {
		store.dispatch({
			type: 'REMOVE_NOTIFICATION',
			payload: this.props.title,
		})
	}

	/**
	 * render icon if pass in payload otherwise default icons based on type
	 * @param {*} icon - svg or html element
	 */
	renderIcon = (icon) => {
		if (icon) {
			return icon
		}
		else {
			let content
			switch (this.props.type) {
				case 'error':
					content = <CloseIcon />
					break;

				case 'success':
					content = <CheckIcon />
					break;

				case 'info':
					content = <ExclamationMark />
					break;

				case 'warning':
					content = <ExclamationMark />
					break;

				default:
					break;
			}
			return content
		}
	}

	render = () => {
		return (
			<div className={`content ${this.props.type}`}>
				<div className="icon">
					{this.renderIcon(this.props.icon)}
				</div>

				<div className="text">
					<div className="title"><b>{this.props.title}</b></div>
					{this.props.text && <div>{this.props.text}</div>}
				</div>

				<div className="cancel" onClick={this.removeNotification}>
					<CloseIcon />
				</div>
			</div>
		)
	}
}

NotificationDetail.propTypes = {
	// type of notification "success(info) | warning | error"
	type: PropTypes.string.isRequired,
	// fa icon to show on notification
	icon: PropTypes.any,

	title: PropTypes.string.isRequired,
	text: PropTypes.string,
	timeout: PropTypes.number,
};