import React, { Component } from 'react'

import CustomInput from '../../common/components/CustomInput'
import ErrorBlock from '../../common/components/ErrorBlock'
import LoadingButton from '../../common/components/LoadingButton'

import { addNotification } from '../../common/data/common.actions'
import { postFormData } from '../../common/utils'
import Urls from '../../UrlConfig'


/**
 * Scss:
 *      _profile_page
 * Common:
 *      _common_page_layout, form
 */
export default class ChangePassword extends Component {
    /**
     * handle change password form
     * 
     * Parent:
     *      ProfilePage
     */

    state = {
		loading: false,
		errors: {}
    }
    
    onFormSubmit = (event) => {
        if(this.state.loading) return
        
        event.preventDefault()
        
        let form = document.getElementById("change-password")
        const data = new FormData(form)
        
        this.setState({ loading: true, errors: {} })
        postFormData(Urls.apiChangePassword(), 
			//data
			data,
			// callback
			(res) => {
                this.setState({ loading: false, errors: {} })
                form.reset()
                this.props.dispatch(addNotification({
                    type: 'success',
                    title: 'Change Password',
                    text: 'Change Password Successfully',
				}))
			}, 
			// errback
			(err) => {
                this.setState({ loading: false, errors: err.error_data })
                this.props.dispatch(addNotification({
                    type: 'error',
                    title: 'Change Password',
                    text: 'Input error',
				}))
			},
		)

    }
    
    render = () => {
        const { loading, errors } = this.state

        return (
            <form id="change-password">
                <div className="title">Change Password</div>

                <CustomInput
                    label="Current Password"
                    errorData={errors}
                    errorField="current_password"
                    type="password"
                    name="current_password"
                    placeholder="Enter current password"
                />
                <CustomInput
                    label="New Password"
                    errorData={errors}
                    errorField="new_password"
                    type="password"
                    name="new_password"
                    placeholder="Enter New password"
                />
                <CustomInput
                    label="Confirm Password"
                    errorData={errors}
                    errorField="confirm_password"
                    type="password"
                    name="confirm_password"
                    placeholder="Enter Confirm password"
                />

                <ErrorBlock error={errors} field="__all__"/>

                <div className="btn-wrapper">
                    <LoadingButton className="btn primary-btn" title="Change Password"
                        isLoading={loading} onClick={this.onFormSubmit} />
                </div>

            </form>
        )
    }
}
