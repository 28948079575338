import { postJsonData } from '../../common/utils'
import Urls from '../../UrlConfig'

/**
 * 
 * @param {*} data : { projectId, reportType, reportChoice }
 */
export const getStructureReportData = (data) => {

  return async dispatch => {
    dispatch({ type: 'REPORT_DATA_FETCHING', payload: data })

    postJsonData(Urls.apiGetProjectReportData(data.projectId), {},
      (res) => {
        dispatch({ type: 'REPORT_DATA_SUCCESS', payload: res })
      },
      (err) => {
        // console.log("-> err", err)
        dispatch({ type: 'REPORT_DATA_ERROR', payload: err })
      }
    )
  }
}

/**
 * 
 * @param {*} data : { projectId, reportType, reportChoice }
 */
export const postActivityReportData = (data) => {

  return async dispatch => {
    dispatch({ type: 'REPORT_DATA_FETCHING', payload: data })
    /**
     * Note : if frontend not send planning_list, data will be fetch by backend
     *      file_type: if file_type provide, response will be blob, otherwise json data
     */
    // here we send empty data to get json data from server
    postJsonData(Urls.apiGetProjectActivityReportData(data.projectId), {},
      (res) => {
        dispatch({ type: 'REPORT_DATA_SUCCESS', payload: res })
      },
      (err) => {
        // console.log("-> err", err)
        dispatch({ type: 'REPORT_DATA_ERROR', payload: err })
      }
    )
  }
}

/**
 * 
 * @param {*} data : { projectId, reportType, reportChoice }
 */
export const getProjectQualityReportData = (data) => {

  return async dispatch => {
    dispatch({ type: 'REPORT_DATA_FETCHING', payload: data })

    postJsonData(Urls.apiGetProjectQualityReportData(data.projectId), {},
      (res) => {
        dispatch({ type: 'REPORT_DATA_SUCCESS', payload: res })
      },
      (err) => {
        // console.log("-> err", err)
        dispatch({ type: 'REPORT_DATA_ERROR', payload: err })
      }
    )
  }
}

/**
 * 
 * @param {*} data : { projectId, reportType, reportChoice }
 */

export const getEngineerReport = (data) => {
  return async dispatch => {
    dispatch({ type: 'REPORT_DATA_FETCHING', payload: data })
    /**
     * Note : if frontend not send planning_list, data will be fetch by backend
     *      file_type: if file_type provide, response will be blob, otherwise json data
     */
    // here we send empty data to get json data from server
    postJsonData(Urls.apiGetEngineerReportData(data.projectId), {},
      (res) => {
        dispatch({ type: 'REPORT_DATA_SUCCESS', payload: res })
      },
      (err) => {
        // console.log("-> err", err)
        dispatch({ type: 'REPORT_DATA_ERROR', payload: err })
      }
    )
  }
}

export const fetchReportData = (data) => {

  const projectId = data.col_1_value
  const reportType = data.col_2_value
  const reportChoice = data.col_3_value

  let url
  if (reportType === 1) {
    if (reportChoice === 1) {
      url = Urls.apiGetProjectReportData(projectId)
    }
    else if (reportChoice === 2) {
      url = Urls.apiGetProjectActivityReportData(projectId)
    }
  }
  else if (reportType === 2) {
    url = Urls.apiGetProjectQualityReportData(projectId)
  }
  else if (reportType === 3) {
    url = Urls.apiGetEngineerReportData(projectId)
  }

  const payload = { projectId, reportType, reportChoice }

  return async dispatch => {
    dispatch({ type: 'REPORT_DATA_FETCHING', payload })

    postJsonData(url, {},
      (res) => {
        dispatch({ type: 'REPORT_DATA_SUCCESS', payload: res })
      },
      (err) => {
        dispatch({ type: 'REPORT_DATA_ERROR', payload: err })
      }
    )
  }
}