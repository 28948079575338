import React, { Component } from 'react'
import { connect } from 'react-redux'

import { isEmpty, find, get } from 'lodash'

import CustomInput, { CustomReactSelect } from '../../../common/components/CustomInput'
import LoadingButton from '../../../common/components/LoadingButton'
import ErrorBlock from '../../../common/components/ErrorBlock'
import { ImagePlaceholder, DeleteIcon } from '../../../common/SvgIcons'

import { postFormData } from '../../../common/utils'
import Urls from '../../../UrlConfig'

/**
 * Scss:
 *      _project_drawing_list
 * 
 * Common:
*      _common_page_layout
 */

const DrawingFormWrapper = ({
  closeModel, onSuccess, drawingData,
  fetched, fetchError
}) => {

  /**
   * Parent:
   *      DrawingList
   * 
   * Renders:
   *      DrawingFormConnect
   */

  let content

  if (fetched) {
    content = <DrawingFormConnect drawingData={drawingData}
      onSuccess={onSuccess} closeModel={closeModel} />
  }
  else if (fetchError) {
    content = <h3>Failed to fetch data</h3>
  }
  else {
    return null;
  }

  return (
    <div className="page-model">
      <div className="page-model-wrapper">
        <div className="page-model-cell">
          <div className="page-model-content pd-drawing-popup">
            <div className="model-form">
              <div className="close-icon-wrapper" onClick={closeModel}>
                <div className="close-icon"></div>
              </div>
              {content}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DrawingFormWrapper


/**
 * Scss:
 *      _project_drawing_list
 */

class DrawingForm extends Component {

  /**
   * Add drawing form, show caption and image upload
   * 
   * Parent:
   *      DrawingFormWrapper
   */

  constructor(props) {
    super(props)

    this.isAdd = isEmpty(props.drawingData)
    let initData

    if (this.isAdd) {
      initData = {
        unitId: null,
        subunitId: null,
        subunitsList: [],
        drawingFileUrl: null,
        caption: ''
      }
    }
    else {
      let subunitsList = find(props.unitList, ['id', props.drawingData.unit.id])
      subunitsList = subunitsList.subunits

      initData = {
        unitId: props.drawingData.unit.id,
        subunitId: props.drawingData.subunit.id,
        subunitsList,
        drawingFileUrl: props.drawingData.drawing_file,
        caption: props.drawingData.caption
      }
    }

    this.state = {
      ...initData,
      drawingFile: null,

      submitting: false,
      submitError: {}
    }
  }

  uploadImage = (event) => {

    if (this.state.submitting) {
      return
    }

    if (event) event.preventDefault()

    const { projectId, loggedUserId, drawingData } = this.props
    const { unitId, subunitId, caption, drawingFile } = this.state

    let data = new FormData();

    data.append("project", projectId)
    data.append("unit", unitId)
    data.append("subunit", subunitId)
    data.append("caption", caption)
    data.append("uploaded_by", loggedUserId)
    if (drawingFile) {
      data.append("drawing_file", drawingFile)
    }
    if (!this.isAdd) {
      data.append("id", drawingData.id)
    }

    const url = this.isAdd ? Urls.apiDrawingAdd() : Urls.apiDrawingEdit()

    this.setState({ submitting: true, submitError: {} })
    postFormData(url, data,
      (res) => {
        this.props.onSuccess()
      },
      (err) => {
        this.setState({ submitting: false, submitError: err.error_data })
      }
    )

  }

  loadFile = (event) => {
    const selected_image = event.target.files[0]

    if (selected_image) {
      this.setState({
        drawingFileUrl: URL.createObjectURL(selected_image),
        drawingFile: selected_image
      })
    }
  }

  clearImg = () => {
    this.setState({ drawingFileUrl: null, drawingFile: null })
  }

  handleUnitSelect = (props) => {
    this.setState({ unitId: props.id, subunitsList: props.subunits })
  }

  handleSubUnitSelect = (props) => {
    this.setState({ subunitId: props.id })
  }

  render = () => {
    const { unitList, closeModel } = this.props

    const { drawingFileUrl, drawingFile, caption,
      unitId, subunitId, subunitsList,
      submitting, submitError
    } = this.state

    return (
      <div>
        <div className="title">{this.isAdd ? "Add Drawing" : "Edit Drawing"}</div>
        <CustomReactSelect label="Select Unit"
          options={unitList}
          isClearable={false}
          isSearchable={true}
          value={unitList.filter(({ id }) => id === unitId)}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id}
          onChange={this.handleUnitSelect}
          errorData={submitError}
          errorField="unit"
        />
        <CustomReactSelect label="Select Subunit"
          options={subunitsList}
          isClearable={false}
          isSearchable={true}
          isDisabled={!subunitsList.length}
          value={subunitsList.filter(({ id }) => id === subunitId)}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id}
          onChange={this.handleSubUnitSelect}
          errorData={submitError}
          errorField="subunit"
        />

        <div className="drawing-img-upload-wrapper">
          <div className="drawing-img-upload-col">
            <div className="drawing-img-upload-block">
              <input type="file" name="drawing_file" onChange={this.loadFile} />
              <div className="drawing-img-upload-content">
                <ImagePlaceholder />
              </div>
              {drawingFileUrl && <img src={drawingFileUrl} alt="" />}
            </div>
          </div>
          <div className="drawing-img-info-col">
            <div className="heading">Browse file</div>
            <div className="desc">any file type accepted</div>
            <ErrorBlock field="drawing_file" error={submitError} />
          </div>
        </div>

        <div className={`drawing-img-indicator ${drawingFile ? "show" : ""}`}>
          <div className="img-name">{get(drawingFile, 'name')}</div>
          <div className="indicator-wrap">
            <div className="ind"></div>
            <div className="remove" onClick={this.clearImg}>
              <DeleteIcon />
            </div>
          </div>
        </div>

        <CustomInput label="Caption"
          value={caption}
          onChange={e => this.setState({ caption: e.target.value })} />

        <ErrorBlock field="__all__" error={submitError} />

        <div className="drawing-action-wrapper">
          <LoadingButton title="Upload" className="btn primary-btn"
            isLoading={submitting} onClick={this.uploadImage} />
          <LoadingButton title="Cancel" className="btn secondary-btn"
            onClick={closeModel} />
        </div>
      </div>
    )
  }
}

const DrawingFormConnect = connect(store => ({
  projectId: store.projectDetails.projectDetails.id,
  unitList: store.projectDetails.projectDetails.units,
  loggedUserId: store.auth.user,
}))(DrawingForm)