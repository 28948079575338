import {findIndex, orderBy} from 'lodash'

const INIT_PROJECT_FORM = {
    fetching: false,
    fetched: false,
    fetchError: false,
    fetchErrorObj: {},
    // form data with default param
    formData: {}
}

export const projectFormReducer =  (state = INIT_PROJECT_FORM, { type, payload }) => {
    switch (type) {

        case 'PROJECT_FORM_DETAILS_FETCHING': {
            return { ...state, 
                fetching: true,
                fetchError: false,
                fetchErrorObj: {},
            }
        }

        case 'PROJECT_FORM_DETAILS_SUCCESS': {
            let formData = {...payload}

            return { ...state, fetching: false,
                fetched: true, formData 
            }
        }

        case 'PROJECT_FORM_DETAILS_ERROR': {
            return { ...state, fetching: false,
                fetched: false, fetchError: true,
                fetchErrorObj: payload
            }
        }

        case 'PROJECT_DATA_SUBUNIT_SUBMIT_SUCCESS': {
            // get index of updated unit and replace using index
            const currUnitIndex = findIndex(state.formData.units, ['id', payload.id])

            let newUnitList = [...state.formData.units]
            newUnitList[currUnitIndex] = {...payload}

            // subunit order by index
            for (let index = 0; index < newUnitList.length; index++) {
                const currUnit = newUnitList[index];
                currUnit.subunits = orderBy(currUnit.subunits, ['index'], ['asc'])
            }

            const newProjectData = {
                ...state.formData,
                units: newUnitList
            }

            return { ...state, formData: newProjectData }
        }

        case "PROJECT_FORM_DATA_RESET" : {
            return { ...INIT_PROJECT_FORM }
        }

        case 'USER_LOGOUT': {
            return INIT_PROJECT_FORM
        }


        default: {
            return state
        }
    }
}