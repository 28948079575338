import React, { Component } from 'react'

import { differenceInCalendarDays, startOfDay, endOfDay } from 'date-fns'

import { DatePickerInput } from 'react-datetime-range-super-picker'

import CustomInput from '../../common/components/CustomInput'
import LoadingButton from '../../common/components/LoadingButton'
import {CheckIcon} from '../../common/SvgIcons'

import { getJsondata, postJsonData, formatSubmitDate } from '../../common/utils'
import Urls from '../../UrlConfig'
import Loader from '../../common/components/Loader'

/**
 * Scss:
 *      _holiday_list
 * Common:
 *     _common_page_layout, form, button, checkbox
 */

export default class HolidayFormWrapper extends Component {
	/**
	 * Data wrapper component
	 * Fetch data of an holiday if holiday id provided
	 * pass that down to actual form
	 * 
	 * Parent
	 * 		HolidayListPage
	 * 
	 * Renders:
	 * 		HolidayForm
	 */

	constructor(props) {
		super(props)

		this.isAdd = props.holidayId === -1

		this.state = {
			// show loading only for edit
			loading: !this.isAdd,
			holidayData: {},
			error: null
		}
	}

	componentDidMount() {
		const holidayId = this.props.holidayId

		if (this.isAdd) {
			// Add:- This is add; no need to load new data
			this.setState({ loading: false })
		} else {
			// Edit:- fetch holiday data
			getJsondata(Urls.apiGetHolidayDetails(holidayId),
				(res) => {
					this.setState({ holidayData: res, loading: false })
				},
				(err) => {
					console.log("-> err", err)
				})
		}
	}

	render = () => {
		const { loading, holidayData, error } = this.state
		const { closeModel, onSubmitSuccess, addNotification } = this.props

		if (loading) {
			return <Loader />
		}
		else if (!!error) {
			return (
				<div className="empty-page-wrapper">
                    <div className="error-block big">Failed to fetch data</div>
                </div>
			)
		}
		else {
			return (
				<HolidayForm data={holidayData} isAdd={this.isAdd} 
					closeModel={closeModel} onSubmitSuccess={onSubmitSuccess} 
					addNotification={addNotification}
				/>
			)
		}
	}
}


class HolidayForm extends Component {

	/**
	 * Props:
	 * 		isAdd: Boolean
	 * 		data: { id, name, start_date, end_date, no_of_days }
	 * 
	 * Parent:
	 * 		HolidayFormWrapper
	 */

	constructor(props) {
		super(props)

		if (props.isAdd) {

			const start_date = startOfDay(new Date())
			const end_date = endOfDay(new Date())

			this.state = {
				name: '',
				start_date, end_date,
				loading: false,
				error: {}
			}
		} else {
			const start_date = new Date(props.data.start_date)
			const end_date = new Date(props.data.end_date)

			this.state = {
				id: props.data.id,
				name: props.data.name,
				start_date, end_date,
				loading: false,
				error: {}
			}
		}
	}

	submitHoliday = () => {
		const { id, name, start_date, end_date, loading } = this.state

		if (loading) return

		this.setState({ loading: true, error: {} })

		const no_of_days = differenceInCalendarDays(end_date, start_date) + 1

		postJsonData(Urls.apiUpsertHoliday(),
			{
				id, name, no_of_days,
				start_date: formatSubmitDate(startOfDay(start_date)),
				end_date: formatSubmitDate(endOfDay(end_date)),
			},
			(res) => {
				// console.log(res)
				this.setState({ loading: false, error: {} })
				this.props.onSubmitSuccess()
				this.props.addNotification({
					type: 'info',
                    icon: <CheckIcon/>,
                    title: this.props.isAdd ? "Holiday Create" : "Holiday Update",
                    text: `${name} ${this.props.isAdd ? "created" : "updated"} successfully`,
				})
			},
			(err) => {
				// console.log(err)
				this.setState({ loading: false, error: err.error_data })
				this.props.addNotification({
                    type: 'error',
                    title: this.props.isAdd ? "Holiday Create" : "Holiday Update",
                    text: 'input error',
                })
			},
		)
	}

	handleTextChange = (event) => {
		this.setState({ [event.target.name]: event.target.value })
	}

	handleFromDateUpdate = ({ date }) => {
		this.setState({ start_date: date })
	}

	handleToDateUpdate = ({ date }) => {
		this.setState({ end_date: date })
	}

	render = () => {
		const { name, start_date, end_date, 
			error, loading } = this.state
		const { closeModel, isAdd } = this.props

		// add 1 in total days to calculate proper
		const no_of_days = differenceInCalendarDays(end_date, start_date) + 1

		return (
			<div className="model-form">
				<div className="close-icon-wrapper" onClick={closeModel}>
					<div className="close-icon"></div>
				</div>
				<div className="title">{isAdd ? "Create Holiday" : "Edit Holiday"}</div>

				<CustomInput
					label="Holiday Name"
					errorData={error}
					errorField="name"
					name="name"
					placeholder="Enter Holiday name"
					value={name}
					onChange={this.handleTextChange}
				/>
				
				<div className="input-group">
					<div className="single left-inp">
						<div className="form-group">
							<div className="label">From</div>
							<DatePickerInput date={start_date} format="dd/MM/yyyy"
								onDateUpdate={this.handleFromDateUpdate} />
						</div>
					</div>
					<div className="single right-inp">
						<div className="form-group">
							<div className="label">To</div>
							<DatePickerInput date={end_date} format="dd/MM/yyyy"
								onDateUpdate={this.handleToDateUpdate} />
						</div>
					</div>
				</div>

				<CustomInput
					label="No of Days"
					errorData={error}
					errorField="no_of_days"
					placeholder="No of Days"
					value={no_of_days} disabled={true}
				/>

				<LoadingButton className="btn primary-btn" title={isAdd ? "Add Holiday" : "Update Holiday"}
                    isLoading={loading} onClick={this.submitHoliday} />

			</div>
		)
	}
}
