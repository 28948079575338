import { isNull } from "lodash";

const DEFAULT_BANNER = "banner_chavda.jpg";

export default class Urls {
  ///////////////////////////////////
  //			Web Urls			//
  /////////////////////////////////

  static getHomePage() {
    return "/";
  }

  static getDemoPage() {
    return "/demo";
  }

  static getDashboardPage() {
    return "/dashboard";
  }

  // auth types : login | forget-password
  static getAuthPages(auth_type = ":auth_type") {
    return `/auth/${auth_type}`;
  }

  static getResetPassPage(uuid = ":uuid") {
    return `/password/reset/${uuid}`;
  }

  // Other urls
  static get404() {
    return "/404";
  }

  static getUnauthorized() {
    return "/unauthorized";
  }

  static getPrivacyPage() {
    return "/privacy-policy";
  }

  // ----------- Admin Urls -----------
  // tab : user | activity | holiday
  static getAdminHomePage(tab = ":tab") {
    return `/admin/${tab}`;
  }

  static getUserInvitePage(uuid = ":uuid") {
    return `/user/invitation/${uuid}`;
  }

  // ----------- Project Urls -----------

  static getProjectPage() {
    return "/project";
  }

  // pass projectId = add for new Project form
  // tab : structure | permission
  static getProjectForm(projectId = ":projectId", tab = ":tab") {
    return `/project/update/${projectId}/${tab}`;
  }

  // tab : planning | quality | drawing
  static getProjectDetails(
    projectId = ":projectId",
    tab = ":tab",
    targetId = null,
    targetType = null
  ) {
    // go to Project Details page
    let opPath;
    if (isNull(targetId)) {
      opPath = `/project/details/${projectId}/${tab}`;
    } else {
      // targetType : add_qa_image | qa_details | show_qa_image
      opPath = `/project/details/${projectId}/${tab}?targetId=${targetId}&targetType=${targetType}`;
    }
    return opPath;
  }

  // ----------- Planning Urls -----------

  static getProjectPlanningList(projectId = ":projectId") {
    return `/project/${projectId}/planning/all`;
  }

  static getProjectPlanningAdd(projectId = ":projectId") {
    return `/project/${projectId}/planning/add/`;
  }

  static getProjectPlanningEdit(
    projectId = ":projectId",
    planningId = ":planningId"
  ) {
    return `/project/${projectId}/planning/${planningId}/edit`;
  }

  static getProjectPlanningChecklist(
    projectId = ":projectId",
    planningId = ":planningId"
  ) {
    return `/project/${projectId}/planning/${planningId}/checklist`;
  }

  // ----------- Reports Urls -----------

  static getReportsPage() {
    return "/reports";
  }

  // ----------- Reports Urls -----------

  static getProfilePage() {
    return "/profile";
  }

  ///////////////////////////////////
  //				Apis			//
  /////////////////////////////////

  // ----------- User Api -----------

  static apiUserLogin() {
    return "/api/user/login/";
  }

  static apiCreateUser() {
    return "/api/user/create/";
  }

  static apiEditUser(userId = ":userId") {
    return `/api/user/edit/${userId}/`;
  }

  static apiUserList() {
    return "/api/user/list/";
  }

  static apiToggleUserStatus() {
    return `/api/user/change-status/`;
  }

  static apiTokenVerify() {
    return "/api/user/token/verify/";
  }

  static apiGetProjectPermissions(project_id = ":project_id") {
    return `/api/user/permission/project/${project_id}/`;
  }

  static apiUpdateProjectPermissions() {
    return `/api/user/permission/project/update/`;
  }

  static apiProfileUpdate() {
    return `/api/user/edit-profile/`;
  }

  static apiChangePassword() {
    return `/api/user/change/password/`;
  }

  static apiForgotPassword() {
    return `/api/user/forgot/password/`;
  }

  static apiResetPassword() {
    return `/api/user/reset/password/`;
  }

  static apiUserInvite() {
    return `/api/user/invitation/`;
  }

  static apiUserInvitationList() {
    return `/api/user/invitation/list/`;
  }

  static apiUserInvitationDelete(inviteId = ":invite_id") {
    return `/api/user/invitation/delete/${inviteId}/`;
  }

  static apiUserVerifyInvitation(inviteUuid = ":invite_uui") {
    return `/api/user/invitation/verify/${inviteUuid}/`;
  }

  static apiUserPermittedProjectList(userId = ":userId") {
    return `/api/user/permitted/project/list/${userId}/`;
  }

  // ----------- Project Api -----------

  static apiGetProjectList() {
    return "/api/project/list/";
  }

  static apiUpsertProject() {
    return "/api/project/upsert/";
  }

  static apiUpsertUnit(projectId = ":projectId") {
    return `/api/project/upsert/${projectId}/unit/`;
  }

  static apiUpsertSubUnit(projectId = ":projectId", unitId = ":unitId") {
    return `/api/project/upsert/${projectId}/unit/${unitId}/subunit/`;
  }

  static apiGetProjectDetails(projectId = ":projectId") {
    return `/api/project/details/${projectId}/`;
  }

  static apiGetProjectDashboardData() {
    return "/api/project/dashboard/list/";
  }

  // ----------- Activity Api -----------

  static apiGetActivityList() {
    return "/api/activity/list/";
  }

  static apiGetActivityDetails(activityId = ":activityId") {
    return `/api/activity/details/${activityId}/`;
  }

  static apiGetActivityDelete(activityId = ":activityId") {
    return `/api/activity/delete/${activityId}/`;
  }

  static apiUpsertActivity() {
    return "/api/activity/upsert/";
  }

  // ----------- Checklist Admin Api -----------

  static apiGetChecklistConfigList() {
    return "/api/quality/checklist/list/";
  }

  static apiGetChecklistConfigDetails(checkId = ":checkId") {
    return `/api/quality/checklist/details/${checkId}/`;
  }

  static apiGetChecklistConfigDelete(checkId = ":checkId") {
    return `/api/quality/checklist/delete/${checkId}/`;
  }

  static apiUpsertChecklistConfig() {
    return "/api/quality/checklist/upsert/";
  }

  // ----------- Checklist Api -----------

  static apiGetQualityCheckList(projectId = ":projectId") {
    return `/api/quality/list/${projectId}/`;
  }

  static apiGetQualityCheckDetails(checklistId = ":checklistId") {
    return `/api/quality/details/${checklistId}/`;
  }

  static apiGetQualityCheckItemsList(checklistId = ":checklistId") {
    return `/api/quality/checklist/${checklistId}/checkitemlist/`;
  }

  static apiUpdateCheckItemMark() {
    return `/api/quality/checkitemmark/`;
  }

  static apiPostQualityImage() {
    return `/api/quality/add-image/`;
  }

  static apiGetChecklistMicroplanningWiseExcel() {
    return `/api/quality/checklist/download/microplanning-wise/`;
  }

  // ----------- Holiday Api -----------
  // duration: "all" | "past" | "future"
  static apiGetHolidayList(duration = ":duration") {
    return `/api/planning/holiday/list/${duration}/`;
  }

  static apiGetHolidayDetails(holidayId = ":holidayId") {
    return `/api/planning/holiday/details/${holidayId}/`;
  }

  static apiUpsertHoliday() {
    return "/api/planning/holiday/upsert/";
  }

  // ----------- Planning Api -----------

  static apiAddPlanning() {
    return "/api/planning/add/";
  }

  static apiEditPlanning() {
    return "/api/planning/edit/";
  }

  static apiGetProjectPlanningList(projectId = ":projectId") {
    return `/api/planning/list/${projectId}/`;
  }

  static apiGetProjectMicroPlanningList(projectId = ":projectId") {
    return `/api/planning/micro/list/${projectId}/`;
  }

  static apiUpdateProjectMicroPlanningStatus(projectId = ":projectId") {
    return `/api/planning/update/status/${projectId}/`;
  }

  static apiGetProjectPlanningDetails(planningId = ":planningId") {
    return `/api/planning/details/${planningId}/`;
  }

  static apiGetMplScheduleList(mplId = ":mplId") {
    return `/api/planning/micro/${mplId}/schedule/list/`;
  }

  static apiGetPlanningChecklist(planningId = ":planningId") {
    return `/api/planning/${planningId}/checklist/`;
  }

  // ----------- Drawing Api -----------

  static apiGetDrawingList() {
    return `/api/drawing/list/`;
  }

  static apiDrawingAdd() {
    return `/api/drawing/add/`;
  }

  static apiDrawingEdit() {
    return `/api/drawing/edit/`;
  }

  static apiDrawingDelete(drawingId) {
    return `/api/drawing/delete/${drawingId}`;
  }

  // ----------- Reports Api -----------

  static apiGetProjectReportData(projectId = ":projectId") {
    return `/api/project/report/structure/${projectId}/`;
  }

  static apiGetProjectActivityReportData(projectId = ":projectId") {
    return `/api/project/report/activity/${projectId}/`;
  }

  static apiGetProjectQualityReportData(projectId = ":projectId") {
    return `/api/project/report/${projectId}/quality/`;
  }

  static apiGetEngineerReportData(projectId = ":projectId") {
    return `/api/project/report/${projectId}/engineer/`;
  }

  // ----------- Activity Api -----------

  static apiGetAllActivityLog() {
    return `/api/activity_logs/all/`;
  }

  // image url
  static getImage(image_url) {
    if (image_url) {
      return `/media/${image_url}`;
    } else {
      return `/static/img/img_placeholder.png`;
    }
  }

  static getStaticImg(image_url = ":null") {
    return `/static/${image_url}`;
  }

  static getDefaultBanner() {
    return `/static/img/${DEFAULT_BANNER}`;
  }
}
