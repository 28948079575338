import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import get from "lodash/get";
import { useSelector } from "react-redux";

import CustomImage from "../CustomImage";
import DemoDummy from "./DemoDummy";

import { CardIcon, RightSideArrow, SearchIcon } from "../../SvgIcons";

import { getWindowHeight, getWindowWidth } from "../../data/common.selector";
import { DEFAULT_ZOOM, plotSearch } from "../../data/demo.constants";
import Urls from "../../../UrlConfig";

import SideBarPlotDetails from "./SideBarPlotDetails";

const DemoSideBar = ({
  setTransform,
  resetTransform,
  selectedPlot,
  setSelectedPlot,
  toggleStatistics,
}) => {
  const contentHeight = useSelector(getWindowHeight);
  const contentWidth = useSelector(getWindowWidth);

  const [isOpen, setIsOpen] = useState(true);
  const [searchToken, setSearchsearchToken] = useState("");

  const toggleSideBar = useCallback(() => {
    setIsOpen((currState) => !currState);
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchsearchToken(e.target.value);
  }, []);

  const handleGoToPlot = useCallback(
    (plotData) => () => {
      setSelectedPlot(plotData);

      const translateX = contentWidth * plotData.x_pos;
      const translateY = contentHeight * plotData.y_pos;
      setTransform(
        translateX,
        translateY,
        plotData.z_pos || DEFAULT_ZOOM,
        500,
        "easeInOutCubic"
      );
    },
    [setTransform]
  );

  const handleSearchSubmit = useCallback(() => {
    const searchResults = plotSearch.search(searchToken);
    // get first item and go to that plot
    const resPlot = get(searchResults, "0.item");

    if (!!resPlot) handleGoToPlot(resPlot)();
  }, [searchToken]);

  const handleReset = useCallback(() => {
    resetTransform();
    setSelectedPlot(null);
    setSearchsearchToken("");
  }, [resetTransform, setSelectedPlot, setSearchsearchToken]);

  if (!!selectedPlot) {
    return (
      <div
        id="demo-sidebar"
        className="demo-sidebar1"
        style={{
          transform: `translateX(0)`,
          display: "flex",
          alignItems: "center",
        }}
      >
        <SideBarPlotDetails
          selectedPlot={selectedPlot}
          handleReset={handleReset}
        />
      </div>
    );
  }

  return (
    <div
      id="demo-sidebar"
      className="demo-sidebar1"
      style={{ transform: `translateX(${isOpen ? "0%" : "calc(100% - 1em)"})` }}
    >
      <div className="sidebar-content-wrapper">
        <div
          className={`toggle-btn ${isOpen ? "right" : "left"}`}
          onClick={toggleSideBar}
        >
          <RightSideArrow />
        </div>

        <div className="card-btn" onClick={handleReset}>
          <CardIcon />
        </div>

        <div className="content">
          <div className="content-image">
            <CustomImage
              imageUrl={Urls.getStaticImg("img/demo-logo.png")}
              title="logo"
            />
          </div>
          <div className="dsc-search-bar">
            <div>
              <input
                value={searchToken}
                onChange={handleSearchChange}
                placeholder="Enter Plot No / Name"
              />
              <button onClick={handleSearchSubmit}>
                <SearchIcon />
              </button>
            </div>
          </div>

          <div className="dsc-dummy">
            <DemoDummy toggleStatistics={toggleStatistics} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoSideBar;
