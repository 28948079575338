
import { getJsondata } from '../../common/utils'
import Urls from '../../UrlConfig'

/**
 * Get USER list
 */
export const getUserList = (callback=null) => {

    return async dispatch => {
        dispatch({ type: 'USER_LIST_FETCHING' })

        getJsondata(Urls.apiUserList(),
			(res) => {
				dispatch({ type: 'USER_LIST_SUCCESS', payload: res })
				// mainly used to setup search from fetched data
				if(callback) callback(res);
			},
			(err) => {
            	// console.log("-> err", err)
                dispatch({ type: 'USER_LIST_ERROR', payload: err })
			}
		)
    }
}

/**
 * 
 * @param {*} data - { status, userId }
 */
export const userSetStatus = (data) => ({ type: 'USER_SET_STATUS', payload: data })