import React, { useState, useCallback } from "react";
import { TransformComponent } from "react-zoom-pan-pinch";

import Urls from "../../../UrlConfig";
import CustomImage from "../CustomImage";
import DemoSideBar from "./DemoSideBar";
import DemoSvg from "./DemoSvg";

export default ({ resetTransform, setTransform }) => {
  /**
   * Parent:
   *      DemoPage
   */

  const [imageDims, setImageDims] = useState({ width: 0, height: 0 });
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [showStatistics, setShowStatistics] = useState(false);

  const toggleStatistics = useCallback((e) => {
    e.preventDefault();
    setShowStatistics((currStatistics) => !currStatistics);
  }, []);

  return (
    <>
      <TransformComponent>
        <div className="plan-img-container">
          <CustomImage
            imageUrl={Urls.getStaticImg("img/demo-plan.png")}
            title="plan"
            onLoad={(e) => {
              const width = e?.target?.width;
              const height = e?.target?.height;
              if (height && width) {
                setImageDims({ width, height });
              }
            }}
          />
        </div>
        <DemoSvg
          imageDims={imageDims}
          selectedPlot={selectedPlot}
          setTransform={setTransform}
          setSelectedPlot={setSelectedPlot}
          showStatistics={showStatistics}
        />
      </TransformComponent>
      <DemoSideBar
        setTransform={setTransform}
        selectedPlot={selectedPlot}
        setSelectedPlot={setSelectedPlot}
        resetTransform={resetTransform}
        imageDims={imageDims}
        toggleStatistics={toggleStatistics}
      />
    </>
  );
};
