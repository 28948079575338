import React, { Component } from "react";
import { Link } from "react-router-dom";

import { sortBy } from "lodash";

import DashbordPlanningList from "./DashbordPlanningList";
import Loader from "../../common/components/Loader";
import ProjectBanner from "../../project/components/ProjectBanner";

import { getJsondata } from "../../common/utils";
import Urls from "../../UrlConfig";

/**
 * Scss:
 *      /common/_dashboard_page.scss
 */

export default class ProjectSummeryList extends Component {
  /**
   * Get project summery data from server
   * handle loading
   *
   * Parent:
   * 		DashboardPage
   *
   * Renders:
   *      DashboradPlanningList
   */

  state = {
    fetching: false,
    fetched: false,
    error: false,
    errorMsg: {},

    projectSummery: [],
  };

  componentDidMount = () => {
    this.getAllActivityLogs();
  };

  getAllActivityLogs = () => {
    this.setState({ fetching: true, error: false, errorMsg: {} });

    getJsondata(
      Urls.apiGetProjectDashboardData(),
      (res) => {
        this.setState({ fetching: false, fetched: true, projectSummery: res });
      },
      (err) => {
        this.setState({
          fetching: false,
          fetched: false,
          error: true,
          errorMsg: err.error_data,
        });
      }
    );
  };

  render = () => {
    const { fetched, error, projectSummery } = this.state;
    let content;

    if (fetched) {
      // sort so we see planned projects on the top
      let filteredSummery = sortBy(projectSummery, (d) => !d.is_planned);

      content = (
        <>
          <h2 className="heading">Project Updates</h2>
          {filteredSummery.length ? (
            filteredSummery.map((project) => {
              const isPlanned = project.is_planned;
              return (
                <div className="project-block-wrapper" key={project.pk}>
                  <div className="project-block-banner">
                    <div className="center">
                      <Link
                        to={Urls.getProjectDetails(project.pk, "planning")}
                        className="heading"
                      >
                        {project.name}
                      </Link>
                    </div>
                    {isPlanned ? (
                      <ProjectBanner
                        projectDetails={{
                          microplanning_summery: project.summery,
                          project_completion_days:
                            project.project_completion_days,
                        }}
                      />
                    ) : (
                      <div className="project-no-pln">
                        <div className="project-no-pln-col">
                          <div className="project-no-pln-text">
                            Looks like you don't have any plans right now
                          </div>
                        </div>
                        <div className="project-no-pln-col center">
                          <img
                            src={Urls.getStaticImg(
                              "/img/project_details_empty.svg"
                            )}
                            alt=""
                          />
                          <Link
                            to={Urls.getProjectPlanningList(project.pk)}
                            className="btn primary-btn"
                          >
                            + Update Planning
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <DashbordPlanningList
                    title="Tasks Waiting for admin approval"
                    planningList={project.mcp_waiting_list?.splice(0, 5) || []}
                  />

                  <DashbordPlanningList
                    title="Tasks Running"
                    planningList={project.mcp_running_list?.splice(0, 5) || []}
                  />

                  <DashbordPlanningList
                    title="Tasks Completed Today"
                    planningList={
                      project.mcp_completed_list?.splice(0, 5) || []
                    }
                  />
                </div>
              );
            })
          ) : (
            <div className="empty-page-wrapper">
              <div className="text">
                Looks like you don't have any <br /> projects updates right now
              </div>
            </div>
          )}
        </>
      );
    } else if (error) {
      content = (
        <div className="empty-page-wrapper">
          <div className="error-block big">Failed to fetch data</div>
        </div>
      );
    } else {
      content = <Loader />;
    }

    return <div className="project-summery-wrapper">{content}</div>;
  };

  getProjectEndMsg = (complitionValue) => {
    if (complitionValue) {
      if (complitionValue === 0) {
        return `Project will complete today`;
      } else if (complitionValue > 0) {
        return `Project completion in ${complitionValue} days`;
      } else if (complitionValue < 0) {
        return `Project was completed ${Math.abs(complitionValue)} day ago`;
      }
    } else {
      return `Project not planned yet`;
    }
  };
}
