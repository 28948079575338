import { getJsondata } from '../../common/utils'
import Urls from '../../UrlConfig'

///////////////////////////////////
//	  Planning List action		//
/////////////////////////////////

/**
 * Get planning list
 */
export const getPlanningList = (projectId) => {

    return async dispatch => {
        dispatch({ type: 'PLANNING_LIST_FETCHING' })

        getJsondata(Urls.apiGetProjectPlanningList(projectId),
			(res) => {
                dispatch({ type: 'PLANNING_LIST_SUCCESS', payload: res })
			},
			(err) => {
            	// console.log("-> err", err)
                dispatch({ type: 'PLANNING_LIST_ERROR', payload: err })
			}
		)
    }
}

export const resetPlanningList = () => ({ type: 'PLANNING_LIST_RESET' })

/**
 * Get planning list
 */
export const getPlanningDetails = (planningId) => {

    return async dispatch => {
        dispatch({ type: 'PLANNING_DETAILS_FETCHING' })

        getJsondata(Urls.apiGetProjectPlanningDetails(planningId),
			(res) => {
                dispatch({ type: 'PLANNING_DETAILS_SUCCESS', payload: res })
			},
			(err) => {
            	// console.log("-> err", err)
                dispatch({ type: 'PLANNING_DETAILS_ERROR', payload: err })
			}
		)
    }
}