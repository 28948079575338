import React, { Component } from 'react'

import LoadingButton from '../components/LoadingButton'

import Urls from '../../UrlConfig'


export default class PageNotFound extends Component {

    /**
     * Parent:
     *      App
     */

    handleHomeClick = (event) => {
        if (event) event.stopPropagation();
        this.props.history.push(Urls.getHomePage())
    }

    handleBackClick = (event) => {
        if (event) event.stopPropagation();
        this.props.history.goBack()
    }

    render = () => {
        return (
            <div className="page-not-found">
                <div className="page-not-found-col">
                    <div className="page-not-found-content">
                        <div className="big-title">404</div>
                        <h2>Look like this page is not belong to our website</h2>
                        <div>
                            <LoadingButton className="primary-btn"
                                title="Go Back" onClick={this.handleBackClick} />
                            <LoadingButton className="secondary-btn"
                                title="Go to Home" onClick={this.handleHomeClick} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}