import React, {useCallback} from 'react'

import { get } from 'lodash'
import { format } from 'date-fns'

/**
 * Scss:
 *      admin/_holiday_list
 * Common:
 *      layout, _common_page_layout
 */


export default ({holidayList}) => {
    /**
     * Show list of upcomming holidays besides Planning form
     * 
     * Parent:
     *      ProjectPlanningAdd
     */

    const formatDate = useCallback((date) => {
        if(date) {
            return format(new Date(date), 'dd/MM/yyyy')
        }
        else {return ''}
    },[])

    return (
        <div className="pl-holiday-list-container">
            <h3 className='title'>Upcoming Holidays</h3>

            <div className="holiday-list-wrapper">
                {holidayList.length ? 
                    holidayList.map((holiday) => {

                        return (
                            <div className="holiday-block" key={holiday.id}>
                                <div className="title">{get(holiday, 'name', '')}</div>
                                <div className="dates-wrapper">
                                    From: <span>{formatDate(holiday.start_date)}</span> 
                                    To: <span>{formatDate(holiday.end_date)}</span>
                                </div>
                            </div>
                        )
                    })
                    :
                    <div className="empty-wrapper">
                        No Upcoming Holidays
                    </div>
                }
            </div>
        </div>
    )
}
