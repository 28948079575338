import React, { Component } from 'react'

import { format } from 'date-fns'
import { get } from 'lodash'

import { StatusInfo } from '../ReportPage'
import LoadingButton from '../../common/components/LoadingButton'

import { fetchBlobData } from '../../common/utils'
import Urls from '../../UrlConfig'

/**
 * Scss:
 *      _reports, _reports_engineers
 */
export default class ProjectStructureReport extends Component {

  /**
   * render structure report
   * handle pdf and excel download
   * 
   * Parent:
   *      ReportPage
   */

  state = {
    loadingXlsx: false,
    loadingPdf: false,
  }

  formatDate = (date) => {
    return format(new Date(date), 'dd/MM/yyyy')
  }

  downloadExcel = () => {
    if (this.state.loadingXlsx) return

    const { reportData } = this.props

    this.setState({ loadingXlsx: true })

    fetchBlobData(Urls.apiGetProjectReportData(reportData.id), 'POST',
      { project_data: reportData, file_type: 'xlsx' },
      (res) => {
        let report_name = get(reportData, 'name', 'report')
        report_name = report_name + "-" + format(new Date(), 'dd/MM/yyyy hh:mm')

        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${report_name}.xlsx`);
        // have to add element to doc for firefox
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        this.setState({ loadingXlsx: false })
      },
      (err) => {
        this.setState({ loadingXlsx: false })
      }
    )
  }

  downloadPDF = () => {
    if (this.state.loadingPdf) return

    const { reportData } = this.props

    this.setState({ loadingPdf: true })

    fetchBlobData(Urls.apiGetProjectReportData(reportData.id), 'POST',
      { project_data: reportData, file_type: 'pdf' },
      (res) => {
        let report_name = get(reportData, 'name', 'report')
        report_name = report_name + "-" + format(new Date(), 'dd/MM/yyyy hh:mm')

        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${report_name}.pdf`);
        // have to add element to doc for firefox
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        this.setState({ loadingPdf: false })
      },
      (err) => {
        this.setState({ loadingPdf: false })
      }
    )
  }

  render = () => {
    const { reportData } = this.props

    return (
      <div className="report-content-wrapper">

        <div className="report-content-info-content">
          <div className="report-content-info-title">{reportData.name} Planning - Structure Wise Report</div>
          <div className="report-content-info-action multi-actions">
            <LoadingButton title="Download Pdf"
              className="primary-btn"
              onClick={this.downloadPDF}
              isLoading={this.state.loadingPdf}
            />
            <LoadingButton title="Download xlsx"
              className="primary-btn"
              onClick={this.downloadExcel}
              isLoading={this.state.loadingXlsx}
            />
          </div>
        </div>

        {reportData.units.length ?
          reportData.units.map((unit) => {
            return (
              <div key={unit.id}>
                {this.renderSubUnitList(unit)}
              </div>
            )
          })
          :
          <div className="empty-report-data">
            <div>No structure found</div>
          </div>
        }

      </div>
    )
  }

  renderSubUnitList = (unit) => {
    const { subunits } = unit

    if (!!subunits) {
      return (
        <>
          {subunits.map((subunit) => {
            const hasPlanning = Boolean(subunit.planning.length)
            return (
              <div key={subunit.id} className="report-content-block">
                <div className='report-content-block-title'>
                  {`${unit.name} > ${subunit.name}`}
                </div>
                {hasPlanning ?
                  <table className="report-engineer-table">
                    <tbody>
                      <tr className='header-row'>
                        <th className="title-col left-align">
                          Activity
                        </th>
                        <th className="summery-col">
                          Duration
                        </th>
                        <th className="days-col">
                          Days
                        </th>
                        <th className="status-col">
                          Status
                        </th>
                      </tr>
                      {subunit.planning.map((pl) => {
                        const start_date = this.formatDate(pl.start_date)
                        const end_date = this.formatDate(pl.end_date)

                        return (
                          <tr key={pl.id}>
                            <td className="title-col left-align">
                              {pl.activity.name}
                            </td>
                            <td className="summery-col center">
                              {start_date} to {end_date}
                            </td>
                            <td className="days-col center">
                              Days {pl.no_of_days} {pl.has_holidays && `, Holidays ${pl.no_of_holidays}`}
                            </td>
                            <td className="status-col center">
                              <StatusInfo status={pl.status} />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  :
                  <div className='report-content-empty no-bot-margin'>
                    <div className='label'>No planning found for this structure </div>
                  </div>
                }
              </div>
            )
          })}
        </>
      )
    }
  }
}
