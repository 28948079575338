import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import {CustomReactSelect} from '../../common/components/CustomInput'
import LoadingButton from '../../common/components/LoadingButton'
import {CheckIcon} from '../../common/SvgIcons'

import { addNotification } from '../../common/data/common.actions'
import { postJsonData } from '../../common/utils'
import Urls from '../../UrlConfig'
import { get } from 'lodash';


/**
 * Scss:
 *      _project_permission_list
 * Common:
 *      _common_page_layout
 */

class ProjectPermissionPopup extends Component {

    /**
     * Parent:
     *      ProjectPermissionList
     */

    constructor(props){
        super(props)

        this.isAdd = props.permissionId === -1

        let initData
        if(this.isAdd) {
            initData = { user: null }
        } else {
            initData = { user: props.permissionData.user.id }
        }

        this.state = {
            ...initData,
            submitting: false,
            deleting: false,
            error: {}
        }
    }

    handleTextChange = (event) => {
		this.setState({ [event.target.name]: event.target.value })
    }
    
    submitPermission = () => {
        const { user } = this.state

        const data = {
			project_id: this.props.projectId,
			new_permissions: [user],
			deleted_permissions: []
        }
        
        this.setState({ submitting: true })
        
		postJsonData(Urls.apiUpdateProjectPermissions(), data,
			(res) => {
                this.props.onSuccess(res)
                this.props.addNotification({
                    type: 'info',
                    icon: <CheckIcon/>,
                    title: "Project Permission",
                    text: `Project permission created successfully`,
                })
			},
			(err) => {
                this.setState({ submitting: false })
                this.props.addNotification({
                    type: 'error',
                    title: "Project Permission",
                    text: 'Project permission not updated successfully',
                })
			}
		)
    }

    deletePermission = () => {

        const data = {
			project_id: this.props.projectId,
			new_permissions: [],
			deleted_permissions: [this.props.permissionId]
        }
        
        this.setState({ deleting: true })

		postJsonData(Urls.apiUpdateProjectPermissions(), data,
			(res) => {
                this.props.onSuccess(res)
                this.props.addNotification({
                    type: 'info',
                    icon: <CheckIcon/>,
                    title: "Project Permission Delete",
                    text: `Project permission deleted successfully`,
                })
			},
			(err) => {
                this.setState({ deleting: false })
                this.props.addNotification({
                    type: 'error',
                    title: "Project Permission Delete",
                    text: 'Project permission not deleted successfully',
                })
			}
		)
    }

    handleUserSelect = (data) => {
        this.setState({ user: data.id })
    }

    render = () => {

        const { closeModel, userList } = this.props
        const { user, submitting, deleting } = this.state

        const selectedUser = userList.filter(({ id }) => id === user)

        return (
            <div className="page-model">
                <div className="page-model-wrapper">
                    <div className="page-model-cell">
                        <div className="page-model-content pro-perm-form-wrapper">
                            <div className="model-form">
                                <div className="close-icon-wrapper" onClick={closeModel}>
                                    <div className="close-icon"></div>
                                </div>
                                <div className="title">{this.isAdd ? "Assign User" : "Assign User"}</div>

                                <CustomReactSelect label="Select User"
                                    isSearchable={true}
                                    options={userList}
                                    isClearable={false}
                                    value={selectedUser}
                                    getOptionLabel={({ fullname }) => fullname}
                                    getOptionValue={({ id }) => id}
                                    onChange={this.handleUserSelect}
                                />

                                <div className="form-group  ">
                                    <div className="pro-perm-form-role">
                                        <div className="label">User Role</div>
                                        <div className="value">{get(selectedUser, '0.role_name', '-NA-')}</div>
                                    </div>
                                </div>

                                <LoadingButton className="btn primary-btn" 
                                    title="Assign User"
                                    isDisable={!this.isAdd}
                                    isLoading={submitting} onClick={this.submitPermission} 
                                />

                                {!this.isAdd && 
                                    <div className="pro-perm-form-del">
                                        <div className="pro-perm-form-del-text" onClick={this.deletePermission}>
                                            { deleting ? "Loading..." : "Delete Permission"}
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ProjectPermissionPopup.propTypes = {
    // -1 for add, otherwise edit
    permissionId: PropTypes.number.isRequired,
    userList: PropTypes.array.isRequired,
    // empty obj in case of add
    permissionData: PropTypes.object.isRequired,
    // for close model
    closeModel: PropTypes.func.isRequired,
    // for submit success
    onSuccess: PropTypes.func.isRequired,
}

export default connect(store => ({
    projectId: store.projectForm.formData.id,
}), { addNotification })(ProjectPermissionPopup)