import React from 'react'
import PropTypes from 'prop-types';

/**
 * Scss:
 *      _checkbox.scss
 */

const CheckBox = ({label, onClick = () => {}, isChecked}) => {
    return (
        <label className="checkbox-container">
            <input type="checkbox" checked={isChecked}
                onChange={onClick} />
            <div className="checkmark"><div className="check"></div></div>
            <div className="checkbox-label">{label}</div>
        </label>
    )
}

CheckBox.propTypes = {
    // to display name
    label: PropTypes.string.isRequired,
    // handler
    onClick: PropTypes.func,
    // to indicate checked or not
    isChecked: PropTypes.bool.isRequired
}

export default CheckBox