import React, { Component } from "react";

import { groupBy, get } from "lodash";
import { format } from "date-fns";

import { StatusInfo } from "../ReportPage";
import LoadingButton from "../../common/components/LoadingButton";

import { fetchBlobData, formatDate } from "../../common/utils";
import Urls from "../../UrlConfig";

/**
 * Scss:
 *      _reports, _reports_engineers
 */
export default class ProjectActionReport extends Component {
  /**
   * render structure report
   * handle pdf and excel download
   *
   * Parent:
   *      ReportPage
   */

  state = {
    loadingXlsx: false,
    loadingPdf: false,
  };

  downloadExcel = () => {
    if (this.state.loadingXlsx) return;

    const { project, planning_list } = this.props.reportData;

    this.setState({ loadingXlsx: true });
    /**
     * Note : if frontend not send planning_list, data will be fetch by backend
     *      file_type: if file_type provide, response will be blob, otherwise json data
     */
    fetchBlobData(
      Urls.apiGetProjectActivityReportData(project.id),
      "POST",
      { planning_list: planning_list, file_type: "xlsx" },
      (res) => {
        let report_name = get(project, "name", "report");
        report_name =
          report_name +
          "- activity report -" +
          format(new Date(), "dd/MM/yyyy hh:mm");

        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${report_name}.xlsx`);
        // have to add element to doc for firefox
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        this.setState({ loadingXlsx: false });
      },
      (err) => {
        this.setState({ loadingXlsx: false });
      }
    );
  };

  downloadPDF = () => {
    if (this.state.loadingPdf) return;

    const { project, planning_list } = this.props.reportData;

    this.setState({ loadingPdf: true });
    /**
     * Note : if frontend not send planning_list, data will be fetch by backend
     *      file_type: if file_type provide, response will be blob, otherwise json data
     */
    fetchBlobData(
      Urls.apiGetProjectActivityReportData(project.id),
      "POST",
      { planning_list: planning_list, file_type: "pdf" },
      (res) => {
        let report_name = get(project, "name", "report");
        report_name =
          report_name +
          "- activity report -" +
          format(new Date(), "dd/MM/yyyy hh:mm");

        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${report_name}.pdf`);
        // have to add element to doc for firefox
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        this.setState({ loadingPdf: false });
      },
      (err) => {
        this.setState({ loadingPdf: false });
      }
    );
  };

  render = () => {
    const { project } = this.props.reportData;

    return (
      <div className="report-content-wrapper">
        <div className="report-content-info-content">
          <div className="report-content-info-title">
            {project.name} Planning - Activity Wise Report
          </div>
          <div className="report-content-info-action multi-actions">
            <LoadingButton
              title="Download Pdf"
              className="primary-btn"
              onClick={this.downloadPDF}
              isLoading={this.state.loadingPdf}
            />
            <LoadingButton
              title="Download xlsx"
              className="primary-btn"
              onClick={this.downloadExcel}
              isLoading={this.state.loadingXlsx}
            />
          </div>
        </div>

        {this.renderActivityPlanningList()}
      </div>
    );
  };

  renderActivityPlanningList = () => {
    const { planning_list } = this.props.reportData;
    const activityWiseReportData = groupBy(planning_list, "activity.name");

    const resultList = [];
    for (const [activityName, planningList] of Object.entries(
      activityWiseReportData
    )) {
      const activityBlock = (
        <div key={activityName} className="report-content-block">
          <div className="report-content-block-title">{activityName}</div>
          <table className="report-engineer-table">
            <tbody>
              <tr className="header-row">
                <th className="title-col left-align">Structure</th>
                <th className="summery-col">Duration</th>
                <th className="days-col">Days</th>
                <th className="status-col">Status</th>
              </tr>
              {planningList.map((planning) => {
                const start_date = formatDate(planning.start_date);
                const end_date = formatDate(planning.end_date);

                let planningLabel = "";
                if (planning.subunit?.name) {
                  planningLabel = `${planning.unit.name} > ${planning.subunit.name}`;
                } else {
                  planningLabel = planning.unit.name;
                }

                return (
                  <tr key={planning.id}>
                    <td className="title-col left-align">{planningLabel}</td>
                    <td className="summery-col center">
                      {start_date} to {end_date}
                    </td>
                    <td className="days-col center">
                      Days {planning.no_of_days}{" "}
                      {planning.has_holidays &&
                        `, Holidays ${planning.no_of_holidays}`}
                    </td>
                    <td className="status-col center">
                      <StatusInfo status={planning.status} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );

      resultList.push(activityBlock);
    }

    if (resultList.length) {
      return resultList;
    } else {
      return (
        <div className="empty-report-data">
          <div>No activities found for this project</div>
        </div>
      );
    }
  };
}
