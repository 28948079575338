import React, { Component } from 'react'

import { fill, concat, slice } from 'lodash'

import CustomInput from './CustomInput'
import { CheckIcon, CloseIcon } from '../../common/SvgIcons'

const REPORT_TYPE = [
    { value: 1, label: 'Planning' },
    { value: 2, label: 'Quality' },
]

const REPORT_CHOICE = [
    { value: 1, label: 'Structure wise' },
    { value: 2, label: 'Activity wise' },
]

export default class ChainSelectionBlock extends Component {

    /**
     * Parent:
     *      ReportPage
     */

    static defaultProps = {
        min_li_count: 7,
        total_col: 3
    }

    constructor(props) {
        super(props)

        this.state = {
            tag_show: '',
            col_1_value: null,
            col_2_value: null,
            col_3_value: null,
            completed: false
        }
    }

    showQueryInterection = (event) => {
        this.setState({ show_dropdown: true })
    }

    hideDropdown = () => {
        this.setState({ show_dropdown: false })
    }

    getTagName = (index, new_name) => {
        let tag_show_to_list = this.state.tag_show.split(' > ')
        let update_list = slice(tag_show_to_list, 0, index)
        update_list.push(new_name)

        return update_list.join(' > ')
    }

    render = () => {
        const { total_col } = this.props
        const { tag_show, show_dropdown, completed } = this.state

        const col_width = { width: `${100 / total_col}%` }

        return (
            <div className="chain-selection-dropdown">
                <CustomInput label=""
                    type="text" className="input-light-grey textbox"
                    placeholder="Select report choice"
                    onFocus={(event) => this.showQueryInterection(event)}
                    value={tag_show}
                    readOnly={true}
                />
                {(show_dropdown) &&	// show close button for drop down
                    <div className="icon-wrapper" onClick={this.hideDropdown}>
                        <CloseIcon />
                    </div>
                }
                {(!(show_dropdown)) && (completed) && // show tick mark if tag selection done
                    <div className="icon-wrapper">
                        <CheckIcon />
                    </div>
                }

                <div className={`dropdown-wrapper left scale-transition ${show_dropdown ? '' : 'scale-out'}`} >
                    <div className='dropdown-container'>
                        <div className="listview-header">

                            <div className="textbox-wrapper header" style={{ ...col_width, paddingLeft: '1.5em' }}>
                                Select Project
                            </div>

                            <div className="textbox-wrapper header" style={col_width} >
                                Report Type
                            </div>

                            <div className="textbox-wrapper header" style={col_width} >
                                Report Choice
                            </div>

                        </div>

                        <div className='listview-table'>
                            {this.renderProjectList(col_width)}
                            {this.renderReportType(col_width)}
                            {this.renderReportChoise(col_width)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handleProjectSelect = (col_1_data) => {

        this.setState({
            col_1_value: col_1_data.id,
            tag_show: col_1_data.name,
            completed: false,
            col_2_value: null, col_3_value: null
        })
    }

    renderProjectList = (col_width) => {
        let { data, min_li_count } = this.props
        const { col_1_value } = this.state

        // add buffer for better design
        if (data.length < min_li_count) {
            const buffer_array = fill(Array(min_li_count - data.length), "")
            data = concat(data, buffer_array)
        }

        return (
            <div className="listview-content" style={col_width}>
                {data.map((q_data, i) => {
                    if (q_data === "") {
                        // just a buffer li for design purpose only
                        return <div className='listview-tabs' key={i} style={{ cursor: 'default' }} />
                    }
                    else {
                        return (
                            <div key={i} className={`listview-tabs ${q_data.id === col_1_value ? 'active' : ''}`}
                                onClick={this.handleProjectSelect.bind(this, q_data)} >
                                {q_data.name}
								<div className='listview-arrow'>
                                    <span className="icon icon-play" />
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        )
    }

    handleCol2Select = (col_2_data) => {
        const { col_1_value, col_2_value } = this.state
        const new_col_2_value = col_2_data.value

        if (col_2_value === new_col_2_value) return

        const tag_show = this.getTagName(1, col_2_data.label)
        const is_done = new_col_2_value === 2

        this.setState({
            col_2_value: new_col_2_value, tag_show,
            col_3_value: null, show_dropdown: !is_done, completed: is_done
        })

        if (is_done) {
            this.props.onDone({ col_1_value, col_2_value: new_col_2_value })
        }
    }

    renderReportType = (col_width) => {

        const { min_li_count } = this.props
        const { col_1_value, col_2_value } = this.state

        let list_data = col_1_value ? REPORT_TYPE : []
        // add buffer for better design
        if (list_data.length < min_li_count) {
            const buffer_array = fill(Array(min_li_count - list_data.length), "")
            list_data = concat(list_data, buffer_array)
        }

        return (
            <div className="listview-content" style={col_width}>
                {list_data.map((q_data, i) => {
                    if (q_data === "") {
                        // just a buffer li for design purpose only
                        return <div className='listview-tabs' key={i} style={{ cursor: 'default' }} />
                    }
                    else {
                        return (
                            <div key={i} className={`listview-tabs ${q_data.value === col_2_value ? 'active' : ''}`}
                                onClick={this.handleCol2Select.bind(this, q_data)} >
                                {q_data.label}
                                <div className='listview-arrow'>
                                    <span className="icon icon-play" />
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        )
    }

    handleCol3Select = (col_3_data) => {
        const { col_1_value, col_2_value, col_3_value } = this.state
        const new_col_3_value = col_3_data.value

        if (col_3_value === new_col_3_value) return

        const tag_show = this.getTagName(2, col_3_data.label)

        this.setState({
            col_3_value: new_col_3_value, tag_show: tag_show,
            show_dropdown: false, completed: true
        })
        this.props.onDone({ col_1_value, col_2_value, col_3_value: new_col_3_value })
    }

    renderReportChoise = (col_width) => {
        const { min_li_count } = this.props
        const { col_2_value, col_3_value } = this.state

        let list_data = col_2_value === 1 ? REPORT_CHOICE : []
        // add buffer for better design
        if (list_data.length < min_li_count) {
            const buffer_array = fill(Array(min_li_count - list_data.length), "")
            list_data = concat(list_data, buffer_array)
        }

        return (
            <div className="listview-content" style={col_width}>
                {list_data.map((q_data, i) => {
                    if (q_data === "") {
                        // just a buffer li for design purpose only
                        return <div className='listview-tabs' key={i} style={{ cursor: 'default' }} />
                    }
                    else {
                        return (
                            <div key={i} className={`listview-tabs ${q_data.value === col_3_value ? 'active' : ''}`}
                                onClick={this.handleCol3Select.bind(this, q_data)} >
                                {q_data.label}
                            </div>
                        )
                    }
                })}
            </div>
        )
    }
}
