import React from 'react'

export default ({height=200, width=200}) => {
    return (
        <div className='loader-wrapper' style={{width, height}}>
            <BuildingSvg />
            <div>Loading ...</div>
        </div>
    )
}

export const BuildingSvg = () => (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 480 480" style={{enableBackground:"new 0 0 480 480"}}>

    <g>
        <path style={{fill:"#78B9EB"}} d="M200,104v48v48v48v48v48v48v48v32h112v-32v-48v-48v-48v-48v-64v-48L200,104z"/>
        <path style={{fill:"#78B9EB"}} d="M312,184v64v48v48v48v48v32h64V216L312,184z"/>
        <ellipse style={{fill:"#78B9EB"}} cx="432" cy="384" rx="24" ry="32"/>
    </g>
    <path style={{fill:"#1E81CE"}} d="M440,464v-41.424c15.363-6.478,24.978-21.932,24-38.576c0-22.056-14.352-40-32-40s-32,17.944-32,40
        c-0.978,16.644,8.637,32.098,24,38.576V464h-40V216c0.001-3.032-1.712-5.805-4.424-7.16L320,179.056V136
        c0.002-3.573-2.365-6.714-5.8-7.696L208,97.96V8c0-4.418-3.582-8-8-8H40c-4.418,0-8,3.582-8,8v456H0v16h480v-16H440z M416,384
        c0-13.008,7.328-24,16-24s16,10.992,16,24c0.259,7.659-2.632,15.09-8,20.56V392h-16v12.56C418.627,399.093,415.735,391.66,416,384z
        M48,448h72v-16H48v-32h72v-16H48v-32h72v-16H48v-32h72v-16H48v-32h72v-16H48v-32h72v-16H48v-32h72v-16H48v-32h72V96H48V64h72V48H48
        V16h144v448H48V448z M208,448h56v-16h-56v-32h56v-16h-56v-32h56v-16h-56v-32h56v-16h-56v-32h56v-16h-56v-32h56v-16h-56v-32h56v-16
        h-56v-29.392l96,27.424V464h-96V448z M320,464v-16h24v-16h-24v-32h24v-16h-24v-32h24v-16h-24v-32h24v-16h-24v-32h24v-16h-24v-43.056
        l48,24V464H320z"/>
    </svg>
)