import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { addNotification } from "../../data/common.actions";
import Urls from "../../../UrlConfig";

const SideBarPlotDetails = ({ selectedPlot, handleReset }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showDetails, setShowDetails] = useState(false);

  const handleToggleShowDetails = useCallback((e) => {
    if (e) e.preventDefault();
    setShowDetails((currDetails) => !currDetails);
  }, []);

  const handleNavigateToProjectDetails = useCallback(
    (e) => {
      if (e) e.preventDefault();
      if (!!selectedPlot?.project_id) {
        history.push(
          Urls.getProjectDetails(selectedPlot.project_id, "planning")
        );
      } else {
        dispatch(
          addNotification({
            type: "error",
            title: "Can not view project",
            text: "Project work not configured yet !!",
          })
        );
      }
    },
    [selectedPlot]
  );

  return (
    <div className={`pCard_card ${showDetails ? "pCard_on" : ""}`}>
      <div className="pCard_up">
        <div className="pCard_back_btn" onClick={handleReset}>
          <i className="fa fa-angle-left" aria-hidden="true"></i> back
        </div>
        <div className="pCard_text">
          <h2>
            {selectedPlot.id} - {selectedPlot.name}
          </h2>
          <p>{selectedPlot.plotType || "Not Planned"}</p>
        </div>
        <div className="pCard_add" onClick={handleToggleShowDetails}>
          <i className={`fa ${showDetails ? "fa-minus" : "fa-plus"}`}></i>
        </div>
      </div>
      <div className="pCard_down">
        <div>
          <p>Area</p>
          <p>{selectedPlot.area || "----"}</p>
        </div>
        <div>
          <p>Status</p>
          <p>{selectedPlot.status || "-NA-"}</p>
        </div>
        <div>
          <p>Architect</p>
          <p>{selectedPlot.architect || "----"}</p>
        </div>
      </div>
      <div className="pCard_back">
        <p>Quick Links For Project</p>
        <a onClick={handleNavigateToProjectDetails} href="#">
          <i className="fa fa-sitemap fa-2x fa-fw" aria-hidden="true"></i>
        </a>
        <Link to={Urls.getProjectForm("add", "new")}>
          <i className="fa fa-plus fa-2x fa-fw"></i>
        </Link>
        <a href="#">
          <i className="fa fa-behance fa-2x fa-fw"></i>
        </a>
        <br />
        <p>
          <b>Recent Updates :-</b>
        </p>
        <p>- Excavation waiting to start since 2 days ago</p>
        <p>- RCC Foundation started 5 days ago</p>
        <p>- Project construction started 9 days ago</p>
      </div>
    </div>
  );
};

export default SideBarPlotDetails;
