import React from "react";
import { useDispatch} from 'react-redux'
import { useHistory } from "react-router-dom";

import { userLogOut } from '../data/auth.actions'
import Urls from '../../UrlConfig'

export default () => {

	const dispatch = useDispatch()
	const history = useHistory()

	const logoutUser = () => {
		dispatch(userLogOut())
		history.push(Urls.getAuthPages('login'))
	}

	return (
		<div onClick={logoutUser}>Logout</div>
	)
}