const INIT_REPORT = {
  fetching: false,
  fetched: false,
  error: false,
  errorMsg: {},
  // selected form data 
  projectId: null,
  reportType: null, // 1: planning, 2: quality: 3: engineer report
  // sub type of planning report
  reportChoice: null, // 1: structure, 2: activity
  // report data shape can be null, {}, []
  reportData: [],
}


export const reportReducer = (state = INIT_REPORT, { type, payload }) => {
  switch (type) {

    case 'REPORT_DATA_FETCHING': {

      return { ...INIT_REPORT, fetching: true, ...payload }
    }

    case 'REPORT_DATA_SUCCESS': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        reportData: payload,
      }
    }

    case 'REPORT_DATA_ERROR': {
      return {
        ...state,
        fetching: false,
        fetched: false,
        reportData: null,
        error: true,
        errorMsg: payload
      }
    }

    case 'USER_LOGOUT': {
      return INIT_REPORT
    }

    default: {
      return state
    }
  }
}