import { postFormData } from '../../common/utils'
import Urls from '../../UrlConfig'

/**
 * 
 * @param {*} data : {email, password, client_id}
 */
export const postLogin = (data) => {

    return async (dispatch) => {
        dispatch({ type: 'USER_LOGGING_IN' })

        postFormData(Urls.apiUserLogin(), data,
            (res) => {
                dispatch({ type: 'USER_LOGGING_SUCCESS', payload: res})
            },
            (err) => {
                dispatch({ type: 'USER_LOGIN_FAILED', payload: err.error_data })
            }
        )
    }
}

export const userLogOut = () => ({ type: "USER_LOGOUT" })