import Fuse from "fuse.js";

export const DEFAULT_ZOOM = 2.5;

export const PLOT_DETAILS = [
  {
    project_id: 10,
    plotType: "KingFisher, 3BHK",
    area: 475,
    status: "Work In Progress",
    architect: "DPA",
    id: "315",
    name: "Jignesh Sorathiya",
    x_pos: -0.4946,
    y_pos: -0.9168,
    z_pos: DEFAULT_ZOOM,
    svgPath: `M 1426.00,1078.00
    C 1426.00,1078.00 1460.00,1071.60 1460.00,1071.60
      1460.00,1071.60 1522.00,1059.20 1522.00,1059.20
      1522.00,1059.20 1598.00,1044.00 1598.00,1044.00
      1598.00,1044.00 1641.00,1035.00 1641.00,1035.00
      1641.00,1035.00 1649.88,1068.00 1649.88,1068.00
      1649.88,1068.00 1662.00,1115.00 1662.00,1115.00
      1662.00,1115.00 1640.00,1119.20 1640.00,1119.20
      1640.00,1119.20 1603.00,1126.60 1603.00,1126.60
      1603.00,1126.60 1493.00,1148.60 1493.00,1148.60
      1493.00,1148.60 1445.00,1158.00 1445.00,1158.00
      1445.00,1158.00 1438.88,1136.00 1438.88,1136.00
      1438.88,1136.00 1430.58,1101.00 1430.58,1101.00
      1430.58,1101.00 1426.00,1078.00 1426.00,1078.00 Z`,

    is_sell: false,
    is_under_c: true,
    svg_style: {
      x: 1490,
      y: 1060,
      width: 70,
      height: 70,
    },
  },
  {
    plotType: "KingFisher, 3BHK",
    area: 440,
    status: "Vacant",
    architect: "R+R",
    id: "320",
    name: "Binita Joshi",
    x_pos: -0.6014,
    y_pos: -1.6253,
    z_pos: DEFAULT_ZOOM,
    svgPath: `M 1592.00,1597.00
    C 1592.00,1597.00 1698.00,1595.00 1698.00,1595.00
      1698.00,1595.00 1715.00,1594.00 1715.00,1594.00
      1715.00,1594.00 1738.00,1594.00 1738.00,1594.00
      1738.00,1594.00 1755.00,1593.00 1755.00,1593.00
      1755.00,1593.00 1777.00,1593.00 1777.00,1593.00
      1777.00,1593.00 1789.00,1592.04 1789.00,1592.04
      1789.00,1592.04 1806.00,1592.04 1806.00,1592.04
      1806.00,1592.04 1807.00,1614.00 1807.00,1614.00
      1807.00,1614.00 1807.00,1641.00 1807.00,1641.00
      1807.00,1641.00 1806.00,1658.00 1806.00,1658.00
      1806.00,1658.00 1806.00,1689.00 1806.00,1689.00
      1806.00,1689.00 1803.00,1734.00 1803.00,1734.00
      1803.00,1734.00 1777.00,1730.27 1777.00,1730.27
      1777.00,1730.27 1729.00,1723.27 1729.00,1723.27
      1729.00,1723.27 1661.00,1713.57 1661.00,1713.57
      1661.00,1713.57 1596.00,1704.00 1596.00,1704.00
      1596.00,1704.00 1596.00,1667.00 1596.00,1667.00
      1596.00,1667.00 1596.96,1648.00 1596.96,1648.00
      1596.96,1648.00 1592.00,1597.00 1592.00,1597.00 Z`,

    is_sell: true,
    is_under_c: false,
    svg_style: {
      x: 1640,
      y: 1615,
      width: 70,
      height: 70,
    },
  },
  {
    plotType: "Humming Bird, 3BHK",
    area: 440,
    status: "Vacant",
    architect: "R+R",
    id: "331",
    name: "Kalpesh Gandhi",
    x_pos: -0.6014,
    y_pos: -1.6253,
    z_pos: DEFAULT_ZOOM,
    is_sell: true,
    is_under_c: false,
    svgPath: `M 1289.00,1618.00
    C 1289.00,1618.00 1306.00,1619.00 1306.00,1619.00
      1306.00,1619.00 1344.00,1621.00 1344.00,1621.00
      1344.00,1621.00 1354.00,1622.00 1354.00,1622.00
      1354.00,1622.00 1364.00,1622.00 1364.00,1622.00
      1364.00,1622.00 1378.00,1623.00 1378.00,1623.00
      1378.00,1623.00 1388.00,1623.91 1388.00,1623.91
      1388.00,1623.91 1412.00,1625.00 1412.00,1625.00
      1412.00,1625.00 1429.00,1626.00 1429.00,1626.00
      1429.00,1626.00 1444.00,1627.00 1444.00,1627.00
      1444.00,1627.00 1481.00,1629.00 1481.00,1629.00
      1481.00,1629.00 1491.00,1629.91 1491.00,1629.91
      1491.00,1629.91 1507.00,1630.83 1507.00,1630.83
      1507.00,1630.83 1516.83,1632.57 1516.83,1632.57
      1516.83,1632.57 1519.00,1650.00 1519.00,1650.00
      1519.00,1650.00 1519.00,1711.00 1519.00,1711.00
      1519.00,1711.00 1516.00,1747.00 1516.00,1747.00
      1516.00,1747.00 1463.00,1740.84 1463.00,1740.84
      1463.00,1740.84 1352.00,1728.28 1352.00,1728.28
      1352.00,1728.28 1308.00,1723.28 1308.00,1723.28
      1305.24,1722.94 1293.11,1722.18 1291.60,1720.98
      1289.22,1719.08 1290.59,1714.59 1290.83,1712.00
      1290.83,1712.00 1292.00,1689.00 1292.00,1689.00
      1292.00,1689.00 1292.91,1675.00 1292.91,1675.00
      1292.91,1675.00 1292.00,1655.00 1292.00,1655.00
      1292.00,1655.00 1289.00,1618.00 1289.00,1618.00 Z`,
    svg_style: {
      x: 1340,
      y: 1640,
      width: 70,
      height: 70,
    },
  },
  {
    plotType: "Humming Bird, 3BHK",
    area: 550,
    status: "Vacant",
    architect: "DPA",
    id: "336",
    name: "Rakesh Sahu",
    x_pos: -0.4946,
    y_pos: -0.9168,
    z_pos: DEFAULT_ZOOM,
    is_sell: true,
    is_under_c: false,
    svgPath: `M 1152.00,1246.00
    C 1152.00,1246.00 1147.75,1233.00 1147.75,1233.00
      1147.75,1233.00 1140.73,1208.00 1140.73,1208.00
      1140.73,1208.00 1133.58,1173.00 1133.58,1173.00
      1133.58,1173.00 1130.13,1155.00 1130.13,1155.00
      1130.13,1155.00 1130.13,1147.60 1130.13,1147.60
      1130.13,1147.60 1144.00,1144.41 1144.00,1144.41
      1144.00,1144.41 1186.00,1138.13 1186.00,1138.13
      1186.00,1138.13 1292.00,1122.58 1292.00,1122.58
      1292.00,1122.58 1355.00,1114.00 1355.00,1114.00
      1355.00,1114.00 1366.87,1160.00 1366.87,1160.00
      1366.87,1160.00 1372.00,1184.00 1372.00,1184.00
      1372.00,1184.00 1321.00,1198.29 1321.00,1198.29
      1321.00,1198.29 1215.00,1228.42 1215.00,1228.42
      1215.00,1228.42 1152.00,1246.00 1152.00,1246.00 Z`,
    svg_style: {
      x: 1214,
      y: 1226,
      width: 70,
      height: 70,
    },
  },
  {
    id: "310",
    name: "Ritesh Prajapati",
    x_pos: -0.7508,
    y_pos: -0.9256,
    z_pos: DEFAULT_ZOOM,
    is_sell: false,
    is_under_c: true,
    svgPath: `M 1868.00,1188.00
    C 1868.00,1188.00 1831.00,1199.28 1831.00,1199.28
      1831.00,1199.28 1764.00,1220.00 1764.00,1220.00
      1764.00,1220.00 1736.00,1228.67 1736.00,1228.67
      1733.45,1229.50 1726.72,1232.04 1724.30,1231.23
      1721.27,1230.21 1719.17,1220.07 1718.29,1217.00
      1718.29,1217.00 1706.00,1175.00 1706.00,1175.00
      1705.05,1171.70 1701.05,1161.46 1703.64,1158.93
      1705.05,1157.55 1712.66,1155.95 1715.00,1155.29
      1715.00,1155.29 1744.00,1147.58 1744.00,1147.58
      1744.00,1147.58 1815.00,1128.58 1815.00,1128.58
      1815.00,1128.58 1857.00,1118.00 1857.00,1118.00
      1857.00,1118.00 1868.00,1188.00 1868.00,1188.00 Z`,
    svg_style: {
      x: 1788,
      y: 1134,
      width: 70,
      height: 70,
    },
  },
  {
    project_id: 11,
    plotType: "KingFisher, 4BHK",
    area: 575,
    status: "Work In Progress",
    architect: "DPA",
    id: "311",
    name: "Ketan Maru",
    x_pos: -0.7508,
    y_pos: -0.9256,
    z_pos: DEFAULT_ZOOM,
    is_sell: true,
    is_under_c: false,
    svgPath: `M 1681.00,1085.00
    C 1681.00,1085.00 1725.00,1076.60 1725.00,1076.60
      1725.00,1076.60 1804.00,1061.00 1804.00,1061.00
      1804.00,1061.00 1848.00,1053.00 1848.00,1053.00
      1848.00,1053.00 1856.00,1118.00 1856.00,1118.00
      1856.00,1118.00 1821.00,1127.12 1821.00,1127.12
      1821.00,1127.12 1741.00,1148.37 1741.00,1148.37
      1741.00,1148.37 1713.00,1155.71 1713.00,1155.71
      1710.43,1156.44 1704.93,1158.52 1702.56,1156.94
      1700.19,1155.36 1697.08,1142.32 1696.14,1139.00
      1696.14,1139.00 1685.58,1103.00 1685.58,1103.00
      1683.84,1097.05 1681.56,1091.20 1681.00,1085.00 Z`,
    svg_style: {
      x: 1720,
      y: 1072,
      width: 70,
      height: 70,
    },
  },
  {
    area: 575,
    status: "Vacant",
    id: "418",
    name: "Sale In Progress",
    x_pos: -0.1383,
    y_pos: -1.1552,
    z_pos: DEFAULT_ZOOM,
    is_sell: true,
    is_under_c: false,
    svgPath: `M 918.00,1215.00
    C 932.83,1215.32 948.76,1221.90 958.67,1233.09
      958.67,1233.09 967.29,1246.00 967.29,1246.00
      971.01,1252.03 971.54,1256.48 973.67,1263.00
      973.67,1263.00 984.00,1296.00 984.00,1296.00
      984.00,1296.00 1001.57,1349.00 1001.57,1349.00
      1001.57,1349.00 1008.21,1378.00 1008.21,1378.00
      1010.70,1389.52 1013.89,1401.14 1014.00,1413.00
      1014.00,1413.00 911.00,1399.00 911.00,1399.00
      911.00,1399.00 912.00,1376.00 912.00,1376.00
      912.00,1376.00 914.00,1314.00 914.00,1314.00
      914.00,1314.00 915.00,1299.00 915.00,1299.00
      915.00,1299.00 915.00,1286.00 915.00,1286.00
      915.00,1286.00 916.04,1271.00 916.04,1271.00
      916.04,1271.00 916.04,1257.00 916.04,1257.00
      916.04,1257.00 917.00,1245.00 917.00,1245.00
      917.00,1245.00 917.00,1234.00 917.00,1234.00
      917.00,1234.00 918.00,1215.00 918.00,1215.00 Z`,
    svg_style: {
      x: 920,
      y: 1300,
      width: 70,
      height: 70,
    },
  },
  {
    plotType: "KingFisher, 3BHK",
    area: 575,
    status: "Completed",
    architect: "DPA",
    id: "419",
    name: "Kartikey Sukla",
    x_pos: -0.1383,
    y_pos: -1.1552,
    z_pos: DEFAULT_ZOOM,
    is_sell: false,
    is_under_c: true,
    svgPath: `M 834.00,1383.00
    C 834.00,1383.00 835.84,1367.00 835.84,1367.00
      838.90,1338.41 843.67,1310.04 850.12,1282.00
      853.89,1265.60 853.28,1264.25 859.99,1248.00
      861.39,1244.60 862.17,1242.04 864.40,1239.00
      872.53,1227.90 880.50,1223.29 893.00,1218.58
      893.00,1218.58 902.00,1215.52 902.00,1215.52
      902.00,1215.52 918.00,1214.00 918.00,1214.00
      918.00,1214.00 917.00,1235.00 917.00,1235.00
      917.00,1235.00 916.00,1269.00 916.00,1269.00
      916.00,1269.00 915.00,1283.00 915.00,1283.00
      915.00,1283.00 914.00,1317.00 914.00,1317.00
      914.00,1317.00 912.96,1333.00 912.96,1333.00
      912.96,1333.00 912.96,1346.00 912.96,1346.00
      912.96,1346.00 911.96,1358.00 911.96,1358.00
      911.96,1358.00 911.96,1371.00 911.96,1371.00
      911.96,1371.00 911.00,1382.00 911.00,1382.00
      911.00,1382.00 911.00,1398.00 911.00,1398.00
      911.00,1398.00 834.00,1383.00 834.00,1383.00 Z`,
    svg_style: {
      x: 836,
      y: 1280,
      width: 70,
      height: 70,
    },
  },
  {
    plotType: "Francolin, 3BHK",
    area: 575,
    status: "Vacant",
    architect: "DPA",
    id: "427",
    name: "Kartikey Sukla",
    x_pos: -0.0146,
    y_pos: -1.5455,
    z_pos: DEFAULT_ZOOM,
    svg_x_pos: "689.00",
    svg_y_pos: "1539.00",
    is_sell: true,
    is_under_c: false,
    svgPath: `M 687.00,1537.00
    C 687.00,1537.00 710.00,1538.04 710.00,1538.04
      710.00,1538.04 723.00,1538.04 723.00,1538.04
      723.00,1538.04 735.00,1539.00 735.00,1539.00
      735.00,1539.00 774.00,1540.00 774.00,1540.00
      774.00,1540.00 766.00,1613.00 766.00,1613.00
      766.00,1613.00 745.00,1612.00 745.00,1612.00
      745.00,1612.00 722.00,1610.96 722.00,1610.96
      722.00,1610.96 710.00,1610.96 710.00,1610.96
      710.00,1610.96 699.00,1610.00 699.00,1610.00
      699.00,1610.00 683.00,1610.00 683.00,1610.00
      683.00,1610.00 686.04,1549.00 686.04,1549.00
      686.04,1549.00 687.00,1537.00 687.00,1537.00 Z`,
    svg_style: {
      x: 689,
      y: 1539,
      width: 50,
      height: 50,
    },
  },
  {
    plotType: "Francolin, 3BHK",
    area: 475,
    status: "Vacant",
    architect: "DPA",
    id: "506",
    name: "Kartikey Sukla",
    x_pos: -0.8304,
    y_pos: -0.2225,
    z_pos: DEFAULT_ZOOM,
    svg_x_pos: "1779.00",
    svg_y_pos: "453.00",
    is_sell: false,
    is_under_c: false,
    svgPath: `M 1777.00,451.00
    C 1777.00,451.00 1829.00,455.83 1829.00,455.83
      1829.00,455.83 1899.00,463.00 1899.00,463.00
      1899.00,463.00 1895.00,484.00 1895.00,484.00
      1895.00,484.00 1886.58,523.00 1886.58,523.00
      1886.58,523.00 1882.00,545.00 1882.00,545.00
      1882.00,545.00 1867.00,545.00 1867.00,545.00
      1867.00,545.00 1856.00,544.04 1856.00,544.04
      1856.00,544.04 1845.00,544.04 1845.00,544.04
      1845.00,544.04 1823.00,543.00 1823.00,543.00
      1823.00,543.00 1808.00,542.00 1808.00,542.00
      1808.00,542.00 1771.00,541.00 1771.00,541.00
      1771.00,510.90 1774.57,480.96 1777.00,451.00 Z`,
  },
  {
    plotType: "Humming Bird, 4BHK",
    area: 475,
    status: "Work In Progress",
    architect: "DPA",
    id: "539",
    name: "Rajesh Dhilon",
    x_pos: -0.1017,
    y_pos: -0.2188,
    z_pos: DEFAULT_ZOOM,
    svg_x_pos: "842.00",
    svg_y_pos: "559.00",
    is_sell: false,
    is_under_c: false,
    svgPath: `M 840.00,557.00
    C 840.00,557.00 871.00,553.28 871.00,553.28
      871.00,553.28 928.00,546.84 928.00,546.84
      928.00,546.84 961.00,543.00 961.00,543.00
      961.00,543.00 973.00,614.00 973.00,614.00
      973.00,614.00 949.00,617.28 949.00,617.28
      949.00,617.28 905.00,623.28 905.00,623.28
      905.00,623.28 851.00,630.00 851.00,630.00
      851.00,630.00 840.00,557.00 840.00,557.00 Z`,
  },
  {
    plotType: "KingFisher, 3BHK",
    area: 475,
    status: "Work In Progress",
    architect: "DPA",
    id: "552",
    name: "Dipak Sukla",
    x_pos: 0,
    y_pos: -0.1296,
    z_pos: DEFAULT_ZOOM,
    is_sell: false,
    is_under_c: true,
    svgPath: `M 515.00,513.00
    C 515.00,513.00 529.00,513.00 529.00,513.00
      529.00,513.00 544.00,511.96 544.00,511.96
      544.00,511.96 557.00,511.96 557.00,511.96
      557.00,511.96 569.00,510.96 569.00,510.96
      569.00,510.96 582.00,510.96 582.00,510.96
      582.00,510.96 594.00,510.00 594.00,510.00
      594.00,510.00 632.00,509.00 632.00,509.00
      632.00,509.00 633.00,530.00 633.00,530.00
      633.00,530.00 634.00,568.00 634.00,568.00
      634.00,568.00 635.00,582.04 635.00,582.04
      635.00,582.04 619.00,582.04 619.00,582.04
      619.00,582.04 607.00,583.00 607.00,583.00
      607.00,583.00 534.00,584.00 534.00,584.00
      530.41,584.01 520.01,585.91 518.02,583.40
      516.77,581.82 517.00,577.98 517.00,576.00
      517.00,576.00 517.00,570.00 517.00,570.00
      517.00,570.00 516.00,555.00 516.00,555.00
      516.00,555.00 515.00,513.00 515.00,513.00 Z`,
    svg_style: {
      x: 517,
      y: 515,
      width: 70,
      height: 70,
    },
  },
  {
    plotType: "Woodpeacker, 3BHK",
    area: 475,
    status: "Work In Progress",
    architect: "DPA",
    id: "553",
    name: "James Christy",
    x_pos: 0,
    y_pos: -0.1296,
    z_pos: DEFAULT_ZOOM,
    is_sell: false,
    is_under_c: true,
    svgPath: `M 509.00,427.00
    C 509.00,427.00 578.00,421.91 578.00,421.91
      578.00,421.91 613.00,419.55 613.00,419.55
      625.02,420.89 628.98,428.82 629.00,440.00
      629.00,440.00 630.00,460.00 630.00,460.00
      630.00,460.00 631.00,488.00 631.00,488.00
      631.00,488.00 632.00,509.00 632.00,509.00
      632.00,509.00 588.00,510.00 588.00,510.00
      588.00,510.00 571.00,511.00 571.00,511.00
      571.00,511.00 527.00,512.00 527.00,512.00
      527.00,512.00 513.00,513.00 513.00,513.00
      513.00,513.00 512.00,492.00 512.00,492.00
      512.00,492.00 511.04,480.00 511.04,480.00
      511.04,480.00 511.04,466.00 511.04,466.00
      511.04,466.00 510.00,449.00 510.00,449.00
      510.00,449.00 509.00,427.00 509.00,427.00 Z`,
    svg_style: {
      x: 511,
      y: 429,
      width: 70,
      height: 70,
    },
  },
  {
    id: "554",
    name: "Rutul Shah",
    x_pos: 0,
    y_pos: -0.1296,
    z_pos: DEFAULT_ZOOM,
    is_sell: false,
    is_under_c: true,
    svgPath: `M 364.00,527.00
    C 364.00,527.00 362.00,492.00 362.00,492.00
      362.00,492.00 361.00,476.00 361.00,476.00
      361.00,476.00 360.00,460.00 360.00,460.00
      359.97,457.26 359.90,453.63 360.53,451.00
      364.40,434.76 379.36,438.21 392.00,436.72
      392.00,436.72 452.00,431.17 452.00,431.17
      452.00,431.17 474.00,429.00 474.00,429.00
      474.00,429.00 476.00,449.00 476.00,449.00
      476.00,449.00 477.00,467.00 477.00,467.00
      477.00,467.00 478.00,480.00 478.00,480.00
      478.00,480.00 478.00,489.00 478.00,489.00
      478.00,489.00 479.00,499.00 479.00,499.00
      479.00,499.00 480.00,519.00 480.00,519.00
      480.00,519.00 453.00,520.91 453.00,520.91
      453.00,520.91 393.00,525.09 393.00,525.09
      393.00,525.09 364.00,527.00 364.00,527.00 Z`,
    svg_style: {
      x: 380,
      y: 450,
      width: 70,
      height: 70,
    },
  },
  {
    id: "555",
    name: "Jay Patel",
    x_pos: 0,
    y_pos: -0.1296,
    z_pos: DEFAULT_ZOOM,

    is_sell: false,
    is_under_c: true,
    svgPath: `M 363.00,526.00
    C 363.00,526.00 389.00,524.09 389.00,524.09
      389.00,524.09 446.00,520.91 446.00,520.91
      446.00,520.91 479.00,519.00 479.00,519.00
      479.00,519.00 480.00,576.00 480.00,576.00
      480.00,576.00 481.00,591.00 481.00,591.00
      481.00,591.00 452.00,593.09 452.00,593.09
      452.00,593.09 398.00,596.91 398.00,596.91
      398.00,596.91 367.00,599.00 367.00,599.00
      367.00,599.00 365.00,571.00 365.00,571.00
      365.00,571.00 363.00,526.00 363.00,526.00 Z`,
    svg_style: {
      x: 380,
      y: 528,
      width: 70,
      height: 70,
    },
  },
];

// setup search
const search_opts = {
  shouldSort: true,
  tokenize: true,
  findAllMatches: false,
  threshold: 1,
  location: 0,
  distance: 32,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  includeScore: true,
  keys: ["id", "name"],
};

export const plotSearch = new Fuse(PLOT_DETAILS, search_opts);

export const getImageXY = (plotDetails, imageDims) => {
  const originalWidth = 3634;
  const originalHeight = 2716;

  return {
    x_pos: (plotDetails.x_pos * imageDims.width) / originalWidth,
    y_pos: (plotDetails.y_pos * imageDims.height) / originalHeight,
  };
};
