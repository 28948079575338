import React from "react";
import { formatDate } from "../../common/utils";

export default ({ title, planningList = [] }) => {
  /**
   * Dumb component
   * Render list of micro plannings from props
   *
   * Parent:
   * 		ProjectSummeryList
   */

  if (planningList.length) {
    return (
      <div className="db-planning-list-wrapper">
        <h2 className="project-summery-title">{title}</h2>

        {planningList.map((planning, i) => {
          let activityName = "";
          if (planning.activity_only) {
            activityName = planning.activity_name;
          } else {
            activityName = `${planning.activity_name} - ${planning.subactivity_name}`;
          }

          let unitName = planning.unit_name;
          if (planning.subunit_name) {
            unitName += `- ${planning.subunit_name}`;
          }

          return (
            <div className="db-pl-block" key={i}>
              <div className="title">
                {activityName} ,<span className="info">{unitName}</span>
              </div>
              <div className="date">
                {formatDate(planning.start_date)} To{" "}
                {formatDate(planning.end_date)}
              </div>
              <div className="summery">{planning.summery}</div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};
