import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { get } from "lodash"

import LoadingButton from '../../common/components/LoadingButton'
import ErrorBlock from '../../common/components/ErrorBlock'
import { CloseIcon, CheckIcon } from '../../common/SvgIcons'

import { updateProjectSubunitData, updateProjectFormData } from '../data/adminProject.actions'
import { addNotification } from '../../common/data/common.actions'
import { postJsonData } from "../../common/utils";
import Urls from "../../UrlConfig";

/**
 * Scss
 * 		_project_unit_list
 * 
 * Common
 * 		_common_page_layout
 */

class SubunitUpdatePopup extends Component {

    /**
     * render unit add-edit model
     * 
     * Parent:
     *      UnitList
     */

    constructor(props){
        super(props)

        this.state = {
            ...props.unitData,

            deletedSubIds : [],
            submitting: false,
            deleting: false,
            error: {}
        }
    }

    submitSubunit = () => {

        const { subunits, deletedSubIds, id, name } = this.state

        let hasError = false, errorData = {}
        for (let index = 0; index < subunits.length; index++) {
            const currAct = subunits[index];
            if(currAct.name) { } 
            else {
                errorData[index] = ["This field cannot be empty."]
                hasError = true
            }
        }

        if(hasError) {
            this.setState({ error: errorData })
            return
        }

        // add index in data
		const newSubunits = subunits.map((currAct, ind) => {
			return {...currAct, index: ind}
        })
        
        const data = { subunits : newSubunits, deleted_subunits: deletedSubIds }

        this.setState({ submitting: true, error: {} })
        postJsonData(Urls.apiUpsertSubUnit(this.props.projectId, id),
            data,
			(res) => {
                this.setState({ submitting: false, error: {} })
                this.props.updateProjectSubunitData(res)
                this.props.closeModel()
                this.props.addNotification({
                    type: 'info',
                    icon: <CheckIcon/>,
                    title: "Subunit Update",
                    text: `subunits for unit ${name} updated successfully`,
                })
			},
			(err) => {
                this.setState({ submitting: false, error: err.error_data })
                this.props.addNotification({
                    type: 'error',
                    title: "Subunit Update",
                    text: 'Input error',
                })
			}
		)
    }

    deleteStructure = () => {
        const { id, name } = this.state

        this.setState({ deleting: true })

        const data = { units : [], deleted_units: [id] }

        postJsonData(Urls.apiUpsertUnit(this.props.projectId),
            data,
			(res) => {
                this.setState({ deleting: false })
                this.props.updateProjectFormData(res)
                this.props.closeModel()
                this.props.addNotification({
                    type: 'info',
                    icon: <CheckIcon/>,
                    title: "Unit Detele",
                    text: `${name} deteted successfully`,
                })
			},
			(err) => {
                this.setState({ deleting: false })
                this.props.addNotification({
                    type: 'error',
                    title: "Unit Detele",
                    text: 'Input error',
                })
			}
		)
    }

	handleAddSubunitForm = () => {
		const newSubunit = {name: '', index: ''}
		const currSubunitList = [...this.state.subunits]

		this.setState({subunits: [...currSubunitList, newSubunit]})
	}

	handleSubunitNameChange = (index) => (e) => {
		const newSubunitList = [...this.state.subunits]
		newSubunitList[index] = {
			...newSubunitList[index],
			name: e.target.value
		}
		this.setState({subunits: newSubunitList})
	}

	// in edit, remove will update data only no delete from backend
	handleRemoveSubunit = (index, subunit) => () => {
		const newSubunitList = [...this.state.subunits]
		newSubunitList.splice(index, 1)

		const currDeletedSubIds = [...this.state.deletedSubIds]
		// if subunit have id, then only delete
		if(get(subunit, 'id', null)) {
			currDeletedSubIds.push(subunit.id)
		}

        this.setState({subunits: newSubunitList, 
            deletedSubIds: currDeletedSubIds, error: {} })
	}

	handleMoveUp = (index) => () => {
		// cant move if ele on top
		if (index === 0) return

		let newSubunitList = [...this.state.subunits]
		const currSubAct = newSubunitList[index]
		const prevSubAct = newSubunitList[index - 1]
		// exchange current and upper index data
		newSubunitList[index] = prevSubAct
		newSubunitList[index - 1] = currSubAct

		this.setState({subunits: newSubunitList})

	}

	handleMoveDown = (index) => () => {
		// cant move if ele on bottom
		if (index === this.state.subunits.length - 1) return

		let newSubunitList = [...this.state.subunits]
		const currSubAct = newSubunitList[index]
		const nextSubAct = newSubunitList[index + 1]
		// exchange current and down index data
		newSubunitList[index] = nextSubAct
		newSubunitList[index + 1] = currSubAct

		this.setState({subunits: newSubunitList})
	}

    render = () => {
        const { closeModel } = this.props
        const { subunits, name, submitting, error } = this.state

        return (
            <div className="unit-model-wrapper">
                <div className="close-icon-wrapper" onClick={closeModel}>
                    <CloseIcon />
                </div>

                <div className="form-wrapper">
                    <div className="form-label">Unit</div>

                    <div className="unit-box">
                        <div className="unit-text">{name}</div>
                    </div>

                    <div className="form-label sub-title">Subunit</div>

                    {subunits.map((subunit, ind) => {
                        return (
                            <div className="subunit-block" key={ind}>
                                <div className="arrow-block">
                                    <div className="arrow-up" onClick={this.handleMoveUp(ind)}></div>
                                    <div className="arrow-down" onClick={this.handleMoveDown(ind)}></div>
                                </div>
                                <div className="ind-text">{ind + 1}</div>
                                <div className="form-input">
                                    <input type="text"
                                        placeholder="Enter Subunit Name"
                                        value={subunit.name}
                                        onChange={this.handleSubunitNameChange(ind)}
                                    />
                                    <ErrorBlock error={error} field={String(ind)} />
                                </div>
                                <div className="remove-text" onClick={this.handleRemoveSubunit(ind, subunit)}>Remove</div>
                            </div>
                        )
                    })}

                    <div className="add-sub-btn" onClick={this.handleAddSubunitForm}>
                        <div className="add-sub-btn-text">Add Subunit</div>
                    </div>

                    <div className="form-action-btn">
                        <div className="action left-ac">
                            <LoadingButton className="btn primary-btn"
                                title="Save Subunit"
                                isLoading={submitting}
                                onClick={this.submitSubunit} />
                        </div>
                        <div className="action right-ac">
                            <LoadingButton className="btn secondary-btn"
                                title="Cancel"
                                onClick={closeModel}
                            />
                        </div>
                    </div>
                    <div className="delete-action" onClick={this.deleteStructure}>Delete Unit</div>
                </div>
            </div>
        )
    }
}

export default connect(store => ({
    projectId: store.projectForm.formData.id,
}), { updateProjectSubunitData, 
    updateProjectFormData, 
addNotification } )(SubunitUpdatePopup)


SubunitUpdatePopup.propTypes = {
    // selected unit id
    unitId: PropTypes.number.isRequired,
    // unit data
    unitData: PropTypes.object.isRequired,
    // for close model
	closeModel: PropTypes.func.isRequired
}