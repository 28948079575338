import React, { Component } from 'react'

import { UnauthorisedIcon } from '../SvgIcons'
import LoadingButton from '../components/LoadingButton'

import Urls from '../../UrlConfig'

export default class UnAuthorizedPage extends Component {

    /**
     * Parent:
     *      App
     */

    handleHomeClick = (event) => {
        if (event) event.stopPropagation();
        this.props.history.push(Urls.getHomePage())
    }

    handleBackClick = (event) => {
        if (event) event.stopPropagation();
        this.props.history.goBack()
    }

    render = () => {
        return (
            <div className="page-not-found">
                <div className="page-not-found-col">
                    <div className="page-not-found-content">
                        <UnauthorisedIcon />
                        <h2>UnAuthorized</h2>
                        <div>
                            <LoadingButton className="primary-btn"
                                title="Go Back" onClick={this.handleBackClick} />
                            <LoadingButton className="secondary-btn"
                                title="Go to Home" onClick={this.handleHomeClick} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
