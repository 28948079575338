import React from 'react'
import { Redirect } from 'react-router-dom'

import Urls from '../../UrlConfig'

/**
 * Hoc component to check user is login or not, 
 * any form, data api return 401, user logout
 * in that case redirect user to login page
 */
export default (Component) => (props) => {

    if(props.logged) {
        return <Component {...props}/>
    }
    else {
        return <Redirect to={Urls.getAuthPages('login')}/>
    }
}

