import {
  addDays,
  differenceInCalendarDays,
  isAfter,
  isSameDay,
} from "date-fns";

import { find, get } from "lodash";

/**
 * Check if new calculated planning endDate has holidays
 * if it does adds those days to current endDate, checks for other holidays
 * returns new calculated endDate
 *
 * @param {Array} holidayList { id, name, start_date, end_date, no_of_days }; dates are JS date objects
 */
export const checkAndAddHolidays = (holidayList, start_date, endDate) => {
  let has_holidays = false;
  let no_of_holidays = 0;
  let newEndDate = endDate;
  // loop over holidays to calculate new holidays and endDate
  for (let index = 0; index < holidayList.length; index++) {
    const currHoliday = holidayList[index];
    const deltaDays = differenceInCalendarDays(
      currHoliday.start_date,
      newEndDate
    );

    if (deltaDays > 0) {
      // has no holidays
      return [has_holidays, no_of_holidays, newEndDate];
    } else {
      // currHoliday start and endDate are same
      if (isSameDay(endDate, currHoliday.start_date)) {
        has_holidays = true;
        no_of_holidays += currHoliday.no_of_days;
        newEndDate = addDays(newEndDate, currHoliday.no_of_days);
        continue;
      } // currHoliday end and start_date is same, add manual 1 day in calculate 1 day
      else if (isSameDay(start_date, currHoliday.end_date)) {
        has_holidays = true;
        no_of_holidays += 1;

        newEndDate = addDays(newEndDate, 1);
        continue;
      }
      // currHoliday ended before start_date
      if (isAfter(start_date, currHoliday.end_date)) {
        // this are previouse old holidays
        continue;
      }
      // has holiday
      has_holidays = true;
      if (isAfter(start_date, currHoliday.start_date)) {
        // start date is in between holiday start - end dates
        const currHolidayCount =
          differenceInCalendarDays(currHoliday.end_date, start_date) + 1;
        no_of_holidays += currHolidayCount;
        newEndDate = addDays(newEndDate, currHolidayCount);
      } else {
        // entire holiday is between curr planning start - end dates
        no_of_holidays += currHoliday.no_of_days;
        newEndDate = addDays(newEndDate, currHoliday.no_of_days);
      }
    }
  }
  // no holidays found return default
  return [has_holidays, no_of_holidays, newEndDate];
};

export const transformPlanningData = (planning, project) => {
  let data = {};

  data.project_id = planning.project.id;
  data.unit_id = planning.unit.id;
  data.activity_id = planning.activity.id;

  data.subunits_id = planning.subunit.id;
  const selectedUnit = find(project.units, ["id", data.unit_id]);
  data.subunits_list = selectedUnit.subunits;

  data.start_date = new Date(planning.start_date);
  data.end_date = new Date(planning.end_date);
  data.has_holidays = planning.has_holidays;
  data.no_of_days = planning.no_of_days;

  let newSubactivityList = [];
  for (let index = 0; index < planning.micro_planning.length; index++) {
    const mpl = planning.micro_planning[index];
    newSubactivityList.push({
      mplId: mpl.id,
      id: get(mpl, "subactivity.id", null), // id is activity id
      name: get(mpl, "subactivity.name", null),
      enabled: mpl.enabled,
      activity_only: mpl.activity_only,
      start_date: new Date(mpl.start_date),
      end_date: new Date(mpl.end_date),
      no_of_days: mpl.no_of_days,
      has_holidays: mpl.has_holidays,
      no_of_holidays: mpl.no_of_holidays,
    });
  }

  data.subactivity = newSubactivityList;
  // helper
  data.isDependentPlanning = Boolean(planning.depends_on.length);
  data.dpndPlnId = get(planning, "depends_on.0.id", "");

  return { ...data };
};

/**
 * return 4 status wise planning list from full plannig list
 *
 * @param {*} mplList - full microplanning list
 */
export const createStatusWiseMplList = (mplList) => {
  const mpWaitingList = [];
  const mpRunningList = [];
  const mpCompletedList = [];
  const mpScheduledList = [];

  for (let index = 0; index < mplList.length; index++) {
    const currMp = mplList[index];

    if (currMp.status === "P") {
      mpScheduledList.push(currMp);
    } else if (currMp.status === "W") {
      mpWaitingList.push(currMp);
    } else if (currMp.status === "R") {
      mpRunningList.push(currMp);
    } else if (currMp.status === "C") {
      mpCompletedList.push(currMp);
    }
  }

  return { mpWaitingList, mpRunningList, mpCompletedList, mpScheduledList };
};

/**
 * returns filtered list by subunit
 *
 * @param {*} listData - list to filter; list must have subunit key { id, } as JSON object
 * @param {*} filterData - filter by list using subunits
 */
export const filterMPList = (listData, selectedUnitId, selectedSubunitId) => {
  const filterByUnit = selectedUnitId !== -1;
  const filterBySubUnit = selectedSubunitId !== -1;

  if (filterByUnit) {
    listData = listData.filter((data) => {
      return data.unit.id === selectedUnitId;
    });
  }
  if (filterBySubUnit) {
    listData = listData.filter((data) => {
      return data.subunit.id === selectedSubunitId;
    });
  }
  // no need to filter
  return listData;
};

/**
 * convert checklist data to frontend form shape
 *
 * return {
 * 		checklistData: planning_checklist_data,
 * 		checklistConfig: checklist_config_list,
 * 		selectionChecklist: converted checklist form from planning_checklist_data.checklist
 * }
 * @param {*} data - { checklist_config_list, planning_checklist_data }
 */
export const convertChecklistFormData = (data) => {
  const checklistConfig = data.checklist_config_list;
  const checklistData = data.planning_checklist_data;

  let selectionChecklist = [];
  // loop over checklist
  for (let index = 0; index < checklistData.checklist.length; index++) {
    const currCheck = checklistData.checklist[index];
    // get checklist_group id from first element of checklist item
    // to show selected in react select in case of edit
    const checkItem = get(
      currCheck,
      "quality_checklist_items.0.checkitem_config.checklist_group.id",
      null
    );
    // boolean to decide form is add or edit,
    // if check item fount in quality_checklist_items, form is edit
    let isChecklistEdit = false;
    if (checkItem) {
      isChecklistEdit = true;
    }
    let checkItemList = [];
    // loop over all checklist item of parent checklist and make list to show in frontend
    for (
      let index = 0;
      index < currCheck.quality_checklist_items.length;
      index++
    ) {
      const currQualityItems = currCheck.quality_checklist_items[index];
      checkItemList.push({
        id: get(currQualityItems, "checkitem_config.id", null),
        name: get(currQualityItems, "checkitem_config.name", "-NA-"),
      });
    }

    selectionChecklist.push({
      qualityCheckListId: currCheck.id,
      qualityCheckListName: currCheck.display_name,
      checkItem,
      checkItemList,
      isChecklistEdit,
    });
  }

  return { checklistData, selectionChecklist, checklistConfig };
};
