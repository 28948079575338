
import { getJsondata } from '../../common/utils'
import Urls from '../../UrlConfig'

///////////////////////////////////
//	    Project Form action		//
/////////////////////////////////

/**
 * get project details
 * 
 * @param {*} projectId - project id
 */
export const getProjectFormDetails = (projectId) => {

    return async dispatch => {
        dispatch({ type: 'PROJECT_FORM_DETAILS_FETCHING' })

        getJsondata(Urls.apiGetProjectDetails(projectId),
			(res) => {
                dispatch({ type: 'PROJECT_FORM_DETAILS_SUCCESS', payload: res })
			},
			(err) => {
                dispatch({ type: 'PROJECT_FORM_DETAILS_ERROR', payload: err })
			}
		)
    }
}

export const updateProjectFormData = (res) => ({ type: 'PROJECT_FORM_DETAILS_SUCCESS', payload: res })

export const updateProjectSubunitData = (res) => ({ type: 'PROJECT_DATA_SUBUNIT_SUBMIT_SUCCESS', payload: res })


export const resetProjectFormData = () => ({ type: "PROJECT_FORM_DATA_RESET"})