import React from 'react'
import { NavLink, Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { get } from 'lodash'

import HolidayListPage from './holiday/HolidayListPage'
import UserList from './userRole/UserList'
import ActivityListPage from './activity/ActivityListPage'
import CheckConfigListPage from './checklist_config/CheckConfigListPage'
import InvitationListPage from './user_invites/InvitationListPage'

import Urls from "../UrlConfig"

/**
 * Scss:
 * 		_common_page_layout, _inline_tabs
 */

export default (props) => {

	/**
	 * Parent:
	 * 		App
	 * 
	 * role 3 ( member ) - will not see this page
	 * role 2 ( project lead ) - will see Activities, Checklist only
	 * role 1 ( admin ) - will see all tabs
	 */

	const currentTab = get(props, 'match.params.tab', null)

	const windowHeight = useSelector(store => store.pageSize.windowHeight)
	const navbarHeight = useSelector(store => store.pageSize.navbarHeight)
	const logged = useSelector(store => store.auth.logged)
	const userRole = useSelector(store => store.auth.userRole)

	const pageHeight = windowHeight - navbarHeight

	if (!logged) {
		return <Redirect to={Urls.getAuthPages('login')} />
	}

	// role is not admin and current route holiday, user, invites, redirect 404
	if(userRole > 1 && (currentTab === 'holiday' || currentTab === 'user' || currentTab === 'invites')) {
		return <Redirect to={Urls.get404()} />
	}

	return (
		<div id="admin-home-page">
			<div className="page-content-wrapper"
				style={{ height: `${pageHeight}px` }}>
				<div className="container">
					<div className="page-inline-tab">
						{userRole === '1' &&
							<>
								<NavLink to={Urls.getAdminHomePage('holiday')}
									className="tab">Holidays</NavLink>
								<NavLink to={Urls.getAdminHomePage('user')}
									className="tab">Users</NavLink>
								<NavLink to={Urls.getAdminHomePage('invites')}
									className="tab">Invitations</NavLink>
							</>
						}
						<NavLink to={Urls.getAdminHomePage('activity')}
							className="tab">Activities</NavLink>
						<NavLink to={Urls.getAdminHomePage('checkConfig')}
							className="tab">Checklist</NavLink>
					</div>

					<Switch>
						<Route path={Urls.getAdminHomePage('holiday')} component={HolidayListPage} />
						<Route path={Urls.getAdminHomePage('user')} component={UserList} />
						<Route path={Urls.getAdminHomePage('invites')} component={InvitationListPage} />
						<Route path={Urls.getAdminHomePage('activity')} component={ActivityListPage} />
						<Route path={Urls.getAdminHomePage('checkConfig')} component={CheckConfigListPage} />
					</Switch>
				</div>
			</div>
		</div>
	)
}
