import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import CustomInput from '../../common/components/CustomInput'
import LoadingButton from '../../common/components/LoadingButton'
import {CheckIcon} from '../../common/SvgIcons'

import { updateProjectFormData } from '../data/adminProject.actions'
import { addNotification } from '../../common/data/common.actions'
import { postJsonData } from '../../common/utils'
import Urls from '../../UrlConfig'

/**
 * Scss
 * 		_project_unit_list
 * 
 * Common
 * 		_common_page_layout
 */

class UnitUpdatePopup extends Component {

    /**
     * render unit add-edit model
     * 
     * Parent:
     *      UnitList
     */

    constructor(props){
        super(props)

        this.isAdd = props.unitId === -1

        let initData
        if(this.isAdd) {
            initData = {name: ''}
        } else {
            initData = props.unitData
        }

        this.state = {
            ...initData,
            submitting: false,
            deleting: false,
            error: {}
        }
    }

    handleTextChange = (event) => {
		this.setState({ [event.target.name]: event.target.value })
    }
    
    submitStructure = () => {
        const { name, id } = this.state

        if(!name) {
            this.setState({ error : { name: ["This field is required."] } })
            return
        }

        this.setState({ submitting: true, error: {} })

        const data = { units : [{name, id}], deleted_units: [] }

        postJsonData(Urls.apiUpsertUnit(this.props.projectId),
            data,
			(res) => {
                this.setState({ submitting: false, error: {} })
                this.props.updateProjectFormData(res)
                this.props.closeModel()
                this.props.addNotification({
                    type: 'info',
                    icon: <CheckIcon/>,
                    title: this.isAdd ? "Unit Create" : "Unit Update",
                    text: `${name} ${this.isAdd ? "created" : "updated"} successfully`,
                })
			},
			(err) => {
                this.setState({ submitting: false, error: err.error_data })
                this.props.addNotification({
                    type: 'error',
                    title: this.isAdd ? "Unit Create" : "Unit Update",
                    text: 'Input error',
                })
			}
		)
    }

    deleteStructure = () => {
        const { id, name } = this.state

        this.setState({ deleting: true })

        const data = { units : [], deleted_units: [id] }

        postJsonData(Urls.apiUpsertUnit(this.props.projectId),
            data,
			(res) => {
                this.setState({ deleting: false })
                this.props.updateProjectFormData(res)
                this.props.closeModel()
                this.props.addNotification({
                    type: 'info',
                    icon: <CheckIcon/>,
                    title: "Unit Detele",
                    text: `${name} deteted successfully`,
                })
			},
			(err) => {
                this.setState({ deleting: false })
                this.props.addNotification({
                    type: 'error',
                    title: "Unit Detele",
                    text: 'Input error',
                })
			}
		)
    }

    render = () => {

        const { closeModel } = this.props
        const { name, error, submitting, deleting } = this.state

        return (
            <div className="page-model">
                <div className="page-model-wrapper">
                    <div className="page-model-cell">
                        <div className="page-model-content unit-form-wrapper">
                            <div className="model-form">
                                <div className="close-icon-wrapper" onClick={closeModel}>
                                    <div className="close-icon"></div>
                                </div>
                                <div className="title">{this.isAdd ? "Create Unit" : "Edit Unit"}</div>

                                <CustomInput
                                    label="Unit Name"
                                    errorData={error}
                                    errorField="name"
                                    name="name"
                                    placeholder="Enter Unit name"
                                    value={name}
                                    onChange={this.handleTextChange}
                                />

                                <LoadingButton className="btn primary-btn" 
                                    title={this.isAdd ? "Add Unit" : "Update Unit"}
                                    isLoading={submitting} onClick={this.submitStructure} 
                                />

                                {!this.isAdd && 
                                    <div className="unit-form-del">
                                        <div className="unit-form-del-text" 
                                            onClick={this.deleteStructure}>{ deleting ? "Loading..." : "Delete Unit"}</div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

UnitUpdatePopup.propTypes = {
    // -1 for add, otherwise edit
    unitId: PropTypes.number.isRequired,
    // empty obj in case of add
    unitData: PropTypes.object.isRequired,
    // for close model
	closeModel: PropTypes.func.isRequired
}

export default connect(store => ({
    projectId: store.projectForm.formData.id,
}),{ updateProjectFormData, addNotification })(UnitUpdatePopup)