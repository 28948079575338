import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { get } from 'lodash'

import Loader from '../../common/components/Loader'
import CustomInput from '../../common/components/CustomInput'
import CheckBox from '../../common/components/CheckBox'
import ErrorBlock from '../../common/components/ErrorBlock'
import LoadingButton from '../../common/components/LoadingButton'

import { getJsondata, postFormData } from '../../common/utils'
import { addNotification } from '../../common/data/common.actions'
import Urls from '../../UrlConfig'

/**
 * Scss:
 *      _profile_page
 */
class UserInvitePage extends Component {

    /**
     * verify uuid is valid or not
     * valid -> show user form, email disable
     *       -> submit form
     * not valid -> redirect to 404
     */
    constructor(props) {
        super(props)
        this.uuid = get(this.props, 'match.params.uuid', null)

        this.state = {
            // fetching user data from uuid
            fetching: true,
            fetched: false,
            fetchError: false,
            inviteData: {}
        }
    }

    componentDidMount = () => {
        this.verifyUUID()
    }

    verifyUUID = () => {
        getJsondata(Urls.apiUserVerifyInvitation(this.uuid),
            (res) => {
                this.setState({
                    fetching: false, fetched: true,
                    fetchError: false, inviteData: res
                })
            },
            (err) => {
                this.setState({ fetching: false, fetched: false, fetchError: true })
            }
        )
    }

    handleForget = (event) => {
        if (event) event.stopPropagation();
        this.props.history.push(Urls.getAuthPages('forget-password'))
    }

    handleHomeClick = (event) => {
        if (event) event.stopPropagation();
        this.props.history.push(Urls.getHomePage())
    }

    render = () => {
        const { fetchError } = this.state

        // redirect to 404 if uuid is not valid, or any error 
        if (fetchError) {
            return <Redirect to={Urls.get404()} />
        }

        return (
            <div id="profile-page">
                <div className="main-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col s12 m10 xl6 offset-m1 offset-xl3">
                                {this.renderPageContent()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderPageContent = () => {
        const { fetched, fetching, inviteData } = this.state
        const { history } = this.props

        if (fetched && !fetching) {

            if (get(inviteData, 'is_accepted', false)) {
                return (
                    <div className="invite-content">
                        <div className="big-title">Welcome</div>
                        <h2>You are already accepted invitation</h2>
                        <div>
                            <LoadingButton className="secondary-btn"
                                title="Forgot Password" onClick={this.handleForget} />
                            <LoadingButton className="primary-btn"
                                title="Go to Home" onClick={this.handleHomeClick} />
                        </div>
                    </div>
                )
            }
            else {
                return <UserInviteForm inviteData={inviteData} 
                    history={history} dispatch={this.props.dispatch} />
            }
        }
        else {
            return <Loader />
        }
    }
}

export default connect(null)(UserInvitePage)


class UserInviteForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            error: {},
            image: null,
            first_name: '',
            last_name: '',
            contact_no: '',
            email: get(props, 'inviteData.email', ''),
            role_name: get(props, 'inviteData.role_name', ''),
            showPassword: false,
            password: ''
        }

    }

    createProfile = (event) => {
        const { loading, email } = this.state
        if (loading) return
        if (event) event.preventDefault()

        const form = document.getElementById('create-profile');
        let data = new FormData(form);
        data.append('email', email)
        data.append('role', get(this.props, 'inviteData.role'))
        data.append('self_created', true)
        
        let error = {}, has_error = false

        if (!data.get('first_name')) {
            error['first_name'] = ['this field is required']
            has_error = true
        }

        if (!data.get('last_name')) {
            error['last_name'] = ['this field is required']
            has_error = true
        }

        if (!data.get('contact_no')) {
            error['contact_no'] = ['this field is required']
            has_error = true
        }

        if (!data.get('password')) {
            error['password'] = ['this field is required']
            has_error = true
        }

        if (has_error) {
            this.setState({ error })
            return
        }

        this.setState({ loading: true, error: {} })
        postFormData(Urls.apiCreateUser(), data,
            (res) => {
                this.props.history.push(Urls.getAuthPages('login'))
                this.props.dispatch(addNotification(
                    {
                        type: 'success',
                        title: 'Account Create',
                        text: 'Account created successfully. Please login',
                    }
                ))
            },
            (err) => {
                this.setState({ loading: false, error: err.error_data })
                this.props.dispatch(addNotification(
                    {
                        type: 'error',
                        title: 'Error',
                        text: 'Input error',
                    }
                ))
            }
        )
    }

    loadFile = (event) => {
        const selected_image = event.target.files[0]
        if (selected_image) {
            this.setState({ image: URL.createObjectURL(selected_image) })
        }
    }

    handleTextChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    }

    render = () => {

        const { error, loading, image, email,
            role_name, showPassword } = this.state

        return (
            <div className="profile-content">
                <form id="create-profile">

                    <div className="profile-info-wrapper">
                        <div className="profile-img-col">
                            <div className="profile-img-circle">
                                <input type="file" name="image" onChange={this.loadFile} />
                                <div className="upload-wrapper">
                                    <div className='upload-text'>Update Image</div>
                                </div>
                                <img src={image} alt="" />
                            </div>
                        </div>
                        <div className="profile-info-col">
                            <CustomInput
                                label="First name"
                                errorData={error}
                                errorField="first_name"
                                type="text" name="first_name"
                                placeholder="Enter first name"
                            />
                            <CustomInput
                                label="Last name"
                                errorData={error}
                                errorField="last_name"
                                type="text" name="last_name"
                                placeholder="Enter last name"
                            />
                        </div>
                    </div>

                    <CustomInput
                        label="Contact No"
                        errorData={error}
                        errorField="contact_no"
                        type="text" name="contact_no"
                        placeholder="Enter Contact No"
                    />

                    <CustomInput
                        label="Email Address"
                        errorData={error}
                        errorField="email"
                        type="text" name="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={() => { }}
                        disabled readOnly
                    />

                    <CustomInput
                        label="Role"
                        errorData={error}
                        errorField="role"
                        type="text" name="role"
                        placeholder="Role"
                        value={role_name}
                        onChange={() => { }}
                        disabled readOnly
                    />

                    <div className="password-group">
                        <CustomInput
                            label="Password"
                            errorData={error}
                            errorField="password"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder="Enter Password"
                        />
                        <div className="show-password">
                            <CheckBox label="Show password" onClick={this.handleShowPassword} isChecked={showPassword} />
                        </div>
                    </div>

                    <ErrorBlock error={error} field="__all__" />

                    <div className="row btn-wrapper">
                        <div className="col s6">
                            <Link to={Urls.getHomePage()} className="btn secondary-btn primary-text-color">
                                Cancel
                            </Link>
                        </div>
                        <div className="col s6">
                            <LoadingButton className="btn primary-btn" title="Submit"
                                isLoading={loading} onClick={this.createProfile} />
                        </div>
                    </div>
                </form>

            </div>
        )
    }
}
