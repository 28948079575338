import React, { Component } from 'react'
import {connect} from 'react-redux'

import {get} from 'lodash'

import CustomInput from '../../common/components/CustomInput'
import ErrorBlock from '../../common/components/ErrorBlock'
import LoadingButton from '../../common/components/LoadingButton'
import HocLoginRequired from '../../common/components/HocLoginRequired'
import ChangePassword from '../components/ChangePassword'

import { updateUserDetails } from '../data/profile.actions'
import { addNotification } from '../../common/data/common.actions'
import { postFormData } from '../../common/utils'
import Urls from '../../UrlConfig'

/**
 * Scss:
 *      _profile_page
 * Common:
 *      _common_page_layout, _layout
 */
class ProfilePage extends Component {

    /**
     * update user profile and password
     * 
     * Parent:
     *      App
     */

    constructor(props){
        super(props)

        const first_name = get(props, 'loggedUser.first_name', "")
        const last_name = get(props, 'loggedUser.last_name', "")        
        const image = get(props, 'loggedUser.image', null)        

        this.state = {
            loading: false,
            error: {},
            image,first_name, last_name
        }
    }

    updateProfile = (event) => {
        if (this.state.loading) return

        event.preventDefault()

        const form = document.getElementById('update-profile');
        let data = new FormData(form);

        this.setState({ loading: true, error: {} })
        postFormData(Urls.apiProfileUpdate(), data,
            (res) => {
                this.setState({ loading: false, error: {} })
                this.props.dispatch(updateUserDetails({user: res}))
                this.props.dispatch(addNotification(
                    {
						type: 'success',
						title: 'Profile Update',
						text: 'Profile Update Successfully',
					}
                ))
            },
            (err) => {
                // console.log("err", err)
                this.setState({ loading: false, error: err.error_data })
                this.props.dispatch(addNotification(
					{
						type: 'error',
						title: 'Error',
						text: 'Input error',
					}
				))
            }
        )
    }

    loadFile = (event) => {

		const selected_image = event.target.files[0]

		if(selected_image) {
            this.setState({ image: URL.createObjectURL(selected_image) })
		}
    }
    
    handleTextChange = (event) => {
        this.setState({ [event.target.name]: event.target.value})
    }

    render = () => {
        const { error, loading, 
            image, first_name, last_name } = this.state
        const { loggedUser, dispatch } = this.props

        return (
            <div id="profile-page">
                <div className="main-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col s12 m10 xl6 offset-m1 offset-xl3">

                                <div className="profile-content">
                                    <form id="update-profile">

                                        <div className="profile-info-wrapper">
                                            <div className="profile-img-col">
                                                <div className="profile-img-circle">
                                                    <input type="file" name="image" onChange={this.loadFile} />
                                                    <div className="upload-wrapper">
                                                        <div className='upload-text'>Update Image</div>
                                                    </div>
                                                    <img src={image} alt="" />
                                                </div>
                                            </div>
                                            <div className="profile-info-col">
                                                <div className="name">
                                                    {get(loggedUser, 'first_name')} {get(loggedUser, 'last_name')}
                                                </div>
                                                <div className="role">
                                                    Role : <span>{get(loggedUser, 'role_name')}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="input-group">
                                            <div className="single left-inp">
                                                <CustomInput
                                                    label="First name"
                                                    errorData={error}
                                                    errorField="first_name"
                                                    type="text" name="first_name"
                                                    placeholder="Enter first name"
                                                    value={first_name}
                                                    onChange={this.handleTextChange}
                                                />
                                            </div>
                                            <div className="single right-inp">
                                                <CustomInput
                                                    label="Last name"
                                                    errorData={error}
                                                    errorField="last_name"
                                                    type="text" name="last_name"
                                                    placeholder="Enter last name"
                                                    value={last_name}
                                                    onChange={this.handleTextChange}
                                                />
                                            </div>
                                        </div>

                                        <CustomInput
                                            label="Email Address"
                                            errorData={error}
                                            errorField="email"
                                            type="text" name="email"
                                            placeholder="Enter email"
                                            value={get(loggedUser, 'email', '')}
                                            disabled readOnly
                                        />

                                        <CustomInput
                                            label="Contact No"
                                            errorData={error}
                                            errorField="contact_no"
                                            type="text" name="contact_no"
                                            placeholder="Enter Contact No"
                                            value={get(loggedUser, 'contact_no', '')}
                                            disabled readOnly
                                        />

                                        <ErrorBlock error={error} field="__all__"/>

                                        <div className="btn-wrapper">
                                            <LoadingButton className="btn primary-btn" title="Update Profile"
                                                isLoading={loading} onClick={this.updateProfile} />
                                        </div>

                                    </form>

                                    <div className="divider"></div>
                                    <ChangePassword dispatch={dispatch} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default connect(store => ({
    loggedUser: store.loggedUser,
    logged: store.auth.logged
}))(HocLoginRequired(ProfilePage))