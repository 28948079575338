import {orderBy} from 'lodash'

const INIT_ACTIVITY_LIST = {
    fetching: false,
    fetched: false,
    error: false,
    errorObj: {},
    
    activityList: [],
}


export const activityListReducer =  (state = INIT_ACTIVITY_LIST, { type, payload }) => {
    switch (type) {

        case 'ACTIVITY_LIST_FETCHING': {
            return { ...state, 
                fetching: true,
                error: false,
                errorObj: {},
            }
        }

        case 'ACTIVITY_LIST_SUCCESS': {
            let activityList = [...payload]

            for (let index = 0; index < activityList.length; index++) {
                const currAct = activityList[index];
                currAct.subactivity = orderBy(currAct.subactivity, ['index'], ['asc'])
            }

            return { ...state,
                fetching: false,
                fetched: true,
                activityList,
            }
        }

        case 'ACTIVITY_LIST_ERROR': {
            return { ...state, 
                fetching: false,
                fetched: false,
                error: true,
                errorObj: payload
            }
        }

        case "USER_LOGOUT": {
            return INIT_ACTIVITY_LIST
        }

        default: {
            return state
        }
    }
}