import React, { Component } from 'react'
import { connect } from 'react-redux'

import { findIndex, get } from 'lodash'

import ProjectPermissionPopup from './ProjectPermissionPopup'
import Loader from '../../common/components/Loader'
import LoadingButton from '../../common/components/LoadingButton'
import CustomImage from '../../common/components/CustomImage'
import { EditIcon } from '../../common/SvgIcons'

import { getJsondata } from '../../common/utils'
import { getUserList } from '../data/userList.action'
import Urls from '../../UrlConfig'

/**
 * Scss:
 *      _project_permission_list
 * Common:
 *      layout
 */
class ProjectPermissionList extends Component {

    /**
     * get permission list and user list from server,
     * handle loading and error
     * 
     * Parent:
     *      ProjectEdit
     * 
     * Render:
     *      ProjectPermissionPopup
     */

    state = {
        fetching: false,
        fetched: false,
        error: false,
        errorMsg: {},
        loadingUserList: true,

        permissionList: [],
        filteredUserList: [],
        permissionId: null,
        permissionData: null,

        contentHeight: 0,
    }

    componentDidMount = () => {
        const { projectId } = this.props
        if (projectId) {
            this.getPermissionList(projectId)
        }
    }

    getPermissionList = (projectId) => {

        this.setState({ fetching: true, fetched: false, error: false, errorMsg: {} })

        getJsondata(Urls.apiGetProjectPermissions(projectId),
            (res) => {
                this.setState({ fetching: false, fetched: true, permissionList: res })
                // if permission data get successfully, get user list for new permission add
                this.props.dispatch(getUserList(this.filterUserList))
            },
            (err) => {
                this.setState({
                    fetching: false, fetched: false,
                    error: true, errorMsg: err.error_data,
                })

            }
        )
    }

    filterUserList = (userList) => {

        let newUserList = []
        for (let index = 0; index < userList.length; index++) {
            const currUser = userList[index];
            // do not add addmin in list
            if(currUser.role === '1') continue
            // exclude permitted user from list
            const permUserIndex = findIndex(this.state.permissionList, ['user.id', currUser.id])
            if(permUserIndex !== -1) continue
            // add rest of user in list
            newUserList.push(currUser)
        }

        this.setState({ filteredUserList: newUserList })
    }

    handlePermissionUpdate = (permissionId, permissionData) => (event) => {
        if (event) event.stopPropagation();

        this.setState({ permissionId, permissionData })
    }

    handleModelClose = () => {
        this.setState({ permissionId: null, permissionData: {} })
    }

    handleFormSuccess = (newPerms) => {
        this.handleModelClose()
        this.setState({ permissionList: newPerms, fetched: true })
        // filter again
        this.filterUserList(this.props.userList)
    }

    resizeContent = (element) => {
        if (element) {
            const { windowHeight } = this.props

            const contentHeight = windowHeight - element.getBoundingClientRect().top
            this.setState({ contentHeight })
        }
    }


    render = () => {
        const { permissionId, permissionData, filteredUserList } = this.state

        return (
            <>
                <div className="page-right-actions">
                    <div className="page-action-wrapper">
                        <div className="page-add-new">
                            <LoadingButton title="+ Assign Roles" className="primary-btn"
                                onClick={this.handlePermissionUpdate(-1, {})} />
                        </div>
                    </div>
                </div>
                <div id="pro-perm-list-page">
                    {this.renderPermissionList()}
                    {permissionId &&
                        <ProjectPermissionPopup permissionId={permissionId}
                            permissionData={permissionData}
                            userList={filteredUserList}
                            closeModel={this.handleModelClose}
                            onSuccess={this.handleFormSuccess}
                        />
                    }
                </div>
            </>
        )
    }

    renderPermissionList = () => {
        const { fetched, error, permissionList, contentHeight } = this.state
        const { userFetched } = this.props
        if (fetched && userFetched) {
            const paddingBot = 15
            let wrapperHeightCss = { paddingBot }
            // set planning cols height to scroll individually
            if (contentHeight) {
                wrapperHeightCss.height = contentHeight - paddingBot
            }

            return (
                <div className="pro-perm-list-wrapper" ref={this.resizeContent}>
                    <div className="pro-perm-list-container" style={wrapperHeightCss}>
                        {permissionList.length ?
                            <div className="flex-wrapper">
                                {permissionList.map((perm) => {
                                    return (
                                        <div className="pro-perm-block" key={perm.id}
                                            onClick={this.handlePermissionUpdate(perm.id, perm)}>
                                            <div className="pro-perm-data">
                                                <CustomImage title={get(perm, 'user.first_name', 'C')} imageUrl={get(perm, 'user.image', null)} />
                                                <div className="pro-perm-content">
                                                    <div className="pro-perm-edit">
                                                        <EditIcon />
                                                    </div>
                                                    <div className="title">
                                                        {get(perm, 'user.first_name', '-')} {get(perm, 'user.last_name', '-')}
                                                    </div>
                                                    <div className="pro-perm-email">{get(perm, 'user.email', '-')}</div>
                                                    <div className="pro-perm-role">{get(perm, 'user.role_name', '-')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            :
                            <div className="empty-page-wrapper">
                                <img src={Urls.getStaticImg('/img/no-projects.svg')} alt="" />
                                <div className="text">You can now start assigning roles for your project!</div>
                            </div>
                        }
                    </div>
                </div>
            )
        }
        else if (error) {
            return (
                <div className="empty-page-wrapper">
                    <div className="error-block big">Failed to fetch data</div>
                </div>
            )
        }

        else {
            return <Loader />
        }
    }
}

export default connect(store => ({
    projectId: store.projectForm.formData.id,
    userFetched: store.userList.fetched,
    userList: store.userList.userList,
    windowHeight: store.pageSize.windowHeight,
}))(ProjectPermissionList)