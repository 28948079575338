import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'

import { get, differenceBy } from 'lodash'

import UpcomingHolidays from './UpcomingHolidays'
import PlanningForm from './PlanningForm'

import ErrorBlock from '../../common/components/ErrorBlock'
import Loader from '../../common/components/Loader'
import { BackIcon } from '../../common/SvgIcons'

import { addNotification } from '../../common/data/common.actions'
import { getPlanningDetails, getPlanningList } from '../data/planning.actions'
import { getProjectDetails } from '../../project/data/project.actions'
import { getActivityList } from "../../admin/data/activity.actions";
import { getJsondata } from '../../common/utils';

import Urls from '../../UrlConfig'

import 'react-datetime-range-super-picker/dist/index.css'

class ProjectPlanningEdit extends Component {

	/**
	 * Handle data issues before edit form
	 * take project data from projectDetails reducer
	 * 
	 * Parent:
	 *      ProjectPlanningWrapperPage
	 */

	state = {
		fetching: false,
		fetchedHoliday: false,
		error: false,
		errorMsg: {},

		holidayList: [],
	}

	componentDidMount = () => {
		const { planningId } = get(this.props, 'match.params')
		this.props.getPlanningDetails(planningId)
		this.getHolidayList()
	}

	getHolidayList = () => {
		this.setState({ fetching: true, error: false, errorMsg: {} })

		getJsondata(Urls.apiGetHolidayList("future"),
			(res) => {
				this.setState({
					fetching: false, fetchedHoliday: true,
					holidayList: res
				})
			},
			(err) => {
				this.setState({
					fetching: false, fetchedHoliday: false,
					error: true, errorMsg: err,
				})
			}
		)
	}

	render = () => {
		const { fetchedHoliday, holidayList } = this.state

		const { projectFetched, projectFetchError, projectFetchErrorObj,
			projectDetails, windowHeight, navbarHeight, backPillHeight,
			activityFetched, activityList,
			planningFetched, planningDetails,
			planningListFetched, planningList
		} = this.props

		if (activityFetched && projectFetched && fetchedHoliday
			&& planningFetched && planningListFetched) {
			const projectId = projectDetails.id
			const newPlanningList = differenceBy(planningList, [planningDetails], 'id')

			const pageHeight = windowHeight - navbarHeight
			const pageContentHeight = pageHeight - backPillHeight

			return (
				<div id="project-details" className='planning-form'
					style={{ height: `${pageHeight}px` }}>
					
					<div className="page-back-pill">
						<Link to={Urls.getProjectPlanningList(projectId)} className="page-back-content">
							<BackIcon />
							<div className="page-back-text">Go back to Planning List</div>
						</Link>
						<div className="page-pill-title hide-on-small-and-down">{get(projectDetails, 'name', '-NA-')}</div>
					</div>

					{/* for content minuse banner height from page height */}
					<div className="page-content-wrapper"
						style={{ height: `${pageContentHeight}px`}}>

						<div className="pl-form-planning-col"
							style={{ height: `${pageContentHeight}px`}}>
							<PlanningForm projectDetails={projectDetails}
								activityList={activityList}
								holidayList={holidayList}
								planningDetails={planningDetails}
								history={this.props.history}
								planningList={newPlanningList}
								handleFormSuccess={this.props.getPlanningList}
								addNotification={this.props.addNotification}
							/>
						</div>
						<div className="pl-form-holiday-col"
							style={{ height: `${pageContentHeight}px`}}>
							<UpcomingHolidays holidayList={holidayList} />
						</div>
					</div>
				</div>
			)
		}
		else if (projectFetchError) {
			if (projectFetchErrorObj.error_code === 403) {
				return <Redirect to={Urls.getUnauthorized()} />
			}

			return (
				<div className="container">
					<ErrorBlock error={projectFetchErrorObj.error_data} field="__all__" />
				</div>
			)
		}
		else {
			return (
				<div className="container">
					<Loader />
				</div>
			)
		}
	}
}

export default connect(store => ({
	// project details
	projectFetching: store.projectDetails.fetching,
	projectFetched: store.projectDetails.fetched,
	projectFetchError: store.projectDetails.fetchError,
	projectFetchErrorObj: store.projectDetails.fetchErrorObj,
	projectDetails: store.projectDetails.projectDetails,

	activityFetched: store.activityList.fetched,
	activityList: store.activityList.activityList,

	planningFetched: store.planningDetails.fetched,
	planningDetails: store.planningDetails.planningDetails,

	planningListFetched: store.planningList.fetched,
	planningList: store.planningList.planningList,
	// handle page size
	windowHeight: store.pageSize.windowHeight,
	navbarHeight: store.pageSize.navbarHeight,
    backPillHeight: store.pageSize.backPillHeight,
}), {
	getProjectDetails,
	getActivityList,
	getPlanningList,
	getPlanningDetails,
	addNotification
})(ProjectPlanningEdit)
