import React, { Component } from 'react'

import { get } from 'lodash'

import LoadingButton from '../../common/components/LoadingButton'
import ErrorBlock from '../../common/components/ErrorBlock'

import { postJsonData } from '../../common/utils'
import Urls from '../../UrlConfig'

/**
 * Scss:
 *      _common_page_layout
 *      _user_form
 */
export default class UserResetPass extends Component {

	/**
	 * 
	 * send reset password link to user
	 * 
	 * Parent:
	 *      UserList
	 */

	state = {
		submitting: false,
		error: {}
	}

	resetPassword = (e) => {
		if (e) e.preventDefault();
		if (this.state.submitting) return

		this.setState({ submitting: true, error: {} })

		postJsonData(Urls.apiForgotPassword(),
			{ email: this.props.selectedResetUser.email },
			(res) => {
				this.setState({ submitting: false, error: {} })
				this.props.closeModel()
				this.props.addNotification({
					type: 'success',
					title: 'Reset Password',
					text: 'Reset link sent successfully',
				})
			},
			(err) => {
				this.setState({ submitting: false, error: err.error_data })
				this.props.addNotification({
					type: 'error',
					title: 'Failed',
					text: 'Reset link not sent successfully',
				})
			},
		)
	}

	render = () => {
		const { closeModel, selectedResetUser } = this.props
		const { error, submitting } = this.state

		return (
			<div className="page-model">
				<div className="page-model-wrapper">
					<div className="page-model-cell">
						<div className="page-model-content user-form-wrapper">
							<div className="model-form">
								<div className="close-icon-wrapper" onClick={closeModel}>
									<div className="close-icon"></div>
								</div>
								<div className="title">Reset Password</div>

								<div className="info-text">
									You have initiated reset password for
								<span> {get(selectedResetUser, 'first_name', '-')} {get(selectedResetUser, 'last_name', '-')}</span>,
								please confirm in order to proceed
								</div>

								<ErrorBlock error={error} field="__all__" />
								<ErrorBlock error={error} field="email" />

								<div className="multi-action">
									<LoadingButton className="btn primary-btn"
										title="Cancel" onClick={closeModel}
									/>
									<LoadingButton className="btn secondary-btn"
										title="Confirm Reset"
										isLoading={submitting} onClick={this.resetPassword}
									/>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
