import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom'

import { get, filter } from 'lodash'

import ErrorBlock from '../common/components/ErrorBlock'
import Loader from '../common/components/Loader'
import LoadingButton, {FloatingBtn} from '../common/components/LoadingButton'
import { BackIcon } from '../common/SvgIcons'
import ProjectViewConfig from '../project/components/ProjectViewConfig'

import { getActivityList } from "./data/activity.actions";
import { filterMPList } from "./data/planning.utils";

import { formatDate } from '../common/utils'
import Urls from "../UrlConfig";

/**
 * Scss:
 * 		project/_project_details.scss
 * Common:
 * 		buttons, layout, _common_page_layout
 */

class ProjectPlanningList extends Component {

	/**
	 * 
	 * manage loading and error for planning list fetch
	 * render ProjectPlanningList on success
	 * 
	 * Parent:
	 *      ProjectPlanningWrapperPage
	 * 
	 * Renders:
	 *      ProjectViewConfig
	 * 		PlanningFormList
	 */

	handleFloatIconClick = (projectId) => () => {
		this.props.history.push(Urls.getProjectPlanningAdd(projectId))
	}

	render = () => {
		const projectId = get(this.props, 'match.params.projectId')

		const { projectFetched, projectFetchError, projectFetchErrorObj,
			projectName, windowHeight, navbarHeight, backPillHeight } = this.props


		if (projectFetched) {

			const pageHeight = windowHeight - navbarHeight
			const pageContentHeight = pageHeight - backPillHeight - 70 // setting block height

			return (
				<div id="project-details" className="project-planning-container" style={{ height: `${pageHeight}px` }}>
					<div className="page-back-pill">
                        <Link to={Urls.getProjectPage()} className="page-back-content">
                            <BackIcon />
                            <div className="page-back-text">Go back to Project List</div>
                        </Link>
                        <Link to={Urls.getProjectDetails(projectId, 'planning')} className="page-pill-title hide-on-small-and-down">
							{projectName}
						</Link>
                    </div>
					<ProjectViewConfig tab="planning-listpage" />
					{/* for content minuse banner height from page height */}
					<div className="project-planninglist" style={{ height: `${pageContentHeight}px`}}>
						{this.renderPlanningList(projectId)}
						<FloatingBtn onClick={this.handleFloatIconClick(projectId)}/>
					</div>
				</div>
			)
		}
		else if (projectFetchError) {

			if (projectFetchErrorObj.error_code === 403) {
				return <Redirect to={Urls.getUnauthorized()} />
			}

			return (
				<div className="container">
					<ErrorBlock error={projectFetchErrorObj.error_data} field="__all__" />
				</div>
			)
		}
		else {
			return (
				<div className="container">
					<Loader />
				</div>
			)
		}
	}

	renderPlanningList = (projectId) => {
		const { planningFetched, planningFetchError,
			planningList, history, windowHeight, projectViewConfig } = this.props

		if (planningFetched) {
			const { activityId, isFilterActive, selectedUnit, selectedSubunit} = projectViewConfig
			// filter planning lists if needed
			let filteredPlanningList = planningList;

			let emptyMessage = ''
			if (isFilterActive) {
				filteredPlanningList = filterMPList(
					filteredPlanningList, Number(selectedUnit.id), Number(selectedSubunit.id))
				emptyMessage = "Looks like you don't have any plan related to filtered structure"
			}

			if (activityId) {
				filteredPlanningList = filter(filteredPlanningList, ['activity.id', activityId])
				emptyMessage = emptyMessage.length ? emptyMessage + " and activity." : "Looks like you don't have any plan related to filtered activity"
			}

			return (
				<PlanningFormList history={history}
					projectId={projectId} planningList={filteredPlanningList}
					windowHeight={windowHeight} emptyMessage={emptyMessage}
				/>
			)
		}
		else if (planningFetchError) {
			return (
				<div>
					<h2>failed to fetch data</h2>
				</div>
			)
		}
		else {
			return (
				<div>
					<Loader />
				</div>
			)
		}
	}
}

export default connect(store => {
	const currSetting = store.projectViewConfig[store.projectDetails.projectDetails.id]
	return ({
		// project details
		projectFetching: store.projectDetails.fetching,
		projectFetched: store.projectDetails.fetched,
		projectFetchError: store.projectDetails.fetchError,
		projectFetchErrorObj: store.projectDetails.fetchErrorObj,
		projectName: store.projectDetails.projectDetails.name,
		projectViewConfig: currSetting,
		// planning list
		planningFetching: store.planningList.fetching,
		planningFetched: store.planningList.fetched,
		planningFetchError: store.planningList.fetchError,
		planningList: store.planningList.planningList,
		// handle page size
		windowHeight: store.pageSize.windowHeight,
		navbarHeight: store.pageSize.navbarHeight,
		backPillHeight: store.pageSize.backPillHeight,
	})
}, { getActivityList })(ProjectPlanningList)



/**
 * Scss:
 * 		project/_planning_list.scss
 * Common:
 * 		layout
 */

class PlanningFormList extends React.Component {

	/**
	 * Show list of all current plannings
	 * Admin can edit / add new plannings with it
	 * 
	 * Parent:
	 *      ProjectPlanningList
	 */

	render = () => {
		const { planningList, projectId, emptyMessage } = this.props

		return (
			<div className="container">
				{planningList.length ?
					planningList.map((planning) => {

						const start_date = formatDate(planning.start_date)
						const end_date = formatDate(planning.end_date)
						const [statusClass, statusText] = this.getStatusClassAndText(planning.status)

						return (
							<div className="planning-block" key={planning.id}>
								<div className="pl-block-title">
									<b>{get(planning, 'activity.name')} </b>
									<small> {get(planning, 'unit.name')} &gt; {get(planning, 'subunit.name')} </small>
								</div>

								<div className="pl-block-content">

									<div className='pl-block-content-status'>
										<small>Status : </small>
										<span className={statusClass}>{statusText}</span>
									</div>

									<div className='pl-block-content-date'>
										<span className='date'>{start_date} </span>
											To<span className='date'> {end_date}</span>
									</div>
								</div>

								<div className='pl-block-content-bottom'>
									<div>{get(planning, 'micro_planning.length', 0)} Micro Planning</div>


									{Boolean(planning.has_holidays) &&
										<div> , {planning.no_of_holidays} Holidays</div>
									}

									{Boolean(planning.depends_on.length) &&
										<b> , Depends on {get(planning, 'depends_on.0.display_name', '-NA-')}</b>}
								</div>

								<div className="pl-block-btn-wrapper">
									{/* <Link to={Urls.getProjectPlanningChecklist(projectId, planning.id)}>
										<LoadingButton title="checklist" className="primary-btn"
											onClick={() => { }} />
									</Link> */}
									<Link to={Urls.getProjectPlanningEdit(projectId, planning.id)}>
										<LoadingButton title="+ Edit Planning" className="primary-btn"
											onClick={() => { }} />
									</Link>

									<Link to={{
										pathname: Urls.getProjectPlanningAdd(projectId),
										state: {
											planningId: planning.id,
											start_date: planning.end_date
										}
									}}>
										<LoadingButton title="+ Add Dependant Planning" className="primary-btn"
											onClick={() => { }} />
									</Link>
								</div>
							</div>
						)
					})
					:
					<div className="empty-page-wrapper">
						<img src={Urls.getStaticImg('/img/project_details_empty.svg')} alt="" />
						<div className="text">{emptyMessage ? emptyMessage : `Looks like you don't have any plan right now`}</div>
					</div>
				}
			</div>
		)
	}

	// status = 'P', 'C', 'R'
	getStatusClassAndText = (status) => {
		if (status === 'P') {
			return ['grey', 'Upcomming']
		}
		else if (status === 'C') {
			return ['green', 'Complete']
		}
		else if (status === 'R') {
			return ['yellow', 'Running']
		}
	}
}