import { findIndex } from "lodash"

const INIT_USER_LIST = {
    fetching: false,
    fetched: false,
    error: false,
    errorObj: {},
    
    userList: [],
}


export const userListReducer =  (state = INIT_USER_LIST, { type, payload }) => {
    switch (type) {

        case 'USER_LIST_FETCHING': {
            return { ...state, 
                fetching: true,
                error: false,
                errorObj: {},
            }
        }

        case 'USER_LIST_SUCCESS': {
            return { ...state,
                fetching: false,
                fetched: true,
                userList: payload,
            }
        }

        case 'USER_LIST_ERROR': {
            return { ...state, 
                fetching: false,
                fetched: false,
                error: true,
                errorObj: payload
            }
        }

        case 'USER_SET_STATUS': {
            const {userId, status} = payload
            let newUserList = [...state.userList]
            
            const userInd = findIndex(newUserList, ['id', userId])
            newUserList[userInd]['is_active'] = status

            return {...state, userList: newUserList }
        }

        case 'USER_LOGOUT': {
            return INIT_USER_LIST
        }

        default: {
            return state
        }
    }
}