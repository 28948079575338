import React, { Component } from 'react'

import { format } from 'date-fns'
import { get } from 'lodash'

import LoadingButton from '../../common/components/LoadingButton'

import { fetchBlobData, formatDateTime } from '../../common/utils'
import Urls from '../../UrlConfig'

/**
 * Scss:
 *      _reports
 */
export default class EngineerReport extends Component {

  /**
   * Parent:
   *      ReportPage
   */

  state = {
    loadingXlsx: false
  }

  formatDate = (date) => {
    return format(new Date(date), 'dd/MM/yyyy')
  }

  downloadExcel = () => {
    if (this.state.loadingXlsx) return

    const { reportData } = this.props

    this.setState({ loadingXlsx: true })

    fetchBlobData(Urls.apiGetProjectReportDownload(reportData.id), 'GET', {},
      (res) => {
        let report_name = get(reportData, 'name', 'report')
        report_name = report_name + "-" + format(new Date(), 'dd/MM/yyyy hh:mm')

        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${report_name}.xlsx`);
        // have to add element to doc for firefox
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        this.setState({ loadingXlsx: false })
      },
      (err) => {
        this.setState({ loadingXlsx: false })
      }
    )
  }

  renderStatus = (status) => {
    switch (status) {
      case 'P': {
        return 'Scheduled'
      }
      case 'W': {
        return 'Waiting'
      }

      case 'R': {
        return 'Running'
      }

      case 'C': {
        return 'Completed'
      }

      default: {
        return ''
      }
    }
  }

  render = () => {
    const { reportData } = this.props
    // console.log("🚀 ~ file: EngineerReport.js ~ line 62 ~ EngineerReport ~ reportData", reportData)

    return (
      <div className="report-content-wrapper">

        <div className="report-content-info-content">
          <div className="report-content-info-title">{reportData.name} - Engineer Report</div>
          <div className="report-content-info-action">
            <LoadingButton title="Download Report"
              className="btn primary-btn"
              onClick={this.downloadExcel}
              isLoading={this.state.loadingXlsx}
            />
          </div>
        </div>

        {reportData.units.length ?
          reportData.units.map((unit) => {
            return (
              <div key={unit.id}>
                {this.renderSubUnitList(unit)}
              </div>
            )
          })
          :
          <div className="empty-report-data">
            <div>No structure found</div>
          </div>
        }

      </div>
    )
  }

  renderSubUnitList = (unit) => {
    const { subunits } = unit

    if (!!subunits) {
      return (
        <>
          {subunits.map((subunit) => {
            const hasPlanning = Boolean(subunit.planning.length)
            return (
              <div key={subunit.id} className="report-content-block">
                <div className='report-content-block-title'>
                  {`${unit.name} > ${subunit.name}`}
                </div>
                {hasPlanning ?
                  subunit.planning.map((pl) => {
                    const start_date = this.formatDate(pl.start_date)
                    const end_date = this.formatDate(pl.end_date)

                    return (
                      <table className="report-engineer-table">
                        <tbody>
                          <tr key={pl.id} className=''>
                            <th className="title-col left-align">
                              {pl.activity.name}
                            </th>
                            <th className="summery-col">
                              {start_date} to {end_date}
                            </th>
                            <th className="days-col">
                              Days {pl.no_of_days} {pl.has_holidays && `, Holidays ${pl.no_of_holidays}`}
                            </th>
                            <th className="status-col">
                              {this.renderStatus(pl.status)}
                            </th>
                          </tr>
                          {this.renderMicroplannings(pl.micro_planning)}
                        </tbody>
                      </table>
                    )
                  })
                  :
                  <>
                    <div className="divider" />
                    <div className='report-content-empty'>
                      <div className='label'>No planning found for this structure </div>
                    </div>
                  </>
                }
              </div>
            )
          })}
        </>
      )
    }
    return null;
  }

  renderMicroplannings = (micro_planning) => {

    return micro_planning.map((mpl) => {
      if (mpl.enabled) {
        const start_date = this.formatDate(mpl.start_date)
        const end_date = this.formatDate(mpl.end_date)
        // const schedules = mpl.schedules

        return (
          <tr key={mpl.id} className=''>
            <td className="title-col">
              <div>{mpl.subactivity.name}</div>
              <div className="title-col-date">{start_date} to {end_date}</div>
            </td>
            <td className="summery-col center">
              <div>
                {mpl.summery}
              </div>
              {/* {Boolean(schedules.length) && this.renderSchedules(mpl.schedules)} */}
            </td>
            <td className="days-col center">
              Days {mpl.no_of_days} {mpl.has_holidays && `, Holidays ${mpl.no_of_holidays}`}
            </td>
            <td className="status-col center">
              {this.renderStatus(mpl.status)}
            </td>
          </tr>
        )
      }
      return null;
    })
  }

  renderSchedules = (schedules) => {
    return (
      <div>
        {schedules.map((schedule, i) => {
          // const work_on = schedule.work_on
          return (
            <div key={schedule.id}>
              <div className='cell-pill-row date'>
                {(!!schedule.is_ongoing) ?
                  // first schedule if on going show end date as today
                  // else show actual end date
                  'On Going'
                  :
                  i === 0 ? formatDateTime(schedule.end_date) : ''
                }
              </div>
              <div className='cell-pill-row date'>
                {formatDateTime(schedule.start_date)}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
