import React, { Component, useEffect } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { get } from 'lodash'

import LoadingButton from '../../common/components/LoadingButton'
import CustomInput from '../../common/components/CustomInput'
import ErrorBlock from '../../common/components/ErrorBlock'
import { ImagePlaceholder, CheckIcon } from '../../common/SvgIcons'

import { updateProjectFormData, getProjectFormDetails } from '../data/adminProject.actions'
import { getProjectList } from '../../project/data/project.actions'
import { addNotification } from '../../common/data/common.actions'

import { postFormData } from '../../common/utils'
import Urls from '../../UrlConfig'
import Loader from '../../common/components/Loader'

export default ({ isEdit, projectId }) => {

    /**
     * wrapper component
     * 
     * in add, direct render upsert form
     * in edit, fetch project data from server if required,
     *          manage loading and error,
     *          on success render upsert form with data
     * 
     * Parent:
     *      ProjectFormPage
     * Renders:
     *      ProjectUpsertConnect
     */

    const fetched = useSelector(store => store.projectForm.fetched)
    const fetchError = useSelector(store => store.projectForm.fetchError)
    const dispatch = useDispatch()

    useEffect(() => {
        if (isEdit && !fetched) {
            dispatch(getProjectFormDetails(projectId))
        }
    }, [isEdit, projectId, dispatch])


    if (isEdit) {
        if (fetched) {
            return <ProjectUpsertConnect isEdit={isEdit} />
        } else if (fetchError) {
            return (
                <div className="container">
                    <div className="error-block big">Failed to fetch data</div>
                </div>
            )
        } else {
            return <Loader />
        }
    }
    else {
        return <ProjectUpsertConnect isEdit={isEdit} />
    }
}


/**
 * Scss:
 *      _project_form
 * 
 * Common:
 *      _common_page_layout
 */

class ProjectUpsert extends Component {

    /**
     * handle project add / edit form,
     * submit form to server
     * in success, update formdata in store, for edit.
     * 
     * Parent:
     *      ProjectUpsertWrapper ( upper functional component )
     */

    state = {
        name: "",
        banner: null,
        bannerFile: null,
        image: null,
        imageFile: null,

        loading: false,
        error: {},
    }

    constructor(props) {
        super(props)

        let initState

        if(props.isEdit) {
            initState = {
                name: get(props, 'formData.name', ''),
                banner: get(props, 'formData.banner', ''),
                bannerFile: null,
                image: get(props, 'formData.image', ''),
                imageFile: null,
            }
        }
        else {
            initState = {
                name: "",
                banner: null,
                bannerFile: null,
                image: null,
                imageFile: null,
            }
        }

        this.state = {
            ...initState,
            loading: false,
            error: {},
        }
    }

    handleTextChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    loadBannerFile = (event) => {
        const selected_image = event.target.files[0]
        if (selected_image) {
            this.setState({
                banner: URL.createObjectURL(selected_image),
                bannerFile: selected_image
            })
        }
    }

    loadFile = (event) => {
        const selected_image = event.target.files[0]
        if (selected_image) {
            this.setState({
                image: URL.createObjectURL(selected_image),
                imageFile: selected_image
            })
        }
    }

    onProjectFormSubmit = (event) => {
        const { formData, loggedUserId, isEdit } = this.props
        const { imageFile, bannerFile } = this.state

        if (event) event.preventDefault()

        let error = {}, has_error = false

        if (!imageFile && !isEdit) {
            error.image = ["project image is required"]
            has_error = true
        }

        if (has_error) {
            this.setState({ error })
            this.props.addNotification({
                type: 'error',
                title: isEdit ? 'Project Update' : 'Project Create',
                text: 'Input error',
            })
            return
        }

        const form = document.getElementById('project-form-page');
        let data = new FormData(form);

        // add project id if edit
        if (get(formData, 'id', null)) {
            data.append('id', formData.id)
        }

        // add project file
        data.append('image', imageFile)
        data.append('banner', bannerFile)
        data.append('created_by', loggedUserId)

        this.setState({ loading: true, error: {} })
        postFormData(Urls.apiUpsertProject(),
            data,
            (res) => {
                this.props.updateProjectFormData(res)
                this.props.getProjectList()
                this.props.addNotification({
                    type: 'info',
                    icon: <CheckIcon />,
                    title: isEdit ? 'Project Update' : 'Project Create',
                    text: `Project ${res.name} ${isEdit ? 'updated' : 'created'} successfully`,
                })
                this.props.history.push(Urls.getProjectForm(res.id, 'structure'))

            },
            (err) => {
                // console.log("err", err)
                this.setState({ loading: false, error: err.error_data })
                this.props.addNotification({
                    type: 'error',
                    title: isEdit ? 'Project Update' : 'Project Create',
                    text: 'Input error',
                })
            }
        )
    }

    handleBackPress = (event) => {
        if (event) event.preventDefault()
        this.props.history.goBack()
    }


    render = () => {

        const { name, image, banner, loading, error } = this.state
        const { windowHeight, navbarHeight, isEdit } = this.props

        const pageHeight = windowHeight - navbarHeight

        return (
            <form id="project-form-page" className="project-add-page">
                <div className="page-banner-wrapper">
                    <input className="project-banner-input"
                        type="file" name="banner" onChange={this.loadBannerFile} />
                    <div className="project-banner-upload-wrapper">
                        <div className='project-banner-upload-text'> + Add Banner Image</div>
                    </div>
                    {banner && <img className="project-banner-img" src={banner} alt="" />}
                </div>

                <div className="main-page-content"
                    style={{ height: `calc(${pageHeight}px - 15em)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col s3 project-img-col">
                                <div className="project-img-wrapper">
                                    <input className="project-img-input"
                                        type="file" name="image" onChange={this.loadFile} />
                                    <div className="project-img-upload-wrapper">
                                        <ImagePlaceholder />
                                        <div className="text">Add Image</div>
                                    </div>
                                    {image && <img className="project-img" src={image} alt="" />}
                                </div>
                            </div>

                            <div className="col s9 project-content-wrapper">
                                <CustomInput label="Project Name"
                                    type="text" name="name"
                                    placeholder="Enter project name"
                                    value={name}
                                    onChange={this.handleTextChange}
                                    errorData={error}
                                    errorField="name"
                                />
                                <ErrorBlock error={error} field="__all__" />
                                <ErrorBlock error={error} field="image" />
                                <div className="action-btn">
                                    <LoadingButton title={isEdit ? "Edit Project" : "Add Project"} className="btn primary-btn"
                                        isLoading={loading}
                                        onClick={this.onProjectFormSubmit} />
                                    <LoadingButton title="Cancel" className="btn secondary-btn"
                                        onClick={this.handleBackPress} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </form>
        )
    }
}

// wrap connect and history Hoc
const ProjectUpsertConnect = withRouter(connect(store => ({
    formData: store.projectForm.formData,
    windowHeight: store.pageSize.windowHeight,
    navbarHeight: store.pageSize.navbarHeight,

    loggedUserId: store.auth.user,
}), { updateProjectFormData, getProjectList, addNotification })(ProjectUpsert))