import { getJsondata } from '../../common/utils'
import Urls from '../../UrlConfig'


export const getActivityList = (callback=null) => {

    return async (dispatch) => {
        dispatch({ type: 'ACTIVITY_LIST_FETCHING' })

        getJsondata(Urls.apiGetActivityList(),
			(res) => {
				dispatch({ type: 'ACTIVITY_LIST_SUCCESS', payload: res })
				// mainly used to setup search from fetched data
				if(callback) callback(res)
			},
			(err) => {
            	// console.log("-> err", err)
                dispatch({ type: 'ACTIVITY_LIST_ERROR', payload: err })
			}
		)
    }
}
