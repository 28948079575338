import React, { Component } from 'react'
import { connect } from 'react-redux'

import { get } from 'lodash'

import { SettingsIcon } from '../../common/SvgIcons'
import { RoundedButton } from '../../common/components/LoadingButton'
import { CustomReactSelect } from '../../common/components/CustomInput'
import ProjectFilterChoice from './ProjectFilterChoice'

import { selectLayout, selectDataType, selectActivityFilter } from '../data/projectViewConfig.actions'
import { getActivityList } from "../../admin/data/activity.actions";


class ProjectViewConfig extends Component {

  /**
   * render project settings bar
   * handle layout swiching
   * handle activity select
   * manage method swiching for mobile
   * 
   * Parent:
   *      ProjectDetailsPage
   * Renders:
   *      ProjectFilterChoice
   */

  constructor(props) {
    super(props)

    this.state = {
      showModel: false,
    }
  }

  componentDidMount = () => {
    if (!this.props.activityFetched) {
      this.props.getActivityList()
    }
  }

  showModel = () => {
    this.setState({ showModel: true })
  }

  closeModel = () => {
    this.setState({ showModel: false })
  }

  handleActivityFilter = (props) => {
    this.props.selectActivityFilter(this.props.projectId, get(props, 'id', null))
  }

  handleLayoutClick = (layoutType) => () => {
    this.props.selectLayout(this.props.projectId, layoutType)
  }

  handleDataTypeClick = (dataType) => () => {
    this.props.selectDataType(this.props.projectId, dataType)
  }

  render = () => {
    const { showModel } = this.state
    const { projectViewConfig, isMedium, tab } = this.props

    const showLayoutBtns = tab === 'planning'
    const showActivitySelect = tab !== 'drawing'

    if (isMedium) {
      return (
        <div className="project-setting-wrapper medium-screen">
          <RoundedButton className="setting-btn"
            icon={<SettingsIcon />}
            onClick={this.showModel}
          />
          {/* {userRole < 3 &&
                        <div className="project-setting-button">
                            <Link to={Urls.getProjectPlanningList(projectId)}>
                                <LoadingButton title="+ Update Planning" className="primary-btn" onClick={() => { }} />
                            </Link>
                        </div>
                    } */}
          {showModel &&
            <div className="page-model">
              <div className="page-model-wrapper">
                <div className="page-model-cell">
                  <div className="page-model-content setting-form-wrapper">

                    <div className="model-form">
                      <div className="close-icon-wrapper" onClick={this.closeModel}>
                        <div className="close-icon"></div>
                      </div>
                      <div className="title">Project Settings</div>

                      <ProjectFilterChoice />

                      {showLayoutBtns &&
                        <div className="form-group">
                          <div className="form-info-box">
                            <div className="form-info-box-label">Layout Type</div>
                            <div className="setting-action-wrapper">
                              <div className={`layout-icon ${projectViewConfig.layoutType === 1 ? "active" : ""}`}
                                onClick={this.handleLayoutClick(1)}>
                                <div className='fullcard-icon'></div>
                                <div className='fullcard-icon'></div>
                              </div>
                              <div className={`layout-icon ${projectViewConfig.layoutType === 2 ? "active" : ""}`}
                                onClick={this.handleLayoutClick(2)}>
                                <div className='halfcard-icon'></div>
                                <div className='halfcard-icon'></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }

                      {/* <div className="form-group">
                                                <div className="form-info-box">
                                                    <div className="form-info-box-label">Data Type</div>
                                                    <div className="setting-action-wrapper">
                                                        <div className={`settings-icon ${setting.dataType === 1 ? "active" : ""}`}
                                                            onClick={this.handleDataTypeClick(1)}>
                                                            <div className="text">P</div>
                                                        </div>
                                                        <div className={`settings-icon ${setting.dataType === 2 ? "active" : ""}`}
                                                            onClick={this.handleDataTypeClick(2)}>
                                                            <div className="text">M</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                      {showActivitySelect && this.renderActivitySelect(projectViewConfig)}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      )
    }
    else {
      return (
        <div className="project-setting-wrapper">
          <div className="project-setting-item">

            <ProjectFilterChoice />

            {showLayoutBtns &&
              <>
                <div className={`layout-icon ${projectViewConfig.layoutType === 1 ? "active" : ""}`}
                  onClick={this.handleLayoutClick(1)}>
                  <div className='fullcard-icon'></div>
                  <div className='fullcard-icon'></div>
                </div>
                <div className={`layout-icon ${projectViewConfig.layoutType === 2 ? "active" : ""}`}
                  onClick={this.handleLayoutClick(2)}>
                  <div className='halfcard-icon'></div>
                  <div className='halfcard-icon'></div>
                </div>
              </>
            }

          </div>
          <div className="project-setting-item">
            {/* <div className={`settings-icon ${setting.dataType === 1 ? "active" : ""}`}
                            onClick={this.handleDataTypeClick(1)}>
                            <div className="text">P</div>
                        </div>
                        <div className={`settings-icon ${setting.dataType === 2 ? "active" : ""}`}
                            onClick={this.handleDataTypeClick(2)}>
                            <div className="text">M</div>
                        </div> */}

            {showActivitySelect && this.renderActivitySelect(projectViewConfig)}

            {/* {userRole < 3 &&
                            <div className="project-setting-button">
                                <Link to={Urls.getProjectPlanningList(projectId)}>
                                    <LoadingButton title="+ Update Planning" className="primary-btn" onClick={() => { }} />
                                </Link>
                            </div>
                        } */}
          </div>
        </div>
      )
    }
  }

  renderActivitySelect = (projectViewConfig) => {
    const { activityList, activityFetched } = this.props
    if (activityFetched) {
      return (
        <CustomReactSelect label="Filter by activity"
          wrapperClass="select-activity"
          options={activityList}
          isClearable={true}
          isSearchable={true}
          value={activityList.filter(({ id }) => id === projectViewConfig.activityId)}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id}
          onChange={this.handleActivityFilter} />
      )
    }
  }
}

export default connect(store => {
  const projectId = store.projectDetails.projectDetails.id
  const currSetting = store.projectViewConfig[projectId]
  return ({
    // activity fetch
    activityFetched: store.activityList.fetched,
    activityList: store.activityList.activityList,
    // project data
    projectViewConfig: currSetting,
    projectId: projectId,
    projectDetails: store.projectDetails.projectDetails,

    userRole: store.auth.userRole,
    isMobile: store.pageSize.isMobile,
    isMedium: store.pageSize.isMedium,
  })
}, { getActivityList, selectLayout, selectDataType, selectActivityFilter })(ProjectViewConfig)