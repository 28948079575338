import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import ErrorBlock from '../../common/components/ErrorBlock'
import CustomInput from '../../common/components/CustomInput'
import LoadingButton from '../../common/components/LoadingButton'

import { addNotification } from '../../common/data/common.actions'
import { postJsonData } from '../../common/utils'

import Urls from '../../UrlConfig'

/**
 * Scss:
 * 		_auth_page.scss
 * Common:
 * 		_layout.scss, _form.scss, _buttons.scss
 */

class SignupPage extends Component {

	/**
	 * signup form,
	 * signup success redirect to Login page
	 * 
	 * Parent:
	 *      AuthPage
	 */

	constructor(props) {
		super(props)

		this.state = {
			loading: false,
			error: {},
		}
	}

	submit = (event) => {
		const { loading } = this.state
		if (loading) return
		event.preventDefault()

		let error = {}, has_error = false
		const form = document.getElementById('signup-form');
		let data = new FormData(form);
		data.append('role', '3')

		if (!data.has('email')) {
			error['email'] = ['this field is required']
			has_error = true
		}
		if (!data.has('contact_no')) {
			error['contact_no'] = ['this field is required']
			has_error = true
		}
		if (!data.has('password')) {
			error['password'] = ['this field is required']
			has_error = true
		}
		if (has_error) {
			this.setState({ error })
			this.props.addNotification({
				type: 'error',
				title: 'Signup',
				text: 'Input error',
			})
			return
		}

		this.setState({ loading: true, error: {} })

		postJsonData(Urls.apiCreateUser(),
			data,
			(res) => {
				this.setState({ loading: false, error: {} })
				this.props.addNotification({
					type: 'success',
					title: 'Signup',
					text: `Signup Successfully. Please Login.`,
				})
				this.props.history.push(Urls.getAuthPages('login'))
			},
			(err) => {
				this.setState({ loading: false, error: err.error_data })
				this.props.addNotification({
					type: 'error',
					title: 'Signup',
					text: 'Input error',
				})
			}
		)
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	render = () => {
		const { error, loading } = this.state

		return (
			<form id="signup-form" className="form-inner-block">
				<div className="company-title">CHAVDA ERP</div>
				<CustomInput
					label="Email"
					errorData={error}
					errorField="email"
					type="text" name="email"
					placeholder="Enter email"
				/>
				<CustomInput
					label="Contact No"
					errorData={error}
					errorField="contact_no"
					type="text" name="contact_no"
					placeholder="Enter Contact No"
				/>
				<CustomInput
					label="Password"
					errorData={error}
					errorField="password"
					type='password' name="password"
					placeholder="Enter Password"
				/>
				<ErrorBlock error={error} field="__all__" />

				<LoadingButton title="Sign up" className="btn primary-btn"
					onClick={this.submit} isLoading={loading}
				/>
				<div className="tiny-url center">
					Already have an Account ? <Link to={Urls.getAuthPages('login')}>Log In</Link>
				</div>
			</form>
		)
	}
}

export default connect(null, { addNotification })(SignupPage)