import React from 'react'
import PropTypes from 'prop-types';
import Select from 'react-select';

import get from 'lodash/get'
import { SearchIcon } from '../SvgIcons'

/**
 * wrapper on input component for design purpose
 * so inherit all input props
 * 
 * Scss:
 *      _form.scss
 */

const CustomInput = ({ label = "Label", errorData = {}, errorField = "", ...restInputProps }) => {

    const err_list = get(errorData, errorField, false)

    return (
        <div className={`form-group ${err_list ? "error" : ""}`}>
            <div className="label">{label}</div>
            <input {...restInputProps} />
            {err_list && err_list.map((err, id) => {
                return <div className="input-error" key={id}>{err}</div>
            })}
        </div>
    )
}

CustomInput.propTypes = {
    // input label
    label: PropTypes.string.isRequired,
    // django error shape { "key" : ["err msg"]}
    errorData: PropTypes.object,
    // error key in djago error msg
    errorField: PropTypes.string,
    // rest input props are inherited
}


export default CustomInput

/**
 * just an design wrapper on input component
 * Scss:
 *      form ( for input only )
 *      _common_page_layout ( for wrapper and icons )
 * 
 * Props:
 *      inherit all input props
 */
export const CustomSearchBox = ({ ...restInputProps }) => {

    return (
        <div className="global-search-container">
            <div className="wrapper">
                <input className="search-input" {...restInputProps} />
                <div className="icon-wrapper">
                    <SearchIcon />
                </div>
            </div>
        </div>
    )
}

/**
 * wrapper on react select for design purpose
 * inherit all react select props
 * 
 * Scss:
 *      form ( for input only )
 *      _react_select
 */
export const CustomReactSelect = ({ 
    label = "Label", wrapperClass = "",
    errorData = {}, errorField = "", 
    ...restSelectProps 
}) => {

    const err_list = get(errorData, errorField, false)

    return (
        <div className={`form-group ${err_list ? "error" : ""} ${wrapperClass}`}>
            <div className="label">{label}</div>

            <Select className='react-select-container'
                classNamePrefix="react-select"
                {...restSelectProps}
            />

            {err_list && err_list.map((err, id) => {
                return <div className="input-error" key={id}>{err}</div>
            })}
        </div>
    )
}

CustomReactSelect.propTypes = {
    // input label
    label: PropTypes.string.isRequired,
    // extra class to override design
    wrapperClass: PropTypes.string,
    // django error shape { "key" : ["err msg"]}
    errorData: PropTypes.object,
    // error key in djago error msg
    errorField: PropTypes.string,
    // rest react select props are inherited
}