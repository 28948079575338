import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { get } from 'lodash'

import CustomInput, { CustomReactSelect } from '../../../common/components/CustomInput'
import LoadingButton from '../../../common/components/LoadingButton'
import ErrorBlock from '../../../common/components/ErrorBlock'

import { postFormData } from '../../../common/utils'
import { ImagePlaceholder, DeleteIcon } from '../../../common/SvgIcons'

import { AddImageToQualityList } from '../../data/quality.actions'

import Urls from '../../../UrlConfig'



class QualityAddPopup extends Component {

  /**
   * show add image popup
   */

  constructor(props) {
    super(props)
    // new or selected check id
    const selectedChecklistId = props.checkId === 'new' ? -1 : Number(props.checkId)

    this.state = {
      selectedChecklistId,
      image: null,
      imageFile: null,
      caption: '',

      submitting: false,
      submitError: {}
    }
  }

  uploadImage = (e) => {
    if (e) e.preventDefault();
    const { selectedChecklistId, imageFile, caption } = this.state

    // if checklist is not selected
    if ((!selectedChecklistId) || (selectedChecklistId === -1)) {
      this.setState({ submitError: { 'checklist': ["please select checklist."] } })
      return
    }

    let data = new FormData();
    data.append('image', imageFile)
    data.append('checklist', selectedChecklistId)
    data.append('caption', caption)
    data.append('uploaded_by', this.props.loggedUserId)

    this.setState({ submitting: true })
    postFormData(Urls.apiPostQualityImage(),
      data,
      (res) => {
        this.props.AddImageToQualityList(res)
        this.props.history.push(Urls.getProjectDetails(this.props.projectId, 'quality'))
      },
      (err) => {
        this.setState({ submitting: false, submitError: err.error_data })
      }
    )

  }

  handleCheckSelect = (props) => {
    this.setState({ selectedChecklistId: props.id, submitError: {} })
  }

  loadFile = (event) => {
    const selected_image = event.target.files[0]
    if (selected_image) {
      this.setState({ image: URL.createObjectURL(selected_image), imageFile: selected_image })
    }
  }

  clearImg = () => {
    this.setState({ image: null, imageFile: null })
  }

  //select search logic.
  customFilter = (option, searchText) => {
    if (option.data.display_name.toLowerCase().includes(searchText.toLowerCase())) {
      return true
    }
    return false
  }

  render = () => {

    const { checklist, projectId } = this.props
    const { selectedChecklistId, image, imageFile,
      submitting, submitError } = this.state

    return (
      <div className="page-model">
        <div className="page-model-wrapper">
          <div className="page-model-cell">
            <div className="page-model-content quality-img-add">
              <div className="model-form">

                <Link className="close-icon-wrapper"
                  to={Urls.getProjectDetails(projectId, 'quality')}>
                  <div className="close-icon"></div>
                </Link>
                <div className="title">Add Quality Image</div>

                <CustomReactSelect label="Select Check"
                  options={checklist}
                  isClearable={false}
                  isSearchable={true}
                  filterOption={this.customFilter}
                  value={checklist.filter(({ id }) => id === selectedChecklistId)}
                  getOptionLabel={(props) => {
                    return (
                      <div className="quality-select-content">
                        <div className="qs-heading">{props.display_name}</div>
                        <div className="qs-text">{get(props, 'unit.name')} &gt; {get(props, 'subunit.name')}</div>
                      </div>
                    )
                  }}
                  getOptionValue={({ id }) => id}
                  onChange={this.handleCheckSelect} />

                <div className="quality-img-upload-wrapper">
                  <div className="quality-img-upload-col">
                    <div className="quality-img-upload-block">
                      <input type="file" name="image" onChange={this.loadFile} />
                      <div className="quality-img-upload-content">
                        <ImagePlaceholder />
                      </div>
                      {image && <img src={image} alt="" />}
                    </div>
                  </div>
                  <div className="quality-img-info-col">
                    <div className="heading">Browse file</div>
                    <div className="desc">only png, jpg, with max file size of 8mb</div>
                    <ErrorBlock field="image" error={submitError} />
                  </div>
                </div>

                <div className={`quality-img-indicator ${imageFile ? "show" : ""}`}>
                  <div className="img-name">{get(imageFile, 'name')}</div>
                  <div className="indicator-wrap">
                    <div className="ind"></div>
                    <div className="remove" onClick={this.clearImg}><DeleteIcon /></div>
                  </div>
                </div>

                <CustomInput label="Caption"
                  onChange={e => this.setState({ caption: e.target.value })} />

                <ErrorBlock field="checklist" error={submitError} />
                <ErrorBlock field="__all__" error={submitError} />

                <div className="quality-action-wrapper">
                  <LoadingButton title="Upload" className="btn primary-btn"
                    isLoading={submitting} onClick={this.uploadImage} />
                  <Link to={Urls.getProjectDetails(projectId, 'quality')} className="btn secondary-btn">
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect((store) => ({
  projectId: store.projectDetails.projectDetails.id,
  checklist: store.checklist.checklist,
  loggedUserId: store.auth.user,
}), { AddImageToQualityList })(QualityAddPopup)