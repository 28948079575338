import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink, Switch, Route, Link } from 'react-router-dom'

import { get } from 'lodash'

import ProjectPermissionList from './ProjectPermissionList'
import UnitList from './UnitList'
import Loader from '../../common/components/Loader'

import { getProjectFormDetails } from '../data/adminProject.actions'
import { BackIcon, EditPencil } from '../../common/SvgIcons'

import Urls from "../../UrlConfig"

class ProjectEdit extends Component {

	/**
	 * fetch project details from server,
	 * manage loading, error
	 * render banner and tabs in case of success
	 * 
	 * Parent:
	 * 		ProjectFormPage
	 * 
	 * Renders:
	 * 		ProjectPermissionList
	 * 		UnitList
	 */

	componentDidMount = () => {
		if (!this.props.fetched) {
			this.props.getProjectFormDetails(this.props.projectId)
		}
	}

	render = () => {
		const { projectId, fetched, fetchError, formData, 
			windowHeight, navbarHeight, backPillHeight } = this.props

		const pageHeight = windowHeight - navbarHeight
		const pageContentHeight = pageHeight - backPillHeight

		if (fetched) {
			return (
				<div id="project-form-page" className="project-edit-page"
					style={{ height: `${pageHeight}px` }}>

					<div className="page-back-pill">
                        <Link to={Urls.getProjectPage()} className="page-back-content">
                            <BackIcon />
                            <div className="page-back-text">Go back to Project List</div>
                        </Link>
                        <div className="page-pill-title">
							<span className="hide-on-small-and-down">{get(formData, 'name', '-NA-')}</span>
							<Link className="project-edit-text" to={Urls.getProjectForm(formData.id, 'edit')}>
								Edit <EditPencil />
							</Link>
						</div>
                    </div>
					
					<div className="page-content-wrapper"
						style={{ height: `${pageContentHeight}px`}}>					
						<div className="container">
							<div className="page-inline-tab">
								<NavLink to={Urls.getProjectForm(projectId, 'structure')}
									className="tab">Structure</NavLink>
								<NavLink to={Urls.getProjectForm(projectId, 'permission')}
									className="tab">Permission</NavLink>
							</div>

							<Switch>
								<Route exact path={Urls.getProjectForm(projectId, 'structure')} component={UnitList} />
								<Route path={Urls.getProjectForm(projectId, 'permission')} component={ProjectPermissionList} />
							</Switch>

						</div>
					</div>
				</div>
			)
		}
		else if (fetchError) {
			return (
				<div className="container">
                    <div className="error-block big">Failed to fetch data</div>
                </div>
			)
		}
		else {
			return <Loader />
		}
	}
}


export default connect(store => ({
	fetched: store.projectForm.fetched,
	fetchError: store.projectForm.fetchError,

	formData: store.projectForm.formData,
	deletedUnitIds: store.projectForm.deletedUnitIds,

	windowHeight: store.pageSize.windowHeight,
	navbarHeight: store.pageSize.navbarHeight,
	bannerMinHeight: store.pageSize.bannerMinHeight,
	backPillHeight: store.pageSize.backPillHeight,
}), { getProjectFormDetails })(ProjectEdit)