import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { TransformWrapper } from "react-zoom-pan-pinch";

import { getWindowHeight } from "../data/common.selector";
import DemoWrapper from "../components/DemoWrapper/DemoWrapper";
import Notification from "../components/Notification";

export default () => {
  /**
   * Parent:
   *      App
   */
  const contentHeight = useSelector(getWindowHeight);

  return (
    <div id="demo-page" style={{ height: `${contentHeight}px` }}>
      <Notification />
      <TransformWrapper
        limitToBounds={true}
        alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
      >
        {({ resetTransform, setTransform }) => (
          <DemoWrapper
            setTransform={setTransform}
            resetTransform={resetTransform}
          />
        )}
      </TransformWrapper>
    </div>
  );
};
