import React from "react";

/**
 * Show only information of a field
 * 
 * Used in place of read only text box/ dropdowns
 */
export default ({label, text}) => {
	return (
		<div className="form-group">
			<div className="form-info-box">
				<div className="form-info-box-label">{label} : </div>
				<div className="form-info-box-text">{text}</div>
			</div>
		</div>
	)
}