import React, { Component } from 'react'
import { connect } from 'react-redux'

import { get } from 'lodash'

import Loader from '../../common/components/Loader'
import LoadingButton from '../../common/components/LoadingButton'
import InvitationForm from './InvitationForm'
import { DeleteIcon } from '../../common/SvgIcons'

import { getInvitationList } from '../data/invitation.actions'

import { addNotification } from '../../common/data/common.actions'
import { getJsondata } from '../../common/utils'
import Urls from '../../UrlConfig'

/**
 * Scss
 * 		_activity_list
 * 
 * Common
 * 		layout, _common_page_layout
 */


class InvitationListPage extends Component {

	/**
	 * Parent:
	 * 		AdminHomePage
	 */

	state = {
		contentHeight: 0,
		showForm: false,
		deletingId: null
	}

	componentDidMount = () => {
		if(!this.props.fetched) {
			this.props.getInvitationList()
		}
	}

	handleShowModel = () => {
		this.setState({ showForm: true })
	}

	handleCloseModel = () => {
		this.setState({ showForm: false })
	}

	resizeContent = (element) => {
		if (element) {
			const { windowHeight } = this.props

			const contentHeight = windowHeight - element.getBoundingClientRect().top
			this.setState({ contentHeight })
		}
	}

	handleDelete = (inviteId) => () => {
		// do not hit, if already one invite is deleting
		if(this.state.deletingId) return
		this.setState({ deletingId: inviteId })

		getJsondata(Urls.apiUserInvitationDelete(inviteId),
			(res) => {
				this.setState({ deletingId: null })
				this.props.getInvitationList()
				this.props.addNotification({
                    type: 'success',
                    title: 'Invitation',
                    text: `Invitation delete successfully`,
                })
			},
			(err) => {
				this.setState({ deletingId: null })
				this.props.addNotification({
                    type: 'error',
                    title: 'Invitation',
                    text: 'requested invite not found',
                })
			}
		)
	}

	render = () => {
		const { showForm } = this.state

		return (
			<>
				<div className="page-right-actions">
					<div className="page-action-wrapper">
						<div className="global-search-container" />
						<div className="page-add-new">
							<LoadingButton className="primary-btn"
								title="+ Invite User"
								onClick={this.handleShowModel}
							/>
						</div>
					</div>
				</div>
				<div id="activity-list-page">

					{this.renderList()}

					{showForm &&
                        <div className="page-model">
                            <div className="page-model-wrapper">
                                <div className="page-model-cell">
                                    <div className="page-model-content user-form-wrapper">
										<InvitationForm closeModel={this.handleCloseModel} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
				</div>
			</>
		)
	}

	renderList = () => {
		const { fetching, fetched, error, invitationList } = this.props
		const { contentHeight, deletingId } = this.state

		if (fetched && !fetching) {
			const paddingBot = 15
			let wrapperHeightCss = {paddingBot}
			// set planning cols height to scroll individually
			if(contentHeight) {
				wrapperHeightCss.height = contentHeight - paddingBot
			}

			return (
				<div className="activity-list-wrapper" ref={this.resizeContent}>
					<div className="activity-list-container" style={wrapperHeightCss}>
						{invitationList.length ?
							<div className="flex-wrapper">
								{invitationList.map((invite) => {
									const isDeleting = deletingId === invite.id
									return (
										<div key={invite.id} className={`activity-block ${invite.is_accepted ? 'active-green' : ''}`}>
											<div className={`activity-edit ${isDeleting ? "active" : ""}`}
												onClick={this.handleDelete(invite.id)}>
												<DeleteIcon />
											</div>
											<div className="title">{get(invite, 'email', '-NA-')}</div>
											<div className="wrapper">
												Role : {get(invite, 'role_name', '-NA-')}
											</div>
										</div>
									)
								})}
							</div>
							:
							<div className="empty-page-wrapper">
								<img src={Urls.getStaticImg('/img/no-activity.svg')} alt="" />
								<div className="text">Looks like you don't invited anyone</div>
							</div>
						}
					</div>
				</div>
			)
		}
		else if (error) {
			return (
                <div className="empty-page-wrapper">
                    <div className="error-block big">Failed to fetch data</div>
                </div>
			)
		}
		else {
			return <Loader />
		}
	}
}


export default connect(store => ({
	fetching: store.invitationList.fetching,
	fetched: store.invitationList.fetched,
	error: store.invitationList.error,
	invitationList: store.invitationList.invitationList,
	windowHeight: store.pageSize.windowHeight,
}), { getInvitationList, addNotification })(InvitationListPage)