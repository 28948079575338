

export const selectUnit = (projectId, unit, subunitList) => ({
    type: "PROJECT_SETTING_SELECT_UNIT", payload: {projectId, unit, subunitList}
})

export const selectSubUnit = (projectId, subunit, subunitList) => ({
    type: "PROJECT_SETTING_SELECT_SUBUNIT", payload: {projectId, subunit, subunitList}
})

export const resetFilterSetting = (projectId) => ({
    type: "PROJECT_SETTING_RESET_FILTER", payload: {projectId}
})

export const selectLayout = (projectId, layoutType) => ({
    type: "PROJECT_SETTING_SELECT_LAYOUT", payload: {projectId, layoutType}
})

export const selectDataType = (projectId, dataType) => ({
    type: "PROJECT_SETTING_SELECT_DATATYPE", payload: {projectId, dataType}
})

export const selectActivityFilter = (projectId, activityId) => ({
    type: "PROJECT_SETTING_SELECT_ACTIVITY", payload: {projectId, activityId}
})
