import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import ErrorBlock from '../../common/components/ErrorBlock'
import CustomInput from '../../common/components/CustomInput'
import LoadingButton from '../../common/components/LoadingButton'

import { postFormData } from '../../common/utils'
import Urls from '../../UrlConfig'

/**
 * Scss:
 * 		_auth_page.scss
 */

export default (props) => {

    /**
     * Parent : 
     *      App
     * 
     */

    const { uuid } = useParams();

    const [api_state, setApiState] = useState({ loading: false, errors: {} })

    const resetPass = useCallback((e) => {
        if (e) e.preventDefault();
        if (api_state.loading) return

        const form = document.getElementById('reset-form');
        let data = new FormData(form);
        data.append('uuid', uuid)

        setApiState({ loading: true, errors: {} })

        postFormData(Urls.apiResetPassword(),
            data,
            (res) => {
                setApiState({ loading: false, errors: {} })
                props.history.push(Urls.getAuthPages('login'))
            },
            (err) => {
                setApiState({ loading: false, errors: err.error_data })
            },
        )

    }, [])


    return (
        <div className="auth-page">
            <div className="shadow" />
            <div className="login-page">
                <div className="login-page-content">
                    <div className="heading">
                        Lorem ipsum dolors sum, ergo cogito scum
					</div>
                    <div className="form-block">
                        <div className="form-inner-block">
                            <div className="company-title">CHAVDA ERP</div>
                            <form id="reset-form">
                                <CustomInput
                                    label="Create Password"
                                    errorData={api_state.errors}
                                    errorField="new_password"
                                    type='password' name="new_password"
                                    placeholder="Create Password"
                                />
                                <CustomInput
                                    label="Re-enter the password"
                                    errorData={api_state.errors}
                                    errorField="confirm_password"
                                    type='password' name="confirm_password"
                                    placeholder="Re-enter the password"
                                />
                                <ErrorBlock error={api_state.errors} field="__all__" />

                                <LoadingButton title="Submit" className="btn primary-btn"
                                    onClick={resetPass} isLoading={api_state.loading}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}