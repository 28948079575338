import { unionBy, pullAllBy } from 'lodash'

const MOBILE_SCREEN = 600
const MEDIUM_SCREEN = 992
const LARGE_SCREEN = 1200

const INIT_PAGE_SIZE = {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    navbarHeight: 50, // 50px
    backPillHeight: 40,
    bannerMinHeight: 70, // 70px
    isMobile: window.innerWidth <= MOBILE_SCREEN,
    isMedium: window.innerWidth <= MEDIUM_SCREEN,
    isLarge: window.innerWidth <= LARGE_SCREEN,
    isExtraLarge: window.innerWidth > LARGE_SCREEN
}

export const pageSizeReducer = (state = INIT_PAGE_SIZE, action) => {
    // change store state with new window sizes when ever window size is changed

    switch (action.type) {
        case "WINDOW_SIZE_CHANGED": {
            return {
                ...state,
                isMobile: window.innerWidth <= MOBILE_SCREEN,
                isMedium: window.innerWidth <= MEDIUM_SCREEN,
                isLarge: window.innerWidth <= LARGE_SCREEN,
                isExtraLarge: window.innerWidth > LARGE_SCREEN,
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight
            }
        }

        default: {
            return state;
        }
    }
}


/**
 * notificationReducer Used By :
 *      Notification
 */

const INIT_NOTIFICATION = {
    noti_count: 0,
    notifications: ''
}
export const notificationReducer = (state = INIT_NOTIFICATION, action) => {

    switch (action.type) {
        case "ADD_NOTIFICATION": {
            const new_notifications = unionBy(state.notifications, action.payload, 'title');
            return { ...state, notifications: new_notifications, noti_count: new_notifications.length }
        }

        case "REMOVE_NOTIFICATION": {
            const new_notifications = pullAllBy(state.notifications, [{ 'title': action.payload }], 'title');
            return { ...state, notifications: new_notifications, noti_count: new_notifications.length }
        }

        default: {
            return { ...state };
        }
    }

}