import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage'

import debounce from 'lodash/debounce'

import { authReducer } from './auth/data/auth.reducers'
import { loggedUserReducer } from './profile/data/profile.reducers'
import { pageSizeReducer, notificationReducer } from './common/data/common.reducers'
import { projectFormReducer } from './admin/data/projectForm.reducer'
import { activityListReducer } from "./admin/data/activity.reducer";
import { checkConfigListReducer } from "./admin/data/checklistConfig.reducer";
import { 
	projectDetailsReducer, 
	projectListReducer,
	microPlanningListReducer, 
} from './project/data/project.reducer'
import { checklistReducer } from './project/data/quality.reducer'
import { userListReducer } from './admin/data/userList.reducer'
import { invitationListReducer } from './admin/data/invitation.reducer'
import { 
	planningListReducer, 
	planningDetailsReducer,
} from "./admin/data/planning.reducer"
import { projectViewConfigReducer } from './project/data/projectViewConfig.reducer'
import { reportReducer } from "./reports/data/reports.reducer";


const config = {
	key: 't&4gkl99_z5%kx_o0@r*s#_)l=1&3^#0nh@0k$jgi9b3lmy!p+',
	whitelist: ['auth', 'projectViewConfig'],
	storage,
}

const reducers = {
	// auth reducers
	auth			:	authReducer,
	loggedUser		:	loggedUserReducer,
	// helper reducers
	pageSize		:	pageSizeReducer,
	notifications	:	notificationReducer,
	// admin reducers
	projectList		:	projectListReducer,
	projectForm		:	projectFormReducer,
	// admin activities
	activityList	:	activityListReducer,
	checkConfigList	:	checkConfigListReducer,
	// project details
	projectDetails	:	projectDetailsReducer,
	projectViewConfig : projectViewConfigReducer,
	microPlanningList: 	microPlanningListReducer,
	planningList	: 	planningListReducer,
	planningDetails	: 	planningDetailsReducer,
	checklist		: 	checklistReducer,

	report			: reportReducer,
	// user list
	userList		:	userListReducer,
	invitationList	:	invitationListReducer,
}

const combinedReducers = persistCombineReducers(config, reducers);

// disable redux log in react-dev-tools in production
const composeEnhancer = (process.env.NODE_ENV === 'development' &&
	typeof window !== 'undefined' &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
	compose;

const store = createStore(combinedReducers,composeEnhancer(applyMiddleware(thunk)));

export default store;

// update window size state when window resize event fires
const handleWindowResize = debounce(() => {
	store.dispatch({
		type: 'WINDOW_SIZE_CHANGED'
	})
}, 500, { trailing: true });

window.addEventListener('resize', handleWindowResize);

// disable react-dev-tools prolifier and component tree in production
if(process.env.NODE_ENV === 'production' && (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object')) {
	for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
		window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? ()=>{} : null;
	}
}