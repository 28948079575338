import Axios from 'axios'

import {get, clamp} from 'lodash'
import store from '../store'

import { format } from 'date-fns'

import Urls from '../UrlConfig'
import { userLogOut } from '../auth/data/auth.actions'
import { addNotification } from '../common/data/common.actions'

export function postJsonData(url, data, callback = false, errback = false) {
	/** Returns a promise of api interaction */
	const token = store.getState().auth.token

	Axios({
		method: 'post',
		url,
		headers: { "Authorization": `Bearer ${token}` },
		data
	})
		.then(res => {
			if (callback) callback(res.data);
		})
		.catch(err => {
			// console.log(err.response)

			const error_code = get(err, 'response.status', 999)
			const error_data = get(err, 'response.data', { "__all__": ["cannot connect to server."] })
			if (error_code === 401) {
				store.dispatch(userLogOut())
				store.dispatch(addNotification({
					type: 'info',
					title: 'Logout Successfully',
					text: 'For security reasons, you are logged out. login again.',
				}))
			}
			if (errback) errback({ error_data, error_code })
		})
}

export function postFormData(url, data, callback = false, errback = false) {
	/** Returns a promise of api interaction */
	const token = store.getState().auth.token

	Axios({
		method: 'post',
		url,
		headers: {
			'content-type': 'multipart/form-data',
			"Authorization": `Bearer ${token}`
		},
		data
	})
		.then(res => {
			if (callback) callback(res.data);
		})
		.catch(err => {
			// console.log(err.response)

			const error_code = get(err, 'response.status', 999)
			const error_data = get(err, 'response.data', { "__all__": ["cannot connect to server."] })
			if (error_code === 401) {
				store.dispatch(userLogOut())
				store.dispatch(addNotification({
					type: 'info',
					title: 'Logout Successfully',
					text: 'For security reasons, you are logged out. login again.',
				}))
			}
			if (errback) errback({ error_data, error_code })
		})
}

export function getJsondata(url, callback = false, errback = false) {
	/** Returns a promise of api interaction */
	const token = store.getState().auth.token

	Axios({
		method: 'get',
		url,
		headers: { "Authorization": `Bearer ${token}` }
	})
		.then(res => {
			if (callback) callback(res.data);
		})
		.catch(err => {
			// console.log(err.response)

			const error_code = get(err, 'response.status', 999)
			const error_data = get(err, 'response.data', { "__all__": ["cannot connect to server."] })
			if (error_code === 401) {
				store.dispatch(userLogOut())
				store.dispatch(addNotification({
					type: 'info',
					title: 'Logout Successfully',
					text: 'For security reasons, you are logged out. login again.',
				}))
			}
			if (errback) errback({ error_data, error_code })
		})
}

export function fetchBlobData(url, method, data = {}, callback = false, errback = false) {
	/** Returns a promise of api interaction */
	const token = store.getState().auth.token

	Axios({
		url,
		method,
		data,
		headers: { "Authorization": `Bearer ${token}` },
		responseType: 'blob',
	})
		.then(res => {
			if (callback) callback(res.data);
		})
		.catch(err => {
			// console.log(err)
			const error_code = get(err, 'response.status', 500)
			const error_data = get(err, 'response.data', { "__all__": ["Server error. Contact admin"] })
			if (error_code === 401) {
				store.dispatch(userLogOut())
				store.dispatch(addNotification({
					type: 'info',
					title: 'Logout Successfully',
					text: 'For security reasons, you are logged out. login again.',
				}))
			}
			if (errback) errback({ error_data, error_code });
		})
}



/**
 * 
 * @param {*} date - String date 
 * 
 * retutn readable data Ex - 3rd Aug 2020
 */
export const formatDate = (date) => {
	return format(new Date(date), 'do MMM yyyy')
}

/**
 * 
 * @param {*} date - String date 
 * 
 * retutn readable data Ex - 3rd Aug 2020
 */
export const formatDateTime = (datetime) => {
	return format(new Date(datetime), 'do MMM yyyy, h:mm aaa')
}

/**
 * 
 * @param {*} date - Date object
 * 
 * retutn formated django accepted date in String
 */
export const formatSubmitDate = (date) => {
	return format(date, "yyyy-MM-dd HH:mm:ssxxx")
}

/***
 * 
 */
export const toggleFromSet = (itemId, itemList) => {
	const newitemList = new Set(itemList)
	if (newitemList.has(itemId)) {
		newitemList.delete(itemId)
	}
	else {
		newitemList.add(itemId)
	}

	return newitemList
}

/**
 * css method to display placeholder image in case of banner image fail
 * @param {*} image - s3 image url
 */
export const getBannerStyle = (image) => {
	return {
		backgroundImage: `url(${image}), url(${Urls.getDefaultBanner()})`
	}
}

/**
 * 
 * @param {*} pageYOffset 
 * 		pageYOffset - window.pageYOffset from store
 * 		min - 0, default scroll position
 * 		max - 140, content scroll up
 * 		so for calculation - 130 is taken
 * 		
 * 		if mobile, header will be on absolute position so that will stick with its position
 *		and banner will hide on little scroll up
 * 
 * 		for other screens except mobile, header will move to top with scroll,
 *		and banner opacity reduce with respecy to banner
 * @param {*} isMobile - Bool
 */
export const calculateBannerOpacity = (pageYOffset, isMobile ) => {
	let bannerOpacity = 1

	if(isMobile) {
		if(pageYOffset > 20) {
			bannerOpacity = 0
		} else {
			bannerOpacity = 1
		}
	}
	else {
		let newVal = (130 - pageYOffset) / 130;
		newVal = clamp(newVal, 0, 1)
		bannerOpacity = newVal
	}

	return bannerOpacity
}

export const getAdminBannerStyle = (pageHeight) => {

	const pageContentMinHeight = pageHeight - 210 // 210 is height of banner
	const pageContentMaxHeight = pageHeight - 70 // 70 in min height after scroll of banner

	return {
		minHeight: `${pageContentMinHeight}px`,
		maxHeight: `${pageContentMaxHeight}px`,
	}
}