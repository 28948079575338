const INIT_DATA = {
    id: null,
    is_superuser: false,
    first_name: "",
    last_name: "",
    is_staff: false,
    is_active: true,
    email: "",
    contact_no: "",
    username: null,
    verified: false,
    uuid: "",
    is_first_login: false,
    web_onesignal_id: null,
    mobile_onesignal_id: null,
    role: null,
    role_name: ''
}

export const loggedUserReducer =  (state = INIT_DATA, { type, payload }) => {
    switch (type) {

        // fire on login success
        case 'USER_LOGGING_SUCCESS': {
            const { user } = payload

            return { ...state, ...user }
        }

        // fire on any kind of user details update
        case 'UPDATE_USER_DETAILS': {
            const { user } = payload

            return { ...state, ...user }
        }

        case 'USER_LOGOUT': {
            return INIT_DATA
        }

        default: {
            return state
        }
    }
}