import {createStatusWiseMplList} from '../../admin/data/planning.utils'

const INIT_PROJECT_DETAILS = {
    fetching: false,
    fetched: false,
    fetchError: false,
    fetchErrorObj: {},
    // project details
    projectDetails: {},
}

/**
 * Used to show project details / form page
 * Used to get project id, unit, subunit for project planning form
 */
export const projectDetailsReducer =  (state = INIT_PROJECT_DETAILS, { type, payload }) => {
    switch (type) {

        case 'PROJECT_DETAILS_FETCHING': {
            return { ...state, 
                fetching: true,
                fetchError: false,
                fetchErrorObj: {},
            }
        }

        case 'PROJECT_DETAILS_SUCCESS': {
            return { ...state,
                fetching: false,
                fetched: true,
                projectDetails: payload,
            }
        }

        case 'PROJECT_DETAILS_ERROR': {
            return { ...state, 
                fetching: false,
                fetched: false,
                fetchError: true,
                fetchErrorObj: payload
            }
        }

        case 'RESET_PROJECT_DETAILS': {
            return { ...INIT_PROJECT_DETAILS }
        }

        case 'USER_LOGOUT': {
            return INIT_PROJECT_DETAILS
        }

        default: {
            return state
        }
    }
}


const INIT_PROJECT_LIST = {
    fetching: false,
    fetched: false,
    error: false,
    errorMsg: {},
    
    projectList: [],
}


export const projectListReducer =  (state = INIT_PROJECT_LIST, { type, payload }) => {
    switch (type) {

        case 'PROJECT_LIST_FETCHING': {
            return { ...state, 
                fetching: true,
                error: false,
                errorMsg: {},
            }
        }

        case 'PROJECT_LIST_SUCCESS': {
            return { ...state,
                fetching: false,
                fetched: true,
                projectList: payload,
            }
        }

        case 'PROJECT_LIST_ERROR': {
            return { ...state, 
                fetching: false,
                fetched: false,
                error: true,
                errorMsg: payload
            }
        }

        case 'USER_LOGOUT': {
            return INIT_PROJECT_LIST
        }

        default: {
            return state
        }
    }
}

const INIT_MICRO_PLANNING_LIST = {
    fetching: false,
    fetched: false,
    fetchError: false,
    fetchErrorObj: {},
    microPlanningList: [],

    mpWaitingList: [],
    mpRunningList: [],
    mpCompletedList: [],
    mpScheduledList: [],
    statusListLength: {
        mpWList: 0,
        mpRList: 0,
        mpCList: 0,
        mpSList: 0,
    },
}


export const microPlanningListReducer =  (state = INIT_MICRO_PLANNING_LIST, { type, payload }) => {
    switch (type) {

        case 'MICRO_PLANNING_LIST_FETCHING': {
            return { ...state, 
                fetching: true,
                error: false,
                errorMsg: {},
            }
        }

        case 'MICRO_PLANNING_LIST_SUCCESS': {
            const { mpWaitingList, mpRunningList,
                mpCompletedList, mpScheduledList } = createStatusWiseMplList(payload)
            
            const statusListLength = {
                mpWList: mpWaitingList.length,
                mpRList: mpRunningList.length,
                mpCList: mpCompletedList.length,
                mpSList: mpScheduledList.length,
            }

            return { ...state,
                fetching: false,
                fetched: true,
                microPlanningList: payload,
                mpWaitingList, mpRunningList, 
                mpCompletedList, mpScheduledList,
                statusListLength
            }
        }

        case 'MICRO_PLANNING_LIST_ERROR': {
            return { ...state, 
                fetching: false,
                fetched: false,
                error: true,
                errorMsg: payload
            }
        }

        case 'RESET_PROJECT_DETAILS': {
            return { ...INIT_MICRO_PLANNING_LIST }
        }

        case 'USER_LOGOUT': {
            return INIT_MICRO_PLANNING_LIST
        }

        default: {
            return state
        }
    }
}