import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import ErrorBlock from '../../common/components/ErrorBlock'
import CustomInput from '../../common/components/CustomInput'
import LoadingButton from '../../common/components/LoadingButton'

import { postFormData } from '../../common/utils'

import Urls from '../../UrlConfig'

/**
 * Scss:
 * 		_auth_page.scss
 * Common:
 * 		_layout.scss, _form.scss, _buttons.scss
 */

export default connect(store => ({
    is_user_logged: store.auth.logged,
    fetching: store.auth.fetching,
    error_obj: store.auth.error_obj,
}))(props => {

    /**
     * forget pass form,
     * 
     * Parent:
     *      AuthPage
     */

    const [api_state, setApiState] = useState({ loading: false, errors: {}, success: false })

    const forgetPass = useCallback((e) => {
        if (e) e.preventDefault();
        if (api_state.loading) return

        const form = document.getElementById('forgot-pass-form');
        let data = new FormData(form);

        setApiState({ loading: true, errors: {} })

        postFormData(Urls.apiForgotPassword(),
            //data
            data,
            // callback
            (res) => {
                setApiState({ loading: false, errors: {}, success: true })
            },
            // errback
            (err) => {
                setApiState({ loading: false, errors: err.error_data })
            },
        )

    }, [])

    return (
        <form id="forgot-pass-form" className="form-inner-block">
            <div className="company-title">CHAVDA ERP</div>

            {api_state.success ?
                <div className="sub-title success-text">
                    Verification link is sent to your email address successfully. Please check
                </div>
                :
                <div className="sub-title accent-text">
                    <div>Please enter your email address</div>
                    <div className="extra-pad">verification code will be sent to given email</div>
                </div>
            }

            <CustomInput
                label="Email"
                errorData={api_state.errors}
                errorField="email"
                type="text" name="email"
                placeholder="Enter email"
            />
            <div className="forgot-pass">
                <Link to={Urls.getAuthPages('login')}>Have Account ? Login</Link>
            </div>
            <ErrorBlock error={api_state.errors} field="__all__" />

            <LoadingButton title="Submit" className="btn primary-btn"
                onClick={forgetPass} isLoading={api_state.loading}
            />
        </form>
    )

})