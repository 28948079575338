import React from "react";
import PropTypes from "prop-types";

import { PlusIcon } from "../SvgIcons";

/**
 * Scss:
 *      _buttons.scss
 */

const LoadingButton = ({
  className,
  title,
  icon = null,
  onClick = () => {},
  isLoading = false,
  isDisable = false,
}) => {
  let handleClick, buttonText, buttonClassName;
  if (isLoading) {
    handleClick = () => {};
    buttonText = "Loading...";
    buttonClassName = `btn ${className} loading-btn`;
  } else if (isDisable) {
    handleClick = () => {};
    buttonText = title;
    buttonClassName = `btn ${className} disable-btn`;
  } else {
    handleClick = onClick;
    buttonText = title;
    buttonClassName = `btn ${className}`;
  }

  return (
    <button className={buttonClassName} onClick={handleClick}>
      {icon} &nbsp;{buttonText}
    </button>
  );
};

export const RoundedButton = ({
  className,
  icon,
  onClick = () => {},
  isLoading = false,
  isDisable = false,
}) => {
  let handleClick, buttonClassName;
  if (isLoading) {
    handleClick = () => {};
    buttonClassName = `rounded-btn ${className} loading-btn`;
  } else if (isDisable) {
    handleClick = () => {};
    buttonClassName = `rounded-btn ${className} disable-btn`;
  } else {
    handleClick = onClick;
    buttonClassName = `rounded-btn ${className}`;
  }

  return (
    <button className={buttonClassName} onClick={handleClick}>
      {icon}
    </button>
  );
};

export const FloatingBtn = ({ onClick }) => {
  return (
    <div className="floating-btn" onClick={onClick}>
      <PlusIcon />
    </div>
  );
};

LoadingButton.propTypes = {
  // for design
  className: PropTypes.string.isRequired,
  // to display button name
  title: PropTypes.string.isRequired,
  // handler for button
  onClick: PropTypes.func,
  // to indicate loading
  isLoading: PropTypes.bool,
  // to indicate disable
  isDisable: PropTypes.bool,
};

export default LoadingButton;
