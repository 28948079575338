import React, { Component } from "react";

import { get } from "lodash";

import LoadingButton from "../../common/components/LoadingButton";
import CustomImage from "../../common/components/CustomImage";
import StatusCheckBox from "./StatusCheckBox";

import { formatDateTime } from "../../common/utils";

/**
 * Scss:
 *      _reports, _reports_quality
 */
export default class QualityReport extends Component {
  /**
   * Parent:
   *      ReportPage
   */

  render = () => {
    const { quality_data, project } = this.props.reportData;

    if (quality_data.length) {
      return (
        <div className="report-content-wrapper">
          <div className="report-content-info-content">
            <div className="report-content-info-title">
              {project.name} - Quality Report
            </div>
            {/* <div className="report-content-info-action">
              <LoadingButton title="Download Report"
                className="btn primary-btn"
                onClick={() => { }} />
            </div> */}
          </div>

          {this.renderQualityBlocks()}
        </div>
      );
    } else {
      return (
        <div className="empty-report-data">
          <div>No Quality data for this project</div>
        </div>
      );
    }
  };

  renderQualityBlocks = () => {
    const { quality_data } = this.props.reportData;

    return (
      <div>
        {quality_data.map((quality) => {
          return (
            <div className="quality-container" key={quality.id}>
              <div className="quality-container-info">
                <div className="quality-container-title">
                  {get(quality, "unit.name")} &gt;{" "}
                  {get(quality, "subunit.name")} &gt;{" "}
                  {get(quality, "display_name")}
                </div>
                <div className="quality-container-check">
                  <StatusCheckBox status={quality.is_checked ? "R" : ""} />
                </div>
              </div>
              <div className="divider" />

              {this.renderQualityImages(get(quality, "quality_images", []))}
            </div>
          );
        })}
      </div>
    );
  };

  renderQualityImages = (quality_images) => {
    if (quality_images.length) {
      return (
        <div className="quality-check-images-wrapper">
          {quality_images.map((checkImg) => {
            return (
              <div className="check-img-block" key={checkImg.id}>
                <div className="check-img-wrapper">
                  <CustomImage
                    imageUrl={checkImg.image}
                    prefixClassName="check"
                  />
                  <div className="check-img-extra-info">
                    <div>
                      <div className="name">
                        {get(checkImg, "uploaded_by.fullname")}
                      </div>
                      <div className="time">
                        {formatDateTime(checkImg.created_on)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="check-img-info">{checkImg.caption}</div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="quality-content-empty">
          <div>No Images found for this structure </div>
        </div>
      );
    }
  };
}
