import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'

import { get, map } from 'lodash'

import Loader from '../../common/components/Loader'
import ErrorBlock from '../../common/components/ErrorBlock'
import LoadingButton from '../../common/components/LoadingButton'
import { CustomReactSelect } from '../../common/components/CustomInput'
import { BackIcon } from '../../common/SvgIcons'

import { convertChecklistFormData } from '../data/planning.utils'
import { getJsondata, formatDate, postJsonData } from '../../common/utils'
import Urls from '../../UrlConfig'

/**
 * Scss:
 * 		_planning_checklist_form
 */
class PlanningChecklist extends Component {

    /**
     * Parent :
     *      App
     */

    state = {
        fetching: false,
        fetched: false,
        error: false,
        errorMsg: {},

        checklistData: {},
        /**
         * shape: [
         *  {id, title, checklist_items: [ {id, name}, ...] }, 
         *  ...]
         */
        checklistConfig: [],

        selectionChecklist: [],
        deletedChecklist: [],

        submitting: false,
        submitError: {},
    }

    componentDidMount = () => {
        const planningId = get(this.props, 'match.params.planningId')
        this.getChecklistData(planningId)
    }

    getChecklistData = (planningId) => {
        this.setState({ fetching: true })

        getJsondata(Urls.apiGetPlanningChecklist(planningId),
            (res) => {
                const { checklistData, selectionChecklist, checklistConfig } = convertChecklistFormData(res)
                this.setState({
                    fetching: false, fetched: true,
                    checklistData, selectionChecklist,
                    checklistConfig
                })
            },
            (err) => {
                this.setState({ fetching: false, error: true, errorMsg: err })
            }
        )
    }

    // projectId is userd to redirect planning list page after success
    checklistSubmit = (projectId) => () => {
        if (this.state.submitting) return

        let selectionChecklist = [...this.state.selectionChecklist]
        selectionChecklist = map(selectionChecklist,
            (currCheck) => ({
                quality_checklist: currCheck.qualityCheckListId,
                checkitem_config_list: get(currCheck, 'checkItemList', []),
                is_edit: currCheck.isChecklistEdit
            })
        )
        // ids of quality_checklist ids
        const deleted_checklist = [...this.state.deletedChecklist]

        this.setState({ submitting: true, submitError: {} })
        const planningId = get(this.props, 'match.params.planningId')
        postJsonData(Urls.apiGetPlanningChecklist(planningId),
            { checklist: selectionChecklist, deleted_checklist },
            (res) => {
                this.setState({ submitting: false })
                this.props.history.push(Urls.getProjectPlanningList(projectId))
            },
            (err) => {
                this.setState({ submitting: false, submitError: err })
            }
        )
    }

    handleCheckItemSelect = (index) => (checkItem) => {
        /**
         * selectionChecklist (shape) : [
         *      {
         *          qualityCheckListId: Number, 
         *          qualityCheckListName: String, 
         *          checkItem: null | config id, 
         *          checkItemList: []
         *      }, 
         * ...]
         */
        let selectionChecklist = [...this.state.selectionChecklist]
        // create set with existing data
        let deletedChecklist = new Set(this.state.deletedChecklist)

        const newValue = get(checkItem, 'id', null)
        const currSelectedChecklist = { ...selectionChecklist[index] }

        if (newValue) {
            selectionChecklist[index] = {
                ...currSelectedChecklist,
                checkItem: newValue,
                checkItemList: checkItem.checklist_items
            }
        }
        else {
            selectionChecklist[index] = {
                ...currSelectedChecklist,
                checkItem: null,
                checkItemList: []
            }
            // if new value is null and current checklist obj is edit
            // set quality checklist id as delete id
            if (get(currSelectedChecklist, 'isChecklistEdit', false)) {
                deletedChecklist.add(currSelectedChecklist.qualityCheckListId)
            }
        }
        this.setState({ selectionChecklist, deletedChecklist })
    }

    gotoPlanningListPage = (projectId) => () => {
        this.props.history.push(Urls.getProjectPlanningList(projectId))
    }

    render = () => {
        const { fetched } = this.state

        const { windowHeight, navbarHeight, backPillHeight,
            projectFetched, planningFetched,
            projectFetchError, projectFetchErrorObj,
            projectDetails } = this.props

        if (fetched && projectFetched && planningFetched) {

            const projectId = projectDetails.id
            const pageHeight = windowHeight - navbarHeight
			const pageContentHeight = pageHeight - backPillHeight

            return (
                <div id="project-details" className='planning-form'
                    style={{ height: `${pageHeight}px` }}>

                    <div className="page-back-pill">
						<Link to={Urls.getProjectPlanningList(projectId)} className="page-back-content">
							<BackIcon />
							<div className="page-back-text">Go back to Planning List</div>
						</Link>
						<div className="page-pill-title hide-on-small-and-down">{get(projectDetails, 'name', '-NA-')}</div>
					</div>

                    {/* for content minuse banner height from page height */}
                    <div className="page-content-wrapper"
                        style={{ height: `${pageContentHeight}px`}}>

                        <div className="pl-form-planning-col planning_checklist_form"
                            style={{ height: `${pageContentHeight}px`}}>
                            {this.renderChecklistForm(projectId)}
                        </div>
                    </div>
                </div>
            )
        }
        else if (projectFetchError) {
            if (projectFetchErrorObj.error_code === 403) {
                return <Redirect to={Urls.getUnauthorized()} />
            }

            return (
                <div className="container">
                    <ErrorBlock error={projectFetchErrorObj.error_data} field="__all__" />
                </div>
            )
        }
        else {
            return (
                <div className="container">
                    <Loader />
                </div>
            )
        }
    }

    renderChecklistForm = (projectId) => {
        const { selectionChecklist, checklistConfig, submitting } = this.state

        return (
            <div className="pl-form-wrapper">
                {this.renderCheclkistFormInfo()}

                <h2>Checklist</h2>

                {selectionChecklist.map((currCheck, ind) => {
                    const currCheckItemList = get(currCheck, 'checkItemList', [])

                    return (
                        <div className="pl-checklist-row" key={currCheck.qualityCheckListId}>
                            <div className="pl-checklist-content">
                                <div className="pl-checklist-content-col">
                                    <div className="title">{currCheck.qualityCheckListName}</div>
                                </div>
                                <div className="pl-checklist-content-col">
                                    <CustomReactSelect label="Select Checklist Config"
                                        options={checklistConfig}
                                        isClearable={true}
                                        isSearchable={true}
                                        value={checklistConfig.filter(({ id }) => id === currCheck.checkItem)}
                                        getOptionLabel={({ title }) => title}
                                        getOptionValue={({ id }) => id}
                                        onChange={this.handleCheckItemSelect(ind)}
                                    />
                                </div>
                            </div>
                            {Boolean(currCheckItemList.length) &&
                                <div className="pl-checklist-item-info">
                                    <div className="title">
                                        checklist items
                                    </div>
                                    <div className="pl-checklist-pills">
                                        {currCheckItemList.map((currCheckItem, index) => {
                                            return <span key={index}>{currCheckItem.name}</span>
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })}

                <div className="row">
                    <div className="col s12">
                        <div className="pl-form-action-btn">
                            <LoadingButton title="Cancel" className="btn secondary-btn"
                                onClick={this.gotoPlanningListPage(projectId)} />
                            <LoadingButton title="Submit" className="btn primary-btn"
                                isLoading={submitting} onClick={this.checklistSubmit(projectId)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderCheclkistFormInfo = () => {
        const { checklistData } = this.state

        const startDate = checklistData.start_date
        const endDate = checklistData.end_date

        return (
            <>
                <div className="pl-form-info center">
                    <b>{get(checklistData, 'activity.name')} </b>
                    <div className="small">
                        {get(checklistData, 'unit.name')} &gt; {get(checklistData, 'subunit.name')}
                    </div>
                    <div className="date">
                        From {formatDate(startDate)} To {formatDate(endDate)}
                    </div>
                </div>
            </>
        )
    }
}


export default connect(store => ({
    // project details
    projectFetching: store.projectDetails.fetching,
    projectFetched: store.projectDetails.fetched,
    projectFetchError: store.projectDetails.fetchError,
    projectFetchErrorObj: store.projectDetails.fetchErrorObj,
    projectDetails: store.projectDetails.projectDetails,

    planningFetched: store.planningList.fetched,
    planningList: store.planningList.planningList,
    // handle page size
    windowHeight: store.pageSize.windowHeight,
    navbarHeight: store.pageSize.navbarHeight,
    backPillHeight: store.pageSize.backPillHeight,
}))(PlanningChecklist)