import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { get, filter, groupBy } from "lodash";

import PlanningListBlock from "./PlanningListBlock";
import Loader from "../../../common/components/Loader";
import { StatusInfo } from "../../../reports/ReportPage";
import { FloatingBtn } from "../../../common/components/LoadingButton";

import { getProjectMicroPlanningList } from "../../data/project.actions";
import { filterMPList } from "../../../admin/data/planning.utils";
import { formatDate } from "../../../common/utils";

import Urls from "../../../UrlConfig";

/**
 * Scss:
 * 		project/_project_details.scss
 *      project/_project_planning_list.scss
 *      admin/_reports.scss
 *      admin/_reports_engineers.scss
 */

class PlanningList extends Component {
  /**
   * fetch microplanning list of project from server,
   * manage error and loading
   *
   * Parent:
   *      ProjectDetailsPage
   *
   * Renders:
   *      PlanningListBlock
   */

  state = {
    contentHeight: 0,
  };

  componentDidMount = () => {
    const { projectId, getProjectMicroPlanningList } = this.props;

    getProjectMicroPlanningList(projectId);
  };

  resizeContent = (element) => {
    if (element) {
      const { windowHeight } = this.props;

      const contentHeight = windowHeight - element.getBoundingClientRect().top;
      this.setState({ contentHeight });
    }
  };

  handleFloatIconClick = () => {
    this.props.history.push(Urls.getProjectPlanningList(this.props.projectId));
  };

  render = () => {
    const {
      projectId,
      isProjectPlanned,
      projectViewConfig,
      fetched,
      fetchError,
      userRole,
    } = this.props;

    const isListLayout = Boolean(projectViewConfig.layoutType === 1);

    if (fetched) {
      if (isProjectPlanned) {
        return (
          <>
            {isListLayout
              ? this.renderListLayout(projectViewConfig)
              : this.renderCardLayout(projectViewConfig)}
            {userRole < 3 && (
              <FloatingBtn onClick={this.handleFloatIconClick} />
            )}
          </>
        );
      } else {
        return (
          <div className="empty-page-wrapper">
            <img
              src={Urls.getStaticImg("/img/project_details_empty.svg")}
              alt=""
            />
            <div className="text">
              Looks like you don't have any plans right now
            </div>
            {userRole < 3 && (
              <Link
                to={Urls.getProjectPlanningAdd(projectId)}
                className="btn secondary-btn"
              >
                {" "}
                + Update Planning{" "}
              </Link>
            )}
          </div>
        );
      }
    } else if (fetchError) {
      return (
        <div className="container">
          <h2>Failed to fetch data</h2>
        </div>
      );
    } else {
      return (
        <div className="container">
          <Loader />
        </div>
      );
    }
  };

  renderCardLayout = ({
    activityId,
    isFilterActive,
    selectedUnit,
    selectedSubunit,
  }) => {
    const {
      mpWaitingList,
      mpRunningList,
      mpCompletedList,
      mpScheduledList, // data
      statusListLength,
      isMobile,
    } = this.props;

    const { contentHeight } = this.state;

    // filter planning lists if needed
    let filteredMpWaitingList = mpWaitingList;
    let filteredMpRunningList = mpRunningList;
    let filteredMpCompletedList = mpCompletedList;
    let filteredMpScheduledList = mpScheduledList;

    if (isFilterActive) {
      filteredMpWaitingList = filterMPList(
        filteredMpWaitingList,
        Number(selectedUnit.id),
        Number(selectedSubunit.id)
      );
      filteredMpRunningList = filterMPList(
        filteredMpRunningList,
        Number(selectedUnit.id),
        Number(selectedSubunit.id)
      );
      filteredMpCompletedList = filterMPList(
        filteredMpCompletedList,
        Number(selectedUnit.id),
        Number(selectedSubunit.id)
      );
      filteredMpScheduledList = filterMPList(
        filteredMpScheduledList,
        Number(selectedUnit.id),
        Number(selectedSubunit.id)
      );
    }

    if (activityId) {
      filteredMpWaitingList = filter(filteredMpWaitingList, [
        "activity.id",
        activityId,
      ]);
      filteredMpRunningList = filter(filteredMpRunningList, [
        "activity.id",
        activityId,
      ]);
      filteredMpCompletedList = filter(filteredMpCompletedList, [
        "activity.id",
        activityId,
      ]);
      filteredMpScheduledList = filter(filteredMpScheduledList, [
        "activity.id",
        activityId,
      ]);
    }

    const paddingBot = isMobile ? 70 : 15;
    let eachColHeightCss = { paddingBot };
    // set planning cols height to scroll individually
    if (contentHeight) {
      eachColHeightCss.height = contentHeight - paddingBot;
    }

    return (
      <div className="pd-planning-wrapper">
        <div className="pd-planning-col">
          <div className="pd-planning-col-title">
            Waiting{" "}
            <span>
              ({isFilterActive && `${filteredMpWaitingList.length}/`}
              {statusListLength.mpWList})
            </span>
          </div>

          <div
            className="pd-planning-col-card-wrapper"
            ref={this.resizeContent}
            style={eachColHeightCss}
          >
            {filteredMpWaitingList.map((mcPlanning) => {
              return (
                <PlanningListBlock
                  key={mcPlanning.id}
                  mcPlanning={mcPlanning}
                />
              );
            })}
          </div>
        </div>

        <div className="pd-planning-col">
          <div className="pd-planning-col-title">
            Running{" "}
            <span>
              ({isFilterActive && `${filteredMpRunningList.length}/`}
              {statusListLength.mpRList})
            </span>
          </div>
          <div
            className="pd-planning-col-card-wrapper"
            style={eachColHeightCss}
          >
            {filteredMpRunningList.map((mcPlanning) => {
              return (
                <PlanningListBlock
                  key={mcPlanning.id}
                  mcPlanning={mcPlanning}
                />
              );
            })}
          </div>
        </div>

        <div className="pd-planning-col">
          <div className="pd-planning-col-title">
            Completed{" "}
            <span>
              ({isFilterActive && `${filteredMpCompletedList.length}/`}
              {statusListLength.mpCList})
            </span>
          </div>
          <div
            className="pd-planning-col-card-wrapper"
            style={eachColHeightCss}
          >
            {filteredMpCompletedList.map((mcPlanning) => {
              return (
                <PlanningListBlock
                  key={mcPlanning.id}
                  mcPlanning={mcPlanning}
                />
              );
            })}
          </div>
        </div>

        <div className="pd-planning-col">
          <div className="pd-planning-col-title">
            Scheduled{" "}
            <span>
              ({isFilterActive && `${filteredMpScheduledList.length}/`}
              {statusListLength.mpSList})
            </span>
          </div>
          <div
            className="pd-planning-col-card-wrapper"
            style={eachColHeightCss}
          >
            {filteredMpScheduledList.map((mcPlanning) => {
              return (
                <PlanningListBlock
                  key={mcPlanning.id}
                  mcPlanning={mcPlanning}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  renderListLayout = ({
    activityId,
    isFilterActive,
    selectedUnit,
    selectedSubunit,
  }) => {
    const { microPlanningList } = this.props;
    const { contentHeight } = this.state;

    let filteredMicroPlanningList = microPlanningList;

    let emptyMessage = "";
    if (isFilterActive) {
      filteredMicroPlanningList = filterMPList(
        filteredMicroPlanningList,
        Number(selectedUnit.id),
        Number(selectedSubunit.id)
      );
      emptyMessage =
        "Looks like you don't have any tasks related to filtered structure";
    }

    if (activityId) {
      filteredMicroPlanningList = filter(filteredMicroPlanningList, [
        "activity.id",
        activityId,
      ]);
      emptyMessage = emptyMessage.length
        ? emptyMessage + " and activity."
        : "Looks like you don't have any tasks related to filtered activity";
    }

    const paddingBot = 15;
    let wrapperHeightCss = { paddingBot };
    // set planning cols height to scroll individually
    if (contentHeight) {
      wrapperHeightCss.height = contentHeight - paddingBot;
    }

    const activityWiseData = groupBy(
      filteredMicroPlanningList,
      "activity.name"
    );

    const resultList = [];
    for (const [activityName, activityList] of Object.entries(
      activityWiseData
    )) {
      const activityBlock = (
        <div key={activityName} className="report-content-block">
          <div className="report-content-block-title">{activityName}</div>
          <table className="report-engineer-table auto-width">
            <tbody>
              <tr className="header-row">
                <th className="title-col left-align">Tasks</th>
                <th className="title-col left-align">Structure</th>
                <th className="summery-col">Duration</th>
                <th className="days-col">Days</th>
                <th className="status-col">Status</th>
                <th className="status-col">Action</th>
              </tr>
              {activityList.map((mcPlanning) => {
                let mcPlanningLabel = "";
                if (mcPlanning.subunit?.name) {
                  mcPlanningLabel = `${mcPlanning.unit.name} > ${mcPlanning.subunit.name}`;
                } else {
                  mcPlanningLabel = mcPlanning.unit.name;
                }

                return (
                  <tr key={mcPlanning.id}>
                    <td className="title-col left-align">
                      {`${get(mcPlanning, "subactivity.name", activityName)}`}
                    </td>
                    <td className="title-col left-align">{mcPlanningLabel}</td>
                    <td className="summery-col center">
                      {formatDate(mcPlanning.start_date)} to{" "}
                      {formatDate(mcPlanning.end_date)}
                    </td>
                    <td className="days-col center">
                      Days {mcPlanning.no_of_days}{" "}
                      {mcPlanning.has_holidays &&
                        `, Holidays ${mcPlanning.no_of_holidays}`}
                    </td>
                    <td className="status-col center">
                      <StatusInfo status={mcPlanning.status} />
                    </td>
                    <td className="status-col center">
                      <Link
                        to={Urls.getProjectDetails(
                          mcPlanning.project.id,
                          "planning",
                          mcPlanning.id
                        )}
                        className="action-btn"
                      >
                        Edit Details
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
      resultList.push(activityBlock);
    }

    return (
      <div className="pd-planning-list-wrapper" ref={this.resizeContent}>
        <div className="report-page" style={wrapperHeightCss}>
          {resultList.length ? (
            resultList
          ) : (
            <div className="empty-report-data">
              <div>
                {emptyMessage
                  ? emptyMessage
                  : "Looks like you don't have any tasks"}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
}

export default connect(
  (store, props) => {
    const projectId = store.projectDetails.projectDetails.id;
    const currSetting = store.projectViewConfig[projectId];
    return {
      fetching: store.microPlanningList.fetching,
      fetched: store.microPlanningList.fetched,
      fetchError: store.microPlanningList.fetchError,
      microPlanningList: store.microPlanningList.microPlanningList,

      statusListLength: store.microPlanningList.statusListLength,
      mpWaitingList: store.microPlanningList.mpWaitingList,
      mpRunningList: store.microPlanningList.mpRunningList,
      mpCompletedList: store.microPlanningList.mpCompletedList,
      mpScheduledList: store.microPlanningList.mpScheduledList,

      projectId: projectId,
      isProjectPlanned: store.projectDetails.projectDetails.is_project_planned,

      projectViewConfig: currSetting,
      activityList: store.activityList.activityList,

      windowHeight: store.pageSize.windowHeight,
      isMobile: store.pageSize.isMobile,
      userRole: store.auth.userRole,
    };
  },
  { getProjectMicroPlanningList }
)(PlanningList);
