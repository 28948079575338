import React, { Component } from 'react'
import { connect } from 'react-redux'

import { get } from 'lodash'
import { format } from 'date-fns'
import Fuse from 'fuse.js'

import HolidayForm from './HolidayForm'
import LoadingButton from '../../common/components/LoadingButton'
import Loader from '../../common/components/Loader'
import { CustomSearchBox } from '../../common/components/CustomInput'
import { EditIcon } from '../../common/SvgIcons'

import { addNotification } from '../../common/data/common.actions'
import { getJsondata } from '../../common/utils'
import Urls from '../../UrlConfig'

/**
 * Scss:
 *      admin/_holiday_list
 * Common:
 *      layout, _common_page_layout, form
 */

class HolidayListPage extends Component {

    /**
	 * Fetch holiday list from server
	 * Render list of holiday
	 * Show edit / add new holiday btns
	 * 
	 * Parent:
	 * 		AdminHomePage
	*/

    state = {
        fetching: false,
        fetched: false,
        error: false,
        errorMsg: {},
        
        holidayList: [],
        holidayId : null,
        contentHeight: 0,

        searchToken: '',
    }

    componentDidMount = () => {
        this.getHolidayList()
    }

    resizeContent = (element) => {
        if(element) {
            const {windowHeight} = this.props

            const contentHeight = windowHeight - element.getBoundingClientRect().top
            this.setState({contentHeight})
        }
    }

    getHolidayList = () => {
        this.setState({ fetching : true, error: false, errorMsg: {} })

        getJsondata(Urls.apiGetHolidayList("all"),
            (res) => {
                // config search
                const search_opts = {
                    shouldSort: true,
                    tokenize: true,
                    findAllMatches: true,
                    threshold: 0.8,
                    location: 0,
                    distance: 32,
                    maxPatternLength: 32,
                    minMatchCharLength: 1,
                    keys: [
                        "name",
                    ]
                }
        
                this.search = new Fuse(res, search_opts)
                // set state
                this.setState({ 
                    fetching: false, fetched: true,
                    holidayList: res
                })
            },
            (err) => {
                this.setState({ 
                    fetching: false, fetched: false,
                    error: true, errorMsg: err,
                })
            }
        )
    }

    formatDate = (date) => {
        return format(new Date(date), 'dd/MM/yyyy')
    }

    handleShowModel = (holidayId) => () => {
        this.setState({ holidayId: holidayId })
    }

    handleCloseModel = () => {
        this.setState({ holidayId: false })
    }

    handleFormSubmitSuccess = () => {
        this.handleCloseModel()
        this.getHolidayList()
    }

    handleTextChange = (e) => {
        this.setState({searchToken: e.target.value})
    }

    render = () => {
        const { fetched, error, holidayList, holidayId, 
            contentHeight, searchToken } = this.state

        let content

        if(fetched) {
            const paddingBot = 15
            let wrapperHeightCss = {paddingBot}
            // set planning cols height to scroll individually
            if(contentHeight) {
                wrapperHeightCss.height = contentHeight - paddingBot
            }
            // search holiday
            let filteredholidayList = holidayList;
            if(searchToken.length > 1) {
                filteredholidayList = this.search.search(searchToken).map(d => d.item)
            }

            content = (
                <div className="holiday-list-wrapper" ref={this.resizeContent}>
                    <div className="holiday-list" style={wrapperHeightCss}>
                        <div className="flex-wrapper">
                            {filteredholidayList.length ?
                                filteredholidayList.map((holiday) => {

                                    return (
                                        <div key={holiday.id} className="holiday-block clickable" 
                                            onClick={this.handleShowModel(holiday.id)}>
                                            <div className="holiday-edit">
                                                <EditIcon />
                                            </div>
                                            <div className="title">{get(holiday, 'name', '')}</div>
                                            <div className="dates-wrapper">
                                                From: <span>{this.formatDate(holiday.start_date)}</span> 
                                                To: <span>{this.formatDate(holiday.end_date)}</span>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="empty-page-wrapper">
                                    <div className="text">Looks like you don't have any holidays right now</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )
        }
        else if(error) {
            content = (
                <div className="empty-page-wrapper">
                    <div className="error-block big">Failed to fetch data</div>
                </div>
            )
        }

        else {
            content = (
                <div className="container">
                    <Loader />
                </div>
            )
        }

        return (
            <>
                <div className="page-right-actions">
                    <div className="page-action-wrapper">
                        <CustomSearchBox type="text" placeholder="Search"
                            value={searchToken} onChange={this.handleTextChange} />
                        <div className="page-add-new">
                            <LoadingButton className="primary-btn" 
                                title="+ Create Holiday"
                                onClick={this.handleShowModel(-1)}
                            />
                        </div>
                    </div>
                </div>
                <div id="holiday-list-page">
                    {content}
                    {holidayId && 
                        <div className="page-model">
                            <div className="page-model-wrapper">
                                <div className="page-model-cell">
                                    <div className="page-model-content holiday-form-wrapper">
                                        <HolidayForm holidayId={holidayId} 
                                            closeModel={this.handleCloseModel}
                                            onSubmitSuccess={this.handleFormSubmitSuccess}
                                            addNotification={this.props.addNotification}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }
}


export default connect(store => ({
    windowHeight: store.pageSize.windowHeight,
}), {addNotification})(HolidayListPage)