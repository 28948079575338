import React, { Component } from 'react'
import { connect } from 'react-redux'

import { get } from 'lodash'
import Fuse from 'fuse.js'

import Loader from '../../common/components/Loader'
import CustomImage from '../../common/components/CustomImage'
import LoadingButton from '../../common/components/LoadingButton'
import { CustomSearchBox } from '../../common/components/CustomInput'
import UserCreateForm from '../userRole/UserCreateForm'

import { EditIcon } from '../../common/SvgIcons'

import { getUserList } from '../data/userList.action'
import { addNotification } from '../../common/data/common.actions'


/**
 * Scss:
 *      _user_list.scss
 *      _user_toggle_switch.scss
 * 
 * Common:
 *      layout, _common_page_layout, form
 */


class UserList extends Component {

    /**
     * fetch data component, fetch user list from server and update store
     * 
     * Parent:
     * 		App
     * 
     */

    state = {
        selectedUser: null, // user obj : null | data (edit) | {} (add)
        contentHeight: 0,

        searchToken: '',
    }

    componentDidMount = () => {
        if (!this.props.fetched) {
            this.props.getUserList(this.setupSearch)
        } else {
            // if users already fetched setup search on it
            this.setupSearch(this.props.userList)
        }
    }

    setupSearch = (userList) => {
        // config search
        const search_opts = {
            shouldSort: true,
            tokenize: true,
            findAllMatches: true,
            threshold: 0.8,
            location: 0,
            distance: 32,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [
                "first_name", "last_name"
            ]
        }

        this.search = new Fuse(userList, search_opts)
    }

    resizeContent = (element) => {
        if (element) {
            const { windowHeight } = this.props

            const contentHeight = windowHeight - element.getBoundingClientRect().top
            this.setState({ contentHeight })
        }
    }

    handleShowModel = (user) => () => {
        this.setState({ selectedUser: user })
    }

    handleCloseModel = () => {
        this.setState({ selectedUser: null })
    }

    handleTextChange = (e) => {
        this.setState({ searchToken: e.target.value })
    }

    render = () => {
        const { selectedUser, searchToken } = this.state

        return (
            <>
                <div className="page-right-actions">
                    <div className="page-action-wrapper">
                        <CustomSearchBox type="text" placeholder="Search"
                            value={searchToken} onChange={this.handleTextChange} />
                        <div className="page-add-new">
                            <LoadingButton title="+ Create User" className="primary-btn" onClick={this.handleShowModel({})} />
                        </div>
                    </div>
                </div>
                <div id="user-list-page">
                    {this.renderUserList()}
                    {selectedUser &&
                        <div className="page-model">
                            <div className="page-model-wrapper">
                                <div className="page-model-cell">
                                    <div className="page-model-content user-form-wrapper">
                                        <UserCreateForm user={selectedUser}
                                            closeModel={this.handleCloseModel}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }

    renderUserList = () => {

        const { fetched, error, userList } = this.props
        const { contentHeight, searchToken } = this.state
        
        if(fetched) {
            if(userList.length) {
                const paddingBot = 15
                let wrapperHeightCss = { paddingBot }
                // set planning cols height to scroll individually
                if (contentHeight) {
                    wrapperHeightCss.height = contentHeight - paddingBot
                }

                // search
                let filtereduserList = userList;
                if (searchToken.length > 1) {
                    filtereduserList = this.search.search(searchToken).map(d => d.item)
                }
                return (
                    <div className="user-list-wrapper" ref={this.resizeContent}>
                        <div className="user-list-container" style={wrapperHeightCss}>
                            <div className="flex-wrapper">
                                {filtereduserList.map((user) => {
                                    return (
                                        <div className="user-block" key={user.id} onClick={this.handleShowModel(user)}>
                                            <div className="user-edit">
												<EditIcon />
											</div>
                                            <div className="user-data">
                                                <CustomImage title={user.first_name} imageUrl={user.image} />
                                                <div className={`user-content ${user.is_active ? "" : "user-inactive"}`}>
                                                    <div className="title">
                                                        {get(user, 'first_name', '-')} {get(user, 'last_name', '-')}
                                                    </div>
                                                    <div className="u-email">{user.role_name}</div>
                                                    <div className="u-reset">{user.email}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className="empty-page-wrapper">
						<div className="text">Looks like you don't have any users right now</div>
                    </div>
                )
            }
        }
        else if (error) {
            return (
                <div className="empty-page-wrapper">
                    <div className="error-block big">Failed to fetch data</div>
                </div>
            )
        }

        else {
            return <Loader />
        }
    }
}

export default connect(store => ({
    fetched: store.userList.fetched,
    error: store.userList.error,
    userList: store.userList.userList,
    windowHeight: store.pageSize.windowHeight,
}), { getUserList, addNotification })(UserList)