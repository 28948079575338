import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { get, filter } from "lodash";

import Loader from "../../../common/components/Loader";
import CustomImage from "../../../common/components/CustomImage";
import { PlusIcon } from "../../../common/SvgIcons";

import { getProjectQualityCheckList } from "../../data/quality.actions";
import { filterMPList } from "../../../admin/data/planning.utils";

import Urls from "../../../UrlConfig";

/**
 * Scss:
 *      _project_quality_list
 */

class QualityListWrapper extends Component {
  /**
   * wrapper component, get quality data from server
   * manage loading. error
   * popup logic
   *
   * Parent:
   *      ProjectDetailsPage
   *
   * Renders:
   *      QualityList
   *      QualityAddPopup
   *      QualityChecklistPopup
   */
  state = {
    contentHeight: 0,
  };

  componentDidMount = () => {
    if (!this.props.fetched) {
      this.props.getProjectQualityCheckList(this.props.projectId);
    }
  };

  resizeContent = (element) => {
    if (element) {
      const { windowHeight } = this.props;

      const contentHeight = windowHeight - element.getBoundingClientRect().top;
      this.setState({ contentHeight });
    }
  };

  render = () => {
    const { isProjectPlanned, projectId, history } = this.props;
    const { contentHeight } = this.state;

    const paddingBot = 30;
    let wrapperHeightCss = { paddingBot };
    // set planning cols height to scroll individually
    if (contentHeight) {
      wrapperHeightCss.height = contentHeight - paddingBot;
    }

    return (
      <>
        {isProjectPlanned ? (
          <div className="pd-quality-wrapper" ref={this.resizeContent}>
            <div className="pd-quality-list" style={wrapperHeightCss}>
              <WrappedQualityList history={history} />
            </div>
          </div>
        ) : (
          <div className="empty-page-wrapper">
            <img
              src={Urls.getStaticImg("/img/project_details_empty.svg")}
              alt=""
            />
            <div className="text">
              Looks like you don't have any plans right now that may need
              Quality Checks
            </div>
          </div>
        )}
        <Link
          className="floating-btn"
          to={Urls.getProjectDetails(
            projectId,
            "quality",
            "new",
            "add_qa_image"
          )}
        >
          <PlusIcon />
        </Link>
      </>
    );
  };
}

export default connect(
  (store) => ({
    projectId: store.projectDetails.projectDetails.id,
    isProjectPlanned: store.projectDetails.projectDetails.is_project_planned,

    loggedUserId: store.auth.user,
    windowHeight: store.pageSize.windowHeight,
  }),
  { getProjectQualityCheckList }
)(QualityListWrapper);

/**
 * show quality list and included image grid
 */
class QualityList extends React.Component {
  openChedkDetails = (checkId) => {
    const { history, projectId } = this.props;
    history.push(
      Urls.getProjectDetails(projectId, "quality", checkId, "qa_details")
    );
  };

  handleImageClick = (imageId) => {
    const { history, projectId } = this.props;
    history.push(
      Urls.getProjectDetails(projectId, "quality", imageId, "show_qa_image")
    );
  };

  render = () => {
    const { fetchError, fetched, checklist, projectViewConfig } = this.props;

    if (fetched) {
      const { activityId, isFilterActive, selectedUnit, selectedSubunit } =
        projectViewConfig;
      let filteredChecklist = checklist;

      let emptyMessage = "";
      if (isFilterActive) {
        emptyMessage =
          "Looks like you don't have any quality images related to filtered structure";
        filteredChecklist = filterMPList(
          filteredChecklist,
          Number(selectedUnit.id),
          Number(selectedSubunit.id)
        );
      }
      if (activityId) {
        emptyMessage = emptyMessage.length
          ? emptyMessage + " and activity."
          : "Looks like you don't have any quality images related to filtered activity";
        filteredChecklist = filter(filteredChecklist, [
          "activity.id",
          activityId,
        ]);
      }

      if (filteredChecklist.length) {
        return <>{this.renderQualityImageList(filteredChecklist)}</>;
      } else {
        return (
          <div className="report-page">
            <div className="empty-report-data">
              <div>
                {emptyMessage
                  ? emptyMessage
                  : "Looks like you don't have any quality images"}
              </div>
            </div>
          </div>
        );
      }
    } else if (fetchError) {
      return (
        <div className="empty-page-wrapper">
          <div className="error-block big">Failed to fetch data</div>
        </div>
      );
    } else {
      return <Loader />;
    }
  };

  renderQualityImageList = (checklist) => {
    const { projectId } = this.props;

    return (
      <div className="pd-checklist-wrapper">
        {checklist.map((checkData) => {
          let activityLabel = checkData?.activity?.name || "--NA--";
          activityLabel += checkData?.subactivity?.name
            ? ` - ${checkData?.subactivity?.name}`
            : "";

          let unitLabel = checkData?.unit?.name || "--NA--";
          unitLabel += checkData?.subunit?.name
            ? ` - ${checkData?.subunit?.name}`
            : "";

          return (
            <div className="pd-checklist-row" key={checkData.id}>
              <div className="pd-checklist-name-wrapper">
                <div className="pd-checklist-name-content">
                  <div className="pd-checklist-name">{activityLabel}</div>
                  <div className="pd-checklist-name-sub">
                    {unitLabel}
                    {checkData.name && <span> ({checkData.name})</span>}
                  </div>
                </div>
              </div>
              <div className="pd-checklist-action-row">
                <Link
                  className="action-btn add-action"
                  to={Urls.getProjectDetails(
                    projectId,
                    "quality",
                    checkData.id,
                    "add_qa_image"
                  )}
                >
                  + Add Image
                </Link>
                <div
                  className="action-btn"
                  onClick={() => this.openChedkDetails(checkData.id)}
                >
                  View Details &gt;
                </div>
              </div>
              <div className="pd-chl-img-wrapper">
                {checkData.quality_images.map((qaImage) => {
                  return (
                    <div
                      className="quality-img-block"
                      key={qaImage.id}
                      onClick={() => this.handleImageClick(qaImage.id)}
                    >
                      <CustomImage imageUrl={qaImage.image} />
                    </div>
                  );
                })}
              </div>
              <div className="divider"></div>
            </div>
          );
        })}
      </div>
    );
  };
}

const WrappedQualityList = connect((store) => {
  const projectId = store.projectDetails.projectDetails.id;
  const currSetting = store.projectViewConfig[projectId];

  return {
    fetched: store.checklist.fetched,
    fetchError: store.checklist.fetchError,
    checklist: store.checklist.checklist,
    projectId,
    projectViewConfig: currSetting,
  };
})(QualityList);
