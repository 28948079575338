import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Urls from '../../UrlConfig'

export default () => {
    
    /**
     * Parent:
     *      App
     */

    const userRole = useSelector(store => store.auth.userRole)

    if(userRole < 3) {
        return <Redirect to={Urls.getDashboardPage()}/>
    }
    else {
        return <Redirect to={Urls.getProjectPage()}/>
    }
}
