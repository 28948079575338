import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom'

import Notification from './components/Notification'
import NavBar from './components/NavBar'

import store from '../store'
import Urls from '../UrlConfig';


/**
 * 50px is nav height
 */
const Layout = ({ component: Component, ...rest }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [rest.path]);

    return (
        <Route {...rest} render={matchProps => (
            <>
                <NavBar />
                <Notification />
                <div style={{paddingTop:'50px'}} />
                <Component {...matchProps} />
            </>
        )} />
    )
}
export default Layout


export const PrivateLayout = ({ component: Component, ...rest }) => {
    const logged = store.getState().auth.logged

    if (logged) {
        return <Layout {...rest} component={Component} />
    } else {
        return (
            <Redirect to={{
                pathname: Urls.getAuthPages('login'),
                state: { from: rest.location }
            }} />
        )
    }
}

export const AdminRequiredLayout = ({ component: Component, ...rest }) => {
    const userRole = store.getState().auth.userRole

    if (userRole < 3) {
        return <PrivateLayout {...rest} component={Component} />
    } else {
        return (
            <Redirect to={{
                pathname: Urls.getUnauthorized(),
                state: { from: rest.location }
            }} />
        )
    }
}