import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { get } from 'lodash'

import ProjectUpsert from './ProjectUpsert'
import ProjectEdit from './ProjectEdit'
import HocLoginRequired from '../../common/components/HocLoginRequired'

import {resetProjectFormData} from '../data/adminProject.actions'
import Urls from '../../UrlConfig'

class ProjectFormPage extends Component {

    /**
     * Data wrapper component
     * decide wo display add / edit project  or edit project details ( structure | permission page )
     * 
     * redirect to 404 if logged userrole is not 1 ( not admin )
     * Parent :
     *      App
     * 
     * Renders:
     *      ProjectUpsert
     *      ProjectEdit
     */

    componentWillUnmount = () => {
        this.props.resetProjectFormData()
    }

    render = () => {
        const { history, userRole } = this.props
        const { projectId, tab } = get(this.props, 'match.params')

        if(userRole > 1) {
            return <Redirect to={Urls.get404()}/>
        }

        if(projectId === 'add' && tab === 'new') {
            return <ProjectUpsert history={history} isEdit={false} />
        }
        else if(tab === 'edit') {
            return <ProjectUpsert history={history} isEdit={true} projectId={projectId} />
        }
        else if(tab === 'structure' || tab === 'permission'){
            return <ProjectEdit projectId={projectId} history={history} />
        }
        else {
            return <Redirect to={Urls.get404()} />
        }
    }
}

export default connect(store => ({
    logged: store.auth.logged,
    userRole: store.auth.userRole
}), { resetProjectFormData })(HocLoginRequired(ProjectFormPage))