import {orderBy} from 'lodash'

const INIT_CHECK_CONFIG_LIST = {
    fetching: false,
    fetched: false,
    error: false,
    errorObj: {},
    
    checkConfigList: [],
}


export const checkConfigListReducer =  (state = INIT_CHECK_CONFIG_LIST, { type, payload }) => {
    switch (type) {

        case 'CHECK_CONFIG_LIST_FETCHING': {
            return { ...state, 
                fetching: true,
                error: false,
                errorObj: {},
            }
        }

        case 'CHECK_CONFIG_LIST_SUCCESS': {
            let checkConfigList = [...payload]

            for (let index = 0; index < checkConfigList.length; index++) {
                const currItem = checkConfigList[index];
                currItem.checklist_items = orderBy(currItem.checklist_items, ['index'], ['asc'])
            }

            return { ...state,
                fetching: false,
                fetched: true,
                checkConfigList,
            }
        }

        case 'CHECK_CONFIG_LIST_ERROR': {
            return { ...state, 
                fetching: false,
                fetched: false,
                error: true,
                errorObj: payload
            }
        }

        case "USER_LOGOUT": {
            return INIT_CHECK_CONFIG_LIST
        }

        default: {
            return state
        }
    }
}