import React, { Component, useState, useCallback } from 'react'
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux'

import { isEmpty, get, difference } from 'lodash'

import CustomInput from '../../common/components/CustomInput'
import LoadingButton from '../../common/components/LoadingButton'
import ErrorBlock from '../../common/components/ErrorBlock'
import CheckBox from '../../common/components/CheckBox'
import UserResetPass from '../userRole/UserResetPass'
import Loader from '../../common/components/Loader';

import { getUserList, userSetStatus } from '../data/userList.action'
import { addNotification } from '../../common/data/common.actions'

import { DeleteIcon } from '../../common/SvgIcons';
import { getJsondata, postFormData, postJsonData } from '../../common/utils'
import { CLIENT_ID } from '../../constant'
import Urls from '../../UrlConfig'

/**
 * Scss:
 *      _common_page_layout, _user_form, forms
 */

class UserCreateForm extends Component {

    /**
     * form to create new user by admin
     * 
     * Parent:
     *      UserList
     * 
     * Renders:
     *      ChangeUserStatus
     *      UserResetPass
     *      PermittedProjectList
     */

    constructor(props) {
        super(props)

        this.isAdd = isEmpty(props.user)
        const role = this.isAdd ? null : Number(props.user.role)

        this.state = {
            role, // 1, 2, 3
            loading: false,
            showResetModel: false,
            error: {},
            showPassword: false
        }
    }

    submit = (event) => {
        if (this.state.loading) return

        event.preventDefault()

        const form = document.getElementById('create-user');
        let data = new FormData(form);
        data.append('role', this.state.role)

        this.setState({ loading: true, error: {} })

        const url = this.isAdd ? Urls.apiCreateUser() : Urls.apiEditUser(this.props.user.id)
        postFormData(url, data,
            (res) => {
                this.setState({ loading: false, error: {} })
                this.props.closeModel()
                // add data to user list
                this.props.getUserList()
                this.props.addNotification({
                    type: 'success',
                    title: this.isAdd ? 'User Create' : 'User Update',
                    text: `User ${this.isAdd ? 'created' : 'updated'} successfully`,
                })
            },
            (err) => {
                this.setState({ loading: false, error: err.error_data })
                this.props.addNotification({
                    type: 'error',
                    title: this.isAdd ? 'User Create' : 'User Update',
                    text: 'Input error',
                })
            }
        )
    }

    handleRoleClick = (role) => () => {
        if (this.state.role === role) {
            this.setState({ role: null })
        }
        else {
            this.setState({ role })
        }
    }

    handleShowResetModel = (event) => {
        if (event) event.preventDefault();

        this.setState({ showResetModel: true })
    }

    handleCloseResetModel = () => {
        this.setState({ showResetModel: false })
    }

    handleShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    }

    render = () => {
        const { error, loading, role, showResetModel, showPassword } = this.state
        const { closeModel, user } = this.props

        return (
            <>
                <form id="create-user" className="model-form">
                    <div className="close-icon-wrapper" onClick={closeModel}>
                        <div className="close-icon"></div>
                    </div>
                    <div className="title">{this.isAdd ? "Create User" : "Update User"}</div>

                    <div className="input-group">
                        <div className="single left-inp">
                            <CustomInput
                                label="First name"
                                errorData={error}
                                errorField="first_name"
                                type="text" name="first_name"
                                placeholder="Enter first name"
                                defaultValue={get(user, 'first_name', '')}
                            />
                        </div>
                        <div className="single right-inp">
                            <CustomInput
                                label="Last name"
                                errorData={error}
                                errorField="last_name"
                                type="text" name="last_name"
                                placeholder="Enter last name"
                                defaultValue={get(user, 'last_name', '')}
                            />
                        </div>
                    </div>

                    <CustomInput
                        label="Email Address"
                        errorData={error}
                        errorField="email"
                        type="text" name="email"
                        placeholder="Enter email"
                        defaultValue={get(user, 'email', '')}
                    />

                    <CustomInput
                        label="Contact No"
                        errorData={error}
                        errorField="contact_no"
                        type="text" name="contact_no"
                        placeholder="Enter Contact No"
                        defaultValue={get(user, 'contact_no', '')}
                    />

                    <div className="checkbox-group">
                        <div className="checkbox-group-label">User Role</div>
                        <CheckBox label="Admin" onClick={this.handleRoleClick(1)} isChecked={role === 1} />
                        <CheckBox label="Project Lead" onClick={this.handleRoleClick(2)} isChecked={role === 2} />
                        <CheckBox label="Project Member" onClick={this.handleRoleClick(3)} isChecked={role === 3} />
                    </div>
                    <ErrorBlock error={error} field="role" />

                    {this.isAdd &&
                        <div className="password-group">
                            <CustomInput
                                label="Password"
                                errorData={error}
                                errorField="password"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                placeholder="Enter Password"
                            />
                            <div className="show-password">
                                <CheckBox label="Show password" onClick={this.handleShowPassword} isChecked={showPassword} />
                            </div>
                        </div>
                    }

                    <ErrorBlock error={error} field="__all__" />

                    <div className="multi-action">
                        {this.isAdd ?
                            <LoadingButton className="btn primary-btn" title="Create new User"
                                isLoading={loading} onClick={this.submit} />
                            :
                            <>
                                <LoadingButton title="Reset Password" className="btn secondary-btn"
                                    onClick={this.handleShowResetModel} />
                                <LoadingButton className="btn primary-btn" title="Update User"
                                    isLoading={loading} onClick={this.submit} />
                            </>
                        }
                    </div>

                    {!this.isAdd && (
                        <ChangeUserStatus userId={user.id} status={user.is_active}
                            fullName={`${get(user, 'first_name', '-')} ${get(user, 'last_name', '-')}`}
                        />
                    )}

                    {this.renderPermittedProjects()}

                </form>
                {showResetModel &&
                    <UserResetPass selectedResetUser={user}
                        closeModel={this.handleCloseResetModel}
                        addNotification={this.props.addNotification}
                    />
                }
            </>
        )
    }
    /**
     * render if form is not add, and selected user is not admin
     */
    renderPermittedProjects = () => {
        const { user } = this.props

        if (!this.isAdd) {
            if (user.role > 1) {
                return (
                    <>
                    <div class="divider"></div>
                    <div className="permitted-project-wrapper">
                        <div className="title">Permitted Projects</div>
                        <PermittedProjectList user={user} />
                    </div>
                    </>
                )
            }
        }
    }
}

export default connect(null, { getUserList, addNotification })(UserCreateForm)

UserCreateForm.propTypes = {
    closeModel: PropTypes.func.isRequired
}


/**
 * 
 * render user active inactive button
 * internal loading, api hit manage
 */
const ChangeUserStatus = (props) => {

    const [status, setStatus] = useState(props.status)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const handleStatusChange = useCallback(() => {
        setLoading(true)

        postJsonData(Urls.apiToggleUserStatus(),
            { user_id: props.userId, client_id: CLIENT_ID },
            (res) => {
                const newStatus = !status
                setLoading(false)
                setStatus(newStatus)
                dispatch(addNotification({
                    type: 'info',
                    title: newStatus ? "Activation" : "Deactivation",
                    text: `${props.fullName} ${newStatus ? "Active" : "Deactive"} Successfully.`,
                }))
                dispatch(userSetStatus({userId: props.userId, status: newStatus }))
            },
            (err) => {
                setLoading(false)
                dispatch(addNotification({
                    type: 'error',
                    title: 'Update Failed',
                    text: 'Activation status update failed due to some serious reason',
                }))
            }
        )
    }, [status])

    return (
        <div className="user-form-del">
            <div className="user-form-del-text" onClick={handleStatusChange}>
                {loading ? "Loading..." : status ? "Deactive User" : "Active User"}
            </div>
        </div>
    )
}


ChangeUserStatus.propTypes = {
    status: PropTypes.bool.isRequired,
    userId: PropTypes.number.isRequired,
}


/**
 * Scss;
 *      _user_form
 */
class PermittedProjectList extends Component {

    /**
     * fetch permitted project list from server,
     * manage error and loding
     * 
     * Parent:
     *      UserCreateForm
     */

    constructor(props) {
        super(props)

        this.state = {
            fetching: true,
            fetched: false,
            error: false,
            errorMsg: {},

            permittedProjectList: [],

            deleting: null, // deleting permission id
        }
        this._mounted = false
    }

    componentDidMount = () => {
        this._mounted = true
        this.getPermittedProjectList()
    }

    componentWillUnmount = () => {
        this._mounted = false
    }

    getPermittedProjectList = () => {
        getJsondata(Urls.apiUserPermittedProjectList(this.props.user.id),
            (res) => {
                if (this._mounted) {
                    this.setState({
                        fetching: false, fetched: true,
                        error: false, errorMsg: {}, permittedProjectList: res
                    })
                }
            },
            (err) => {
                if (this._mounted) {
                    this.setState({
                        fetching: false, fetched: false,
                        error: true, errorMsg: err.error_data, permittedProjectList: []
                    })
                }
            }
        )
    }

    handleDelete = (permisson) => () => {
        if(this.state.deleting) return

        const data = {
			project_id: permisson.project.id,
			new_permissions: [],
			deleted_permissions: [permisson.id]
        }

        this.setState({ deleting: permisson.id })
        postJsonData(Urls.apiUpdateProjectPermissions(), data,
			(res) => {
                if(this._mounted) {
                    const newPerm = difference(this.state.permittedProjectList, [permisson])
                    this.setState({ deleting: null, permittedProjectList: newPerm })
                }
			},
			(err) => {
                if(this._mounted) {
                    this.setState({ deleting: null })
                }
			}
		)
    }

    render = () => {
        const { fetching, fetched, error, permittedProjectList } = this.state

        if (fetched && !fetching) {
            if (permittedProjectList.length) {
                return (
                    <div className="perm-table">
                        {permittedProjectList.map((permisson) => {
                            return (
                                <div className="perm-row" key={permisson.id}>
                                    <div className="perm-col small-col">
                                        <div className="pro-img">
                                            <img src={get(permisson, 'project.image', null)}/>
                                        </div>
                                    </div>
                                    <div className="perm-col info-text">{get(permisson, 'project.name', '-NA-')}</div>
                                    <div className="perm-col small-col">
                                        <div className="perm-delete" onClick={this.handleDelete(permisson)}><DeleteIcon /></div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            }
            else {
                return <div className="empty-text">Not assigned to any project</div>
            }
        }
        else if (error) {
            return (
                <div className="error-block big">Failed to fetch data</div>
            )
        }
        else {
            return <Loader />
        }
    }
}