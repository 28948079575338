import React from 'react'
import { Link } from 'react-router-dom'

import Urls from '../../UrlConfig'

export default () => (
	<div id='privacy-policy' className="container">
		<div className="header">
			<div className="left">
				<Link to={Urls.getHomePage()}>Go to website</Link>
			</div>
			<div className="right">
				<img className="logo" src={Urls.getStaticImg('img/logoHorizontal.png')} alt="Chavda" />
			</div>
			<div class='clearfix' />
		</div>

		<h1>Privacy Policy for Chavda ERP</h1>

		<p>At Chavda, accessible from https://chavda-erp.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Chavda and how we use it.</p>

		<p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

		<p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Chavda. This policy is not applicable to any information collected offline or via channels other than this website.</p>

		<h2>Consent</h2>

		<p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

		<h2>Information we collect</h2>

		<p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
		<p>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>
		<p>When you register for an Account, we may ask for your contact information, including items such as name, email address, and telephone number.</p>

		<h2>How we use your information</h2>

		<p>We use the information we collect in various ways, including to:</p>

		<ul>
			<li>Provide, operate, and maintain our website</li>
			<li>Improve, personalize, and expand our website</li>
			<li>Understand and analyze how you use our website</li>
			<li>Develop new products, services, features, and functionality</li>
			<li>Send you emails</li>
			<li>Find and prevent fraud</li>
		</ul>

		<p>Our app may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>

		<p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
		<p>This policy is effective as of 1 December 2020.</p>
	</div>
)