import React, { Component } from 'react'
import { connect } from 'react-redux'

import { get } from 'lodash'
import Fuse from 'fuse.js'

import Loader from '../../common/components/Loader'
import LoadingButton from '../../common/components/LoadingButton'
import { CustomSearchBox } from '../../common/components/CustomInput'
import ActivityForm from './ActivityForm'
import { EditIcon } from '../../common/SvgIcons'

import { getActivityList } from '../data/activity.actions'

import Urls from '../../UrlConfig'

/**
 * Scss
 * 		_activity_list
 * 
 * Common
 * 		layout, _common_page_layout
 */


class ActivityListPage extends Component {

	/**
	 * Parent:
	 * 		AdminHomePage
	 */

	state = {
		activityId: false,
		contentHeight: 0,

		searchToken: '',
	}

	componentDidMount = () => {
		if(!this.props.fetched) {
			this.props.getActivityList(this.setupSearch)
		} else {
			this.setupSearch(this.props.activityList)
		}
	}

	setupSearch = (activityList) => {
        // config search
        const search_opts = {
            shouldSort: true,
            tokenize: true,
            findAllMatches: true,
            threshold: 0.8,
            location: 0,
            distance: 32,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [
                "name",
            ]
        }

        this.search = new Fuse(activityList, search_opts)
    }

	handleShowModel = (activityId) => () => {
		if (activityId === this.state.activityId) {
			this.setState({ activityId: false })
		} else {
			this.setState({ activityId: activityId })
		}
	}

	handleCloseModel = () => {
		this.setState({ activityId: false })
	}

	resizeContent = (element) => {
		if (element) {
			const { windowHeight } = this.props

			const contentHeight = windowHeight - element.getBoundingClientRect().top
			this.setState({ contentHeight })
		}
	}

	handleTextChange = (e) => {
        this.setState({searchToken: e.target.value})
    }

	render = () => {
		const { activityId, searchToken } = this.state

		return (
			<>
				<div className="page-right-actions">
					<div className="page-action-wrapper">
						<CustomSearchBox type="text" placeholder="Search"
							value={searchToken} onChange={this.handleTextChange} />
						<div className="page-add-new">
							<LoadingButton className="primary-btn"
								title="+ Create Activity"
								onClick={this.handleShowModel(-1)}
							/>
						</div>
					</div>
				</div>
				<div id="activity-list-page">

					{this.renderList()}

					<div className={`activity-model ${activityId ? "model-in" : "model-out"}`}>
						{activityId &&
							<ActivityForm activityId={activityId} key={activityId}
								closeModel={this.handleCloseModel}
							/>
						}
					</div>
				</div>
			</>
		)
	}

	renderList = () => {
		const { fetched, error, activityList } = this.props
		const { activityId, contentHeight, searchToken } = this.state

		if (fetched) {
			const paddingBot = 15
			let wrapperHeightCss = {paddingBot}
			// set planning cols height to scroll individually
			if(contentHeight) {
				wrapperHeightCss.height = contentHeight - paddingBot
			}

			// search
			let filteredactivityList = activityList;
			if(searchToken.length > 1) {
				filteredactivityList = this.search.search(searchToken).map(d => d.item)
			}
			return (
				<div className="activity-list-wrapper" ref={this.resizeContent}>
					<div className="activity-list-container" style={wrapperHeightCss}>
						{filteredactivityList.length ?
							<div className="flex-wrapper">
								{filteredactivityList.map((activity) => {
									const isActive = activityId === activity.id
									return (
										<div key={activity.id} className={`activity-block ${isActive ? 'active' : ''}`} 
											onClick={this.handleShowModel(activity.id)}>
											<div className="activity-edit">
												<EditIcon />
											</div>
											<div className="title">{get(activity, 'name', '')}</div>
											<div className="wrapper">
												Subactivities: <span>{get(activity, 'subactivity.length', '--')}</span>
											</div>
										</div>
									)
								})}
							</div>
							:
							<div className="empty-page-wrapper">
								<img src={Urls.getStaticImg('/img/no-activity.svg')} alt="" />
								<div className="text">Looks like you don't have any activity right now</div>
							</div>
						}
					</div>
				</div>
			)
		}
		else if (error) {
			return (
                <div className="empty-page-wrapper">
                    <div className="error-block big">Failed to fetch data</div>
                </div>
			)
		}
		else {
			return <Loader />
		}
	}
}


export default connect(store => ({
	fetching: store.activityList.fetching,
	fetched: store.activityList.fetched,
	error: store.activityList.error,
	activityList: store.activityList.activityList,
	windowHeight: store.pageSize.windowHeight,
}), { getActivityList })(ActivityListPage)