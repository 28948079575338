import { has, isNull } from "lodash"

const DEFAULT_DATA = {
    selectedUnit: {
        id: -1,
        name: 'All Blocks'
    }, // defaulf all block selected
    selectedSubunit: {
        id: -1,
        name: 'All Floors'
    }, // defaulf all floors selected
    subunitList: [{id: -1, name: 'All Floors'}],
    layoutType: 2, // 1: list, 2: card
    dataType: 2, // 1: planning, 2: micro planning
    activityId: null, // null | activity id
    isFilterActive: false,
}

const INIT_DATA = {
    /**
     * { project_id1: { DEFAULT_DATA } }
     * { project_id2: { DEFAULT_DATA } }
     */
}

export const projectViewConfigReducer = (state = INIT_DATA, { type, payload }) => {
    switch (type) {

        case 'PROJECT_DETAILS_SUCCESS': {

            // if config exist, do nothing
            if (has(state, payload.id)) {
                return state
            } // if not exist, add new data
            else {
                return { ...state, [payload.id]: DEFAULT_DATA }
            }
        }

        case 'PROJECT_SETTING_SELECT_UNIT': {
            const { projectId, unit, subunitList } = payload
            // get current project setting
            let setting = { ...state[projectId] }
            // update unit and subunit
            setting.selectedUnit = unit
            setting.selectedSubunit = { id: -1, name: 'All Floors' }
            // make list of subunits
            const newSubunitList = [{ id: -1, name: 'All Floors' }, ...subunitList]
            setting.subunitList = newSubunitList
            // set filter active
            setting.isFilterActive = (unit.id !== -1) || !isNull(setting.activityId)

            return { ...state, [projectId]: setting }
        }

        case 'PROJECT_SETTING_SELECT_SUBUNIT': {
            const { projectId, subunit } = payload
            // get current project setting
            let setting = { ...state[projectId] }
            // update subunit
            setting.selectedSubunit = subunit
            // set filter active
            const unitId = setting.selectedUnit.id
            setting.isFilterActive = (unitId !== -1) || (subunit.id !== -1) || !isNull(setting.activityId)

            return { ...state, [projectId]: setting }
        }

        case 'PROJECT_SETTING_RESET_FILTER': {
            const { projectId } = payload
            // get current project setting and update
            let setting = { 
                ...state[projectId],
                selectedUnit: {
                    id: -1,
                    name: 'All Blocks'
                }, // defaulf all block selected
                selectedSubunit: {
                    id: -1,
                    name: 'All Floors'
                }, // defaulf all floors selected
                subunitList: [{id: -1, name: 'All Floors'}],
                isFilterActive: false
            }
            return { ...state, [projectId]: setting }
        }

        case 'PROJECT_SETTING_SELECT_LAYOUT': {
            const { projectId, layoutType } = payload
            // get current project setting
            let setting = { ...state[projectId], layoutType }
            return { ...state, [projectId]: setting }
        }

        case 'PROJECT_SETTING_SELECT_DATATYPE': {
            const { projectId, dataType } = payload
            // get current project setting
            let setting = { ...state[projectId], dataType }
            return { ...state, [projectId]: setting }
        }

        case 'PROJECT_SETTING_SELECT_ACTIVITY': {
            const { projectId, activityId } = payload
            // get current project setting
            let setting = { ...state[projectId], activityId }
            // set filter active flag
            const unitId = setting.selectedUnit.id
            const subunitId = setting.selectedSubunit.id
            setting.isFilterActive = (unitId !== -1) || (subunitId !== -1) || !isNull(activityId)

            return { ...state, [projectId]: setting }
        }

        default: {
            return state
        }
    }
}