import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'

import ErrorBlock from '../../common/components/ErrorBlock'
import CustomInput from '../../common/components/CustomInput'
import LoadingButton from '../../common/components/LoadingButton'

import { postLogin } from '../data/auth.actions'

import Urls from '../../UrlConfig'
import { CLIENT_ID } from '../../constant'

/**
 * Scss:
 * 		_auth_page.scss
 * Common:
 * 		_layout.scss, _form.scss, _buttons.scss
 */

export default connect(store => ({
	is_user_logged: store.auth.logged,
	fetching: store.auth.fetching,
	error_obj: store.auth.error_obj,
}))(props => {

	/**
	 * login form,
	 * login success redirect to home page
	 * 
	 * Parent:
	 *      AuthPage
	 */

	const loginUser = useCallback((e) => {
		if (e) e.preventDefault();

		const form = document.getElementById('login-form');
		let data = new FormData(form);

		data.append('client_id', CLIENT_ID)

		props.dispatch(postLogin(data))
	}, [])


	if (props.is_user_logged) {
		let path_to_redirect = props.from_route || Urls.getHomePage()
		return <Redirect to={path_to_redirect} />
	}


	return (
		<form id="login-form" className="form-inner-block">
			<div className="company-title">CHAVDA ERP</div>
			<CustomInput
				label="Email"
				errorData={props.error_obj}
				errorField="email"
				type="text" name="email"
				placeholder="Enter email"
			/>
			<CustomInput
				label="Password"
				errorData={props.error_obj}
				errorField="password"
				type='password' name="password"
				placeholder="Enter Password"
			/>
			<div className="forgot-pass">
				<Link to={Urls.getAuthPages('forget-password')}>Forgot Password</Link>
			</div>
			<ErrorBlock error={props.error_obj} field="__all__" />

			<LoadingButton title="Login" className="btn primary-btn"
				onClick={loginUser} isLoading={props.fetching}
			/>

			<div className="tiny-url center">
				Don't Have an Account ? <Link to={Urls.getAuthPages('signup')}> Sign up</Link>
			</div>
		</form>
	)

})