import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Switch, Route, NavLink } from 'react-router-dom'

import { get, isNull } from 'lodash'

import PlanningList from '../components/planning/PlanningList'
import ProjectPlanningPopup from '../components/planning/ProjectPlanningPopup'

import QualityList from '../components/quality/QualityList'
import QualityChecklistPopup from "../components/quality/QualityChecklistPopup";
import QualityImageGallery from "../components/quality/QualityImageGallery";
import QualityAddPopup from '../components/quality/QualityAddPopup'

import DrawingList from '../components/drawing/DrawingList'

import ProjectViewConfig from '../components/ProjectViewConfig'
import PageNotFound from '../../common/pages/PageNotFound'

import ErrorBlock from '../../common/components/ErrorBlock'
import Loader from '../../common/components/Loader'
import HocLoginRequired from '../../common/components/HocLoginRequired'
import { BackIcon, DrawingIcon, QualityIcon, PlanningIcon } from '../../common/SvgIcons'

import { getProjectDetails, resetProjectDetails } from '../../project/data/project.actions'


import Urls from '../../UrlConfig'

/**
 * Scss:
 * 		project/_project_details.scss
 * Common:
 * 		buttons, layout, _common_page_layout
 */


class ProjectDetailsPage extends Component {

  /**
   * 
   * fetch data from server
   * manage loading and error
   * render ProjectDetails on success
   * 
   * Parent:
   *      App
   * 
   * Renders:
   *      ProjectViewConfig
   *      (nav tabs)
   *          - PlanningList (default)
   *          - QualityList
   *          - DrawingList
   *      - ProjectPlanningPopup - in planning card, details btn click popup will show
   */

  state = {
    contentHeight: 0,
  }

  componentDidMount = () => {
    const projectId = get(this.props, 'match.params.projectId', 1)
    // fetch project details
    this.props.getProjectDetails(projectId)
  }

  render = () => {
    const projectId = get(this.props, 'match.params.projectId')

    const { fetched, fetchError, fetchErrorObj, projectName,
      windowHeight, navbarHeight, backPillHeight } = this.props

    if (fetched) {
      const tab = get(this.props, 'match.params.tab')
      const pageHeight = windowHeight - navbarHeight

      const pageContentHeight = pageHeight - backPillHeight

      return (
        <div id="project-details" className="project-details-page" style={{ height: `${pageHeight}px` }}>

          <div className="page-back-pill">
            <Link to={Urls.getProjectPage()} className="page-back-content">
              <BackIcon />
              <div className="page-back-text">Go back to Project List</div>
            </Link>
            <div className="page-pill-title hide-on-small-and-down">{projectName}</div>
          </div>

          <SideBarMenu projectId={projectId} />

          <div className="page-content-wrapper sidebar-aside-wrapper"
            style={{ height: `${pageContentHeight}px` }}>
            <div className="page-content project-details-page">

              <ProjectViewConfig tab={tab} />

              <Switch>
                <Route path={Urls.getProjectDetails(projectId, 'planning')} component={PlanningList} />
                <Route path={Urls.getProjectDetails(projectId, 'quality')} component={QualityList} />
                <Route path={Urls.getProjectDetails(projectId, 'drawing')} component={DrawingList} />
                {/** Handle wrong tab by user */}
                <Route path='*' component={PageNotFound} />
              </Switch>

              {this.renderTargetDetailsPopup(tab)}

            </div>
          </div>
        </div>
      )
    }
    else if (fetchError) {

      if (fetchErrorObj.error_code === 403) {
        // return <Redirect to={Urls.getUnauthorized()} />
      }
      return (
        <div className="container">
          <ErrorBlock error={fetchErrorObj.error_data} field="__all__" />
        </div>
      )
    }
    else {
      return (
        <div className="container">
          <Loader />
        </div>
      )
    }
  }

  renderTargetDetailsPopup = (tab) => {
    const { history, checkListFetched } = this.props
    const urlGetParams = new URLSearchParams(this.props.location.search);
    const targetId = urlGetParams.get('targetId')
    const targetType = urlGetParams.get('targetType')

    if (tab === 'planning') {
      if (!isNull(targetId)) {
        return <ProjectPlanningPopup history={history}
          mcPlanningId={targetId} />
      }
    }
    else if (tab === 'quality') {
      // wait till data fetched
      if (!checkListFetched) return;
      if (!isNull(targetId)) {
        if (targetType === 'add_qa_image') {
          return <QualityAddPopup checkId={targetId} history={history} />
        }
        else if (targetType === 'qa_details') {
          return <QualityChecklistPopup checkId={targetId} history={history} />
        }
        else if (targetType === 'show_qa_image') {
          return <QualityImageGallery imageId={targetId} history={history} />
        }
      }
    }
  }
}

export default connect(store => ({
  fetching: store.projectDetails.fetching,
  fetched: store.projectDetails.fetched,
  fetchError: store.projectDetails.fetchError,
  fetchErrorObj: store.projectDetails.fetchErrorObj,
  // popups may only render once list are fetched
  checkListFetched: store.checklist.fetched,

  projectName: store.projectDetails.projectDetails.name,
  // handle page size
  windowHeight: store.pageSize.windowHeight,
  navbarHeight: store.pageSize.navbarHeight,
  backPillHeight: store.pageSize.backPillHeight,
  bannerMinHeight: store.pageSize.bannerMinHeight,
  // for HocLoginRequired
  logged: store.auth.logged
}), {
  getProjectDetails, resetProjectDetails,
})(HocLoginRequired(ProjectDetailsPage))


const SideBarMenu = ({ projectId }) => {
  return (
    <div className='sidebar-column-wrapper'>
      <div className='sidebar-menu'>
        <NavLink
          activeClassName="active"
          className="sidebar-block"
          to={Urls.getProjectDetails(projectId, 'planning')}
        >
          <div className="sb-block-icon"><PlanningIcon /></div>
          <div className='sb-block-title'>Planning</div>
        </NavLink>
        <NavLink
          activeClassName="active"
          className="sidebar-block"
          to={Urls.getProjectDetails(projectId, 'quality')}
        >
          <div className="sb-block-icon"><QualityIcon /></div>
          <div className='sb-block-title'>Quality</div>
        </NavLink>
        <NavLink
          activeClassName="active"
          className="sidebar-block"
          to={Urls.getProjectDetails(projectId, 'drawing')}
        >
          <div className="sb-block-icon"><DrawingIcon /></div>
          <div className='sb-block-title'>Drawing</div>
        </NavLink>
      </div>
    </div>
  )
}