import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

import ImageGallery from 'react-image-gallery';

import { get, findIndex, find } from 'lodash'

import CustomImage from '../../../common/components/CustomImage'

import { CloseIcon } from '../../../common/SvgIcons'
import Urls from '../../../UrlConfig'


class QualityImageGallery extends PureComponent {

  /**
   * Parent:
   *      QualityList
   */

  constructor(props) {
    super(props);
    this.imageGallery = React.createRef();
  }

  componentDidMount = () => {
    if (this.imageGallery.current) {
      this.imageGallery.current.fullScreen()
    }
  }

  renderFullscreenButton = () => {
    const { projectId } = this.props

    return (
      <Link to={Urls.getProjectDetails(projectId, 'quality')}
        className='image-gallery-fullscreen-close'>
        <CloseIcon />
      </Link>
    );
  }

  render = () => {
    const { selectedCheckData, imageId } = this.props

    const qualityImages = get(selectedCheckData, 'quality_images', [])
    const startIndex = findIndex(qualityImages, ["id", Number(imageId)])

    return (
      <ImageGallery ref={this.imageGallery}
        items={qualityImages} startIndex={startIndex}
        showPlayButton={false} useBrowserFullscreen={false}
        renderFullscreenButton={this.renderFullscreenButton}
        renderItem={props => {
          return (
            <div className="image-gallery-block ">
              <CustomImage imageUrl={props.image} prefixClassName="image-gallery" />
              <div className="image-gallery-text">
                <div>
                  {get(selectedCheckData, 'activity.name', '')} - {get(selectedCheckData, 'subactivity.name', '')} ,
                  &nbsp;{get(selectedCheckData, 'unit.name', '')} , {get(selectedCheckData, 'subunit.name', '')}
                </div>
                {props.caption &&
                  <div className="caption">{props.caption}</div>
                }
              </div>
            </div>
          )
        }}
        renderThumbInner={props => {
          return (
            <div className="image-gallery-thumbnail-inner">
              <CustomImage imageUrl={props.image} prefixClassName="image-gallery-thumbnail" />
            </div>
          )
        }}
      />
    )

  }
}


export default connect((store, props) => {
  // get current selected check data from imageId
  const selectedCheckData = find(store.checklist.checklist, (check) => {
    return findIndex(check.quality_images, ["id", Number(props.imageId)]) !== -1
  })
  return ({
    projectId: store.projectDetails.projectDetails.id,
    selectedCheckData,
  })
})(QualityImageGallery)