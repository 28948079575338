import { getJsondata } from '../../common/utils'
import Urls from '../../UrlConfig'


export const getCheckConfigList = (callback=null) => {

    return async (dispatch) => {
        dispatch({ type: 'CHECK_CONFIG_LIST_FETCHING' })

        getJsondata(Urls.apiGetChecklistConfigList(),
			(res) => {
				dispatch({ type: 'CHECK_CONFIG_LIST_SUCCESS', payload: res })
				// mainly used to setup search from fetched data
				if(callback) callback(res)
			},
			(err) => {
            	// console.log("-> err", err)
                dispatch({ type: 'CHECK_CONFIG_LIST_ERROR', payload: err })
			}
		)
    }
}
