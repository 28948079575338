import { getJsondata } from '../../common/utils'
import Urls from '../../UrlConfig'

///////////////////////////////////
//	    Quality action		    //
/////////////////////////////////

export const getProjectQualityCheckList = (projectId) => {

  return async dispatch => {
    dispatch({ type: 'PROJECT_CHECKLIST_FETCHING' })

    getJsondata(Urls.apiGetQualityCheckList(projectId),
      (res) => {
        dispatch({ type: 'PROJECT_CHECKLIST_SUCCESS', payload: res })
      },
      (err) => {
        // console.log("-> err", err)
        dispatch({ type: 'PROJECT_CHECKLIST_ERROR', payload: err })
      }
    )
  }
}

export const AddImageToQualityList = (data) => ({
  type: "PROJECT_CHECKLIST_ADD_NEW_IMAGE",
  payload: data
})