import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import find from "lodash/find";

import { getWindowHeight, getWindowWidth } from "../../data/common.selector";
import { PLOT_DETAILS, DEFAULT_ZOOM } from "../../data/demo.constants";
import { PLOT_AVAILABLE, PLOT_CONSTRUCTION } from "../../SvgIcons";

const DemoSvg = ({
  imageDims,
  selectedPlot,
  setSelectedPlot,
  setTransform,
  showStatistics,
}) => {
  const contentHeight = useSelector(getWindowHeight);
  const contentWidth = useSelector(getWindowWidth);

  const handleGoToPlot = useCallback(
    (plotId) => () => {
      const plotData = find(PLOT_DETAILS, ["id", plotId]);
      setSelectedPlot(plotData);

      const translateX = contentWidth * plotData.x_pos;
      const translateY = contentHeight * plotData.y_pos;
      setTransform(
        translateX,
        translateY,
        plotData.z_pos || DEFAULT_ZOOM,
        500,
        "easeInOutCubic"
      );
    },
    [setTransform]
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${imageDims.width}px`}
      height={`${imageDims.height}px`}
      viewBox="0 0 3634 2716"
      className="svg-plan"
    >
      {PLOT_DETAILS.map((plot) => {
        const isFocused = plot.id === selectedPlot?.id;
        return (
          <g key={plot.id}>
            {/* <path d={plot.svgPath} fill="url(#image-pattern)" /> */}
            <path
              id={plot.id}
              className={`svg-plot ${isFocused ? "focused" : ""}`}
              d={plot.svgPath}
              onClick={handleGoToPlot(plot.id)}
            />
            {showStatistics ? (
              <>
                {plot.is_sell ? (
                  <PLOT_AVAILABLE svgStyle={plot.svg_style} />
                ) : null}
                {plot.is_under_c ? (
                  <PLOT_CONSTRUCTION svgStyle={plot.svg_style} />
                ) : null}
              </>
            ) : null}
          </g>
        );
      })}
    </svg>
  );
};

export default DemoSvg;
