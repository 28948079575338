import React from "react";

const DemoDummy = ({ toggleStatistics }) => {
  return (
    <>
      <svg className="sprites" display="none">
        <defs>
          <linearGradient id="icon-grad" x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" stopColor="#fff" />
            <stop offset="100%" stopColor="#222" />
          </linearGradient>
          <mask id="icon-mask">
            <rect x="0" y="0" width="24" height="24" fill="url(#icon-grad)" />
          </mask>
          <symbol id="files" viewBox="0 0 24 24">
            <g fill="hsl(0,0%,100%)" mask="url(#icon-mask)">
              <path d="m2.003,3h3.997c1.105,0,2,.895,2,2h0s14.005,0,14.005,0c1.102,0,1.995.893,1.995,1.995v1.005H0v-2.997c0-1.106.897-2.003,2.003-2.003Z" />
              <path d="m22.005,21H1.996c-1.102,0-1.996-.893-1.996-1.996v-9.004l24-.172v9.177c0,1.102-.893,1.995-1.995,1.995Z" />
            </g>
          </symbol>
          <symbol id="books" viewBox="0 0 24 24">
            <g fill="hsl(0,0%,100%)" mask="url(#icon-mask)">
              <path d="m14.113,22.696c-.356.389-1.022.176-1.022-.351V3.287c1.091-1.091,2.277-1.558,3.509-1.83,2.182-.481,4.125-.377,5.314-.222,1.317.171,2.087,1.342,2.087,2.495v14.357c0,1.553-1.296,2.777-2.802,2.727-1.246-.041-2.975.003-4.541.382-1.195.289-1.954.856-2.544,1.501Z" />
              <path d="m9.887,22.696c.356.389,1.022.176,1.022-.351V3.287c-1.091-1.091-2.277-1.558-3.509-1.83-2.182-.481-4.125-.377-5.314-.222C.77,1.406,0,2.577,0,3.729v14.357c0,1.553,1.296,2.777,2.802,2.727,1.246-.041,2.975.003,4.541.382,1.194.289,1.954.856,2.544,1.501Z" />
            </g>
          </symbol>
          <symbol id="graph" viewBox="0 0 24 24">
            <g fill="hsl(0,0%,100%)" mask="url(#icon-mask)">
              <rect rx="1" ry="1" x="0" y="12" width="6" height="12" />
              <rect rx="1" ry="1" x="9" y="0" width="6" height="24" />
              <rect rx="1" ry="1" x="18" y="6" width="6" height="18" />
            </g>
          </symbol>
          <symbol id="weather" viewBox="0 0 24 24">
            <g fill="hsl(0,0%,100%)" mask="url(#icon-mask)">
              <path d="m2.65,10.95c.916-.546,1.986-.858,3.129-.858.138,0,.276.004.414.013.388-1.331,1.13-2.499,2.113-3.403-.187-2.126-1.971-3.791-4.144-3.791-2.299,0-4.162,1.863-4.162,4.162,0,1.766,1.1,3.273,2.65,3.877Z" />
              <path d="m19.687,12.464s-.003,0-.005,0c.002-.072.005-.144.005-.216,0-3.454-2.8-6.254-6.253-6.254-3.235,0-5.897,2.457-6.22,5.607-.454-.14-.936-.216-1.435-.216-2.679,0-4.852,2.172-4.852,4.852s2.172,4.852,4.852,4.852h13.908c2.382,0,4.313-1.931,4.313-4.312,0-2.382-1.931-4.313-4.313-4.313Z" />
            </g>
          </symbol>
          <symbol id="pen" viewBox="0 0 24 24">
            <g fill="hsl(0,0%,100%)" mask="url(#icon-mask)">
              <path d="M3.1,0C2.5-0.1,2.1,0.6,2.5,1L10,8.5c0.2,0,0.4-0.1,0.6-0.1c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2c0-0.2,0-0.4,0.1-0.6L1,2.5C0.6,2.1-0.1,2.5,0,3.1L3.4,17c0.1,0.4,0.4,0.7,0.9,0.8l6.4,1.3c-0.2,0.4-0.1,0.9,0.2,1.2l3.3,3.3c0.4,0.4,1.1,0.4,1.6,0l7.8-7.8c0.4-0.4,0.4-1.1,0-1.6l-3.3-3.3c-0.3-0.3-0.8-0.4-1.2-0.2l-1.3-6.4c-0.1-0.4-0.4-0.8-0.8-0.9L3.1,0z" />
            </g>
          </symbol>
          <symbol id="heart" viewBox="0 0 24 24">
            <g fill="hsl(0,0%,100%)" mask="url(#icon-mask)">
              <path d="m.204,9.117c.272,1.039.791,1.942,1.558,2.709l10.238,10.597,10.238-10.597c.767-.767,1.287-1.67,1.558-2.709.272-1.039.272-2.07,0-3.093-.272-1.023-.791-1.918-1.558-2.685-.767-.767-1.662-1.287-2.685-1.558-1.023-.272-2.062-.272-3.117,0-1.055.272-1.95.791-2.685,1.558l-1.75,2.11-1.75-2.11c-.767-.767-1.662-1.287-2.685-1.558s-2.054-.272-3.093,0c-1.039.272-1.942.791-2.709,1.558-.767.767-1.287,1.662-1.558,2.685-.272,1.023-.272,2.054,0,3.093Z" />
            </g>
          </symbol>
        </defs>
      </svg>
      <div className="icon-btns">
        <button className="icon-btn icon-btn--blue" type="button">
          <span className="icon-btn__back"></span>
          <span className="icon-btn__front">
            <svg
              className="icon-btn__icon"
              width="24px"
              height="24px"
              aria-hidden="true"
            >
              <use xlinkHref="#files" />
            </svg>
          </span>
          <span className="icon-btn__label">Files</span>
        </button>
        <button className="icon-btn icon-btn--purple" type="button">
          <span className="icon-btn__back"></span>
          <span className="icon-btn__front">
            <svg
              className="icon-btn__icon"
              width="24px"
              height="24px"
              aria-hidden="true"
            >
              <use xlinkHref="#books" />
            </svg>
          </span>
          <span className="icon-btn__label">Marketing</span>
        </button>
        <button className="icon-btn icon-btn--red" type="button">
          <span className="icon-btn__back"></span>
          <span className="icon-btn__front">
            <svg
              className="icon-btn__icon"
              width="24px"
              height="24px"
              aria-hidden="true"
            >
              <use xlinkHref="#heart" />
            </svg>
          </span>
          <span className="icon-btn__label">Favourite</span>
        </button>
        <button className="icon-btn icon-btn--indigo" type="button">
          <span className="icon-btn__back"></span>
          <span className="icon-btn__front">
            <svg
              className="icon-btn__icon"
              width="24px"
              height="24px"
              aria-hidden="true"
            >
              <use xlinkHref="#weather" />
            </svg>
          </span>
          <span className="icon-btn__label">Weather</span>
        </button>
        <button className="icon-btn icon-btn--orange" type="button">
          <span className="icon-btn__back"></span>
          <span className="icon-btn__front">
            <svg
              className="icon-btn__icon"
              width="24px"
              height="24px"
              aria-hidden="true"
            >
              <use xlinkHref="#pen" />
            </svg>
          </span>
          <span className="icon-btn__label">Notes</span>
        </button>
        <button
          className="icon-btn icon-btn--green"
          type="button"
          onClick={toggleStatistics}
        >
          <span className="icon-btn__back"></span>
          <span className="icon-btn__front">
            <svg
              className="icon-btn__icon"
              width="24px"
              height="24px"
              aria-hidden="true"
            >
              <use xlinkHref="#graph" />
            </svg>
          </span>
          <span className="icon-btn__label">Statistics</span>
        </button>
      </div>
    </>
  );
};

export default DemoDummy;
