import React from "react";

import get from "lodash/get";
import noop from "lodash/noop";

import ReactImageFallback from "react-image-fallback";

import Urls from "../../UrlConfig";

// Defining Colors
const COLORS = [
  "#1abc9c",
  "#16a085",
  "#f1c40f",
  "#f39c12",
  "#2ecc71",
  "#27ae60",
  "#e67e22",
  "#d35400",
  "#3498db",
  "#2980b9",
  "#e74c3c",
  "#c0392b",
  "#9b59b6",
  "#8e44ad",
  "#bdc3c7",
  "#34495e",
  "#2c3e50",
  "#95a5a6",
  "#7f8c8d",
  "#ec87bf",
  "#d870ad",
  "#f69785",
  "#9ba37e",
  "#b49255",
  "#b49255",
  "#a94136",
];

/**
 * Parent:
 *      ProjectListPage
 *      QualityList
 *      QualityImageGallery
 */
export default ({
  imageUrl,
  title = "",
  prefixClassName = "common",
  autoColor = false,
  extraStyle = {},
  onLoad = noop,
}) => {
  if (imageUrl) {
    return (
      <ReactImageFallback
        style={extraStyle}
        src={imageUrl}
        fallbackImage={Urls.getStaticImg("img/brokeImage.png")}
        initialImage={Urls.getStaticImg("img/imageLoading.png")}
        alt={title}
        className={`${prefixClassName}-image`}
        onLoad={onLoad}
      />
    );
  } else {
    let firstLetter = get(title, "0", "C");
    firstLetter = firstLetter.toUpperCase();

    const charColor = autoColor
      ? COLORS[firstLetter.charCodeAt(0) - 65]
      : "#4f55dd";

    return (
      <div
        className={`${prefixClassName}-letter-wrapper`}
        style={{ background: charColor }}
      >
        <div className={`${prefixClassName}-letter`}>{firstLetter}</div>
      </div>
    );
  }
};
