import React, { Component } from 'react'
import { connect } from 'react-redux'

import { get } from 'lodash'

import LoadingButton from '../../common/components/LoadingButton'
import UnitUpdatePopup from './UnitUpdatePopup'
import SubunitUpdatePopup from './SubunitUpdatePopup'
import { EditIcon } from '../../common/SvgIcons'

import Urls from '../../UrlConfig'


/**
 * Scss
 * 		_project_unit_list
 * 
 * Common
 * 		layout, _common_page_layout
 */


class UnitList extends Component {

    /**
     * render unit list with, unit add-edit model and subunit add-edit slider
     * 
     * Parent:
     * 		ProjectEdit
     * 
     * Renders:
     *      UnitUpdatePopup
     *      SubunitUpdatePopup
     */

    state = {
        contentHeight: 0,

        unitId: false,
        unitData: {},
        modelType: null, // 1: add edit unit, 2: add edit subunit
    }

    handleShowModel = (unitId) => () => {
        if (unitId === this.state.unitId) {
            this.setState({ unitId: false })
        } else {
            this.setState({ unitId: unitId })
        }
    }

    handleUnitUpdate = (unitId, unitData) => (event) => {
        if (event) event.stopPropagation();

        this.setState({ unitId, unitData, modelType: 1 })
    }

    handleModelClose = () => {
        this.setState({ unitId: null, unitData: {}, modelType: null })
    }

    handleSubunitUpdate = (unitId, unitData) => (event) => {
        if (event) event.stopPropagation();

        this.setState({ unitId, unitData, modelType: 2 })
    }

    resizeContent = (element) => {
        if (element) {
            const { windowHeight } = this.props

            const contentHeight = windowHeight - element.getBoundingClientRect().top
            this.setState({ contentHeight })
        }
    }

    render = () => {
        const { formData } = this.props
        const { unitId, unitData, modelType, contentHeight } = this.state

        const showUnitModel = unitId && modelType === 1
        const showSubUnitModel = unitId && modelType === 2

        const paddingBot = 15
        let wrapperHeightCss = { paddingBot }
        // set planning cols height to scroll individually
        if (contentHeight) {
            wrapperHeightCss.height = contentHeight - paddingBot
        }

        return (
            <>
                <div className="page-right-actions">
                    <div className="page-action-wrapper">
                        <div className="page-add-new">
                            <LoadingButton title="+ Add Unit" className="primary-btn"
                                onClick={this.handleUnitUpdate(-1, {})} />
                        </div>
                    </div>
                </div>
                <div className="unit-list-page">

                    <div className="unit-list-wrapper" ref={this.resizeContent}>
                        <div className="unit-list-container" style={wrapperHeightCss}>
                            {formData.units.length ?
                                <div className="flex-wrapper">
                                    {formData.units.map((unit) => {
                                        const isActive = unitId === unit.id
                                        return (
                                            <div key={unit.id} className={`unit-block ${isActive ? 'active' : ''}`}
                                                onClick={this.handleSubunitUpdate(unit.id, unit)}>
                                                <div className="unit-edit" onClick={this.handleUnitUpdate(unit.id, unit)}>
                                                    <EditIcon />
                                                </div>
                                                <div className="title">{get(unit, 'name', '')}</div>
                                                <div className="wrapper">
                                                    Subunits: <span>{get(unit, 'subunits.length', '--')}</span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                <div className="empty-page-wrapper">
                                    <img src={Urls.getStaticImg('/img/no-projects.svg')} alt="" />
                                    <div className="text">You can now start creating structure for your project!</div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className={`unit-model ${showSubUnitModel ? "model-in" : "model-out"}`}>
                        {showSubUnitModel &&
                            <SubunitUpdatePopup key={unitId}
                                unitId={unitId} unitData={unitData}
                                closeModel={this.handleModelClose} />
                        }
                    </div>

                    {showUnitModel &&
                        <UnitUpdatePopup unitId={unitId} unitData={unitData}
                            closeModel={this.handleModelClose} />
                    }
                </div>
            </>
        )
    }
}


export default connect(store => ({
    formData: store.projectForm.formData,
    deletedUnitIds: store.projectForm.deletedUnitIds,
    windowHeight: store.pageSize.windowHeight,
}))(UnitList)