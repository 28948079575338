import React, { Component } from 'react'

import {isNaN, get} from 'lodash'

import ProgressRing from '../components/ProcessRing'

/**
 * Scss:
 *      _project_banner
 */
export default class ProjectBanner extends Component {

    /**
     * render project details and process ring
     * dynamic banner opacity will change according to prop
     * 
     * Parent:
     *      dashboard / ProjectSummeryList
     * Render
     *      ProgressRing
     */

    static defaultProps = {
        bannerOpacity : 1
    }

    getProjectEndMsg = (complitionValue) => {
		if (complitionValue) {
			if (complitionValue === 0) {
				return `Project ends today`
			}
			else if (complitionValue > 0) {
				return `Project ends in ${complitionValue} days`
			}
			else if (complitionValue < 0) {
				return `Project was ended ${Math.abs(complitionValue)} day ago`
			}
		}
		else {
			return null
		}
    }
    
    render = () => {
        const {projectDetails, bannerOpacity} = this.props

        const totalTasks = get(projectDetails, 'microplanning_summery.total_tasks')
        const runningTasks = get(projectDetails, 'microplanning_summery.running_tasks')
        const pendingTasks = get(projectDetails, 'microplanning_summery.pending_tasks')
        const completedTasks = get(projectDetails, 'microplanning_summery.completed_tasks')
        const complitionValue = get(projectDetails, 'project_completion_days', null)
        const complitionMsg = this.getProjectEndMsg(complitionValue)

        let completedInPer = Math.floor((completedTasks * 100) / totalTasks)
        completedInPer = isNaN(completedInPer) ? 0 : completedInPer

        return (
            <div className="project-banner-col project-banner-content" style={{opacity: bannerOpacity}}>
                <div className="project-ban-content-info-col">
                    <div className="project-ban-content-col">
                        <div className="label">Tasks Running</div>
                        <div className="value">
                            {runningTasks}/{totalTasks}
                        </div>
                    </div>
                    <div className="project-ban-content-col">
                        <div className="label">Upcoming Tasks</div>
                        <div className="value">
                            {pendingTasks}/{totalTasks}
                        </div>
                    </div>
                    <div className="project-ban-content-col">
                        <div className="label">Completed Tasks</div>
                        <div className="value">
                            {completedTasks}/{totalTasks}
                        </div>
                    </div>
                </div>
                <div className="project-ban-content-col">
                    <div className="pb-circle">
                        <ProgressRing radius={70} stroke={8}
                            progress={completedInPer}
                        />
                        <div className="pb-circle-wrapper">
                            <div className="percentage">{completedInPer}%</div>
                            {complitionMsg && <div className="text">{complitionMsg}</div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}