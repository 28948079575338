import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { findIndex, get } from "lodash";
import { format } from "date-fns";

import Loader from "../../../common/components/Loader";
import { CheckIcon, CloseIcon } from "../../../common/SvgIcons";
import LoadingButton, {
  RoundedButton,
} from "../../../common/components/LoadingButton";

import {
  getJsondata,
  fetchBlobData,
  postJsonData,
} from "../../../common/utils";
import Urls from "../../../UrlConfig";

/**
 * Scss:
 *      _project_quality_list : line 279
 */
class QualityChecklist extends Component {
  /**
   * Parent:
   *      QualityChecklistPopup
   *
   * Renders:
   *      MarkComplete
   */

  constructor(props) {
    super(props);

    this.state = {
      fetching: true,
      fetched: false,
      error: false,
      errorMsg: {},

      checkItemsList: [],

      downloading: false,
    };
    this._mounted = false;
  }

  componentDidMount = () => {
    this._mounted = true;
    getJsondata(
      Urls.apiGetQualityCheckItemsList(this.props.checkDetails.id),
      (res) => {
        if (this._mounted) {
          this.setState({
            fetching: false,
            fetched: true,
            error: false,
            errorMsg: {},
            checkItemsList: res,
          });
        }
      },
      (err) => {
        if (this._mounted) {
          this.setState({
            fetching: false,
            fetched: false,
            error: true,
            errorMsg: err,
            checkItemsList: [],
          });
        }
      }
    );
  };

  componentWillUnmount = () => {
    this._mounted = false;
  };

  onMarkSuccess = (newData) => {
    let newList = [...this.state.checkItemsList];
    const index = findIndex(newList, ["id", newData.id]);
    newList[index] = { ...newData };

    this.setState({ checkItemsList: newList });
  };

  onDownload = () => {
    const { checkDetails } = this.props;
    // subject will be filename
    const activity = get(checkDetails, "activity.name");
    const subactivity = get(checkDetails, "subactivity.name");
    const subject = subactivity ? `${activity} - ${subactivity}` : activity;

    const unit = get(checkDetails, "unit.name");
    const subunit = get(checkDetails, "subunit.name");
    const floor = subunit ? `${unit} , ${subunit}` : unit;

    const data = {
      data: this.state.checkItemsList,
      header_data: {
        site_name: get(this.props, "projectName", ""),
        subject,
        floor,
        formated_date: format(new Date(), "dd-MM-yyyy"),
      },
    };

    this.setState({ downloading: true });
    fetchBlobData(
      Urls.apiGetChecklistMicroplanningWiseExcel(),
      "POST",
      data,
      (res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${subject}.xlsx`);
        // have to add element to doc for firefox
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        this.setState({ downloading: false });
      },
      (err) => {
        this.setState({ downloading: false });
      }
    );
  };

  render = () => {
    const { fetched, error, checkItemsList, downloading } = this.state;
    const { userRole, checkDetails, projectId } = this.props;

    if (fetched) {
      const hasCheckList = Boolean(checkItemsList.length);

      if (hasCheckList) {
        return (
          <>
            <div className="checkitems-download">
              <LoadingButton
                className="download-btn primary-btn"
                isLoading={downloading}
                title="Download Report"
                onClick={this.onDownload}
              />
            </div>
            <div className="checkitems-table">
              {checkItemsList.map((checkData) => {
                return (
                  <div key={checkData.id} className="checkitems-row">
                    <div className="checkitems-col info-col">
                      {get(checkData, "checkitem_config.name", "-NA-")}
                    </div>
                    <div className="checkitems-col action-col">
                      {this.renderAction(checkData, userRole)}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="btn-wrapper">
              <Link
                to={Urls.getProjectPlanningChecklist(
                  projectId,
                  checkDetails.planning
                )}
                className="btn secondary-btn"
                style={{ maxWidth: "250px" }}
              >
                Update CheckList
              </Link>
            </div>
          </>
        );
      } else {
        return (
          <div className="empty-wrapper">
            No Checklist Found
            <div className="btn-wrapper">
              <Link
                to={Urls.getProjectPlanningChecklist(
                  projectId,
                  checkDetails.planning
                )}
                className="btn secondary-btn"
                style={{ maxWidth: "250px" }}
              >
                Update CheckList
              </Link>
            </div>
          </div>
        );
      }
    } else if (error) {
      return <div className="error-block big">Failed to fetch data</div>;
    } else {
      return <Loader />;
    }
  };

  renderAction = (checkData, userRole) => {
    if (checkData.is_checked) {
      return (
        <div className="checkitems-info">
          <div className="label">
            {" "}
            - {get(checkData, "approved_by.fullname", "-NA-")}
          </div>
          <MarkUpdate
            key={checkData.id}
            checkItemId={checkData.id}
            isChecked={checkData.is_checked}
            onMarkSuccess={this.onMarkSuccess}
          />
        </div>
      );
    } else if (userRole < 3) {
      return (
        <MarkUpdate
          key={checkData.id}
          checkItemId={checkData.id}
          isChecked={checkData.is_checked}
          onMarkSuccess={this.onMarkSuccess}
        />
      );
    }
  };
}

export default connect((store) => ({
  projectId: store.projectDetails.projectDetails.id,
  projectName: store.projectDetails.projectDetails.name,
  userRole: store.auth.userRole,
}))(QualityChecklist);

class MarkUpdate extends Component {
  /**
   * Parent:
   *      QualityChecklist
   */

  state = {
    loading: false,
  };

  handleClick = () => {
    if (this.state.loading) return;
    this.setState({ loading: true });

    postJsonData(
      Urls.apiUpdateCheckItemMark(this.props.checkItemId),
      { checkitem_id: this.props.checkItemId, is_mark: !this.props.isChecked },
      (res) => {
        this.setState({ loading: false });
        this.props.onMarkSuccess(res);
      },
      (err) => {
        this.setState({ loading: false });
      }
    );
  };

  render = () => {
    const { isChecked } = this.props;
    const { loading } = this.state;

    if (isChecked) {
      return (
        <div
          className={`checkitems-info-uncheck ${loading ? "loading" : ""}`}
          onClick={this.handleClick}
        >
          <CloseIcon />
          <span>{loading ? "Loading" : "Uncheck"}</span>
        </div>
      );
    } else {
      return (
        <RoundedButton
          icon={<CheckIcon />}
          className="btn primary-btn"
          isLoading={loading}
          onClick={this.handleClick}
        />
      );
    }
  };
}
