import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import { get } from 'lodash'

import ProjectPlanningList from './ProjectPlanningList'
import ProjectPlanningAdd from './ProjectPlanning/ProjectPlanningAdd'
import ProjectPlanningEdit from './ProjectPlanning/ProjectPlanningEdit'
import PlanningChecklist from './ProjectPlanning/PlanningChecklist'


import { getProjectDetails, resetProjectDetails } from '../project/data/project.actions'
import { getActivityList } from "./data/activity.actions";
import { getPlanningList, resetPlanningList } from "./data/planning.actions";


import Urls from '../UrlConfig'

export default connect(store => ({
    // project details
	projectFetching: store.projectDetails.fetching,
	projectFetched: store.projectDetails.fetched,
	projectFetchError: store.projectDetails.fetchError,
	projectFetchErrorObj: store.projectDetails.fetchErrorObj,
    projectDetails: store.projectDetails.projectDetails,
    // activity list
    activityFetched: store.activityList.fetched,
    logged: store.auth.logged
}), { getProjectDetails, resetProjectDetails, 
    getActivityList, getPlanningList, resetPlanningList })(props => {

    /**
     * wrapper component to fetch project details for planning list and form
     * 
     * Parent:
     *      App
     * 
     * Router:
     *      ProjectPlanningList
     *      ProjectPlanningAdd
     *      ProjectPlanningEdit
     *      PlanningChecklist
     */

    const projectId = get(props, 'match.params.projectId')

    useEffect(() => {
        props.getProjectDetails(projectId)
        props.getPlanningList(projectId)

        if(!props.activityFetched) {
            props.getActivityList()
        }

        return () => {
            props.resetProjectDetails()
            props.resetPlanningList()
        }
    }, [projectId])

    if(!props.logged) {
        return <Redirect to={Urls.getAuthPages('login')}/>
    }

    return (
        <Switch>
			<Route exact path={Urls.getProjectPlanningList()} component={ProjectPlanningList} />
			<Route exact path={Urls.getProjectPlanningAdd()} component={ProjectPlanningAdd} />
			<Route exact path={Urls.getProjectPlanningEdit()} component={ProjectPlanningEdit} />
			<Route exact path={Urls.getProjectPlanningChecklist()} component={PlanningChecklist} />
		</Switch>
    )
})