import React, { Component } from 'react'
import { connect } from 'react-redux'

import Loader from '../common/components/Loader'
import HocLoginRequired from '../common/components/HocLoginRequired'

import ProjectStructureReport from './components/ProjectStructureReport'
import ProjectActivityReport from './components/ProjectActivityReport'
import QualityReport from './components/QualityReport'
import EngineerReport from './components/EngineerReport'
import ChainSelectionBlock from '../common/components/ChainSelectionBlock'

import { fetchReportData } from "./data/reports.actions";
import { getProjectList } from '../project/data/project.actions'


/**
 * Scss:
 *      _reports
 */
class ReportPage extends Component {

  /**
   * fetch data from server
   * 
   * Parent:
   *      App
   * 
   * Renders:
   *      ChainSelectionBlock
   *      ProjectStructureReport
   *      ProjectActivityReport
   *      QualityReport
   */

  componentDidMount = () => {
    if (!this.props.projectListfetched) {
      this.props.getProjectList()
    }
  }

  handleDropDownData = (data) => {
    this.props.fetchReportData(data)
  }

  render = () => {
    const { projectList } = this.props

    return (
      <div className="report-page container">

        <div className="report-form-wrapper">
          <div className="report-form-select">
            <div className="report-form-title">Select Project</div>
            <ChainSelectionBlock data={projectList}
              onDone={this.handleDropDownData}
            />
          </div>
        </div>

        {this.renderReportData()}

      </div>
    )
  }

  renderReportData = () => {
    const { fetching, fetched, fetchError,
      reportData, reportType, reportChoice } = this.props

    if (!fetching && fetched) {
      if (reportType === 1) {
        if (reportChoice === 1) {
          return <ProjectStructureReport reportData={reportData} />
        }
        else if (reportChoice === 2) {
          return <ProjectActivityReport reportData={reportData} />
        }
      }
      else if (reportType === 2) {
        return <QualityReport reportData={reportData} />
      }
      else if (reportType === 3) {
        return <EngineerReport reportData={reportData} />
      }
    }
    else if (fetchError) {
      return (
        <div className="container">
          <div className="error-block big">Failed to fetch data</div>
        </div>
      )
    }
    else if (fetching) {
      return <Loader />
    }
  }
}

export default connect((store) => ({
  fetching: store.report.fetching,
  fetched: store.report.fetched,
  fetchError: store.report.error,
  reportType: store.report.reportType,
  reportChoice: store.report.reportChoice,
  reportData: store.report.reportData,

  projectListfetched: store.projectList.fetched,
  error: store.projectList.error,
  projectList: store.projectList.projectList,
  logged: store.auth.logged
}), { getProjectList, fetchReportData })(HocLoginRequired(ReportPage))


export const StatusInfo = ({ status }) => {
  switch (status) {
    case 'P': {
      return <span className="s-schedule">Scheduled</span>
    }
    case 'W': {
      return <span className="s-waiting">Waiting</span>
    }

    case 'R': {
      return <span className="s-running">Running</span>
    }

    case 'C': {
      return <span className="s-complete">Completed</span>
    }

    default: {
      return ''
    }
  }
}