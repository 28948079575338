import { getJsondata } from '../../common/utils'
import Urls from '../../UrlConfig'

///////////////////////////////////
//	    Other List action		//
/////////////////////////////////

export const getProjectMicroPlanningList = (projectId) => {

  return async dispatch => {
    dispatch({ type: 'MICRO_PLANNING_LIST_FETCHING' })

    getJsondata(Urls.apiGetProjectMicroPlanningList(projectId),
      (res) => {
        dispatch({ type: 'MICRO_PLANNING_LIST_SUCCESS', payload: res })
      },
      (err) => {
        // console.log("-> err", err)
        dispatch({ type: 'MICRO_PLANNING_LIST_ERROR', payload: err })
      }
    )
  }
}


///////////////////////////////////
//	 Project actions	    	//
/////////////////////////////////

/**
 * get project details
 * 
 * @param {*} projectId - project id
 */
export const getProjectDetails = (projectId) => {

  return async dispatch => {
    dispatch({ type: 'PROJECT_DETAILS_FETCHING' })

    getJsondata(Urls.apiGetProjectDetails(projectId),
      (res) => {
        dispatch({ type: 'PROJECT_DETAILS_SUCCESS', payload: res })
      },
      (err) => {
        dispatch({ type: 'PROJECT_DETAILS_ERROR', payload: err })
      }
    )
  }
}

export const resetProjectDetails = () => ({ type: 'RESET_PROJECT_DETAILS' })


/**
 * Get project list
 */
export const getProjectList = () => {

  return async dispatch => {
    dispatch({ type: 'PROJECT_LIST_FETCHING' })

    getJsondata(Urls.apiGetProjectList(),
      (res) => {
        dispatch({ type: 'PROJECT_LIST_SUCCESS', payload: res })
      },
      (err) => {
        // console.log("-> err", err)
        dispatch({ type: 'PROJECT_LIST_ERROR', payload: err })
      }
    )
  }
}