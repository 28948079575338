const INIT_PLANNING_LIST = {
    fetching: false,
    fetched: false,
    fetchError: false,
    fetchErrorObj: {},
	/** plannig list shape
	list of objects 
	[ {
		...planningData,
		micro_planning: [ {...microPlanning data} ],
		checklist: [ '', '' ]
	} ]
	 */
    planningList: [],
}


export const planningListReducer =  (state = INIT_PLANNING_LIST, { type, payload }) => {
    switch (type) {

        case 'PLANNING_LIST_FETCHING': {
            return { ...state, 
                fetching: true,
                error: false,
                errorMsg: {},
            }
        }

        case 'PLANNING_LIST_SUCCESS': {
            return { ...state,
                fetching: false,
                fetched: true,
                planningList: payload,
            }
        }

        case 'PLANNING_LIST_ERROR': {
            return { ...state, 
                fetching: false,
                fetched: false,
                error: true,
                errorMsg: payload
            }
        }

        case 'PLANNING_LIST_RESET': {
            return {...INIT_PLANNING_LIST}
        }

        case "USER_LOGOUT": {
            return INIT_PLANNING_LIST
        }

        default: {
            return state
        }
    }
}

const INIT_PLANNING_DETAILS = {
    fetching: false,
    fetched: false,
    fetchError: false,
    fetchErrorObj: {},
	/** plannig details shape
	list of objects 
	{
		...planningData,
		micro_planning: [ {...microPlanning data} ],
		checklist: [ '', '' ]
	}
	 */
    planningDetails: {},
}

export const planningDetailsReducer =  (state = INIT_PLANNING_DETAILS, { type, payload }) => {
    switch (type) {

        case 'PLANNING_DETAILS_FETCHING': {
            return { ...state, 
                fetching: true,
                fetched: false,
                error: false,
                errorMsg: {},
            }
        }

        case 'PLANNING_DETAILS_SUCCESS': {
            return { ...state,
                fetching: false,
                fetched: true,
                planningDetails: payload,
            }
        }

        case 'PLANNING_DETAILS_ERROR': {
            return { ...state, 
                fetching: false,
                fetched: false,
                error: true,
                errorMsg: payload
            }
        }

        case "USER_LOGOUT": {
            return INIT_PLANNING_DETAILS
        }

        default: {
            return state
        }
    }
}