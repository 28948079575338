import React, { Component } from 'react'
import { connect } from 'react-redux'

import ProjectSummeryList from './ProjectSummeryList'
import HocLoginRequired from '../../common/components/HocLoginRequired'


/**
 * Scss:
 *      /common/_dashboard_page.scss
 */

class DashboardPage extends Component {
    /**
    * Parent page component
    * 
    * Parent:
    * 		App
    * 
    * Renders:
    *      ProjectSummeryList
    *      ActivityLogs
    */

    render = () => {
        return (
            <div id="dashboard">
                <div className="page-content-wrapper container">
                    <ProjectSummeryList />
                </div>
            </div>
        )
    }
}


export default connect(store => ({
    logged: store.auth.logged
}))(HocLoginRequired(DashboardPage))