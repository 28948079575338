import React from 'react'
import { get } from "lodash";
import { Link } from 'react-router-dom'

import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import ForgetPass from "./pages/ForgetPass";

import PageNotFound from "../common/pages/PageNotFound";
import UnAuthorizedPage from "../common/pages/UnAuthorizedPage";
import Notification from "../common/components/Notification";

import Urls from '../UrlConfig';

/**
 * Scss:
 * 		_auth_page.scss
 */

export default (props) => {

	/**
	 * Parent : 
	 *      App
	 * 
	 * Renders: 
	 *      LoginPage
	 * 		SignupPage
	 *      ForgetPass
	 *      PageNotFound
	 * 		UnAuthorizedPage
	 */

	const auth_type = get(props, 'match.params.auth_type', 'login')
	const from_route = get(props, 'location.state.from.pathname')

	let page;

	switch (auth_type) {
		case 'login':
			page = <LoginPage from_route={from_route} />;
			break;

		case 'signup':
			page = <SignupPage from_route={from_route} history={props.history} />;
			break;

		case 'forget-password':
			page = <ForgetPass />;
			break;

		// when user tries to go to admin pages
		// redirect to this page
		case 'unauthorized':
			page = <UnAuthorizedPage />
			break;

		default:
			return <PageNotFound />
	}

	return (
		<div className="auth-page">
			<Notification />
			<div className="shadow" />
			<div className="login-page">
				<div className="login-page-content">
					<div className="heading hide-on-small-and-down">
						<div className="text">
							An innovation centric group with over 30 years experience
						</div>
						<div className="store-icons">
							<a className="store-link" href="https://play.google.com/store/apps/details?id=com.chavda_erp"
								target="_blank" without rel="noopener noreferrer">
								<img className="store-google" src={Urls.getStaticImg('img/google300.png')} alt="Google" />
							</a>
							<a className="store-link" href="https://apps.apple.com/us/app/chavda-erp/id1555241125"
								target="_blank" without rel="noopener noreferrer">
								<img src={Urls.getStaticImg('img/apple512.png')} alt="Apple" />
							</a>
						</div>
					</div>
					<div className="form-block">
						{page}
						<div className="privacy-policy-link">
							<Link to={Urls.getPrivacyPage()}>Read Privacy Policy</Link>
						</div>
					</div>
					<div className="heading show-on-small" style={{ display: 'none' }}>
						<div className="store-icons">
							<a className="store-link" href="https://play.google.com/store/apps/details?id=com.chavda_erp"
								target="_blank" without rel="noopener noreferrer">
								<img className="store-google" src={Urls.getStaticImg('img/google300.png')} alt="Google" />
							</a>
							<a className="store-link" href="https://apps.apple.com/us/app/chavda-erp/id1555241125"
								target="_blank" without rel="noopener noreferrer">
								<img src={Urls.getStaticImg('img/apple512.png')} alt="Apple" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}