import React from 'react'
import { Link } from 'react-router-dom'

import { get } from 'lodash'

import { formatDate } from "../../../common/utils";

import Urls from '../../../UrlConfig'


/**
 * Scss:
 * 		project/_project_planning_list.scss
 */

/**
 * mcPlanning: microplanning obj
 */
export default ({ mcPlanning }) => {

  /**
   * show microplanning details.
   * on details btn click, show full screen model, model id set in reducer
   * 
   * Parent:
   * 		PlanningList
   */

  const activityName = get(mcPlanning, 'activity.name')
  const subActName = get(mcPlanning, 'subactivity.name')
  const showBeforeTime = mcPlanning.completion_status === "B"
  const showDelayTime = mcPlanning.completion_status === "D"
  const differenceInDays = Math.abs(get(mcPlanning, 'difference_in_days'))

  return (
    <div className="pd-pln-col-card" key={mcPlanning.id}>
      <div className="pd-pln-col-card-title">
        {activityName} - {subActName}
      </div>
      <div className="pd-pln-col-card-desc">
        {get(mcPlanning, 'unit.name', '')} &gt; {get(mcPlanning, 'subunit.name', '')}
      </div>
      <div className="pd-pln-col-card-desc">
        From {formatDate(new Date(mcPlanning.start_date))} :-
        To {formatDate(new Date(mcPlanning.end_date))}
      </div>
      <div className="pd-pln-col-card-action">
        <Link to={Urls.getProjectDetails(mcPlanning.project.id, 'planning', mcPlanning.id)}
          className="action-btn">
          Edit Details
        </Link>
      </div>

      {showBeforeTime && Boolean(differenceInDays) &&
        <div className="pd-pln-col-card-status before">
          <div className="text">{differenceInDays} days early</div>
          <div className="ind"></div>
        </div>
      }

      {showDelayTime && Boolean(differenceInDays) &&
        <div className="pd-pln-col-card-status delay">
          <div className="text">{differenceInDays} days late</div>
          <div className="ind"></div>
        </div>
      }

    </div>
  )
}