import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { get } from "lodash";
import Fuse from "fuse.js";

import LoadingButton, {
  RoundedButton,
} from "../../common/components/LoadingButton";
import CustomImage from "../../common/components/CustomImage";
import Loader from "../../common/components/Loader";
import HocLoginRequired from "../../common/components/HocLoginRequired";
import { CustomSearchBox } from "../../common/components/CustomInput";
import { ProjectMore, CloseIcon, PlusIcon } from "../../common/SvgIcons";

import { getProjectList, resetProjectDetails } from "../data/project.actions";

import Urls from "../../UrlConfig";

/**
 * Scss:
 * 		project/_project_list.scss
 * Common:
 * 		buttons, layout, _common_page_layout
 */

class ProjectListPage extends Component {
  /**
   * fetch data component
   *
   * Parent:
   * 		AdminHomePage
   *
   * Renders:
   *      ProjectList
   */

  state = {
    searchToken: "",
  };

  componentDidMount = () => {
    if (!this.props.fetched) {
      this.props.getProjectList();
    }

    // when user back to project list
    this.props.resetProjectDetails();
  };

  handleSearchText = (e) => {
    this.setState({ searchToken: e.target.value });
  };

  render = () => {
    const { userRole, isMobile } = this.props;
    const { searchToken } = this.state;

    return (
      <div id="project-list-page">
        <div className="page-banner-wrapper">
          <div
            className="page-banner-image"
            style={{ backgroundImage: `url(${Urls.getDefaultBanner()})` }}
          />
          <div className="page-banner-gradient" />
          <div className="container">
            <div className="page-header">Project Listing</div>
          </div>
        </div>
        <div className="container main-page-content">
          <div
            className={`pl-action-container ${
              isMobile ? "pl-mobile-action-container" : ""
            }`}
          >
            <div className="pl-left-wrapper">
              <div className="page-action-wrapper">
                <CustomSearchBox
                  type="text"
                  placeholder="Search by Project name"
                  value={searchToken}
                  onChange={this.handleSearchText}
                />
              </div>
            </div>
            {userRole === "1" && (
              <div className="pl-right-wrapper">
                <div className="add-btn-wrapper">
                  <Link to={Urls.getProjectForm("add", "new")}>
                    {isMobile ? (
                      <RoundedButton
                        className="btn primary-btn"
                        icon={<PlusIcon />}
                      />
                    ) : (
                      <LoadingButton
                        className="btn primary-btn"
                        title="+ Create Project"
                      />
                    )}
                  </Link>
                </div>
                <div className="add-btn-wrapper">
                  <Link to={Urls.getDemoPage()}>
                    {isMobile ? (
                      <RoundedButton
                        className="btn primary-btn"
                        icon={<PlusIcon />}
                      />
                    ) : (
                      <LoadingButton
                        className="btn primary-btn"
                        icon={<i class="fa fa-desktop" aria-hidden="true"></i>}
                        title="Map"
                      />
                    )}
                  </Link>
                </div>
              </div>
            )}
          </div>
          {this.renderProjectList()}
        </div>
      </div>
    );
  };

  renderProjectList = () => {
    const { fetched, error, projectList, userRole } = this.props;
    const { searchToken } = this.state;

    if (fetched) {
      return (
        <div className="project-list-wrapper">
          <ProjectList
            projectList={projectList}
            searchToken={searchToken}
            userRole={userRole}
          />
        </div>
      );
    } else if (error) {
      return (
        <div className="container">
          <div className="error-block big">Failed to fetch data</div>
        </div>
      );
    } else {
      return <Loader />;
    }
  };
}

export default connect(
  (store) => ({
    fetched: store.projectList.fetched,
    error: store.projectList.error,
    projectList: store.projectList.projectList,
    userRole: store.auth.userRole,
    logged: store.auth.logged,
    isMobile: store.pageSize.isMobile,
  }),
  { getProjectList, resetProjectDetails }
)(HocLoginRequired(ProjectListPage));

/**
 * render list project, can to go to view, edit project
 *
 * @param {*} projectList : [{id, name, units: list of units,}, ...]
 *
 * Parent :
 *      ProjectList
 */
class ProjectList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAction: null,
    };

    const search_opts = {
      shouldSort: true,
      tokenize: true,
      findAllMatches: true,
      threshold: 0.8,
      location: 0,
      distance: 32,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["name"],
    };

    this.search = new Fuse(props.projectList, search_opts);
  }

  handleProjectOptionsClick = (projectId) => (e) => {
    if (e) e.stopPropagation();

    if (this.state.isAction === projectId) {
      this.setState({ isAction: null });
    } else {
      this.setState({ isAction: projectId });
    }
  };

  renderSettingsBtn = (settngsOpen, projectId) => {
    const { userRole } = this.props;

    if (userRole < 3) {
      if (settngsOpen) {
        // show close btn
        return (
          <div
            className="project-action-icon"
            onClick={this.handleProjectOptionsClick(projectId)}
          >
            <CloseIcon />
          </div>
        );
      } else {
        return (
          <div
            className="project-action-icon"
            onClick={this.handleProjectOptionsClick(projectId)}
          >
            <ProjectMore />
          </div>
        );
      }
    }
  };

  getProjectEndMsg = (complitionValue) => {
    if (complitionValue) {
      if (complitionValue === 0) {
        return `Project will complete today`;
      } else if (complitionValue > 0) {
        return `Project completion in ${complitionValue} days`;
      } else if (complitionValue < 0) {
        return `Project was completed ${Math.abs(complitionValue)} day ago`;
      }
    } else {
      return `Project not planned yet`;
    }
  };

  render = () => {
    const { projectList, searchToken } = this.props;
    const { isAction } = this.state;

    let filteredProjectList;
    if (searchToken.length > 2) {
      filteredProjectList = this.search.search(searchToken).map((d) => d.item);
    } else {
      filteredProjectList = projectList;
    }

    if (filteredProjectList.length) {
      return (
        <div className="project-list-container">
          {filteredProjectList.map((project) => {
            const settingsOpen = isAction === project.id;

            return (
              <div className="project-block" key={project.id}>
                {this.renderSettingsBtn(settingsOpen, project.id)}

                <div
                  className={`pb-wrapper ${settingsOpen ? "move-card" : ""}`}
                >
                  <Link
                    className="pb-inner-block"
                    to={Urls.getProjectDetails(project.id, "planning")}
                  >
                    <div className="pb-img">
                      <CustomImage
                        imageUrl={project.image}
                        title={project.name}
                      />
                    </div>
                    <div className="pb-content">
                      <div className="pb-title">{project.name}</div>
                      <div className="pb-table">
                        <div className="pb-table-row">
                          {this.getProjectEndMsg(
                            project.project_completion_days
                          )}
                        </div>
                        <div className="pb-table-row">
                          Running Tasks :{" "}
                          {get(project, "project_summery.running_tasks")}
                        </div>
                        <div className="pb-table-row">
                          Completed Tasks :{" "}
                          {get(project, "project_summery.completed_tasks")}
                        </div>
                      </div>
                    </div>
                  </Link>

                  <div className="pb-inner-block">
                    <div className="p-actions">
                      <Link to={Urls.getProjectPlanningList(project.id)}>
                        View Planning &gt;
                      </Link>

                      <Link to={Urls.getProjectForm(project.id, "structure")}>
                        Edit Project &gt;
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="empty-page-wrapper">
          <img src={Urls.getStaticImg("/img/no-projects.svg")} alt="" />
          <div className="text">No projects to show !!</div>
        </div>
      );
    }
  };
}
