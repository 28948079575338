import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import CustomInput from '../../common/components/CustomInput'
import LoadingButton from '../../common/components/LoadingButton'
import ErrorBlock from '../../common/components/ErrorBlock'
import CheckBox from '../../common/components/CheckBox'

import { getInvitationList } from '../data/invitation.actions'
import { addNotification } from '../../common/data/common.actions'

import { postJsonData } from '../../common/utils'
import Urls from '../../UrlConfig'

/**
 * Scss:
 *      _common_page_layout, _user_form, forms
 */

class InvitationForm extends Component {

    /**
     * form to invite user
     * 
     * Parent:
     *      InvitationListPage
     */

    constructor(props) {
        super(props)

        this.state = {
            email: '',
            role: null, // 1, 2, 3
            loading: false,
            error: {},
        }
    }

    submit = (event) => {
        const { loading, email, role } = this.state
        if (loading) return
        event.preventDefault()

        let error = {}, has_error = false
        if(!email) {
            error['email'] = ['this field is required']
            has_error = true
        }
        if(!role) {
            error['role'] = ['please select role']
            has_error = true
        }

        if(has_error) {
            this.setState({ error })
            this.props.addNotification({
                type: 'error',
                title: 'Invitation',
                text: 'Input error',
            })
            return
        }

        this.setState({ loading: true, error: {} })

        postJsonData(Urls.apiUserInvite(),
            { email, role },
            (res) => {
                this.setState({ loading: false, error: {} })
                this.props.closeModel()
                this.props.getInvitationList()
                this.props.addNotification({
                    type: 'success',
                    title: 'Invitation',
                    text: `Invitation send successfully`,
                })
            },
            (err) => {
                this.setState({ loading: false, error: err.error_data })
                this.props.addNotification({
                    type: 'error',
                    title: 'Invitation',
                    text: 'Input error',
                })
            }
        )
    }

    handleRoleClick = (role) => () => {
        if (this.state.role === role) {
            this.setState({ role: null })
        }
        else {
            this.setState({ role })
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name] : e.target.value })
    }

    render = () => {
        const { error, loading, role, email } = this.state
        const { closeModel } = this.props

        return (
            <div className="model-form">
                <div className="close-icon-wrapper" onClick={closeModel}>
                    <div className="close-icon"></div>
                </div>
                <div className="title">Invite User</div>

                <CustomInput
                    label="Email Address"
                    errorData={error}
                    errorField="email"
                    type="text" name="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={this.handleChange}
                />

                <div className="checkbox-group">
                    <div className="checkbox-group-label">User Role</div>
                    <CheckBox label="Admin" onClick={this.handleRoleClick(1)} isChecked={role === 1} />
                    <CheckBox label="Project Lead" onClick={this.handleRoleClick(2)} isChecked={role === 2} />
                    <CheckBox label="Project Member" onClick={this.handleRoleClick(3)} isChecked={role === 3} />
                </div>
                <ErrorBlock error={error} field="role" />

                <ErrorBlock error={error} field="__all__" />

                <div className="multi-action">
                    <LoadingButton className="btn primary-btn" title="Invite User"
                        isLoading={loading} onClick={this.submit} />
                </div>

            </div>
        )
    }
}

export default connect(null, { getInvitationList, addNotification })(InvitationForm)

InvitationForm.propTypes = {
    closeModel: PropTypes.func.isRequired
}