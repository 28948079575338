import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { startsWith, get } from 'lodash'

import { RightSideArrow } from '../SvgIcons'
import LogOutBtn from '../../auth/components/LogOutBtn'

import Urls from '../../UrlConfig'

/**
 * Scss
 *      _navbar.scss
 */

class NavBar extends Component {

    /**
     * Parent:
     *      Layout
     */

    state = {
        showMenu: false,
        showSettingSubMenu: false
    }

    toggleMenu = () => {
        this.setState({ showMenu: !this.state.showMenu })
    }

    toggleSettingMenu = () => {
        this.setState({ showSettingSubMenu: !this.state.showSettingSubMenu })
    }

    render = () => {
        const { isMobile, role_name, userRole, logged } = this.props
        const { showMenu, showSettingSubMenu } = this.state

        if (isMobile) {
            return (
                <nav>
                    <div className="nav-container nav-sidebar-container">
                        <div className="burger-menu-wrapper">
                            <div className={`burger-menu ${showMenu ? "active" : ""}`}
                                onClick={this.toggleMenu}>
                                <div />
                                <div />
                                <div />
                            </div>
                        </div>
                        <div className="nav-brand--mobile">
                            <span>CHAVDA ERP</span>
                        </div>
                    </div>
                    {showMenu && <div className="overlay" onClick={this.toggleMenu} />}
                    <div className={`nav-sidebar-wrapper ${showMenu ? "slide-in" : "slide-out"}`}>
                        {logged ?
                            <div className="nav-sidebar-content-wrapper">
                                <div className="avatar-wrapper">
                                    <div className="profile-img-circle"></div>
                                    <div className="username">
                                        {get(this.props, 'first_name')}
                                    </div>
                                    <div className="userrole">
                                        <span>{get(this.props, 'role_name')}</span>
                                    </div>
                                </div>
                                <div className="nav-sidebar">
                                    <NavLink exact activeClassName="active"
                                        className="nav-sidebar-tab"
                                        to={Urls.getDashboardPage()}
                                        onClick={this.toggleMenu}>
                                        Dashboard
                                    </NavLink>
                                    <NavLink activeClassName="active"
                                        className="nav-sidebar-tab"
                                        to={Urls.getProjectPage()}
                                        onClick={this.toggleMenu}>
                                        Projects
                                    </NavLink>
                                    {userRole < 3 &&
                                        <>
                                            <NavLink activeClassName="active"
                                                className="nav-sidebar-tab"
                                                to={Urls.getAdminHomePage(userRole === '1' ? 'holiday' : 'activity')}
                                                isActive={(_, location) => {
                                                    return startsWith(location.pathname, '/admin/')
                                                }}
                                                onClick={this.toggleMenu}>
                                                Admin
                                            </NavLink>
                                            <NavLink activeClassName="active"
                                                className="nav-sidebar-tab"
                                                to={Urls.getReportsPage()}
                                                onClick={this.toggleMenu}>
                                                Reports
                                            </NavLink>
                                        </>
                                    }

                                    <div className="nav-expand-menu">
                                        <div className={`nav-sidebar-tab nav-expand-menu-tab ${showSettingSubMenu ? "active" : ""}`}
                                            onClick={this.toggleSettingMenu}>
                                            <div className="nav-label">Settings</div>
                                            <div className="nav-icon"><RightSideArrow /></div>
                                        </div>
                                        <div className={`nav-expand-submenu ${showSettingSubMenu ? "show" : ""}`}>
                                            {showSettingSubMenu &&
                                                <>
                                                    <NavLink activeClassName="active"
                                                        className="nav-sidebar-tab"
                                                        to={Urls.getProfilePage()}
                                                        onClick={this.toggleMenu}>
                                                        User Profile
                                                    </NavLink>
                                                    <div className="nav-sidebar-tab logout-tab">
                                                        <LogOutBtn />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="nav-sidebar-content-wrapper">
                                <div className="avatar-wrapper">
                                    <div className="username">
                                        Hi, user
                                    </div>
                                </div>
                                <div className="nav-sidebar">
                                    <NavLink exact activeClassName="active"
                                        className="nav-sidebar-tab"
                                        to={Urls.getAuthPages('login')}
                                        onClick={this.toggleMenu}>
                                        Login
                                    </NavLink>
                                </div>
                            </div>
                        }
                    </div>
                </nav>
            )
        }
        else {
            return (
                <nav>
                    <div className="nav-container">
                        <div className="nav-brand">
                            <span>CHAVDA ERP</span>
                        </div>
                        {logged ?
                            <>
                                <div className='nav-user'>

                                    <div className='nav-user-content'>
                                        <div className='nav-user-info'>
                                            <div className="circle">{get(this.props, 'first_name.0', 'C')}</div>
                                        </div>
                                        <div className='nav-user-info'>
                                            <div className="username">
                                                {get(this.props, 'first_name')} {get(this.props, 'last_name')}
                                            </div>
                                            <div className="user-role">
                                                {role_name}
                                            </div>
                                        </div>
                                        <div className='nav-user-info'>
                                            <div className="down-arw"></div>
                                        </div>
                                    </div>

                                    <div className="nav-dropdown">
                                        <Link to={Urls.getProfilePage()} className="submenu-wrap">
                                            <div className="submenu border-bot">
                                                <div>User Profile</div>
                                            </div>
                                        </Link>
                                        <div className="submenu-wrap">
                                            <div className="submenu">
                                                <LogOutBtn />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {userRole < 3 &&
                                    <>
                                    <NavLink activeClassName="active" className="nav-tab" exact
                                        to={Urls.getDashboardPage()}>
                                        Dashboard
                                    </NavLink>
                                    <NavLink activeClassName="active" to={Urls.getReportsPage()} className="nav-tab" >
                                        Reports
                                    </NavLink>
                                    <NavLink activeClassName="active" className="nav-tab"
                                        to={Urls.getAdminHomePage(userRole === '1' ? 'holiday' : 'activity')}
                                        isActive={(_, location) => {
                                            return startsWith(location.pathname, '/admin/')
                                        }}>
                                        Admin
                                    </NavLink>
                                    </>
                                }
                                <NavLink activeClassName="active" to={Urls.getProjectPage()} className="nav-tab">
                                    Projects
                                </NavLink>
                            </>
                            :
                            <NavLink activeClassName="active" to={Urls.getAuthPages('login')} className="nav-tab">
                                Login
                            </NavLink>
                        }
                    </div>
                </nav>
            )
        }
    }
}

export default connect(store => ({
    userRole: store.auth.userRole,
    logged: store.auth.logged,
    role_name: store.loggedUser.role_name,
    image: store.loggedUser.image,
    first_name: store.loggedUser.first_name,
    last_name: store.loggedUser.last_name,
    isMobile: store.pageSize.isMobile,
}))(NavBar)