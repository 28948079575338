import {pick} from 'lodash'

const INIT_AUTH = {
    fetched: false,
    fetching: false,
    error: false,
    error_obj: {},

    logged: false,
    verified : false,
    is_first_login: true,
    // token and details
    token: '',
    user : null, // user pk
    userRole: null,
    userRoleDisplay: null
}


export const authReducer = (state = INIT_AUTH, action) => {

    switch (action.type) {
        case "USER_LOGGING_IN": {
            return {
                ...state,
                fetched: false,
                fetching: true,
                error: false,
                error_obj: {},

                logged: false,
                token: null,
                userRole: null
            }
        }

        case "USER_LOGGING_SUCCESS": {
            const { token, user } = action.payload
            const { verified, is_first_login, 
                role, id, role_name } = pick(user, ['verified', 'is_first_login', 'role', 'id', 'role_name'])

            return {
                ...state,
                fetched: true,
                fetching: false,
                error: false,
                error_obj: {},
                
                logged: true,
                verified, is_first_login, 
                userRole: role,
                user: id, userRoleDisplay: role_name,
                // sometimes this state needs to update from simple api hits
                // thos api will not return token, so take state token for that
                token: token || state.token,
            }
        }

        case "USER_LOGIN_FAILED": {
            return {
                ...state,
                fetched: true,
                fetching: false,
                error: true,
                error_obj: action.payload
            }
        }

        case "USER_LOGOUT": {
            return INIT_AUTH
        }

        default: {
            return state;
        }
    }
}