import React, { Component } from 'react'

import { getJsondata, formatDateTime } from '../../../common/utils'
import Urls from '../../../UrlConfig'

/**
 * Scss
 *      _project_planning_details
 */

export default class ScheduleList extends Component {

  /**
   * fetch list from server
   * loading and error manage
   * 
   * Parent:
   *      ProjectPlanningPopup
   */

  state = {
    fetching: false,
    fetched: false,
    error: false,
    errorMsg: {},

    scheduleList: [],
  }

  componentDidMount = () => {
    this.getScheduleList()
  }

  getScheduleList = () => {
    this.setState({ fetching: true, error: false, errorMsg: {} })

    getJsondata(Urls.apiGetMplScheduleList(this.props.mcPlanningId),
      (res) => {
        this.setState({
          fetching: false, fetched: true,
          scheduleList: res
        })
      },
      (err) => {
        this.setState({
          fetching: false, fetched: false,
          error: true, errorMsg: err,
        })
      }
    )
  }

  render = () => {
    const { fetching, error, scheduleList } = this.state

    let content

    if (fetching) {
      content = <h1>Loading</h1>
    }
    else if (error) {
      content = <h1>Failed to fetch data</h1>
    }

    else {
      content = (
        <table className="pd-schedule-list-wrapper"><tbody>
          {scheduleList.length ?
            scheduleList.map((schedule, i) => {
              const blockClass = schedule.work_on ? 'on pd-schedule-list-block-indicator' : 'off pd-schedule-list-block-indicator'

              return (
                <tr key={schedule.id} className='pd-schedule-list-block' >
                  <td className={blockClass}></td>

                  <td className='pd-schedule-list-block-content'>
                    <div className='cell-pill-row date'>
                      {(!!schedule.is_ongoing) ?
                        // first schedule if on going show end date as today
                        // else show actual end date
                        'On Going'
                        :
                        i === 0 ? formatDateTime(schedule.end_date) : ''
                      }
                    </div>
                    <div className='remark'>
                      {Boolean(schedule.remarks) &&
                        <span>{schedule.remarks}</span>
                      }
                    </div>
                    <div className='cell-pill-row date'>
                      {formatDateTime(schedule.start_date)}
                    </div>
                  </td>
                </tr>
              )
            })
            :
            <tr>
              <td><h3>No work history registered yet</h3></td>
            </tr>
          }
        </tbody></table>
      )
    }

    return (
      <div>
        <h2 className="schedule-title">Work History</h2>
        <div>
          {content}
        </div>
      </div>
    )
  }
}