import React from 'react'

export default ({ status, wrapperClassName = "" }) => {

  let statusClass
  if (status === "R" || status === "W") {
    statusClass = "green"
  } else if (status === "C") {
    statusClass = "yellow"
  } else {
    statusClass = ""
  }

  return (
    <div className={`report-checkbox-wrapper ${statusClass} ${wrapperClassName}`}>
      <div className="checkbox-circle" />
    </div>
  )
}
