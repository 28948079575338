import React from 'react'
import { connect } from 'react-redux';

import { get, find } from "lodash";

import ErrorBlock from '../../common/components/ErrorBlock'
import LoadingButton from '../../common/components/LoadingButton'
import { CloseIcon, CheckIcon } from '../../common/SvgIcons'
import CustomInput from '../../common/components/CustomInput'

import { getActivityList } from '../data/activity.actions'
import { addNotification } from '../../common/data/common.actions'

import { postJsonData, getJsondata } from "../../common/utils";
import Urls from "../../UrlConfig";

/**
 * Scss
 * 		_activity_list
 * 
 * Common
 * 		_common_page_layout
 */

class ActivityFormWrapper extends React.Component {
	/**
	 * Data wrapper component to decide form is add or edit
	 * 
	 * Parent
	 * 		ActivityListPage
	 * 
	 * Renders:
	 * 		ActivitForm
	 */

	constructor(props) {
		super(props)
		this.isAdd = props.activityId === -1
	}

	handleSuccess = () => {
        this.props.closeModel()
        this.props.getActivityList()
    }

	render = () => {
		const { closeModel, activityList, activityId } = this.props

		const activityData = find(activityList, ['id', activityId])
		return (
			<div className="activity-model-wrapper">
				<div className="close-icon-wrapper" onClick={closeModel}>
					<CloseIcon />
				</div>
				<ActivitForm data={activityData} isAdd={this.isAdd} 
					closeModel={closeModel}
					onSubmitSuccess={this.handleSuccess}
					addNotification={this.props.addNotification}
				/>
			</div>
		)
	}
}

export default connect(store => ({
	activityList: store.activityList.activityList
}), {getActivityList, addNotification})(ActivityFormWrapper)

class ActivitForm extends React.Component {

	/**
	 * Props:
	 * 		isAdd: Boolean
	 * 		data: { id, name, subactivity: [{id, name},...] }
	 * 
	 * Parent:
	 * 		ActivityFormWrapper
	 */

	constructor(props) {
		super(props)
		
		if(props.isAdd) {
			this.state = {
				name: '',
				subactivityList: [],
				deletedSubIds: [],
				error: {},
				deleting: false,
				submitting: false
			}
		} else {
			this.state = {
				id: props.data.id,
				name: props.data.name,
				subactivityList: [...props.data.subactivity],
				deletedSubIds: [],
				error: {},
				deleting: false,
				submitting: false
			}
		}
	}

	submitActivity = () => {
		const {id, name, subactivityList, deletedSubIds} = this.state

		let hasError = false, errorData = {}
        for (let index = 0; index < subactivityList.length; index++) {
            const currAct = subactivityList[index];
            if(currAct.name) { } 
            else {
                errorData[index] = ["This field cannot be empty."]
                hasError = true
            }
		}
		if(hasError) {
            this.setState({ error: errorData })
            return
		}
		
		// add index in data
		const newSubactivityList = subactivityList.map((currAct, ind) => {
			return {...currAct, index: ind}
		})

		this.setState({ error: {}, submitting: true })
		postJsonData(Urls.apiUpsertActivity(),
			{id, name, 
				subactivity: [...newSubactivityList], 
				deleted_subactivity: [...deletedSubIds]
			},
			(res) => {
				this.props.onSubmitSuccess()
				this.props.addNotification({
					type: 'info',
                    icon: <CheckIcon/>,
                    title: this.props.isAdd ? "Activity Create" : "Activity Update",
                    text: `${name} ${this.props.isAdd ? "created" : "updated"} successfully`,
				})
			},
			(err) => {
				this.setState({ error: err.error_data, submitting: false })
				this.props.addNotification({
                    type: 'error',
                    title: this.props.isAdd ? "Activity Create" : "Activity Update",
                    text: 'input error',
                })
			},
		)
	}

	handleDelete = () => {
		if(this.state.deleting) return

		this.setState({ deleting: true})

		getJsondata(Urls.apiGetActivityDelete(this.props.data.id),
			(res) => {
				this.props.onSubmitSuccess()
				this.props.addNotification({
					type: 'info',
                    icon: <CheckIcon/>,
                    title: "Activity Delete",
                    text: `Activity deleted successfully`,
				})
			},
			(err) => {
				this.setState({ deleting: false })
				this.props.addNotification({
                    type: 'error',
                    title: "Activity Delete",
                    text: 'input error',
                })
			}
		)
	}

	handleActivityNameChange = (e) => {
		this.setState({ name: e.target.value })
	}

	handleAddSubactivityForm = () => {
		const newSubAct = {name: '', index: ''}
		const currSubactList = [...this.state.subactivityList]

		this.setState({subactivityList: [...currSubactList, newSubAct]})
	}

	handleSubActivityNameChange = (index) => (e) => {
		const newSubActList = [...this.state.subactivityList]
		newSubActList[index] = {
			...newSubActList[index],
			name: e.target.value
		}
		this.setState({subactivityList: newSubActList})
	}

	handleSubActivityIndexChange = (index) => (e) => {
		const newSubActList = [...this.state.subactivityList]
		newSubActList[index] = {
			...newSubActList[index],
			index: e.target.value
		}
		this.setState({subactivityList: newSubActList})
	}

	// in edit, remove will update data only no delete from backend
	handleRemoveSubActivity = (index, subActivity) => () => {
		const newSubActList = [...this.state.subactivityList]
		newSubActList.splice(index, 1)

		const currDeletedSubIds = [...this.state.deletedSubIds]
		// if subactivity have id, then only delete
		if(get(subActivity, 'id', null)) {
			currDeletedSubIds.push(subActivity.id)
		}

		this.setState({subactivityList: newSubActList, 
			deletedSubIds: currDeletedSubIds, error: {} })
	}

	handleMoveUp = (index) => () => {
		// cant move if ele on top
		if (index === 0) return

		let newSubActList = [...this.state.subactivityList]
		const currSubAct = newSubActList[index]
		const prevSubAct = newSubActList[index - 1]
		// exchange current and upper index data
		newSubActList[index] = prevSubAct
		newSubActList[index - 1] = currSubAct

		this.setState({subactivityList: newSubActList})

	}

	handleMoveDown = (index) => () => {
		// cant move if ele on bottom
		if (index === this.state.subactivityList.length - 1) return

		let newSubActList = [...this.state.subactivityList]
		const currSubAct = newSubActList[index]
		const nextSubAct = newSubActList[index + 1]
		// exchange current and down index data
		newSubActList[index] = nextSubAct
		newSubActList[index + 1] = currSubAct

		this.setState({subactivityList: newSubActList})
	}

	render = ()=> {
		const {subactivityList, name, error, 
			deleting, submitting } = this.state
		const { closeModel, isAdd } = this.props

		return(
			<div className="form-wrapper">
				<div className="form-label">Activity</div>

				<CustomInput label="Activity Name"
					type="text"
					placeholder="Enter Activity Name"
					value={name} 
					onChange={this.handleActivityNameChange}
					errorField="activity.name" errorData={error}
				/>

				<div className="form-label sub-title">Sub Activities</div>

				{subactivityList.map((subActivity, ind) => {
					return (
						<div className="subAct-block" key={ind}>
							<div className="arrow-block">
								<div className="arrow-up" onClick={this.handleMoveUp(ind)}></div>
								<div className="arrow-down" onClick={this.handleMoveDown(ind)}></div>
							</div>
							<div className="ind-text">{ind+1}</div>
							<div className="form-input">
								<input type="text"
									placeholder="Enter Sub Activity Name"
									value={subActivity.name}
									onChange={this.handleSubActivityNameChange(ind)}
								/>
								<ErrorBlock error={error} field={String(ind)} />
							</div>
							<div className="remove-text" onClick={this.handleRemoveSubActivity(ind, subActivity)}>Remove</div>
						</div>
					)
				})}

				<div className="add-sub-btn" onClick={this.handleAddSubactivityForm}>
					<div className="add-sub-btn-text">Add Sub Activity</div>
				</div>

				<div className="form-action-btn">
					<div className="action left-ac">
						<LoadingButton className="btn primary-btn" 
							isLoading={submitting}
							title={isAdd ? "Save Activity" : "Update Activity"}
							onClick={this.submitActivity} />
					</div>
					<div className="action right-ac">
						<LoadingButton className="btn secondary-btn" 
							title="Cancel"
							onClick={closeModel} 
						/>
					</div>
				</div>

				{!isAdd &&
					<div className="delete-action" onClick={this.handleDelete}>
						{deleting ? "Deleting..." : "Delete Activity"}
					</div>
				}
			</div>
		)
	}
}