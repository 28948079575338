import React, { Component } from 'react'
import PropTypes from 'prop-types';

/**
 * Scss:
 * 		project/_project_banner.scss line 57
 */

class ProgressRing extends Component {
    /**
     * render svg circle and process stroke
     * 
     * Parent:
     *      ProjectBanner
     * 
     */
    constructor(props) {
        super(props);

        const { radius, stroke } = props;

        this.normalizedRadius = radius - stroke / 2;
        this.circumference = this.normalizedRadius * 2 * Math.PI;
    }

    render = () => {
        const { radius, stroke, progress } = this.props;
        const strokeDashoffset = this.circumference - progress / 100 * this.circumference;

        return (
            <svg preserveAspectRatio="xMidYMid meet"
                viewBox={`0 0 ${radius * 2} ${radius * 2}`}>
                <circle strokeWidth={stroke}
                    strokeDasharray={this.circumference + ' ' + this.circumference}
                    style={{ strokeDashoffset }}
                    strokeLinecap="round"
                    r={this.normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
            </svg>
        );
    }
}

export default ProgressRing

ProgressRing.propTypes = {
    // width height = radius * 2
    radius: PropTypes.number,
    // stroke width
    stroke: PropTypes.number,
    // indicate process %
	progress: PropTypes.number
}