import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// redux
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/es/integration/react";

import HomePage from "./common/pages/HomePage";
import PageNotFound from "./common/pages/PageNotFound";
import UnAuthorizedPage from "./common/pages/UnAuthorizedPage";

import AuthPage from "./auth/AuthPage";
import ResetPassword from "./auth/pages/ResetPassword";

import AdminHomePage from "./admin/AdminHomePage";
import DashboardPage from "./admin/dashboard/DashboardPage";

import ProjectListPage from "./project/pages/ProjectListPage";
import ProjectFormPage from "./admin/project/ProjectFormPage";
import ProjectDetailsPage from "./project/pages/ProjectDetailsPage";
import ProjectPlanningWrapperPage from "./admin/ProjectPlanningWrapperPage";

import ReportPage from "./reports/ReportPage";
import ProfilePage from "./profile/pages/ProfilePage";
import UserInvitePage from "./profile/pages/UserInvitePage";
import PrivacyPage from "./common/pages/PrivacyPolicy";
import DemoPage from "./common/pages/DemoPage";

import Loader from "./common/components/Loader";

import Layout, { PrivateLayout, AdminRequiredLayout } from "./common/Layout";

import { updateUserDetails } from "./profile/data/profile.actions";

import { postJsonData } from "./common/utils";
import { CLIENT_ID } from "./constant";
import Urls from "./UrlConfig";
import store from "./store";

const persistor = persistStore(store);

export default class App extends Component {
  state = {
    mount_app: false,
  };

  onBeforeLift = () => {
    if (!store.getState().auth.logged) {
      // user not logged no need to check token
      this.setState({ mount_app: true });
    } else {
      postJsonData(
        Urls.apiTokenVerify(),
        { client_id: CLIENT_ID },
        (res) => {
          store.dispatch(updateUserDetails(res));
          // render the rest of the app
          this.setState({ mount_app: true });
        },
        (err) => {
          // render the rest of the app
          this.setState({ mount_app: true });
        }
      );
    }
  };

  render = () => {
    return (
      <Provider store={store}>
        <PersistGate
          persistor={persistor}
          loading={<Loader />}
          onBeforeLift={this.onBeforeLift}
        >
          {this.state.mount_app ? (
            <BrowserRouter>
              <Switch>
                {/** Project routes */}
                <PrivateLayout
                  exact
                  path={Urls.getHomePage()}
                  component={HomePage}
                />
                <Route exact path={Urls.getDemoPage()} component={DemoPage} />
                <PrivateLayout
                  exact
                  path={Urls.getProjectPage()}
                  component={ProjectListPage}
                />
                <AdminRequiredLayout
                  exact
                  path={Urls.getDashboardPage()}
                  component={DashboardPage}
                />
                <AdminRequiredLayout
                  exact
                  path={Urls.getProjectForm()}
                  component={ProjectFormPage}
                />
                <PrivateLayout
                  exact
                  path={Urls.getProjectDetails()}
                  component={ProjectDetailsPage}
                />
                {/** Project Planning routes */}
                <AdminRequiredLayout
                  path={Urls.getProjectPlanningList()}
                  component={ProjectPlanningWrapperPage}
                />
                <AdminRequiredLayout
                  path={Urls.getProjectPlanningAdd()}
                  component={ProjectPlanningWrapperPage}
                />
                <AdminRequiredLayout
                  path={Urls.getProjectPlanningEdit()}
                  component={ProjectPlanningWrapperPage}
                />
                <AdminRequiredLayout
                  path={Urls.getProjectPlanningChecklist()}
                  component={ProjectPlanningWrapperPage}
                />

                {/** Admin page routes */}
                <AdminRequiredLayout
                  exact
                  path={Urls.getAdminHomePage()}
                  component={AdminHomePage}
                />
                <AdminRequiredLayout
                  exact
                  path={Urls.getReportsPage()}
                  component={ReportPage}
                />

                <PrivateLayout
                  exact
                  path={Urls.getProfilePage()}
                  component={ProfilePage}
                />

                <Route path={Urls.getAuthPages()} component={AuthPage} />
                <Route
                  path={Urls.getResetPassPage()}
                  component={ResetPassword}
                />

                <Layout
                  exact
                  path={Urls.getUserInvitePage()}
                  component={UserInvitePage}
                />
                <Layout
                  exact
                  path={Urls.getUnauthorized()}
                  component={UnAuthorizedPage}
                />
                <Route
                  exact
                  path={Urls.getPrivacyPage()}
                  component={PrivacyPage}
                />
                <Layout exact path={Urls.get404()} component={PageNotFound} />
                <Layout exact path="*" component={PageNotFound} />
              </Switch>
            </BrowserRouter>
          ) : (
            <Loader />
          )}
        </PersistGate>
      </Provider>
    );
  };
}
