const INIT_INVITATION_LIST = {
    fetching: false,
    fetched: false,
    error: false,
    errorObj: {},
    
    invitationList: [],
}


export const invitationListReducer =  (state = INIT_INVITATION_LIST, { type, payload }) => {
    switch (type) {

        case 'INVITATION_LIST_FETCHING': {
            return { ...state, 
                fetching: true,
                error: false,
                errorObj: {},
            }
        }

        case 'INVITATION_LIST_SUCCESS': {

            return { ...state,
                fetching: false,
                fetched: true,
                invitationList: [...payload],
            }
        }

        case 'INVITATION_LIST_ERROR': {
            return { ...state, 
                fetching: false,
                fetched: false,
                error: true,
                errorObj: payload
            }
        }

        case "USER_LOGOUT": {
            return INIT_INVITATION_LIST
        }

        default: {
            return state
        }
    }
}