import { find, findIndex } from 'lodash'

const INIT_QUALITY = {
  fetching: false,
  fetched: false,
  fetchError: false,
  fetchErrorObj: {},
  // check details
  /**
   * Shape : [
   *  { id, name, planning ...checkdata, quality_images: [{ image },...] }, ...
   * ]
   */
  checklist: [],
  // for gallery purpose
  selectedCheckData: {},
}


export const checklistReducer = (state = INIT_QUALITY, { type, payload }) => {
  switch (type) {

    case 'PROJECT_CHECKLIST_FETCHING': {
      return {
        ...state,
        fetching: true,
        fetchError: false,
        fetchErrorObj: {},
      }
    }

    case 'PROJECT_CHECKLIST_SUCCESS': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        checklist: payload
      }
    }

    case 'PROJECT_CHECKLIST_ERROR': {
      return {
        ...state,
        fetching: false,
        fetched: false,
        fetchError: true,
        fetchErrorObj: payload
      }
    }

    case "PROJECT_CHECKLIST_ADD_NEW_IMAGE": {
      let newChecklist = [...state.checklist]
      const checkIndex = findIndex(newChecklist, ['id', payload.checklist])
      // add image to data
      let currCheckDetails = { ...newChecklist[checkIndex] }
      currCheckDetails.quality_images.push(payload)
      // update current list
      newChecklist[checkIndex] = currCheckDetails

      return { ...state, checklist: newChecklist }
    }

    case 'RESET_PROJECT_DETAILS': {
      return { ...INIT_QUALITY }
    }

    case 'USER_LOGOUT': {
      return INIT_QUALITY
    }

    default: {
      return state
    }
  }
}