import React, { Component } from 'react'

import { DatePickerInput } from 'react-datetime-range-super-picker'
import { addDays, differenceInDays, startOfToday } from "date-fns";
import { get, findIndex, isEmpty, isNull } from 'lodash'

import { CustomReactSelect } from '../../common/components/CustomInput'
import LoadingButton from '../../common/components/LoadingButton'
import FormInfoBox from '../../common/components/FormInfoBox'
import CheckBox from '../../common/components/CheckBox'

import { checkAndAddHolidays, transformPlanningData } from "../data/planning.utils";
import { postJsonData, formatSubmitDate, formatDate } from '../../common/utils';
import Urls from '../../UrlConfig'

/**
 * Scss:
 * 		_planning_form
 * 
 * Common:
 *      _layout
 */

export default class PlanningForm extends Component {

	/**
	 * general component render add edit planning form
	 * 
	 * Parent:
	 *      ProjectPlanningAdd
	 *      ProjectPlanningEdit
	 */

	constructor(props) {
		super(props)

		const upcommingHolidays = props.holidayList || []
		this.holidayList = upcommingHolidays.map(holiday => {
			return {
				...holiday,
				start_date: new Date(holiday.start_date),
				end_date: new Date(holiday.end_date)
			}
		})

		this.isAdd = !Boolean(props.planningDetails)

		let start_date, end_date, initData
		let isDependentPlanning = false // to detect that planning is actually dependent or not
		let dpndPlnId = null // dependent planning id

		if (this.isAdd) {
			start_date = startOfToday()
			end_date = startOfToday()

			// if dependsOnData in prop, added planning is dependent planning
			// shape {planningId, start_date (end date of parent planning ) }
			if (!isEmpty(props.dependsOnData)) {
				start_date = new Date(props.dependsOnData.start_date)
				start_date = addDays(start_date, 1)
				end_date = new Date(props.dependsOnData.start_date)
				end_date = addDays(end_date, 1)
				isDependentPlanning = true
				dpndPlnId = props.dependsOnData.planningId
			}

			initData = {
				project_id: props.projectDetails.id,
				unit_id: null,
				subunits_list: [],
				subunits_id: null,
				activity_id: null,
				start_date, end_date,
				has_holidays: false,
				// no of holidays = sum all holidays from micro planning
				subactivity: [],
				// helpers
				isDependentPlanning,
				dpndPlnId
			}
		}
		else {
			initData = transformPlanningData(props.planningDetails, props.projectDetails)
		}

		this.state = {
			...initData,
			loading: false,
			errorData: {}
		}
	}

	gotoPlanningListPage = (projectId) => () => {
        this.props.history.push(Urls.getProjectPlanningList(projectId))
    }

	planningSubmit = (goTochecklist=false) => {

		const { activity_id, project_id, start_date, end_date,
			subunits_id, unit_id, subactivity,
			isDependentPlanning, dpndPlnId } = this.state

		let planning_no_of_holidays = 0
		// make microplanning list
		let micro_planning = []
		for (let index = 0; index < subactivity.length; index++) {
			const currSub = subactivity[index];

			const microData = {
				project: project_id, activity: activity_id,
				subunit: subunits_id, unit: unit_id,
				start_date: formatSubmitDate(currSub.start_date),
				end_date: formatSubmitDate(currSub.end_date),
				subactivity: currSub.id,
				no_of_days: currSub.no_of_days,
				has_holidays: currSub.has_holidays,
				no_of_holidays: currSub.no_of_holidays,
				activity_only: currSub.activity_only,
				enabled: currSub.enabled,
				mplId: get(currSub, 'mplId', undefined) // in case of edit
			}
			micro_planning.push(microData)
			planning_no_of_holidays += currSub.no_of_holidays
		}

		let data = {
			planning: {
				project: project_id,
				activity: activity_id,
				unit: unit_id,
				subunit: subunits_id,
				start_date: formatSubmitDate(start_date),
				end_date: formatSubmitDate(end_date),
				no_of_days: ((differenceInDays(end_date, start_date) + 1) - planning_no_of_holidays),
				// holiday logic
				has_holidays: planning_no_of_holidays > 0,
				no_of_holidays: planning_no_of_holidays,
			},
			micro_planning,
		}

		// set this param to add planning as dependent, REQUIRED for backend otherwise planning will not link as dependent
		if (isDependentPlanning) {
			// if add, dependent data will be in prop
			if (this.isAdd) {
				data['planning']['depend_project_id'] = dpndPlnId
			} else {
				// in edit, dependent data in planning details
				data['planning']['depends_on'] = [dpndPlnId]
			}
		} else {
			// if not dependent, this field is empty
			data['planning']['depends_on'] = []
		}

		let postUrl = Urls.apiAddPlanning()
		if (!this.isAdd) {
			data['planning']['planning_id'] = this.props.planningDetails.id
			postUrl = Urls.apiEditPlanning()
		}
		// hit post url
		this.setState({ loading: true, errorData: {} })
		postJsonData(postUrl, data,
			(res) => {
				const projectId = this.state.project_id
				const planningId = res.planning.id
				
				this.props.handleFormSuccess(projectId)
				this.props.addNotification({
					type: 'success',
					title: 'Planning Update',
					text: 'Planning updated successfully',
				})
				if(goTochecklist) {
					this.props.history.push(Urls.getProjectPlanningChecklist(projectId, planningId))
				} else { // go to planning list page
					this.props.history.push(Urls.getProjectPlanningList(projectId))
				}
			},
			(err) => {
				this.setState({ loading: false, errorData: err.error_data })
				this.props.addNotification({
					type: 'error',
					title: 'Planning Update',
					text: 'Input error',
				})
			}
		)
	}

	handleUnitSelect = (unit) => {
		this.setState({ unit_id: unit.id, subunits_list: unit.subunits })
	}

	handleSubUnitSelect = (subunit) => {
		this.setState({ subunits_id: subunit.id })
	}

	handleActivitySelect = (activity) => {
		const { start_date } = this.state
		let newSubactivityList;
		let currDate = start_date
		if (activity.subactivity.length) {
			// create subactivity form list data
			newSubactivityList = activity.subactivity.map((subAct) => {
				const result = {
					id: subAct.id, name: subAct.name,
					enabled: true,
					start_date: currDate, end_date: currDate, no_of_days: 1,
					has_holidays: false, no_of_holidays: 0,
				}
				currDate = addDays(currDate, 1)
				return result
			})
			// add holidays
			const [has_holidays, no_of_holidays, endDateWithHolidays] = checkAndAddHolidays(
				this.holidayList,
				newSubactivityList[0].start_date, newSubactivityList[0].end_date)
			newSubactivityList[0] = {
				...newSubactivityList[0], end_date: endDateWithHolidays,
				has_holidays, no_of_holidays,
			}
			this._updateDatesForDependantPlannings(endDateWithHolidays, 0, newSubactivityList)
			// update states
			this.setState({
				activity_id: activity.id, subactivity: newSubactivityList,
			})
		} else {
			newSubactivityList = [{
				id: null, name: activity.name,
				enabled: true, activity_only: true,
				start_date: currDate, end_date: currDate, no_of_days: 1,
				has_holidays: false, no_of_holidays: 0,
			}]
			this.setState({
				activity_id: activity.id, subactivity: newSubactivityList,
				end_date: currDate, // reset end date
			})
		}
	}

	onStartDateUpdate = ({ date }) => {
		// if state has subactivity list change its start date too
		let newSubactivityList = [...this.state.subactivity]
		if (newSubactivityList.length) {
			// get the first subactivity that is enabled
			const firstEnabledIndex = findIndex(newSubactivityList, 'enabled')
			const firstSubAct = newSubactivityList[firstEnabledIndex]
			// get end date
			const newEndDate = addDays(date, firstSubAct.no_of_days - 1)
			const [has_holidays, no_of_holidays, endDateWithHolidays] = checkAndAddHolidays(
				this.holidayList, date, newEndDate)
			newSubactivityList[firstEnabledIndex] = {
				...firstSubAct, start_date: date, end_date: endDateWithHolidays,
				has_holidays, no_of_holidays
			}
			this._updateDatesForDependantPlannings(endDateWithHolidays, firstEnabledIndex, newSubactivityList)
		}
		this.setState({ start_date: date, subactivity: newSubactivityList })
	}

	handleDependsOnUpdate = () => {
		this.setState({ isDependentPlanning: !this.state.isDependentPlanning })
	}

	handleDepnPlnSelect = (props) => {
		const newValue = get(props, 'id', null)
		const isSubAct = this.state.subactivity.length

		let newState = { dpndPlnId: newValue, isDependentPlanning: !!newValue }

		// update start time if depends on select
		if (newValue) {
			this.onStartDateUpdate({ date: new Date(props.end_date) })
			// end date == start date in case of subactivity not selected
			if (!isSubAct) {
				newState.end_date = new Date(props.end_date)
			}
		}
		else {
			this.onStartDateUpdate({ date: startOfToday() })
			// end date == start date in case of subactivity not selected
			if (!isSubAct) {
				newState.end_date = startOfToday()
			}
		}

		this.setState(newState)
	}

	//select search logic.
	customFilter = (option, searchText) => {
		return option.data.display_name.toLowerCase()
			.includes(searchText.toLowerCase())
	}

	render = () => {
		const { projectDetails, activityList, planningList } = this.props
		const { unit_id, subunits_id, subunits_list, activity_id,
			subactivity, loading, errorData,
			start_date, end_date,
			isDependentPlanning, dpndPlnId } = this.state

		// user selected activity that has no sub activity
		const hasNoSubActivity = (!isNull(activity_id)) && (!subactivity.length)
		const disableEndDate = true

		return (
			<div className="pl-form-wrapper">
				<h2>Structure</h2>

				<div className="row">
					<div className="col s6">
						<CustomReactSelect label="Select Unit"
							options={projectDetails.units}
							isClearable={false}
							isSearchable={true}
							value={projectDetails.units.filter(({ id }) => id === unit_id)}
							getOptionLabel={({ name }) => name}
							getOptionValue={({ id }) => id}
							onChange={this.handleUnitSelect}
							errorData={errorData}
							errorField="unit"
						/>
					</div>
					<div className="col s6">
						<CustomReactSelect label="Select Subunit"
							options={subunits_list}
							isClearable={false}
							isSearchable={true}
							isDisabled={!subunits_list.length}
							value={subunits_list.filter(({ id }) => id === subunits_id)}
							getOptionLabel={({ name }) => name}
							getOptionValue={({ id }) => id}
							onChange={this.handleSubUnitSelect}
							errorData={errorData}
							errorField="subunit"
						/>
					</div>
				</div>

				<h2>Activity</h2>

				<div className="row">
					<div className="col s6">
						<CustomReactSelect label="Select Activity"
							options={activityList}
							isClearable={false}
							isSearchable={true}
							value={activityList.filter(({ id }) => id === activity_id)}
							getOptionLabel={({ name }) => name}
							getOptionValue={({ id }) => id}
							onChange={this.handleActivitySelect}
							errorData={errorData}
							errorField="activity"
						/>
					</div>
					<div className="col s6">
						<CustomReactSelect label="Depends on"
							options={planningList}
							isClearable={true}
							isSearchable={true}
							filterOption={this.customFilter}
							value={planningList.filter(({ id }) => id === dpndPlnId)}
							getOptionValue={({ id }) => id}
							getOptionLabel={(props) => {
								return (
									<div className="planning-select-content">
										<div className="qs-heading">{props.display_name}</div>
										<div className="qs-text">Ends On : {formatDate(props.end_date)}</div>
									</div>
								)
							}}
							onChange={this.handleDepnPlnSelect}
						/>
					</div>
				</div>

				<h2>Planning</h2>

				<div className="row">

					<div className="col s6">
						{isDependentPlanning ?
							<FormInfoBox label="Start Date" text={formatDate(start_date)} />
							:
							<div className="form-group">
								<div className="label">Start Date</div>
								<DatePickerInput date={start_date}
									onDateUpdate={this.onStartDateUpdate}
								/>
							</div>
						}
					</div>
					<div className="col s6">
						{disableEndDate ?
							<FormInfoBox label="End Date" text={formatDate(end_date)} />
							:
							<div className="form-group">
								<div className="label">End Date</div>
								<DatePickerInput isDisabled={true} date={end_date} onDateUpdate={() => { }} />
							</div>
						}
					</div>
				</div>

				<div className="row">
					<div className="col s6">
						{hasNoSubActivity ?
							<div className="form-group">
								<div className="label">Estimated duration (days)</div>
								<input placeholder='Estimation of work days' name='planning_days'
									value={differenceInDays(end_date, start_date) + 1}
									onChange={() => { }} />
							</div>
							:
							<FormInfoBox label="Estimated duration (days)"
								text={differenceInDays(end_date, start_date) + 1} />
						}
					</div>
				</div>

				{this.renderSubActivityList(hasNoSubActivity)}

				<div className="row">
					<div className="col s12">
						<div className="pl-form-action-btn">
                            <LoadingButton title="Cancel" className="btn secondary-btn"
                                onClick={this.gotoPlanningListPage(projectDetails.id)} />
                            <LoadingButton title="Update" className="btn primary-btn"
								isLoading={loading} onClick={() => this.planningSubmit(false)} />
							<LoadingButton title="Update & Go to Checklist" 
								className="btn secondary-btn"
								isLoading={loading} onClick={() => this.planningSubmit(true)} />
                        </div>
						<div className="pl-form-action-btn">
							
						</div>
					</div>
				</div>

			</div>
		)
	}

	/**
	 * Change all start and end dates of planning after user changes in current index
	 * NOTE : Will mutate planningList
	 * 
	 * @param {Date} lastEndDate end_date of last planning user updated
	 * @param {Int} index index of updated planning
	 * @param {Array} planningList whole micro planning list
	 */
	_updateDatesForDependantPlannings = (lastEndDate, index, planningList) => {
		let actInd = index + 1;
		// Update all subactivity bellow this one
		while (actInd < planningList.length) {
			const currSubAct = planningList[actInd];
			if (currSubAct.enabled) {
				const newCurrEndDate = addDays(lastEndDate, currSubAct.no_of_days)
				const newStartDate = addDays(lastEndDate, 1)
				const [has_holidays, no_of_holidays, endDateWithHolidays] = checkAndAddHolidays(
					this.holidayList, newStartDate, newCurrEndDate)
				planningList[actInd] = {
					...planningList[actInd],
					start_date: newStartDate, end_date: endDateWithHolidays,
					has_holidays, no_of_holidays,
				}
				lastEndDate = endDateWithHolidays
			}
			actInd += 1
		}
		// update end time of parent planning
		this.setState({ end_date: lastEndDate })
		return planningList
	}

	toggleSubActEnable = (index) => () => {
		let newSubactivityList = [...this.state.subactivity]
		const currSubAct = newSubactivityList[index]
		const enabled = !currSubAct.enabled
		if (enabled) {
			// calculate start date
			let start_date = this.state.start_date;
			if (index !== 0) {
				// get end date of last enabled component
				for (let sbActInd = index - 1; sbActInd >= 0; sbActInd--) {
					if (newSubactivityList[sbActInd].enabled) {
						start_date = addDays(newSubactivityList[sbActInd].end_date, 1)
						break
					}
				}
				// if all prev sub act are disabled start date will be parent start date
			}
			// calculate end date
			const newEndDate = addDays(start_date, currSubAct.no_of_days - 1)
			const [has_holidays, no_of_holidays, endDateWithHolidays] = checkAndAddHolidays(
				this.holidayList, start_date, newEndDate)
			newSubactivityList[index] = {
				...currSubAct,
				enabled, start_date, end_date: endDateWithHolidays,
				has_holidays, no_of_holidays,
			}
			this._updateDatesForDependantPlannings(endDateWithHolidays, index, newSubactivityList)
		} else {
			// just disable this element
			newSubactivityList[index] = {
				...currSubAct, enabled,
			}
			// get last end date
			let newEndDate = this.state.start_date;
			if (index !== 0) {
				// get end date of last enabled component
				for (let sbActInd = index - 1; sbActInd >= 0; sbActInd--) {
					if (newSubactivityList[sbActInd].enabled) {
						newEndDate = newSubactivityList[sbActInd].end_date
						break;
					}
				}
			}
			this._updateDatesForDependantPlannings(newEndDate, index, newSubactivityList)
		}
		this.setState({ subactivity: newSubactivityList })
	}

	updateSubActStartDate = (index) => ({ date }) => {
		const { subactivity } = this.state
		var newSubactivityList = [...subactivity]
		// calculate new end date
		let currSubAct = newSubactivityList[index]
		const newEndDate = addDays(date, currSubAct.no_of_days - 1)
		const [has_holidays, no_of_holidays, endDateWithHolidays] = checkAndAddHolidays(
			this.holidayList, date, newEndDate)
		newSubactivityList[index] = {
			...currSubAct,
			start_date: date, end_date: endDateWithHolidays,
			has_holidays, no_of_holidays,
		}
		// update all dependat planning dates
		this._updateDatesForDependantPlannings(endDateWithHolidays, index, newSubactivityList)
		this.setState({ subactivity: newSubactivityList })
		if (index === 0) {
			// update parent start date
			this.setState({ start_date: date })
		}
	}

	handleSubActInput = (index) => (event) => {
		// estimated days cannot less then 1
		let no_of_days = event.target.value
		no_of_days = no_of_days < 1 ? 1 : no_of_days
		this.updateSubActNoOfDays(index, no_of_days)
	}

	handleSubActDecrease = (index, no_of_days) => () => {
		// estimated days cannot less then 1
		const new_no_of_days = no_of_days <= 1 ? 1 : (+no_of_days) - 1

		this.updateSubActNoOfDays(index, new_no_of_days)
	}

	handleSubActIncrease = (index, no_of_days) => () => {
		const new_no_of_days = (+no_of_days) + 1

		this.updateSubActNoOfDays(index, new_no_of_days)
	}

	updateSubActNoOfDays = (index, no_of_days) => {
		// validate days are integer no ----------
		let newSubactivityList = [...this.state.subactivity]
		let currSubAct = newSubactivityList[index]
		// calculate end date
		const newEndDate = addDays(currSubAct.start_date, no_of_days - 1)
		const [has_holidays, no_of_holidays, endDateWithHolidays] = checkAndAddHolidays(
			this.holidayList, currSubAct.start_date, newEndDate)
		newSubactivityList[index] = {
			...currSubAct,
			no_of_days, end_date: endDateWithHolidays,
			has_holidays, no_of_holidays,
		}

		this._updateDatesForDependantPlannings(endDateWithHolidays, index, newSubactivityList)
		this.setState({ subactivity: newSubactivityList })
	}

	renderSubActivityList = (hasNoSubActivity) => {
		if (hasNoSubActivity) return undefined;
		// render only if subactivity list in selected activity
		const { subactivity, activity_id } = this.state
		// render only after user selected activity
		if (isNull(activity_id)) return undefined;

		return (
			<>
				<h2>Micro Planning</h2>
				{subactivity.map((subAct, i) => {
					const { id, name, enabled,
						start_date, no_of_days, end_date,
						no_of_holidays, has_holidays } = subAct
					return (
						<div key={id} className="subAct-row">
							<div className="subAct-title">{name}</div>
							<div className="flex-wrapper">
								{enabled &&
									<>
										<div className="flex-md subAct-row-pad">
											<div className="form-group">
												<div className="label">Start Date</div>
												<DatePickerInput date={start_date}
													onDateUpdate={(this.updateSubActStartDate(i))} />
											</div>
										</div>

										<div className="flex-lg subAct-row-pad">
											<div className="estimat-input">
												<span className="button"
													onClick={this.handleSubActDecrease(i, no_of_days)}>-</span>

												<div className="form-group">
													<div className="label">Estimated duration (days)</div>
													<input type="number" placeholder='Estimation of work days'
														name='planning_days' value={no_of_days}
														onChange={this.handleSubActInput(i)} />

													{has_holidays && (
														<span className='yellow'>+ {no_of_holidays} holidays</span>
													)}
												</div>
												<span className="button" onClick={this.handleSubActIncrease(i, no_of_days)} >+</span>
											</div>
										</div>

										<div className="flex-md subAct-row-pad">
											<FormInfoBox label="End Date" text={formatDate(end_date)} />
										</div>
									</>
								}

								<div className="flex-sm subAct-row-pad">
									<div className={`subAct-check ${enabled ? "enable" : "not-enable"}`}>
										<CheckBox label="Enabled" isChecked={enabled}
											onClick={this.toggleSubActEnable(i)} />
									</div>
								</div>
							</div>
						</div>
					)
				})}
			</>
		)
	}
}