import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { find, get } from 'lodash'
import { differenceInCalendarDays } from 'date-fns'

import LoadingButton, { RoundedButton } from '../../../common/components/LoadingButton'
import CustomInput from '../../../common/components/CustomInput'
import ErrorBlock from '../../../common/components/ErrorBlock'
import ScheduleList from './ScheduleList'
import { CheckIcon, CloseIcon } from '../../../common/SvgIcons'

import { addNotification } from '../../../common/data/common.actions'
import { getProjectMicroPlanningList } from '../../data/project.actions'
import { postJsonData, formatDate } from '../../../common/utils'
import Urls from '../../../UrlConfig'

/**
 * Scss:
 * 		project/_project_planning_details.scss
 */


const ProjectPlanningPopupWrapper = (props) => {

  /**
   * Wrapper component
   * check that selected microplanning details exist in store or not
   * show loading if data fetching
   * show error in fetch fail
   * 
   * find details from selected Mpl id
   * 
   * fetch microplanning list on status change
   * 
   * Props:
   *      all connect props
   *      mcPlanningId - selected micro planning id from url
   * 
   * Parent:
   *      ProjectDetailsPage
   * Render :
   *      ProjectPlanningPopup
   */

  const handleStatusUpdate = () => {
    // get mpl list with updated data
    props.dispatch(getProjectMicroPlanningList(props.projectId))
    // close model
    props.history.push(Urls.getProjectDetails(props.projectId, 'planning', null, null))
  }

  let content;

  if (props.fetched) {
    // we will get matching mcPlanning only if list is fetched
    const mcPlanning = find(props.microPlanningList, ['id', Number(props.mcPlanningId)]);
    const activityName = get(mcPlanning, 'activity.name')
    const subActName = get(mcPlanning, 'subactivity.name')

    if (mcPlanning) {
      content = (
        <>
          <div className="title">{activityName} - {subActName}</div>
          <ProjectPlanningPopup mcPlanning={mcPlanning}
            userRole={props.userRole}
            onStatusUpdate={handleStatusUpdate}
            dispatch={props.dispatch}
          />
        </>
      )
    }
    else {
      content = <div className="error-block big">Failed to fetch data</div>
    }
  }
  else if (props.fetchError) {
    content = <div className="error-block big">Failed to fetch data</div>
  }
  else {
    return null;
  }

  return (
    <div className="page-model">
      <div className="page-model-wrapper">
        <div className="page-model-cell">
          <div className="page-model-content pd-pln-col-card-details">
            <div className="model-form">
              <Link to={Urls.getProjectDetails(props.projectId, 'planning', null, null)}>
                <div className="close-icon-wrapper">
                  <div className="close-icon"></div>
                </div>
              </Link>
              {content}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(store => ({
  fetching: store.microPlanningList.fetching,
  fetched: store.microPlanningList.fetched,
  fetchError: store.microPlanningList.fetchError,
  microPlanningList: store.microPlanningList.microPlanningList,
  projectId: store.projectDetails.projectDetails.id,
  userRole: store.auth.userRole,
}))(ProjectPlanningPopupWrapper)


/**
 * Scss:
 * 		project/_project_planning_list.scss
 */
class ProjectPlanningPopup extends Component {

  /**
   * component will render if mpl data exist
   */

  state = {
    // "W" : if user pause planning, "C" : if user mark as completed
    actionType: '',
    remarks: '',
    loading: false,
    errors: {}
  }

  handleActionStatus = (actionType) => () => {
    this.setState({ actionType })
  }

  changePlanningStatus = () => {
    this.setState({ loading: true, errors: {} })

    const { actionType, remarks } = this.state

    let text, notiType
    switch (actionType) {
      case "R":
        text = "Planning started successfully"
        notiType = "success"
        break;

      case "C":
        text = "Planning completed successfully"
        notiType = "info"
        break;

      case "W":
        text = "Planning stoped successfully"
        notiType = "warning"
        break;

      default:
        text = ""
        notiType = ""
        break;
    }

    const mcPlanningId = this.props.mcPlanning.id
    postJsonData(Urls.apiUpdateProjectMicroPlanningStatus(mcPlanningId),
      { status: actionType, remarks },
      (res) => {
        this.setState({ loading: false, actionType: '', errors: {} })
        this.props.onStatusUpdate()
        this.props.dispatch(addNotification({
          type: notiType,
          title: 'Planning Status Update',
          text,
        }))
      },
      (err) => {
        this.setState({ loading: false, actionType: '', errors: err.error_data })
        this.props.dispatch(addNotification({
          type: 'error',
          title: 'Planning Status Update',
          text: 'Input error',
        }))
      },
    )
  }

  render = () => {

    const { mcPlanning } = this.props

    return (
      <>
        <h2 className="schedule-title">Planning</h2>
        <div className="pd-details-desc">
          From {formatDate(new Date(mcPlanning.start_date))} :-
          To {formatDate(new Date(mcPlanning.end_date))}
        </div>

        {this.renderActionsBtns()}

        <h2 className="schedule-title">Work Summery</h2>
        {this.renderSummery()}

        <ScheduleList mcPlanningId={mcPlanning.id} />
      </>
    )
  }

  renderActionsBtns = () => {
    const { loading, errors, actionType, remarks } = this.state
    const { userRole } = this.props
    const status = this.props.mcPlanning.status
    const showActions = status !== 'C' && userRole < 3
    const showOnlyStartAction = status === 'W' || status === 'P'

    if (showActions) {
      return (
        <div className="pd-details-action">
          {actionType ?
            <div className="remark-wrapper">
              <CustomInput
                label="Remarks"
                type="text"
                placeholder="Enter remarks"
                value={remarks}
                onChange={e => this.setState({ remarks: e.target.value })}
              />

              <RoundedButton icon={<CheckIcon />} className="btn primary-btn"
                isLoading={loading && Boolean(actionType)}
                onClick={this.changePlanningStatus} />
              <RoundedButton icon={<CloseIcon />} className="btn secondary-btn"
                onClick={this.handleActionStatus('')} />

              <ErrorBlock error={errors} field="planning" />
            </div>
            :
            <div className="multi-action">
              {showOnlyStartAction ?
                <LoadingButton title="Start"
                  icon={<span className="icon icon-play" />}
                  className="btn primary-btn"
                  isLoading={loading}
                  onClick={this.handleActionStatus('R')} />
                :
                <>
                  <LoadingButton title="Pause"
                    icon={<span className="icon icon-pause" />}
                    className="btn primary-btn"
                    onClick={this.handleActionStatus('W')} />
                  <LoadingButton title="Mark Completed"
                    icon={<span className="icon icon-check" />}
                    className="btn primary-btn"
                    onClick={this.handleActionStatus('C')} />
                </>
              }
            </div>
          }
        </div>
      )
    }

  }

  renderSummery = () => {
    const { mcPlanning } = this.props
    const { actual_start_date, completion_date, start_date, end_date } = mcPlanning
    let summery = ''
    // Planning was started once
    if (actual_start_date) {
      // if planning started early, late or on time
      const diff_in_start = differenceInCalendarDays(new Date(start_date), new Date(actual_start_date))

      if (diff_in_start > 0) {
        summery = `Work started early by ${Math.abs(diff_in_start)} days. `
      }
      else if (diff_in_start < 0) {
        summery = `Work started late by ${Math.abs(diff_in_start)} days. `
      }
      else {
        summery = `Work started On Time. `
      }

      if (completion_date) {
        // planning ended show all details
        const diff_in_complition = differenceInCalendarDays(
          new Date(end_date), new Date(completion_date))

        if (diff_in_complition > 0) {
          summery += `Work completed ${Math.abs(diff_in_complition)} days early. `
        }
        else if (diff_in_complition < 0) {
          summery += `Work completed ${Math.abs(diff_in_complition)} days late. `
        }
        else {
          summery += `Work completed On Time. `
        }
      } else {
        // planning started but not ended yet
        const ends_in_days = differenceInCalendarDays(new Date(), new Date(end_date))

        if (ends_in_days < 0) {
          summery += `Work should complete in ${Math.abs(ends_in_days)} days. `
        }
        else if (ends_in_days > 0) {
          summery += `Work completion delayed by ${Math.abs(ends_in_days)} days as per Today. `
        }
        else {
          summery += `Work should complete Today. `
        }
      }

    } else {
      // planning was never started, maybe in waiting or planned status
      const days_diff = differenceInCalendarDays(new Date(start_date), new Date())

      if (days_diff < 0) {
        summery += `Waiting to start for ${Math.abs(days_diff)} days. `
      }
      else if (days_diff > 0) {
        summery += `Work expected to start in ${Math.abs(days_diff)} days. `
      }
      else {
        summery += `Work may start today !! `
      }
    }

    return (
      <div className="pd-details-summery">
        <span>{summery}</span>
      </div>
    )
  }
}

ProjectPlanningPopup.propTypes = {
  // mpl object
  mcPlanning: PropTypes.object,
  // function call when status update successfully
  onStatusUpdate: PropTypes.func,
}