import React from 'react'

import PropTypes from 'prop-types';
import get from 'lodash/get'


/**
 * Scss:
 *      _utils.scss - 54
 */

const ErrorBlock = ({ field, error, className = 'error-block' }) => {
	const err_list = get(error, field, false)

	if(err_list) {
		return (
			err_list.map((err, id) => {
				return <div className={className} key={id}>{err}</div>
			})
		)
	} else {
		return null
	}
}

ErrorBlock.propTypes = {
	field: PropTypes.string.isRequired,
	// shape: { field: ["error text1", "error text 2"], ... }
	error: PropTypes.object.isRequired,
	className: PropTypes.string
}

export default ErrorBlock