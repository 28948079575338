import React, { Component } from "react";
import { connect } from "react-redux";

import Fuse from "fuse.js";
import { get, find } from "lodash";

import { FloatingBtn } from "../../../common/components/LoadingButton";
import Loader from "../../../common/components/Loader";
import DrawingForm from "./DrawingForm";

import { postJsonData } from "../../../common/utils";
import { filterMPList } from "../../../admin/data/planning.utils";
import { EditIcon } from "../../../common/SvgIcons";
import Urls from "../../../UrlConfig";

/**
 * Scss:
 * 		project/_project_drawing_list.scss
 * Common:
 * 		buttons, layout, _common_page_layout
 */

class DrawingList extends Component {
  /**
   * render basic info of unit and related subunit
   *
   * Parent:
   *      ProjectDetailsPage
   *
   * Renders
   *      DrawingBlock
   *      DrawingForm
   */

  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);
    const drawingId = query.get("drawingpopup");

    let selectedDrawingId = null;
    let modelType = null;

    // show model if data in search
    if (drawingId) {
      selectedDrawingId = drawingId;
      modelType = 2;
    }

    this.state = {
      contentHeight: 0,
      selectedDrawingId,
      modelType, // 1: add, 2: edit,
      fetching: false,
      fetched: false,
      drawingList: [],
      error: null,
      // search
      searchToken: "",
    };

    this.search = false;
  }

  componentDidMount = () => {
    const { projectId } = this.props;

    const data = {
      project_id: projectId,
    };

    this.getDrawingList(data);
  };

  getDrawingList = (data) => {
    this.setState({ fetching: true });

    postJsonData(
      Urls.apiGetDrawingList(),
      data,
      (res) => {
        this.setState({ fetching: false, fetched: true, drawingList: res });
        // setup search
        this.setup_search(res);
      },
      (err) => {
        this.setState({ fetching: false, fetched: false, error: err });
      }
    );
  };

  setup_search = (data) => {
    const search_opts = {
      shouldSort: true,
      tokenize: true,
      findAllMatches: true,
      threshold: 0.8,
      location: 0,
      distance: 32,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["caption"],
    };

    this.search = new Fuse(data, search_opts);
  };

  resizeContent = (element) => {
    if (element) {
      const { windowHeight } = this.props;

      const contentHeight = windowHeight - element.getBoundingClientRect().top;
      this.setState({ contentHeight });
    }
  };

  showModel = (checkId) => () => {
    this.setState({ selectedDrawingId: checkId, modelType: 1 });
  };

  closeModel = () => {
    this.setState({ selectedDrawingId: null, modelType: null });
  };

  handleDrawingSuccess = () => {
    this.closeModel();
    const { projectId } = this.props;

    const data = {
      project_id: projectId,
    };
    this.getDrawingList(data);
  };

  handleSearchText = (e) => {
    this.setState({ searchToken: e.target.value });
  };

  handleDownload = (url) => (e) => {
    if (e) e.preventDefault();

    const link = document.createElement("a");
    link.href = url;
    const filename = url.substring(url.lastIndexOf("/") + 1);
    link.setAttribute("download", filename);
    link.setAttribute("target", "_blank");
    // have to add element to doc for firefox
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render = () => {
    const { userRole, projectViewConfig } = this.props;
    const {
      fetched,
      drawingList,
      error,
      searchToken,
      selectedDrawingId,
      modelType,
      contentHeight,
    } = this.state;

    let content;
    if (fetched) {
      const { isFilterActive, selectedUnit, selectedSubunit } =
        projectViewConfig;

      const paddingBot = 30;
      let wrapperHeightCss = { paddingBot };
      // set planning cols height to scroll individually
      if (contentHeight) {
        wrapperHeightCss.height = contentHeight - paddingBot;
      }
      let emptyMessage = "Looks like you don't have any drawings right now";
      // filter list by caption search token
      let filteredDrawingList;
      if (searchToken.length > 2) {
        filteredDrawingList = this.search
          .search(searchToken)
          .map((d) => d.item);
      } else {
        filteredDrawingList = drawingList;
      }
      // filter list by structure
      if (Boolean(isFilterActive)) {
        filteredDrawingList = filterMPList(
          filteredDrawingList,
          Number(selectedUnit.id),
          Number(selectedSubunit.id)
        );
        emptyMessage =
          "Looks like you don't have any drawings related to filtered structure";
      }

      content = (
        <div className="pd-drawing-list" style={wrapperHeightCss}>
          {filteredDrawingList.length ? (
            filteredDrawingList.map((drawing) => {
              const caption = get(drawing, "caption", "");

              let drawingLabel = "";
              if (drawing.subunit?.name) {
                drawingLabel = `${drawing.unit.name} > ${drawing.subunit.name}`;
              } else {
                drawingLabel = drawing.unit.name;
              }

              return (
                <div key={drawing.id} className="pd-drawing-block">
                  {userRole < 3 && (
                    <div
                      className="pd-drawing-edit"
                      onClick={this.showModel(drawing.id)}
                    >
                      <EditIcon />
                    </div>
                  )}
                  <div className="pd-drawing-title truncate">{caption}</div>
                  <div className="pd-drawing-desc">{drawingLabel}</div>

                  <div className="pd-drawing-action">
                    <div
                      className="action-btn"
                      onClick={this.handleDownload(drawing.drawing_file)}
                    >
                      Download
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="empty-page-wrapper">
              <img src={Urls.getStaticImg("/img/no-activity.svg")} alt="" />
              <div className="text">{emptyMessage}</div>
            </div>
          )}
        </div>
      );
    } else if (!!error) {
      content = (
        <div className="container">
          <div className="empty-page-wrapper">
            <div className="error-block big">Failed to fetch data</div>
          </div>
        </div>
      );
    } else {
      content = (
        <div className="container">
          <Loader />
        </div>
      );
    }

    // get selected drawing data or null
    const drawingData = find(drawingList, ["id", selectedDrawingId]);

    return (
      <>
        {/* <div className="page-right-actions">
                    <div className="page-action-wrapper">
                        <CustomSearchBox type="text" placeholder="Search by title"
                            value={searchToken} onChange={this.handleSearchText} />
                        {userRole < 3 &&
                            <div className="page-add-new">
                                <LoadingButton title="+ Add Drawing" className="primary-btn"
                                    onClick={this.showModel(-1)} />
                            </div>
                        }
                    </div>
                </div> */}
        <div className="pd-drawing-list-wrapper" ref={this.resizeContent}>
          {content}

          {selectedDrawingId && modelType === 1 && (
            <DrawingForm
              fetched={fetched}
              fetchError={!!error}
              drawingData={drawingData}
              closeModel={this.closeModel}
              onSuccess={this.handleDrawingSuccess}
            />
          )}
        </div>
        {userRole < 3 && <FloatingBtn onClick={this.showModel(-1)} />}
      </>
    );
  };
}

export default connect((store) => {
  const projectId = store.projectDetails.projectDetails.id;
  const currSetting = store.projectViewConfig[projectId];
  return {
    projectId: projectId,
    projectViewConfig: currSetting,
    // check role and show edit btn or not
    userRole: store.auth.userRole,
    windowHeight: store.pageSize.windowHeight,
  };
})(DrawingList);
