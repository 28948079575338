import React from 'react'
import { connect } from 'react-redux';

import { get, find } from "lodash";

import ErrorBlock from '../../common/components/ErrorBlock'
import LoadingButton from '../../common/components/LoadingButton'
import { CloseIcon, CheckIcon } from '../../common/SvgIcons'
import CustomInput from '../../common/components/CustomInput'

import { addNotification } from '../../common/data/common.actions'
import { getCheckConfigList } from '../data/checklistConfig.actions'
import { getJsondata, postJsonData } from "../../common/utils";
import Urls from "../../UrlConfig";

/**
 * Scss
 * 		_activity_list
 * 
 * Common
 * 		_common_page_layout
 */

class CheckConfigForm extends React.Component {
	/**
	 * Data wrapper component to decide form is add or edit
	 * 
	 * Parent
	 * 		CheckConfigListPage
	 * 
	 * Renders:
	 * 		CheckForm
	 */

	constructor(props) {
		super(props)
		this.isAdd = props.checkConfigId === -1
    }
    
    handleSuccess = () => {
        this.props.closeModel()
        this.props.getCheckConfigList()
    }

	render = () => {
		const { closeModel, checkConfigList, checkConfigId } = this.props

		const checkData = find(checkConfigList, ['id', checkConfigId])
		return (
			<div className="activity-model-wrapper">
				<div className="close-icon-wrapper" onClick={closeModel}>
					<CloseIcon />
				</div>
				<CheckForm data={checkData} isAdd={this.isAdd} 
					closeModel={closeModel}
					onSubmitSuccess={this.handleSuccess}
					addNotification={this.props.addNotification}
				/>
			</div>
		)
	}
}

export default connect(store => ({
	checkConfigList: store.checkConfigList.checkConfigList,
}), { getCheckConfigList, addNotification })(CheckConfigForm)

class CheckForm extends React.Component {

	/**
	 * Props:
	 * 		isAdd: Boolean
	 * 		data: { id, title, checklist_items: [{id, name},...] }
	 * 
	 * Parent:
	 * 		CheckConfigForm
	 */

	constructor(props) {
		super(props)
		
		if(props.isAdd) {
			this.state = {
				title: '',
				checklistItems: [],
				deletedCheckItems: [],
				error: {},
				deleting: false,
				submitting: false
			}
		} else {
			this.state = {
				id: props.data.id,
				title: props.data.title,
				checklistItems: [...props.data.checklist_items],
				deletedCheckItems: [],
				error: {},
				deleting: false,
				submitting: false
			}
		}
	}

	submitCheckConfig = () => {
		const {id, title, checklistItems, deletedCheckItems, submitting } = this.state

		if (submitting) return

		let hasError = false, errorData = {}

		if(!title) {
			errorData['check_config.title'] = ["This field is required"]
            hasError = true
		}
        for (let index = 0; index < checklistItems.length; index++) {
            const currCheckItem = checklistItems[index];
            if(!currCheckItem.name) {
                errorData[index] = ["This field is required"]
                hasError = true
            }
		}
		if(hasError) {
            this.setState({ error: errorData })
            return
		}
		
		// add index in data
		const newCheckItems = checklistItems.map((currItem, ind) => {
			return {...currItem, index: ind}
		})

		this.setState({ error: {}, submitting: true })
		postJsonData(Urls.apiUpsertChecklistConfig(),
			{id, title, 
				checklist_items: [...newCheckItems], 
				deleted_checklist_items: [...deletedCheckItems]
			},
			(res) => {
				this.props.onSubmitSuccess()
				this.props.addNotification({
					type: 'info',
                    icon: <CheckIcon/>,
                    title: this.props.isAdd ? "Checklist Create" : "Checklist Update",
                    text: `${title} ${this.props.isAdd ? "created" : "updated"} successfully`,
				})
			},
			(err) => {
				this.setState({ error: err.error_data, submitting: false })
				this.props.addNotification({
                    type: 'error',
                    title: this.props.isAdd ? "Checklist Create" : "Checklist Update",
                    text: 'input error',
                })
			},
		)
	}

	handleDelete = () => {
		if(this.state.deleting) return

		this.setState({ deleting: true})

		getJsondata(Urls.apiGetChecklistConfigDelete(this.props.data.id),
			(res) => {
				this.props.onSubmitSuccess()
				this.props.addNotification({
					type: 'info',
                    icon: <CheckIcon/>,
                    title: "Checklist Delete",
                    text: `Checklist deleted successfully`,
				})
			},
			(err) => {
				this.setState({ deleting: false })
				this.props.addNotification({
                    type: 'error',
                    title: "Checklist Delete",
                    text: 'input error',
                })
			}
		)
	}

	handleConfigTitleChange = (e) => {
		this.setState({ title: e.target.value })
	}

	handleAddCheckItemForm = () => {
		const newItem = {name: '', index: ''}
		const currCheckItemList = [...this.state.checklistItems]

		this.setState({checklistItems: [...currCheckItemList, newItem]})
	}

	handleCheckItemNameChange = (index) => (e) => {
		const currCheckItemList = [...this.state.checklistItems]
		currCheckItemList[index] = {
			...currCheckItemList[index],
			name: e.target.value
		}
		this.setState({checklistItems: currCheckItemList})
	}

	// in edit, remove will update data only no delete from backend
	handleRemoveCheckItem = (index, checkItem) => () => {
		const newCheckItems = [...this.state.checklistItems]
		newCheckItems.splice(index, 1)

		const currDeletedCheckItemId = [...this.state.deletedCheckItems]
		// if checkItem have id, then only delete
		if(get(checkItem, 'id', null)) {
			currDeletedCheckItemId.push(checkItem.id)
		}

		this.setState({checklistItems: newCheckItems, 
			deletedCheckItems: currDeletedCheckItemId, error: {} })
	}

	handleMoveUp = (index) => () => {
		// cant move if ele on top
		if (index === 0) return

		const newCheckItems = [...this.state.checklistItems]
		const currSubAct = newCheckItems[index]
		const prevSubAct = newCheckItems[index - 1]
		// exchange current and upper index data
		newCheckItems[index] = prevSubAct
		newCheckItems[index - 1] = currSubAct

		this.setState({checklistItems: newCheckItems})

	}

	handleMoveDown = (index) => () => {
		// cant move if ele on bottom
		if (index === this.state.checklistItems.length - 1) return

		let newCheckItems = [...this.state.checklistItems]
		const currSubAct = newCheckItems[index]
		const nextSubAct = newCheckItems[index + 1]
		// exchange current and down index data
		newCheckItems[index] = nextSubAct
		newCheckItems[index + 1] = currSubAct

		this.setState({checklistItems: newCheckItems})
	}

	render = ()=> {
		const {checklistItems, title, error, 
			deleting, submitting } = this.state
		const { closeModel, isAdd } = this.props

		return(
			<div className="form-wrapper">
				<div className="form-label">Checklist Form</div>

				<CustomInput label="Checklist title"
					type="text"
					placeholder="Enter checklist title"
					value={title} 
					onChange={this.handleConfigTitleChange}
					errorField="check_config.title" errorData={error}
				/>

				<div className="form-label sub-title">Checklist Items</div>

				{checklistItems.map((checkItem, ind) => {
					return (
						<div className="subAct-block" key={ind}>
							<div className="arrow-block">
								<div className="arrow-up" onClick={this.handleMoveUp(ind)}></div>
								<div className="arrow-down" onClick={this.handleMoveDown(ind)}></div>
							</div>
							<div className="ind-text">{ind+1}</div>
							<div className="form-input">
								<input type="text"
									placeholder="Enter checklist point"
									value={checkItem.name}
									onChange={this.handleCheckItemNameChange(ind)}
								/>
								<ErrorBlock error={error} field={String(ind)} />
							</div>
							<div className="remove-text" onClick={this.handleRemoveCheckItem(ind, checkItem)}>Remove</div>
						</div>
					)
				})}

				<div className="add-sub-btn" onClick={this.handleAddCheckItemForm}>
					<div className="add-sub-btn-text">Add checklist point</div>
				</div>

				<div className="form-action-btn">
					<div className="action left-ac">
						<LoadingButton className="btn primary-btn"
							isLoading={submitting}
							title={isAdd ? "Save Checklist" : "Update Checklist"}
							onClick={this.submitCheckConfig} />
					</div>
					<div className="action right-ac">
						<LoadingButton className="btn secondary-btn" 
							title="Cancel"
							onClick={closeModel} 
						/>
					</div>
				</div>
				{!isAdd &&
					<div className="delete-action" onClick={this.handleDelete}>
						{deleting ? "Deleting..." : "Delete Checklist"}
					</div>
				}
			</div>
		)
	}
}